import { Button, Modal, Pagination, Popconfirm, Input, notification, Table, Tooltip, Switch, Select } from 'antd';
import React, { Fragment, useEffect, useState, useRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as ExtensionFieldConfig from '../../configure/extensionFieldConfig';
import * as Actions from '../../libs/actions';
import Update from './update';
import * as CoporationServices from "./CorporationServices";
import * as ExtensionFieldService from '../../services/extensionFieldService';
import { FEATURE } from '../../configureHIS/common/constants';
import { FeatureDeactive } from '../../configure/featureConfig';
import HeaderModal from '../../configureHIS/common/headerModal'

// function Corporation() {
const Corporation = forwardRef((props, ref) => {
    const _hiddenLink = useRef();
    const [dataSource, setDataSource] = useState([]);
    const [detail, setDetail] = useState({});
    const [modalCreate, setModalCreate] = useState(false);
    const [edit, setEdit] = useState(false);
    const [kwd, setKwd] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [hide, setHide] = useState(false);
    const [status, setStatus] = useState('');
    const {Option} = Select;
    const [extensionFields, setExtensionFields] = useState([]);
    const [extraColumns, setExtraColumns] = useState([]);

    const columns = [
        {
            title: 'Mã',
            dataIndex: 'ma',
            key: 'ma',
            width: 90
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: 220
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'diachi',
            key: 'diachi',
            width: 250,
            render: (value) => {
                return (
                    <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: `${value}` }}>
                    </div>
                )
            }
        },
        {
            title: 'Người đại diện',
            dataIndex: 'daidien',
            key: 'daidien',
            width: 200
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 160
        },
        {
            title: 'Tài khoản ngân hàng',
            dataIndex: 'taikhoan',
            key: 'taikhoan',
            width: 160
        },
        // {
        //     title: 'Tài khoản xem KQ',
        //     dataIndex: 'username',
        //     key: 'username',
        //     width: 160
        // },
        // {
        //     title: 'Mật khẩu xem KQ',
        //     dataIndex: 'password',
        //     key: 'password',
        //     width: 160
        // },
        {
            title: 'Sale',
            dataIndex: 'saleName',
            key: 'sale',
            width: 160
        },
        {
            title: 'Người giới thiệu',
            dataIndex: 'tennguoigioithieu',
            key: 'nguoigioithieu',
            width: 160
        },
        ...extraColumns,
        ...FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 ? [{
            title: 'Bảng giá',
            dataIndex: 'banggia',
            key: 'banggia',
            width: 160,
            render:(value) => {
                return (
                    <span>{value === 'default' ? "Mặc định" : value}</span>
                )
            }
        }] : [],
        {
            title: 'Hiển thị',
            align: 'center',
            width: 100,
            render: (row) => {
                return (
                    <Switch loading={hide} checkedChildren="Hiển thị" unCheckedChildren="Ẩn" checked={!row.hienthi}
                        onChange={(e) => { updateHide(row.ma, e); }} />
                )
            }
        },
        {
            title: 'Thao tác',
            width: 150,
            render: (row) => {
                return (
                    <div className='d-flex'>
                        <Tooltip placement="top" title="Sửa">
                            <Button className='btn btn-warning'
                                onClick={() => {
                                    setEdit(true);
                                    setDetail(row);
                                    setModalCreate(true);
                                }}
                            ><i className='fa fa-edit'></i></Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Xóa">
                            <Popconfirm
                                title="Xác nhận xóa?"
                                onConfirm={(e) => {
                                    deleteCoporation(row.ma);
                                }}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Đồng ý" cancelText="Hủy">
                                <Button className='="btn btn-danger ml-2'><i className='fa fa-trash'></i></Button>
                            </Popconfirm>
                        </Tooltip>
                        {/* <Tooltip placement="top" title="Đặt lại mật khẩu">
                            <Popconfirm
                                title="Xác nhận đặt lại mật khẩu?"
                                onConfirm={(e) => {
                                    ResetPWCoporation(row.ma);
                                }}
                                onCancel={(e) => e.stopPropagation()}
                                okText="Đồng ý" cancelText="Hủy">
                                <Button className='="btn btn-color ml-2'><i className='fa fa-sync-alt'></i></Button>
                            </Popconfirm>
                        </Tooltip> */}
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        getExtensionFields(props.activeTab);
    }, []);

    useEffect(() => {
        getList()
    }, [currentPage, pageSize, status]);

    const getList = (page = currentPage, size = pageSize) => {
        CoporationServices.GetAllCoporation(kwd, status, page, size).then(res => {
            if (res.isSuccess) {
                setDataSource(res.data);
                setTotalRecords(res.totalRecords);
            }
        }).catch(() => { })
    }

    const ResetPWCoporation = (ma) => {
        Actions.setLoading(true);
        CoporationServices.ResetPWCoporation(ma).then(result => {
            if (result.isSuccess) {
                Actions.openMessageDialog("Thông báo", "Thao tác thành công. Mật khẩu mới là: " + result.returnObject.password);
            } else {
                result.err.msgString ?
                    notification.warning({ message: result.err.msgString })
                    : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const deleteCoporation = (ma) => {
        Actions.setLoading(true);
        CoporationServices.DeleteCoporation(ma).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công' })
                getList();
            } else {
                result.err.msgString ?
                    notification.warning({ message: result.err.msgString })
                    : notification.warning({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" })
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    const updateHide = (ma, check) =>{
        CoporationServices.HideCoporation(ma, !check).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Cập nhật thành công!' })
                getList();
            }
            else {
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
        }).catch(err => {
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }

    const onExportExcelClinkHandle = () => {
    Actions.setLoading(true);
    CoporationServices.CoporationListExport().then(result => {
        result.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            _hiddenLink.current.href = url;
            _hiddenLink.current.download = "DanhSachNguonKhachHang_"+moment(new Date()).format('DDMMYY');
            _hiddenLink.current.click();
        })
        }).catch(err => {
            Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            if (err.error && err.error.messageText)
                notification.success({ message: err.error.messageText });
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const getExtensionFields = (menuKey) => {
        let data = {menuKey: [menuKey]};
        ExtensionFieldService.getList(data).then(res => {
            if (res.isSuccess) {
                setExtensionFields(res.data);
                let dt = ExtensionFieldConfig.GENERATE_EXTRAFIELD_COLUMNS(res.data,'extraFieldsDic');
                setExtraColumns(dt);
            }
        }).catch(() => { })
    }
    return (
        <Fragment>
            <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a> 
            <div className={'containerChilderWrapper'}>
                <HeaderModal title={'Quản lý nguồn khách hàng'} />
                <div className="row mb-2">
                    <div className="col-md-5 col-sm-6">
                        <label>Trạng thái</label>
                        <Select className="w-100"
                            value={status}
                            onChange={e => { setStatus(e) }}
                        >
                            <Option key={0} value=''>Tất cả</Option>
                            <Option key={1} value='False'>Hiển thị</Option>
                            <Option key={2} value='True'>Đã ẩn</Option>
                        </Select>
                    </div>
                    <div className="col-md-7 col-sm-6">
                        <label>Từ khóa</label>
                        <Input
                            value={kwd} placeholder="Nhập tên hoặc mã hoặc số điện thoại sau đó bấm Enter để tìm kiếm"
                            onChange={(e) => setKwd(e.target.value)}
                            onPressEnter={() => {currentPage !== 1 ? setCurrentPage(1) : getList(1);}}
                            suffix={<span onClick={() => { currentPage !== 1 ? setCurrentPage(1) : getList(1); }}><i className='fas fa-search' /></span>}>
                        </Input>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn btn-primary mr-1" onClick={() => { currentPage !== 1 ? setCurrentPage(1) : getList(1); }}>
                            <i className="fa fa-search mr-1"></i> Tìm kiếm
                        </button>
                        <button className="btn btn-secondary" onClick={() => { setKwd('') }}>
                            <i className="fa fa-eraser mr-1"></i> Xóa dữ liệu nhập
                        </button>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12 d-flex justify-content-between">
                        <button className="btn btn-color" onClick={() => {setEdit(false); setModalCreate(true);}}><i className="fa fa-plus mr-2"></i> Tạo mới</button>
                        <button className="btn btn-color" onClick={() => { onExportExcelClinkHandle() }}><i className="fas fa-file-excel mr-2"></i> Xuất excel</button>
                    </div>
                </div>
                <div className='mt-2' style={{width:'100%', height:'calc(100% - 245px)'}}>
                    <Table dataSource={dataSource} scroll={{ y: 0 }}
                        pagination={false}
                        columns={columns}
                        rowKey={(e, index) => `tbl-copr-${e.ma}`}
                    />
                </div>
                <Pagination
                    style={{ textAlignLast: 'center', marginTop: 10 }}
                    total={totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    current={currentPage}
                    onChange={(page, size) => {
                        setCurrentPage(page);
                        setPageSize(size);
                    }}
                    onShowSizeChange={(page, size) => {
                        setCurrentPage(1);
                        setPageSize(size);
                    }}
                    showSizeChanger={true}
                />
            </div>

            {modalCreate &&
                <Modal
                    visible={modalCreate}
                    onCancel={() => {
                        setEdit(false);
                        setModalCreate(false);
                        setDetail({});
                    }}
                    width={1000}
                >
                    <Update edit={edit}
                        detail={detail}
                        extensionFields={extensionFields}
                        onSuccess={() => {
                            getList();
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                        }}
                        onClose={() => {
                            getList();
                            setEdit(false);
                            setModalCreate(false);
                            setDetail({});
                        }} />
                </Modal>
            }
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        tabs: state.global.tabs,
        activeTab: state.global.activeTab,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Corporation)
// export default Corporation;