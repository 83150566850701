import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Select, Steps } from 'antd';
import ReactJson from 'react-json-view';
import * as SystemConfig from '../../configure/systemConfig';
/**
 Ngoài 3 cái props này:
 * props.detailScreenMode: [detail | create | update] để biết screen dành để làm gì
 * props.onCancel(true): hàm đóng dialog trả ra component cha
 * props.isDialog: hiện screen dạn dialog hay page thường
 Thì thêm các props này:
 * props.createMode: createCardModeEnum để biết gọi screen create này từ quy trình tạo account hay từ list page
 * props.cardAccountSelected: truyền từ screen tạo tài khoản vào 
 */
export default forwardRef((props, ref) => {
  const [data, setData] = useState(props.data);
  const [requestData, setRequestData] = useState();
  const [responseData, setResponseData] = useState();
  
  useEffect(() => {
    try {
      let t = JSON.parse(props.data.requestData);
      setRequestData(t);
    } catch (ex) {
      setRequestData(props.data.requestData);
    }
    try {
      let t = JSON.parse(props.data.responseData);
      setResponseData(t);
    } catch (ex) {
      setResponseData(props.data.responseData);
    }
  }, [props.data])
  
  return (
    <React.Fragment>
      {/* <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a> */}
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Chi tiết log</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row mt-2">
                <div className="col-md-4">
                    <label className='mita-title'>Controller</label>
                </div>
                <div className="col-md-8">
                    <label className='mita-value'>{data.controllerName}</label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                    <label className='mita-title'>Action</label>
                </div>
                <div className="col-md-8">
                    <label className='mita-value'>{data.actionName}</label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                    <label className='mita-title'>Method</label>
                </div>
                <div className="col-md-8">
                    <label className='mita-value'>{data.httpMethod}</label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                    <label className='mita-title'>Thời gian gọi</label>
                </div>
                <div className="col-md-8">
                    <label className='mita-value'>{moment(data.executionTimeStart).format(SystemConfig.FORMAT_DATE_TIME_SECOND)}</label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                    <label className='mita-title'>Thời gian thực thi</label>
                </div>
                <div className="col-md-8">
                    <label className='mita-value'>{data.executionDuration}</label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                    <label className='mita-title'>Người gọi</label>
                </div>
                <div className="col-md-8">
                    <label className='mita-value'>{data.userFullName}</label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                    <label className='mita-title'>Tài khoản</label>
                </div>
                <div className="col-md-8">
                    <label className='mita-value'>{data.userName}</label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className='mita-title font-weight-bold'>PARAM</label>
                  </div>
                  <div className="">
                    {data.parameters}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className='mita-title font-weight-bold'>REQUEST DATA</label>
                  </div>
                  <div className="">
                    {requestData && typeof(requestData) == 'object' ? <ReactJson src={requestData} /> : requestData}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12 d-flex flex-column">
                  <div>
                    <label className='mita-title font-weight-bold'>RESPONSE DATA</label>
                  </div>
                  <div className="">
                    {responseData && typeof(responseData) == 'object' ? <ReactJson src={responseData} /> : responseData}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
                >
                  <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})