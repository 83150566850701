import { DatePicker, Form, Input, notification, Pagination, Select, Table, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as KhoService from '../../../services/KhoService';
import * as StatisticService from '../../../services/statisticService';
import * as Actions from '../../../libs/actions';
import './medicine.css';
import { FORMAT_DATE, FORMAT_PREFIX_EXCEL } from '../../../configure/systemConfig';
import HeaderModal from '../../../configureHIS/common/headerModal';

const { Option } = Select;
const initialState = {
    mabd: 0,
    tenthuoc: '',
    maKho: 0,
    ngay: moment(),
    totalRecords: 0,
}
let task = null;
export default class Medicine extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstMedicine: [],
            lstKho: [],
            currentPage: 1,
            pageSize: 10,
            pinDays: 60,
            listSoNgayHetHan: [],
        };
    }
    componentDidMount() {
        this.loadExprired();
        this.loadAllKho();
        this.loadConfig();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }
    loadAllKho() {
        KhoService.GetListKho('all').then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data,
                });
            }
            else {
                this.setState({
                    lstKho: []
                });
            }
        }).catch(err => {
        })
    }
    loadExprired(page = this.state.currentPage, size = this.state.pageSize) {
        if (this.state.ngay === null || this.state.ngay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian!' });
            return;
        }
        KhoService.DanhSachThuocHetHan(this.state.ngay.format('YYYY-MM-DD'), this.state.maKho, this.state.tenthuoc, page, size, this.props.isExpired == true ? 0 : this.state.pinDays).then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        lstMedicine: result.data,
                        totalRecords: result.totalRecords,
                    })
                }
                else {
                    this.setState({
                        lstMedicine: [],
                        totalRecords: 0,
                    })
                }
            }
            else {
                this.setState({
                    lstMedicine: [],
                    totalRecords: 0,
                })
            }
            this.setState({ currentPage: page })
        }).catch(err => {
        })
    }
    disabledDateHH(current) {
        return current && current < moment().startOf('day');
    }
    reset() {
        this.setState({
            ...initialState,
            lstKho: [],
            lstMedicine: []
        })

    }

    loadConfig() {
        KhoService.ConfigSoNgayHetHan().then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.setState({
                        listSoNgayHetHan: result.data,
                    })
                }
                else {
                    this.setState({
                        listSoNgayHetHan: [],
                    })
                }
            }
            else {
                this.setState({
                    listSoNgayHetHan: [],
                })
            }
        }).catch(() => { })
    }

    ExportExprired() {
        Actions.setLoading(true);
        StatisticService.ExportExprired(this.state.ngay.format('YYYY-MM-DD'), this.state.maKho, this.state.tenthuoc, this.props.isExpired == true ? 0 : this.state.pinDays, this.props.isExpired)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    let fileName = `${moment().format(FORMAT_PREFIX_EXCEL)}_BCKho_HetHan`; 
                    this._hiddenLink.download = fileName;
                    this._hiddenLink.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }

    render() {

        const columns = [
            {
                title: 'Mã thuốc-vật tư',
                dataIndex: 'mathuoc',
                key: 'mathuoc',
                align: 'center',
                width: 120,
            },
            {
                title: 'Tên thuốc-vật tư',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
                width: 200

            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',
                width: 100
            },
            {
                title: 'Kho',
                dataIndex: 'tenkho',
                key: 'tenkho',
                align: 'left',
                width: 100
            },
            {
                title: 'Lô',
                dataIndex: 'losx',
                key: 'losx',
                align: 'left',
                width: 90
            },
            {
                title: 'SL nhập',
                dataIndex: 'sluongnhap',
                key: 'sluongnhap',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'SL xuất',
                dataIndex: 'sluongxuat',
                key: 'sluongxuat',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Tồn kho',
                dataIndex: 'tonkho',
                key: 'tonkho',
                align: 'right',
                width: 80,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Ngày hết hạn',
                dataIndex: 'ngayhh',
                key: 'ngayhh',
                align: 'left',
                width: 100,
                render: (value) => {
                    return <span>{moment(value).year() === 1 ? '' : moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Số ngày còn hạn',
                dataIndex: 'songayton',
                key: 'songayton',
                align: 'left',
                width: 90,
                render: (value, row) => {
                    if (value <= 0) {
                        if (moment(row.ngayhh).year() > 1)
                            return <span style={{ color: 'red' }}>{'HẾT HẠN'}</span>;
                        else
                            return <span></span>;

                    }
                    return <span>{value} ngày</span>;
                }
            },
        ]
        let dataSource1 = this.state.lstMedicine.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c} style={{ height: 'auto' }}>
                    <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                    {this.props.isExpired ? <HeaderModal title="Danh sách thuốc - vật tư hết hạn" />
                        :
                        <HeaderModal title="Danh sách thuốc - vật tư sắp hết hạn"
                        onClose={() => { this.props.onCancel() }} />}
                    <div className="row">
                        <div className="container-fluid row equal pr-0 pl-2" style={{ padding: '5px', display: 'flex' }}>
                            <div className="col-md-3 my-1">
                                <Input
                                    value={this.state.tenthuoc}
                                    onChange={(e) => {
                                        this.setState({ tenthuoc: e.target.value });
                                        task = setTimeout(() => this.loadExprired(1, this.state.pageSize), 800);
                                    }}
                                    placeholder="Nhập mã hoặc tên thuốc, lô nhập"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.loadExprired(1, this.state.pageSize);
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-md-3 my-1">
                                <Select defaultValue={0}
                                    onChange={(value) => { this.setState({ maKho: value }, () => this.loadExprired(1, this.state.pageSize)) }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}
                                >
                                    <Option key={null} value={0}>Tất cả kho</Option>
                                    {this.state.lstKho.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-6 my-1 d-flex justify-content-end">
                                {!this.props.isExpired &&
                                    <React.Fragment>
                                        <DatePicker locale={viVN} format={"DD-MM-YYYY"} allowClear={false}
                                            style={{ width: 200 }} className='mx-1'
                                            defaultValue={moment()} disabledDate={this.disabledDateHH}
                                            placeholder="DD-MM-YYYY"
                                            onChange={(value) => (this.setState({ ngay: value }))} />
                                        <Tooltip className='mx-1' title={`Thuốc - vật tư hết hạn trong ${this.state.pinDays} ngày kể từ ngày ${moment(this.state.ngay).format(FORMAT_DATE)}`}>
                                            <Select value={this.state.pinDays} onChange={(e) => this.setState({ pinDays: e }, () => this.loadExprired())}>
                                                {
                                                    this.state.listSoNgayHetHan && this.state.listSoNgayHetHan.map((item) => {
                                                        return <Option value={item.value} key={item.value}>{item.label}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                                <button className="btn btn-color ml-1" onClick={() => this.loadExprired(1, this.state.pageSize)}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                
                            </div>
                            <div className="col-12 my-1 text-right">
                                <button className="btn btn-color" onClick={() => this.ExportExprired(false)}><i style={{ marginRight: 5 }} className="fa fa-file-excel" />Xuất Excel</button>
                            </div>
                        </div>
                    </div>
                </Form >
                <div className='table-responsive mt-2' style={{ width:'100%', height:'calc(100% - 200px)' }}>
                    <Table dataSource={dataSource1}
                        scroll={{ x: 'max-content', y: 0 }}
                        pagination={false}
                        columns={columns} rowKey={(e, index) => index} />
                </div>
                <Pagination
                    current={this.state.currentPage}
                    style={{ textAlignLast: "center", marginTop: "10px" }}
                    total={this.state.totalRecords}
                    showTotal={total => `Tổng: ${total}`}
                    onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadExprired(page, size); }}
                    onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, pageSize: size }); this.loadExprired(1, size) }}
                    showSizeChanger={true}
                />
                {!this.props.isExpired &&
                    <Fragment>
                        <div className='custom-hr' />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-danger" style={{ marginLeft: 5 }}
                                onClick={() => { this.props.onCancel() }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} /> Đóng</button>
                        </div>
                    </Fragment>
                }
            </React.Fragment>
        )
    }
}