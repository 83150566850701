import { Button, notification, Drawer } from 'antd';
import React ,{ Fragment, useState, useEffect, useCallback } from "react";
import moment from 'moment';
import { FORMAT_TIME_DATE } from '../../../configure/systemConfig';
import * as PatientService from '../../../services/patientService';
/** Component này dùng để hiển thị thành tiền, thanh toán của hóa đơn/bill thanh toán
 * Hướng dẫn sử dụng component
 * Các component đang sử dụng:
    - src\screens\invoice\trahoadon\preview.js
 ** Các props nhận vào:
* @param {string} props.managementId: mã quản lý.
* @param {string} props.patientId: mã bệnh nhân.
 */
export default (props) => {
    //biến cục bộ
    const [managementId, setManagementId] = useState(props.managementId);
    const [patientId, setPatientId] = useState(props.patientId);
    const [masterInfo, setMasterInfo] = useState({});

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    useEffect(() => {
        if (managementId != props.managementId) setManagementId(props.managementId);
        if (patientId != props.patientId) setPatientId(props.patientId);
    }, [props])   

    useEffect(() => {
        if(managementId && managementId !== '' && managementId != null)
            GetPatientInfo();
    }, [managementId])

    const GetPatientInfo = () => {
        PatientService.GetRegisteredPatientByMaql(managementId).then(result => {
            if (result.isSuccess) {
                setMasterInfo(result.item);
                forceUpdate();
            } else {
                notification.error({ message: result.err.msgString });
            }
        }).catch(err => {
            if (err.err && err.err.msgString)
                notification.error({ message: err.err.msgString });
            else notification.error({ message: err });
        })
    }

    return (
        <Fragment>
            <div className='col-12 my-2'>
                <div className="row">
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Mã đăng ký</label>
                        <label className="mita-value">{managementId}</label>
                    </div>
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Ngày đăng ký</label>
                        <label className="mita-value">{masterInfo?.dateIn && moment(masterInfo?.dateIn).format(FORMAT_TIME_DATE)}</label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6 d-flex flex-column">
                        <label className="mita-title">Nguồn khách hàng</label>
                        <label className="mita-value">{masterInfo?.nguonkhachhang}</label>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
