import React from "react";
import { connect } from 'react-redux';
import jwt_decode from "jwt-decode";
import { Upload, Button} from 'antd';
import {  subCDHA } from '../../../commons/menuData';
import * as Actions from '../../../libs/actions';
import { store } from '../../../stores';
import * as UserService from '../../../services/usersService';
import * as PhongKhamService from '../../../services/PhongKhamService';
import * as MenuService from '../../../services/MenuService';
import * as FeatureService from '../../../services/FeatureService';
import * as MenuDefaultConfig from '../../../configure/menuDefaultConfig';
import * as SuperAdminConfig from '../../../configure/superAdminConfig';
import * as FeatureConfig from '../../../configure/featureConfig';
import * as ConfigService from '../../../services/configService';
import * as UrlConfig from '../../../configure/urlConfig';
import KhamBenh from '../../../screens/exam/examination';
import PascResult from '../../../screens/pasc/result';
import VideoList from '../../../screens/pasc/videolist';
import './main.css';
import * as ScreenComponentsModule from '../../../commons/screenComponents';

var groupid = '';
var ScreenComponents = ScreenComponentsModule.screenComponents;
var defaultActives = [];
var isSuperAdmin = localStorage.getItem('userID') == 'admin';
var menuDefaultLoad = isSuperAdmin ? MenuDefaultConfig.List() : [];
// var autoActiveNode = null; 
class NewTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // ScreenComponents: ScreenComponentsModule.screenComponents,
            listData: [],
            openNodes: {},
            location: '',
            lstKey: [],
            lstTabs: [],
            activeTab: '',
            permissions: '',
            permissionsarrays: [],
        }
    }
    
    groupArrayOfObjects(list, key) {
        return list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    includeMenu(menuName, tempList) {
        var found = tempList.find(x => x.key === menuName);
        if (found !== null && found !== undefined)
            return true;
        return false;
    }

    importJson(file) {
        // Actions.setLoading(true);
        // let file = e.file;
        if (!file) return;
        let formData = new FormData();
        formData.append('file', file);
        MenuService.importByJson(formData).then(result => {
            if (result.isSuccess) {
            window.location.reload();

            // notiRef && notiRef.current.Close();
            }
            else {
            // notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
            }
        }).catch(err => {
            // if (err.error && err.error.messageText)
            // notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
            // else notiRef.current.Show(err, NotifyStatus.Warning);
        }).finally(() => {
            // Actions.setLoading(false);
            // forceUpdate();
        })
    }

    renderBtnImportMenu(){
        return (
            <Upload
              hidden
              name='file'
              accept=".json"
              showUploadList={false}
              customRequest={({ file, onSuccess }) => {
                this.importJson(file);
                onSuccess("ok")
              }}
            >
              <Button>Import Menu</Button>
            </Upload>
        )
    }

    renderPhongKham(tempList = this.state.listData) {
        ScreenComponents['KhamBenhContent'] = KhamBenh;
        ScreenComponents['PascResultContent'] = PascResult;
        ScreenComponents['VideoListContent'] = VideoList;
        if (this.includeMenu("Khám Bệnh", tempList)) tempList = tempList.filter(x => x.key !== "Khám Bệnh");
        if (this.includeMenu("cdha", tempList)) tempList = tempList.filter(x => x.key !== "cdha");
        PhongKhamService.GetActiveExaminationRoom().then(result => {
            if (result.isSuccess) {
                if (result.item.examRooms.length > 0) {
                    let rooms = this.groupArrayOfObjects(result.item.examRooms, 'tenkhoa');
                    let objectRoom = {
                        menuKey: 'Khám Bệnh',
                        label: UrlConfig.KHAMBENH_NAME,
                        nodes: [
                        ],
                        code: 'khambenh',
                        icon: 'fa fa-user-md'
                    };
                    let currentIndex = 0;

                    for (let key in rooms) {
                        objectRoom.nodes.push({
                            menuKey: key,
                            label: key,
                            nodes: [
                            ],
                            code: key,
                        })

                        for (let record of rooms[key]) {
                            objectRoom.nodes[currentIndex].nodes.push({
                                menuKey: record.tenkp,
                                label: record.tenkp,
                                code: record.tenkp,
                                content: 'KhamBenh',
                                props: {
                                    clinicID: record.makhoa,
                                    roomCode: record.makp,
                                    roomName: record.tenkp,
                                    clinicName : key,
                                }
                            })
                        }
                        currentIndex++;
                    }
                    tempList.splice(2, 0, objectRoom);
                }
                // TẠM KHÓA
                if (result.item.cdhaRooms.length > 0) {
                    // let temp = subCDHA;
                    let cdhaRoom = {
                        menuKey: 'cdha',
                        label: UrlConfig.CDHA_NAME,
                        nodes: [
                        ],
                        code: 'ddc',
                        icon: 'fas fa-images'
                    };
                    for(let record of result.item.cdhaRooms) {
                        // if (this.includeMenu(record.name, temp.nodes)) temp.nodes = temp.nodes.filter(x => x.key !== record.name);
                        let roomchda = {
                            menuKey: record.name,
                            label: record.name,
                            code: record.name,
                            content: 'PascResult',
                            props:  {idloai : record.typeid , title:record.name}
                        }
                        cdhaRoom.nodes.splice(0, 0, roomchda);
                    }
                    let videochda = {
                        menuKey: "listvideochda",
                        label: "Danh sách video",
                        code: 'listvideochda',
                        content: 'VideoList'
                    }
                    cdhaRoom.nodes.splice(cdhaRoom.nodes.length, 0, videochda);
                    tempList.splice(3, 0, cdhaRoom);
                }
            }

            this.setState({ listData: tempList })
        }).catch(err => {
            this.setState({ listData: tempList })
        })
        this.findDup(tempList);
        groupid = localStorage.getItem('groupid') || '';
    }

    componentWillMount() {
        let storedToken = localStorage.getItem('token');
        if(storedToken) {
            let token = jwt_decode(storedToken)
            localStorage.setItem('id', token.nameid);
        }
        MenuService.getMenu().then(result => {
            if (result.isSuccess) {
                // let listMenu = [...result.data];//phải clone như này vì result.data ban đầu không có "Phòng khám" & "CĐHA"
                Actions.setMenuData(result.data);
                this.setState({listData: result.data});
                if ((!result.data || result.data.length == 0) && isSuperAdmin)
                    return;
                this.renderPhongKham(result.data);
            }
        }).catch(err => {
        }).finally(() => {
        });
    }
    
    checkNode(node) {
        let temp = {
            title: node.label,
            key: node.code,
            children: [
            ]
        }
        if (node.nodes !== undefined)
            temp.children = node.nodes.map((data, index) => {
                return this.checkNode(data)
            })
        return temp
    }
    findDup(tempList) {
        let listPermission = [];
        for (let i = 0; i < tempList.length; i++) {
            if (tempList[i].code !== 'controller')
                listPermission.push(this.checkNode(tempList[i]))
        }
        const recursiveSearch = (obj, map = {}, res = []) => {
            Object.keys(obj).forEach(key => {
                if (key !== 'title') {
                    if (typeof obj[key] === "object") {
                        return recursiveSearch(obj[key], map, res);
                    };
                    map[obj[key]] = (map[obj[key]] || 0) + 1;
                    if (map[obj[key]] === 2) {
                        res.push(obj['title']);
                    }
                }
            });
            return res;
        };
        const list = listPermission && listPermission.length > 0 && recursiveSearch(listPermission);
        if (list.length > 0)
            localStorage.getItem('userID') == 'admin' ?
                Actions.openMessageDialog("U là trời! Thật là ngu ngok!", "menuData.js đang bị trùng mã: " + list.join(", ") + ".")
                : Actions.openMessageDialog("Cảnh báo", "Menu đang bị trùng mã: " + list.join(", ") + ". Vui lòng báo đơn vị cung cấp phần mềm để được chỉnh sửa.");
    }
    componentDidMount() {
        UserService.getPermission().then((res) => {
            if (res.isSuccess === true) {
                let array = res.item.split(",");
                if (array.indexOf('superadmin') > -1) SuperAdminConfig.Set();
                else SuperAdminConfig.Remove();
                this.setState({ permissions: res.item, permissionsarrays: array });
                if (res.err.msgString) {
                    Actions.openMessageDialog("Thông báo", res.err.msgString);
                }
            }
        });
        this.getFeature();
        this.getUserScreenAuthorization();
        //Khi reload page sẽ mở ngay tới menu cần mở -> test cho nhanh (cần open comment những line có autoActiveNode)
        setTimeout(() => {
            defaultActives.forEach(x => {
                this.handleToogle(x.node, x.parentKey);
            });
        //   this.handleToogle(autoActiveNode, "quan-ly-nguoidung***cau-hinh-he-thong***danh-muc-phan-he");
        },1500);
    }

    isOpennode(key) {
        let openNodes = this.state.openNodes
        if (openNodes[key] && (openNodes[key] === true)) {
            return true;
        }
        return false
    }

    getClassOpen(node) {
        if (node.nodes === undefined || node.nodes.length < 1) {
            return (<div></div>)
        }
        let openNodes = this.state.openNodes
        if (openNodes[node.key] !== undefined && (openNodes[node.key] === true)) {
            return (
                <i key={`fa fa-caret-down` + node.key} style={{ marginLeft: 6, fontSize: 12 }} className={`fa fa-caret-down`}></i>
            )
        } else {
            return (
                <i key={`fa fa-caret-right` + node.key} style={{ marginLeft: 6, fontSize: 12 }} className={`fa fa-caret-right`}></i>
            )
        }
    }
    CloseSideBarMobile() {
        let sideBar = document.getElementById('left-sidebar')
        let com = window.getComputedStyle(sideBar);
        let widthSideBar = com.left.replace('px', '')
        if (Number(widthSideBar) >= 0) {
            document.getElementById('sidebartoggler').click();
        }
        let sideWidth = window.getComputedStyle(sideBar).width;
        let tabHeader = document.querySelector('#tab-header-menu>.ant-tabs-nav')
        if (window.innerWidth > 768 && window.innerWidth < 993) {
            sideWidth === '220px' ? tabHeader.style.left = '220px' : tabHeader.style.left = '75px';
        }
        else {
            tabHeader.style.left = 'unset';
        }
    }
    handleToogle(node, parentKey = "") {
        let itemClick = parentKey.split('***');
        let lst = this.state.lstKey;
        const num = lst.findIndex(x => x === node.key);
        if (num < 0) {
            lst = itemClick;
        }
        else {
            lst = lst.filter(x => x !== node.key)
        }
        this.setState({ lstKey: lst }, () => {
            setTimeout(() => {
                if (node.key === "Logout") {
                    Actions.logout();
                    return;
                }
                let width = window.innerWidth;
                //let lsttab = this.state.lstTabs;
                // if ((width < 768 && lsttab.findIndex(x => x === node.key) > 0)) {
                //     this.CloseSideBarMobile();
                // }
                if (node.nodes !== undefined && node.nodes.length > 0) {
                    let toogleValue = true;
                    let openNodes = this.state.openNodes
                    if (openNodes[node.key] && (openNodes[node.key] === true)) {
                        toogleValue = false;
                    }
                    openNodes[node.key] = toogleValue;
                    this.setState({ openNodes });
                } else {
                    if (node.content) {
                        let lsttab = this.state.lstTabs;
                        let { tabs, activeTab } = this.props
                        let isAddTabs = true;
                        if (tabs === undefined) tabs = [];

                        if (activeTab === undefined) activeTab = 0;
                        for (let i = 0; i < tabs.length; i++) {
                            if (node.key === tabs[i].key) {
                                isAddTabs = false;
                                activeTab = tabs[i].key;
                                break;
                            }
                        }

                        if (!isAddTabs) {
                            this.CloseSideBarMobile();
                            this.setState({ activeTab: activeTab })
                            store.dispatch({ type: 'SET_TAB', tabs: tabs, activeTab: activeTab });
                        } else {
                            tabs.push(node);
                            activeTab = node.key;
                            lsttab.push(node.key);
                            this.setState({ lstTabs: lsttab }, () => {
                                this.CloseSideBarMobile();
                            })
                            this.setState({ activeTab: activeTab })
                            store.dispatch({ type: 'SET_TAB', tabs: tabs, activeTab: activeTab });
                        }
                        if (width < 768 && lsttab.findIndex(x => x === node.key) > 0) {
                            this.CloseSideBarMobile();
                        }
                    }
                }
            }, 100);

        })
    }

    checkActive(node) {
        let lstKey = this.state.lstKey;
        //let lstTab = this.state.lstTabs;
        //const numTab = lstTab.findIndex(x => x === node.key);
        const numKey = lstKey.findIndex(x => x === node.key);
        if (numKey < 0) {
            if (this.props.activeTab === node.key)
                return true;
            return false;
        }
        else {
            return true;
        }
    }

    renderNode(nodeMenu, lvl, index, parentkey = "") {
        let node = {
            key: nodeMenu.menuKey,
            label: nodeMenu.label,
            content: (nodeMenu.content && nodeMenu.content.length > 0) ? ScreenComponents[nodeMenu.content+'Content'] : undefined,
            code: nodeMenu.code,
            icon: nodeMenu.icon,
            nodes: (nodeMenu.nodes && nodeMenu.nodes.length > 0) ? nodeMenu.nodes : undefined,
            props: nodeMenu.props
        };
        if (menuDefaultLoad.indexOf(nodeMenu.id) > -1)
            defaultActives=[...defaultActives, {node: node, parentKey:parentkey}];
        // if (node.key == 'danh-muc-phan-he') autoActiveNode=node;
        return (
            <li key={node.key + this.isOpennode(node.key)}>
                <a
                    className={`${(node.nodes === undefined || node.nodes.length < 1) ? '' : 'has-arrow'} waves-effect waves-dark ${this.checkActive(node) === true ? 'active font-weight-bold' : 'no-active'}`}
                    href="javascript:void(0)"
                    aria-expanded="false"
                    onClick={() => { this.handleToogle(node, `${parentkey}`); }}
                >
                    {node.icon &&
                        <i style={{ marginRight: 5 }} className={node.icon}></i>
                    }
                    {lvl === 0 ?
                        <span className="hide-menu">{node.label}</span>
                        :
                        node.label
                    }</a>
                {node.nodes !== undefined &&
                    <ul key={node.key + 'sub'} aria-expanded="false" className={`collapse ${this.checkActive(node) === true ? 'in' : ''}`}>
                        {node.nodes !== undefined && node.nodes.map((data, index) => {

                            if (this.IshasPermission(data.code, data.menuKey)) {
                                return this.renderNode(data, lvl + 1, index, `${parentkey}***${data.menuKey}`)
                            }
                            else {
                                return null
                            }
                        }
                        )}
                    </ul>
                }
            </li>

        )
    }

    IshasPermission(code, key) {

        if (key === 'Logout') return true;
        if (this.state.permissions.includes('@@@')) {
            return true
        }

        for (let item of this.state.permissionsarrays) {

            if (item.includes(code) || item === code) {
                return true;
            }
        }
        return false

    }

    getFeature() {
        FeatureService.cGetList().then(result => {
            if (result.isSuccess) {
                FeatureConfig.Set(result.data);
            }
        }).catch(err => {
        }).finally(() => {
        });
    }
    
    getUserScreenAuthorization() {
        let feature = "";
        ConfigService.getUserScreenAuthorization(feature).then(res => {
            if (res.isSuccess) {
                Actions.setUserScreenAuthorization(res.data);
            } else { }
        }).catch((err) => {
        }).finally(() => { })
    }

    render() {
        let { permissions, listData } = this.state
        return (
            <React.Fragment>
                {permissions !== '' &&
                    <ul id="sidebarnav">
                        {this.state.listData && this.state.listData.map((data, index) => {
                            if (this.IshasPermission(data.code, data.menuKey)) {
                                return this.renderNode(data, 0, index, data.menuKey)
                            }
                        }
                        )}
                    </ul>
                }
                {
                    (this.state.listData.length==0 && isSuperAdmin) && this.renderBtnImportMenu()
                }
            </React.Fragment>
        );
    }

}
const mapStateToProps = (state) => ({
    tabs: state.global.tabs,
    activeTab: state.global.activeTab,
});
export default connect(
    mapStateToProps,
)(NewTree);