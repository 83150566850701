import moment from "moment";
import React, { useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Collapse } from 'antd';
import * as SystemConfig from '../../../configure/systemConfig';
import * as Actions from '../../../libs/actions';
import * as CardAccountService from '../../../services/CardAccountService';
import Notify, { NotifyStatus } from '../../../components/notify';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import CardHistoryList from './list';

const { Panel } = Collapse;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const detailRef = useRef();
  const _hiddenLink = useRef();
  const firstRender = useRef(true); 
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [cardAccountModel, setCardAccountModel] = useState({});
  //result model
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    firstRender.current = false;
    console.log(props.cardAccountId);
    getCardAccountById(props.cardAccountId);
  }, []);

  const setData = (data) => {
    // setCardTypeId(data.cardTypeId);
    // setCardAccountCode(data.cardAccountCode);
    // setContractNo(data.contractNo);
    // setAccountName(data.accountName);
    // setIdentifierNo(data.identifierNo,);
    // setPhoneNumber(data.phoneNumber);
    // setEmail(data.email);
    // setAddress(data.address);
    // setIsActive(data.isActive);
    // setDateActivated(moment(data.dateActivated));
    // setDateExpired(moment(data.dateExpired));
    // setRemark(data.remark);
    // //extent
    // setCardTypeName(data.cardTypeName);
  }

  const getCardAccountById = (id) => {
    Actions.setLoading(true);
    CardAccountService.getById(id).then(result => {
      if (result.isSuccess) {
        // setData(result.data);
        setCardAccountModel(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
  }
  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Lịch sử áp dụng</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-table overflow-auto">
              <Collapse defaultActiveKey={['2']}
                expandIconPosition="right"
              >
                <Panel header={<b>Thông tin tài khoản</b>} key="1">
                  <div className="row">
                    <div className="col-md-6 d-flex flex-column">
                      <label>Chủ tài khoản</label>
                      <label><b>{cardAccountModel.accountName}</b></label>
                    </div>
                    <div className="col-md-6 d-flex flex-column">
                      <label>Hạn mức</label>
                      <label><b><u className="mr-1">đ</u>{cardAccountModel.debitLimit?.toLocaleString('en-US')}</b></label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex flex-column">
                      <label>Thẻ trả trước</label>
                      <label><b>{cardAccountModel.cardTypeName}</b></label>
                    </div>
                    <div className="col-md-6 d-flex flex-column">
                      <label>Đã sử dụng</label>
                      <label><b><u className="mr-1">đ</u>{cardAccountModel.valueUsed?.toLocaleString('en-US')}</b></label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex flex-column">
                      <label>Hiệu lực</label>
                      <label><b>{moment(cardAccountModel.dateActivated).format(SystemConfig.FORMAT_DATE)} ~ {moment(cardAccountModel.dateExpired).format(SystemConfig.FORMAT_DATE)}</b></label>
                    </div>
                    <div className="col-md-6 d-flex flex-column">
                      <label>Còn lại</label>
                      <label><b><u className="mr-1">đ</u>{(cardAccountModel.debitLimit - cardAccountModel.valueUsed)?.toLocaleString('en-US')}</b></label>
                    </div>
                  </div>
                </Panel>
                <Panel header={<b>Lịch sử giao dịch</b>} key="2">
                  <CardHistoryList
                    isDialog={true}
                    cardAccountId={props.cardAccountId}
                  ></CardHistoryList>
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <button
                  className='btn btn-danger'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})