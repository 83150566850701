import * as ApiCaller from '../libs/httpRequests';
import { isNullOrEmpty } from '../libs/util';
const prefix = 'ConfirmOrder';

export function listOrderXN(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ListOrderXN`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListStatusNhanMau() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListStatusNhanMau`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListTestReceived(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ListTestReceived`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListTestReceivedBySID(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ListTestReceivedBySID`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getTestReceivedByOrderId(orderId) {
    if (isNullOrEmpty(orderId)) orderId = "";
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetTestReceivedByOrderId/${orderId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListConfirmTestReceived(maql, orderId, chinhanh) {
    if (isNullOrEmpty(orderId)) orderId = "";
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListConfirmTestReceived?managermentID=${maql}&orderId=${orderId}&site=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ConfirmTest(maquanly, orderid, chinhanh) {
    return new Promise((resolve, reject) => {
        // return ApiCaller.httpPost(`${prefix}/ConfirmTest?managermentID=${maquanly}&site=${chinhanh}`).then(respone => {
        return ApiCaller.httpPost(`${prefix}/XacNhanDaLayMau?maql=${maquanly}&orderid=${orderid}&siteid=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function createUpdateThoiGianLayMau(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateThoiGianLayMau`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListSidByListManagementId(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListSidByListManagementId`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};