import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Table, Pagination, Select, DatePicker, Spin, Tabs } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../../../configure/systemConfig';
import * as Actions from '../../../../libs/actions';
import * as CoporationServices from '../../../../services/CorporationServices';
import * as ProductServices from '../../../../services/ProductService';
import * as ManageService from '../../../../services/ManageService';
import * as ReportServices from '../../../../services/ReportService';
import Notify, { NotifyStatus } from '../../../../components/notify';
import { KHAMBENH, NHOMCDHA, NHOMPTTT } from '../../../../configureHIS/common/constants';
import SiteFilter from '../../../../configureHIS/common/siteFilter';
import Summary from './summary';
import Detail from './detail';
import PatientDetail from './patientDetail';

export default forwardRef((props, ref) => {
  //#region biến cục bộ
  const { Option } = Select;
  const now = new Date();
  const viewTypeEnum = {
    summary: "summary",
    detail: "detail"
  };
  //#endregion
  const _summaryRef = useRef();
  const _detailRef = useRef();
  const _patientDetailRef = useRef();
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //search/filter
  const [siteIds, setSiteIds] = useState([]);
  const [coporationId, setCoporationId] = useState(null);
  const [accountId, setAccountId] = useState([]);
  const [dateFrom, setDateFrom] = useState(new moment());//(new moment((now).setMonth(now.getMonth() - 1)));
  const [dateTo, setDateTo] = useState(new moment());
  const [selectedNhomShortcutKey, setSelectedNhomShortcutKey] = useState([]);
  const [nhomMas, setNhomMas] = useState([]);
  const [loaiIds, setLoaiIds] = useState([]);
  //other
  const [listNhomShortcut, setListNhomShortcut] = useState([]);
  const [listAccounts, setListAccounts] = useState([]);
  const [listCoporations, setListCoporations] = useState([]);
  const [viewType, setViewType] = useState(viewTypeEnum.summary);
  const [curTab, setCurTab] = useState('summary');
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    loadChartOrList();
  }, [viewType]);
  
  useEffect(() => {
    console.clear();
    getListCoporations();
    getListServiceType();
    getListAccount();
    onClickStatisticHandle();
    firstRender.current = false;
  }, []);

    useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
    setNhomMas([]);
    setLoaiIds([]);
    selectedNhomShortcutKey.map(c => {
      let nhom = listNhomShortcut.filter(x => x.keyValue == c);
      if (nhom.length > 0) {
        let selectedNhomShortcut = nhom[0];
        if (selectedNhomShortcut.loaiId > 0){
          setLoaiIds(u => [...u, selectedNhomShortcut.loaiId]);
        } else {
          setNhomMas(u => [...u, selectedNhomShortcut.nhomMa]);
        }
      }
    })
  },[selectedNhomShortcutKey])
  //#endregion 
  //#region get list data cho bộ lọc
  const getListCoporations = () => {
    CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
      if (result.isSuccess) {
        setListCoporations(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }
  const getListServiceType = () => {
    ProductServices.nhomGetListShortcut().then(result => {
      if (result.isSuccess) {
        let listNhom = result.data.filter(x => x.nhomMa == KHAMBENH || x.nhomMa == NHOMCDHA || x.nhomMa == NHOMPTTT);
        setListNhomShortcut(listNhom);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }
  const getListAccount = (keyword = '') => {
    let filter = {
      "keyword" : keyword
    }
    ManageService.ListAccountForSelect(filter).then(result => {
      if (result.isSuccess) {
        setListAccounts(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }
  //#endregion 
  const loadChartOrList = () => {
    switch (curTab) {
      case 'summary':
        _summaryRef.current && _summaryRef.current.loadData();
        break;
      case 'detail':
        _detailRef.current && _detailRef.current.loadData();
        break;
      case 'patientdetail':
        _patientDetailRef.current && _patientDetailRef.current.loadData();
        break;
      default:
        break;
    }
  } 
  const validate = () => {
    let listErr = [];
    // if (!dateFrom) listErr.push('Phải nhập ngày lập phiếu');
    // if (dateFrom > dateTo) listErr.push('Ngày lập phiếu từ phải nhỏ hơn ngày lập phiếu đến');
    return listErr;
  }
  const onClickStatisticHandle = () => {
    let listErr = validate();
    if (listErr.length > 0) {
      let err = listErr.join('\n');
      notiRef.current.Show(err, NotifyStatus.Error);
      return;
    }
    loadChartOrList();
    notiRef && notiRef.current.Close();
  }

  const clearFilter = () => {
    setAccountId([]);
    setSelectedNhomShortcutKey([]);
    setNhomMas([]);
    setLoaiIds([]);
    setCoporationId(null);
    setDateFrom(new moment());
    setDateTo(new moment());
  }

  const onExportExcelClinkHandle = () => {
    Actions.setLoading(true);
    let filterModel = {
      siteIds: siteIds ?? [],
      accountIds : accountId ?? [],
      coporationIds: coporationId,
      dateFrom: dateFrom ?? '',
      dateTo: dateTo ?? '',
      nhomMas : nhomMas ?? [],
      loaiIds : loaiIds ?? [],
      skipCount: 0,
      maxResultCount: 0,
      IsGetAll : true
    }
    ReportServices.PerformanceServiceListExport(filterModel).then(result => {
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        let title = viewType == viewTypeEnum.summary ? "TongHop" : viewType == viewTypeEnum.detail ? "ChiTiet" : "ChiTietBenhNhan";
        let fileName = `${moment().format(SystemConfig.FORMAT_PREFIX_EXCEL)}_ThongKeThucHien_${title}`; 
        _hiddenLink.current.download = fileName;
        _hiddenLink.current.click();
      })
    }).catch(err => {
      Actions.openMessageDialog("Lỗi", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Actions.setLoading(false);
      forceUpdate();
    })
  }

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Báo cáo thực hiện dịch vụ</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body overflow-auto">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">{/* Chi nhánh */}
                <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
              </div>
              <div className="row mt-2">{/* Nguồn khách hàng | Ngày lập phiếu */}
                  <div className='col-md-6 d-flex flex-column'>{/* Ngày lập phiếu */}
                    <div>
                      <label>Khoảng thời gian</label>
                    </div>
                    <div className="d-flex">
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Từ ngày"}
                        value={dateFrom}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateFrom(e ? moment(e) : null);
                        }}
                      />
                      <div className="m-auto">&#160;-&#160;</div>
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Đến ngày"}
                        value={dateTo}
                        format = { SystemConfig.FORMAT_DATE }
                        disabledDate={(date) => date < moment(dateFrom)}
                        onChange={(e) => { 
                          setDateTo(e ? moment(e) : null);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-6 d-flex flex-column'>{/* Nguồn khách hàng */}
                    <div>
                      <label>Nguồn khách hàng</label>
                    </div>
                    <Select
                      allowClear
                      showSearch
                      className="w-100"
                      placeholder="Chọn nguồn khách hàng"
                      maxTagCount= 'responsive'
                      value={ coporationId }
                      filterOption={(input, option) => {
                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) || (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                      }}
                      onChange={(value) => {setCoporationId(value);}}
                    >
                    {(listCoporations ?? []).map((record, index) => {
                      return (
                        <Option key={record.ma} value={record.ma} label={record.ten}>
                          <div className="d-flex justify-content-between">
                            <span>{record.ten}</span>
                            <span>{record.ma}</span>
                          </div>
                        </Option>
                      )
                    })}
                    </Select>
                  </div>
                </div>
                <div className="row mt-2">{/* Người thực hiện | Nhóm dịch vụ */}
                  <div className='col-md-6 d-flex flex-column'>{/* Người thực hiện */}
                    <div>
                      <label>Người thực hiện</label>
                    </div>
                    <Select
                      allowClear
                      showSearch
                      mode="multiple"
                      className="w-100"
                      placeholder="Chọn người đăng kí"
                      maxTagCount= 'responsive'
                      value={ accountId }
                      onChange={(value) => setAccountId(value)}
                      onSearch={(value) => getListAccount(value)}
                      onClear={() => setAccountId(null)}
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                    {(listAccounts ?? []).map((record, index) => {
                      return (
                        <Option key={record.value} value={record.value}>{record.label}</Option>
                      )
                    })}
                    </Select>
                  </div>
                  <div className='col-md-6 d-flex flex-column'>{/* Nhóm dịch vụ */}
                    <div>
                      <label>Nhóm dịch vụ</label>
                    </div>
                    <Select
                      allowClear
                      mode="multiple"
                      className="w-100"
                      maxTagCount= 'responsive'
                      placeholder="Chọn nhóm dịch vụ"
                      value={ selectedNhomShortcutKey }
                      onChange={(value) => {setSelectedNhomShortcutKey(value);}}
                    >
                    {(listNhomShortcut ?? []).map((record, index) => {
                      return (
                        <Option key={record.keyValue} value={record.keyValue}>{record.label}</Option>
                      )
                    })}
                    </Select>
                  </div>
              </div>
              <div className="row mt-2">{/* button Thống kê */}
                  <div className='col-md-12 d-flex justify-content-end'>
                    <button 
                      className='btn btn-color ml-2' 
                      onClick={() => {
                        onClickStatisticHandle();
                      }}
                    >
                      <i className='far fa-chart-bar mr-2'></i> Thống kê
                    </button>
                    <button 
                      className='btn btn-secondary ml-2' 
                      onClick={() => {
                        clearFilter();
                      }}
                    >
                      <i className='fa fa-eraser mr-2'></i> Xóa dữ liệu nhập
                    </button>
                  </div>
              </div>
            </div>
            <div className="list-page-header mt-2" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
            </div>
            <div className="row">{/* Dashboard & [Chart | List] */}
            <div key="summary" className='col-md-12'>
              <Tabs defaultActiveKey="summary"
                  onChange={(curTab) => { setCurTab(curTab); setViewType(curTab) }}
                  tabBarExtraContent={
                    <button className="btn btn-color" type="button" 
                      onClick={() => { onExportExcelClinkHandle(); }}
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel</button>
                  }>
                  <Tabs.TabPane tab="Tổng hợp" key="summary">
                      <Summary ref={_summaryRef}
                        accountId={accountId}
                        nhomMas={nhomMas}
                        loaiIds={loaiIds}
                        siteIds={siteIds}
                        coporationIds={coporationId}
                        dateFrom={dateFrom}
                        dateTo={dateTo} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Chi tiết dịch vụ" key="detail">
                      <Detail ref={_detailRef} 
                        accountId={accountId}
                        nhomMas={nhomMas}
                        loaiIds={loaiIds}
                        siteIds={siteIds}
                        coporationIds={coporationId}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                      />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Chi tiết bệnh nhân" key="patientdetail">
                      <PatientDetail ref={_patientDetailRef}
                        accountId={accountId}
                        nhomMas={nhomMas}
                        loaiIds={loaiIds}
                        siteIds={siteIds}
                        coporationIds={coporationId}
                        dateFrom={dateFrom}
                        dateTo={dateTo} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"} >
            {/* footer (nếu có) */}
            <div> </div>
          </div>
      </div>
    </React.Fragment>
  );
})