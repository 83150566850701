import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Notify, { NotifyStatus } from '../../components/notify';
import { BUTTON } from '../common/constants';
import HeaderModal from '../common/headerModal';
import { Input, InputNumber, Select, Switch } from 'antd';
import { ExtensionFields } from "../../configureHIS/common/constants";
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as ExtensionFieldService from '../../services/extensionFieldService';
import { setLoading } from '../../libs/actions';

const CreateExtensionField = forwardRef((props, ref) => {
    const _notify = useRef();
    const [menuOption, setMenuOption] = useState([]);
    const [menuKey, setMenuKey] = useState();
    const [description, setDescription] = useState();
    const [tableName, setTableName] = useState();
    const [fieldName, setFieldName] = useState();
    const [fieldLabel, setFieldLabel] = useState();
    const [data_Type, setData_Type] = useState();
    const [ui_Type, setUi_Type] = useState();
    const [sorting, setSorting] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        renderScreenList();
    }, []);

    const renderScreenList = () => {
        let listOpts = [];
        props.menuData && props.menuData.length > 0 && props.menuData.map((x) => {
            if (x.nodes && x.nodes.length > 0) {
                x.nodes.map((n) => {
                    if (n.nodes && n.nodes.length > 0) {
                        n.nodes.map((nc) => {
                            listOpts.push(nc);
                        })
                    } else listOpts.push(n);
                })
            } else listOpts.push(x);
        })
        setMenuOption(listOpts);
    }

    const onClose = () => {
        if (props.onClose) props.onClose();
        else _notify.current.Show("Không tìm thấy function", NotifyStatus.Error);
    }

    const onSave = () => {
        if (validator.current.allValid()) {
            _notify.current.Close();
            const data = {
                menuKey, tableName, fieldLabel, fieldName, description, data_Type, ui_Type, isActive, sorting
            }
            setLoading(true);
            ExtensionFieldService.create(data).then(res => {
                if (res.isSuccess) {
                    _notify.current.Show("Tạo thành công.", NotifyStatus.Success);
                    resetState();
                    validator.current.hideMessages();
                    props.onSuccess();
                } else {
                    _notify.current.Show(res.error.messageText, NotifyStatus.Error);
                }
            }).catch(err => {
                _notify.current.Show(err.error.messageText ?? "Đã xảy ra lỗi, vui lòng thử lại sau.", NotifyStatus.Error);
            }).finally(() => setLoading(false))
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }
    const resetState = () => {
        setMenuKey();
        setDescription();
        setSorting(0);
        setTableName();
        setFieldLabel();
        setFieldName();
        setData_Type();
        setUi_Type();
        setIsActive(true);
    }

    return (
        <div className='list-page'>
            <HeaderModal title="Tạo mới extension field" onClose={() => onClose()} />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className='d-flex flex-wrap' style={{ gap: 10 }}>
                    <div className='mt-2' style={{ flexGrow: 1, flexBasis: 100 }}>
                        <label>Active</label>
                        <div className='w-100'>
                            <Switch checked={isActive} onChange={(e) => setIsActive(e)} checkedChildren="Hoạt động" unCheckedChildren="Ẩn"></Switch>
                        </div>
                    </div>
                    <div className='mt-2' style={{ flexGrow: 1, flexBasis: 100 }}>
                        <label>Sorting</label>
                        <InputNumber className='w-100' placeholder='Nhập sorting'
                            value={sorting} onChange={(e) => setSorting(e)}
                            min={0}>
                        </InputNumber>
                        {validator.current && validator.current.message("Sorting", sorting, "required|numeric|min:0,num")}
                    </div>
                </div>
                <div className='mt-2'>
                    <label className='required-field'>Screen</label>{menuKey && <span className='text-primary ml-2'>menuKey: {menuKey}</span>}
                    <Select
                        className='w-100'
                        placeholder="Chọn màn hình"
                        onChange={(val) => setMenuKey(val)}
                        value={menuKey}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {menuOption && menuOption.map((opt) => {
                            return <Select.Option key={`${opt.menuKey}`} value={opt.menuKey}>{opt.label}</Select.Option>
                        })}
                    </Select>
                    {validator.current && validator.current.message("Screen", menuKey, "selection")}
                </div>
                <div className='mt-2'>
                    <label className='required-field'>Table name</label>
                    <Input className='w-100' placeholder='Nhập table name'
                        value={tableName} onChange={(e) => setTableName(e.target.value)}>
                    </Input>
                    {validator.current && validator.current.message("Table name", tableName, "required")}
                </div>
                <div className='mt-2'>
                    <label className='required-field'>Field name</label>
                    <Input className='w-100' placeholder='Nhập field name'
                        value={fieldName} onChange={(e) => setFieldName(e.target.value)}>
                    </Input>
                    {validator.current && validator.current.message("Field name", fieldName, "required")}
                </div>
                <div className='mt-2'>
                    <label className='required-field'>Field label</label>
                    <Input className='w-100' placeholder='Nhập field label'
                        value={fieldLabel} onChange={(e) => setFieldLabel(e.target.value)}>
                    </Input>
                    {validator.current && validator.current.message("Field label", fieldLabel, "required")}
                </div>
                <div className='mt-2'>
                    <label className='required-field'>Data type</label>
                    <Select className='w-100' placeholder='Chọn data type'
                        value={data_Type} onChange={(e) => setData_Type(e)}>
                        {ExtensionFields.DataType.map((dt, index) => {
                            return <Select.Option key={index} value={dt.value}>{dt.label}</Select.Option>
                        })}
                    </Select>
                    {validator.current && validator.current.message("Data type", data_Type, "selection")}
                </div>
                <div className='mt-2'>
                    <label className='required-field'>UI type</label>
                    <Select className='w-100' placeholder='Chọn UI type'
                        value={ui_Type} onChange={(e) => setUi_Type(e)}>
                        {ExtensionFields.UIType.map((ut, index) => {
                            return <Select.Option key={index} value={ut.value}>{ut.label}</Select.Option>
                        })}
                    </Select>
                    {validator.current && validator.current.message("UI type", ui_Type, "selection")}
                </div>
                <div className='mt-2'>
                    <label className='required-field'>Description</label>
                    <Input.TextArea rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder='Nhập mô tả'>
                    </Input.TextArea>
                    {validator.current && validator.current.message("Desciption", description, "required")}
                </div>
            </div>
            <div className="list-page-footer text-right">
                <button className={`${BUTTON.THEME}`} onClick={() => onSave()}><i className='fas fa-save mr-1' />Lưu</button>
                <button className={`${BUTTON.DANGER} ml-2`} onClick={() => onClose()}><i className='fas fa-sign-out-alt mr-1' />Đóng</button>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
        menuData: state.global.menuData,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CreateExtensionField)