import { Tabs, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { store } from '../../stores';
import MIDItem from './mdiItem.js';
import * as signalR from '@aspnet/signalr';
import * as Configure from '../../configure/urlConfig';
import * as Actions from '../../libs/actions';
import * as WorldfoneService from '../../services/worldfoneService';
import moment from 'moment';
import * as UserService from '../../services/usersService';
import * as KhoService from '../../services/KhoService';
import DateMedicine from '../../screens/medicine/components/datemedicine';
import MinRequirement from '../../screens/medicine/components/minRequirement';
import { GetMedicineMinRequirement } from '../../services/KhoService';
import { getPageSetting, isNullOrEmpty, isnotnull } from '../../libs/util';
import { GetUserId, GetUsername } from '../../commons/utils.js';
import * as NotificationMessageService from "../../services/notificationMessage";
const { TabPane } = Tabs;

let timeOut = null;
var oldHeader = 0;
let userWF = localStorage.getItem('userWF');
var audio = new Audio('telephonering.mp3');
let atWarehouse = false;
class MdiContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            stateDisplay: "Tab",
            activeKey: '', //  initialPanes[0].key,
            panes: [],   // initialPanes,
            listIndex: [],
            maxIndex: 0,
            WFstatus: '',
            WFdata: {},
            WFopen: false,
            medicineFlag: false,
            visibleMinRequired: false
        };
        this.refsObj = {}

    }
    componentWillUnmount() {
        window.removeEventListener('resize', () => { })
        window.removeEventListener('afterprint', () => { })
        this.refsObj = null;
    }

    getCheckMedExp() {
        return getPageSetting("checkMedExp");
    }

    componentDidMount() {
        this.checkGlobalTime();
        const checkMedExp = this.getCheckMedExp();
        UserService.getPermission().then((res) => {
            audio.loop = true;
            audio.play();
            audio.pause();
            let arrays = res.item.split(",")?.filter(x => x != "");
            Actions.setPermission(arrays);
            if (res.isSuccess == true) {
                if (checkMedExp == true) {
                    for (let item of arrays) {
                        if (item == "Kho" || item == '@@@' || item == 'gg') {
                            this.checkExpiredMedicine();
                            this.checkMedicineCatchMinRequired();
                            atWarehouse = true;
                            break;
                        }
                    }
                }
                if (res.err.msgString) {
                    Actions.openMessageDialog("Thông báo", res.err.msgString);
                }
            }
        })
        try {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(Configure.BASE_NOTIFY_URL + "/notify")
                .build();

            connection.on("notify", (data, dataWF) => {
                if (this.refsObj) {
                    // console.log(data, dataWF);
                    if (this.refsObj[data]) {
                        this.refsObj[data].notifyUpdate();
                    }
                    if (data === "Worldfone") {
                        if (userWF && userWF.length > 0) {
                            userWF = userWF.split('@')[0];
                            if (userWF === dataWF.userReceive) {
                                // this.SetTimeoutCDR(dataWF.dataCall.calluuid, dataWF.dataCall.secret);
                                if (dataWF.dataCall.callstatus === 'DialAnswer') {
                                    audio.pause();
                                    audio.currentTime = 0;
                                    Actions.OpenCallWorldfone(true, dataWF.dataCall.callstatus, dataWF);
                                    // this.hangup();
                                }
                                else if (dataWF.dataCall.callstatus === 'HangUp') {
                                    audio.pause();
                                    audio.currentTime = 0;
                                    Actions.OpenCallWorldfone(false, '');
                                    this.getcdr2(dataWF.dataCall.calluuid, dataWF.dataCall.secret);
                                    this.hangup();
                                }
                                else {
                                    if (dataWF.dataCall.callstatus === 'Dialing') {
                                        audio.src = 'ringphonedialing.mp3';
                                    }
                                    else {
                                        audio.src = 'telephonering.mp3';
                                    }
                                    audio.play();
                                    Actions.OpenCallWorldfone(true, dataWF.dataCall.callstatus, dataWF);
                                }
                            }
                        }
                    }
                    if (data == "ExamScreen") {
                        if (this.refsObj["khamngoai"]) {
                            this.refsObj["khamngoai"].notifyUpdate();
                        }
                        if (this.refsObj["khamnoi"]) {
                            this.refsObj["khamnoi"].notifyUpdate();
                        }
                        if (this.refsObj["khamsan"]) {
                            this.refsObj["khamsan"].notifyUpdate();
                        }
                        if (this.refsObj["khamdongy"]) {
                            this.refsObj["khamdongy"].notifyUpdate();
                        }

                        if (this.refsObj["khamcapcuu"]) {
                            this.refsObj["khamcapcuu"].notifyUpdate();
                        }
                        if (this.refsObj["goisothutu"]) {
                            this.refsObj["goisothutu"].notifyUpdate();
                        }
                    }
                    if (data == "Warehouse") {
                        if (atWarehouse) {
                            let notificationData = dataWF;
                            let newNotificationData = [];
                            newNotificationData.push(notificationData);
                            let oldNotificationData = sessionStorage.getItem("notificationData") ?? '[]';
                            sessionStorage.setItem("notificationData", JSON.stringify(newNotificationData));
                        }
                    }
                    if (data == "NotificationMessage") {
                        if (!isNullOrEmpty(dataWF.screen)) {
                            if (this.props.permission.includes(dataWF.screen) || this.props.permission.includes("superadmin"))
                                this.CheckUserToSendMessage(dataWF);
                        } else this.CheckUserToSendMessage(dataWF);
                    }
                }
            });
            this.resizeHeader();
            window.addEventListener('resize', (size, e) => {
                this.resizeHeader();
            })
            window.addEventListener("afterprint", function (event) {
                setTimeout(() => {
                    let tab = document.getElementById('navbar-nav-header-tab');
                    let width = window.getComputedStyle(tab).marginRight.replace('px', '');
                    let tabHeader = document.querySelector('#tab-header-menu>.ant-tabs-nav')
                    tabHeader.style.width = (Number(width) - 150).toString() + 'px';
                }, 500);

            });
            connection.start();
        }
        catch (ex) {
        }
    }
    
    checkExpiredMedicine() {
        KhoService.DanhSachThuocHetHan(moment().format('YYYY-MM-DD'), 0, '', 1, 10000).then(lstMedicineRes => {
            if (lstMedicineRes.isSuccess) {
                if (lstMedicineRes.data.length > 0) {
                    this.setState({ medicineFlag: true })
                }
            }
            else {
                Actions.openMessageDialog("Lỗi", lstMedicineRes);
            }

        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }

    checkMedicineCatchMinRequired(page = 1, size = 10) {
        GetMedicineMinRequirement("all", "", page, size).then(res => {
            if (res.isSuccess) {
                if (res.data.length > 0) {
                    this.setState({ visibleMinRequired: true })
                }
            }
        }).catch(() => { })
    }

    resizeHeader() {
        let tab = document.getElementById('navbar-nav-header-tab');
        if (tab) {
            let width = window.getComputedStyle(tab).marginRight.replace('px', '');
            let sideBar = document.getElementById('left-sidebar')
            let sideWidth = window.getComputedStyle(sideBar).width;
            let tabHeader = document.querySelector('#tab-header-menu>.ant-tabs-nav')
            tabHeader.style.width = (Number(width) - 150).toString() + 'px';
            oldHeader = Number(width) - 50;
            if (window.innerWidth > 768 && window.innerWidth < 993) {
                sideWidth !== '220px' ? tabHeader.style.left = '220px' : tabHeader.style.left = '75px';
            }
            else {
                tabHeader.style.left = 'unset';
            }
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        //console.log(nextProps, prevState);
        return {
            activeKey: nextProps.activeTab,
            panes: nextProps.tabs,
            WFstatus: nextProps.WFstatus,
            WFdata: nextProps.WFdata,
            WFopen: nextProps.WFopen,
        }
    }

    onChange = activeKey => {
        store.dispatch({ type: 'SET_ACTIVE_TAB', activeTab: activeKey });
    };



    onEdit = (targetKey, action) => {
        this[action](targetKey);
    };



    remove = targetKey => {

        const { panes, activeKey } = this.state;
        let newActiveKey = activeKey;
        let lastIndex;
        panes.forEach((pane, i) => {
            if (pane.key.toString() === targetKey.toString()) {
                lastIndex = i - 1;
            }
        });
        const newPanes = panes.filter(pane => pane.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        } else {
            newActiveKey = 0
        }


        store.dispatch({ type: 'SET_TAB', tabs: newPanes, activeTab: newActiveKey });
    };

    hangup() {
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            return;
        }
        WorldfoneService.hangup(tokenWF).then(result => {
            if (result.status !== 1) {
                // Actions.openMessageDialog("Thông báo", "Không thể thao tác. Status:" + result.status)
            }
            audio.pause();
            audio.currentTime = 0;
            Actions.OpenCallWorldfone(false, '');
        }).catch(err => {
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }

    answer() {
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            return;
        }
        WorldfoneService.answer(tokenWF).then(result => {
            if (result.status !== 1) {
                Actions.openMessageDialog("Thông báo", "Không thể thao tác. Status:" + result.status)
            }
        }).catch(err => {
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }
    getcdr2(calluuid, secrect) {
        WorldfoneService.getcdr2(calluuid, secrect).then(result => {
            if (result.status === '200') {
                let data = result.data;
                WorldfoneService.UpdateWorldfone(data).then(response => {

                })
                if (timeOut) {
                    clearInterval(timeOut);
                }
                this.hangup();
            }
        }).catch(err => {
            console.log(err);
            if (timeOut) {
                clearInterval(timeOut);
            }
        })
    }
    SetTimeoutCDR(calluuid, secrect) {
        timeOut = setInterval(() => {
            this.getcdr2(calluuid, secrect);
        }, 2000);
    }
    async checkGlobalTime() {
        const response = await fetch(Configure.API_TIME ?? "https://worldtimeapi.org/api/timezone/Asia/Ho_Chi_Minh");
        if (response.status == 200) {
            const data = await response.json();
            let globalUTCTime = moment(data.utc_datetime);
            let pcTime = moment();
            let approximate = pcTime.diff(globalUTCTime, "minutes");
            if (Math.abs(approximate) > Configure.MAX_TIME_DIFFERENCE_IN_MIN ?? 5) {
                let warning = approximate < 0 ? "chạy chậm" : "chạy nhanh";
                Actions.openMessageDialog("Cảnh báo", `Thời gian của máy tính đang ${warning}. Vui lòng cài đặt lại thời gian, sau đó tắt và mở lại trình duyệt.`, false);
            }
        }
    }

    // Nhận thông báo từ SignalR và thêm vào danh sách thông báo hiện đang có
    SetNotificationMessage(notificationData) {
        let oldNotificationData = JSON.parse(sessionStorage.getItem("notificationData") ?? '[]');
        let newNotificationData = oldNotificationData;
        if (!isnotnull(newNotificationData) || newNotificationData.find(x => x.notification == notificationData.notification) == undefined) {
            if (isNullOrEmpty(notificationData.key)) notificationData.key = crypto.randomUUID();// `${newNotificationData.length}-${moment().format("DDMMyyyyHHmmss")}`;
            newNotificationData.unshift(notificationData);
        }
        Actions.setNotificationCount(newNotificationData.length);
        sessionStorage.setItem("notificationData", JSON.stringify(newNotificationData));
    }

    CheckUserToSendMessage(data) {
        if (isnotnull(data.userReceive)) {
            if (data.userReceive.includes(GetUserId()) || data.userReceive.includes(GetUsername()))
                // this.SetNotificationMessage(data);
                this.updateListNotificationMessage();
        } else
                // this.SetNotificationMessage(data);
                this.updateListNotificationMessage();
    }

    // Gọi BE lấy danh sách thông báo
    updateListNotificationMessage() {
        NotificationMessageService.GetListNotificationMessage().then(res => {
            if (res.isSuccess) {
                const dataNoti = JSON.stringify(res.data);
                sessionStorage.setItem("notificationData", dataNoti);
                Actions.setNotificationCount(dataNoti.length);
            }
        })
    }

    render() {
        const { panes, activeKey } = this.state;
        return (
            <React.Fragment>
                <div id="mita-HIS" data-text={this.props.isDemo ? window.publicConfig.watermark : ""} style={{
                    width: '100%', height: '100%'
                }}>
                    {this.state.stateDisplay === "Tab" ?
                        <Tabs animated={true} id="tab-header-menu"
                            style={{ overflow: "auto" }}
                            hideAdd={true}
                            type="editable-card"
                            onChange={this.onChange}
                            activeKey={activeKey}
                            onEdit={this.onEdit}
                        >
                            {this.refsObj !== null && panes && panes.map(pane => (
                                <TabPane tab={pane.label} key={pane.key} closable={pane.closable}>
                                    {pane.props != null ?
                                        <div className="warpperchildpage">
                                            <pane.content compprops={pane.props} ref={(c) => { if (this.refsObj !== null) { this.refsObj[pane.key] = c } }}></pane.content>
                                        </div>
                                        :
                                        <div className="warpperchildpage">
                                            <pane.content ref={(c) => { if (this.refsObj !== null) { this.refsObj[pane.key] = c } }}></pane.content>
                                        </div>
                                    }
                                </TabPane>
                            ))}
                        </Tabs>
                        :
                        <div
                            onMouseUp={(e) => {
                                if (this.activeMoveChild)
                                    this.activeMoveChild.clearState();
                                this.activeMoveChild = null;
                            }}
                            onMouseMove={(e) => {
                                if (this.activeMoveChild) {
                                    this.activeMoveChild.handleMouseMove(e);
                                }
                            }}
                            onMouseLeave={(e) => {

                            }}
                            style={{
                                overflow: 'hidden',
                                position: "relative", width: '100%', height: "calc(100vh - 20px)", border: "1px solid black", padding: 2
                            }}>
                            {
                                panes && panes.map((pane, index) => (
                                    <MIDItem key={pane.key}
                                        onClose={() => { this.remove(pane.key) }}
                                        title={pane.title} index={this.state.listIndex[index]} top={20 * index} left={20 * index}
                                        onActiveMove={(c) => {
                                            if (c === null) {
                                                if (this.activeMoveChild)
                                                    this.activeMoveChild.clearState()
                                            }
                                            this.activeMoveChild = c;

                                        }}

                                        onClick={() => {
                                            let maxIndex = this.state.maxIndex + 1;
                                            let listIndex = this.state.listIndex;
                                            listIndex[index] = maxIndex;
                                            this.setState({ maxIndex, listIndex })
                                        }} >
                                        <div className="warpperchildpage">
                                            {pane.content}
                                        </div>
                                    </MIDItem>
                                ))
                            }
                        </div>
                    }
                    {this.state.WFopen === true &&
                        <div className='wf_call_modal'>
                            {this.state.WFstatus === 'NewCall' ?
                                <div className='wf_call_title'>Đang thực hiện cuộc gọi</div>
                                :
                                <React.Fragment>
                                    <div className='wf_call_title'>{this.state.WFstatus !== "Dialing" ? "Có cuộc gọi đến" : "Đang gọi"}</div>
                                    <div className='wf_call_phone'>{this.state.WFdata.patient.phone}</div>
                                    <div className='wf_call_note'>{this.state.WFdata.patient.patientName} - {moment(this.state.WFdata.patient.dob).year()}</div>
                                    {this.state.WFdata.patient &&
                                        <div className='wf_call_note'>{this.state.WFdata.patient?.patientID}</div>
                                    }
                                    {this.state.WFstatus !== 'Dialing' &&
                                        <React.Fragment>
                                            <div className='wf_call_action'>
                                                <div className='wf_call_deny' onClick={() => {
                                                    audio.pause();
                                                    audio.currentTime = 0;
                                                    Actions.OpenCallWorldfone(false, '');
                                                    this.hangup();
                                                }}>
                                                    <i className='fas fa-phone-alt'></i>
                                                </div>
                                            </div>
                                            <div className='wf_call_action'>
                                                <div className='wf_call_accept' onClick={() => {
                                                    audio.pause();
                                                    audio.currentTime = 0;
                                                    Actions.OpenCallWorldfone(true, 'Dialing', this.state.WFdata);
                                                    this.answer();
                                                }}>
                                                    <i className='fas fa-phone-alt'></i>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {(this.state.WFstatus === 'Dialing' || this.state.WFstatus === 'DialAnswer') &&
                                        <div className='wf_call_calling'>
                                            <div className='wf_call_deny' onClick={() => {
                                                audio.pause();
                                                audio.currentTime = 0;
                                                Actions.OpenCallWorldfone(false, '');
                                                this.hangup();
                                            }}>
                                                <i className='fas fa-phone-alt'></i>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    }
                </div>
                <Modal maskClosable={true}
                    width={1500}
                    visible={this.state.medicineFlag}
                    onCancel={() => { this.setState({ medicineFlag: false }) }}
                    cancelButtonProps={{ hidden: false, disabled: false }}
                    okButtonProps={{ disabled: true, hidden: true }}>
                    <DateMedicine onCancel={() => this.setState({ medicineFlag: false })} />
                </Modal>
                <Modal maskClosable={true}
                    width={1500}
                    visible={this.state.visibleMinRequired}
                    onCancel={() => this.setState({ visibleMinRequired: false })}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <MinRequirement onCancel={() => { this.setState({ visibleMinRequired: false }) }} />
                </Modal>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return ({
        tabs: state.global.tabs,
        activeTab: state.global.activeTab,
        WFdata: state.modal.WFdata,
        WFstatus: state.modal.WFstatus,
        WFopen: state.modal.WFopen,
        isDemo: state.global.sysConfig?.demoVersion ?? false,
    })
};
export default connect(
    mapStateToProps,
)(MdiContainer);