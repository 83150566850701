import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Tabs, Table, DatePicker, Pagination, Tag, Select } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import * as VPService from '../update_vp/services/VPService';
import * as ReportPaymentService from "./reportPaymentService";
import * as CorporationService from '../../services/CorporationServices';
import * as Actions from '../../libs/actions';
import { connect } from 'react-redux';
import { FORMAT_DATE, FORMAT_PREFIX_EXCEL } from '../../configure/systemConfig';
import HeaderModal from '../common/headerModal';
import SiteFilter from '../common/siteFilter';
import { isnotnull } from '../../libs/util';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;

function PhieuThanhToan(props) {
    const _hiddenLink = useRef();
    const [dataSource, setDataSource] = useState([]);
    const [selectedDate, setSelectedDate] = useState([moment(), moment()]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    // const [columns, setColums] = useState([]);
    const [dataSourceSec, setDataSourceSec] = useState([]);
    const [totalRecordsSec, setTotalRecordsSec] = useState(0);
    const [currentPageSec, setCurrentPageSec] = useState(1);
    const [pageSizeSec, setPageSizeSec] = useState(10);
    const [site, setSite] = useState(props.siteid);
    const [curTab, setCurTab] = useState(1);
    const [coporator, setCoporator] = useState('');
    const [listCoporators, setListCoporators] = useState([]);
    const [siteIds, setSiteIds] = useState([]);

    useEffect(() => {
        getPaymentsList();
        loadCoporation();
    }, []);

    useEffect(() => {
        LoadThongKe();
    }, [currentPage, pageSize, site])

    useEffect(() => {
        LoadThongKeTheoDichVu();
    }, [currentPageSec, pageSizeSec, site])
    
    useEffect(() => {
        setSite(props.siteid);
    }, [props.siteid]);
    
    useEffect(() => {
        if (curTab === 1) {
            LoadThongKe();
        } else {
            LoadThongKeTheoDichVu();
        }
    }, [curTab]);

    const columnsSec = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPageSec - 1) * pageSizeSec + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        {
            title: 'Mã bệnh nhân',
            dataIndex: 'patientid',
            key: 'patientid',
            align: 'center',
            width: 120,
        },
        {
            title: 'Tên bệnh nhân',
            dataIndex: 'patientname',
            key: 'patientname',
            align: 'left',
            width: 200,
        },
        {
            title: 'Nguồn khách hàng',
            dataIndex: 'coporator',
            key: 'coporator',
            align: 'left',
            width: 150,
        },
        {
            title: 'Phiếu yêu cầu',
            dataIndex: 'receipt',
            key: 'receipt',
            align: 'center',
            width: 130,
        },
        {
            title: 'Loại phiếu',
            dataIndex: 'type',
            key: 'type',
            align: 'left',
            width: 120,
        },
        {
            title: 'Số phiếu',
            dataIndex: 'billnumber',
            key: 'billnumber',
            align: 'center',
            width: 100,
        },
        {
            title: 'Ngày',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: 120,
            render: (ngay) => {
                return moment(ngay).format(FORMAT_DATE);
            }
        },
        {
            title: 'Dịch vụ',
            dataIndex: 'service',
            key: 'service',
            align: 'left',
            width: 200,
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
            width: 100,
        },
        {
            title: 'Đơn giá',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            width: 100,
            render: (value) => {
                return value && value.toLocaleString('en-US');
            }
        },
        // {
        //     title: 'Giảm trừ (%)',
        //     dataIndex: 'discount',
        //     key: 'discount',
        //     align: 'center',
        //     width: 100,
        // },
        {
            title: 'Thành tiền',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 120,
            render: (value) => {
                return value && value >= 0 ? <Tag color="green">{value.toLocaleString('en-US')}</Tag> : <Tag color="error">{value.toLocaleString('en-US')}</Tag>;
            }
        },
        ...((!isnotnull(siteIds) || siteIds.length <= 1) ? [] : [{
            title: 'Chi nhánh',
            dataIndex: 'chiNhanh',
            key: 'chiNhanh',
            align: 'left',
            width: 190,
        }]),
    ]

    var columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        {
            title: 'Mã bệnh nhân',
            dataIndex: 'patientid',
            key: 'patientid',
            align: 'center',
            width: 120,
        },
        {
            title: 'Tên bệnh nhân',
            dataIndex: 'patientname',
            key: 'patientname',
            align: 'left',
            width: 200,
        },
        {
            title: 'Nguồn khách hàng',
            dataIndex: 'coporator',
            key: 'coporator',
            align: 'left',
            width: 150,
        },
        {
            title: 'Phiếu yêu cầu',
            dataIndex: 'receipt',
            key: 'receipt',
            align: 'center',
            width: 130,
        },
        {
            title: 'Loại phiếu',
            dataIndex: 'type',
            key: 'type',
            align: 'left',
            width: 120,
        },
        {
            title: 'Số phiếu',
            dataIndex: 'billnumber',
            key: 'billnumber',
            align: 'center',
            width: 100,
        },
        {
            title: 'Ngày',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: 120,
            render: (ngay) => {
                return moment(ngay).format(FORMAT_DATE);
            }
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 120,
            render: (value , record) => {
                return record.type === "Thanh toán" ? <Tag color="green">{value.toLocaleString('en-US')}</Tag> : <Tag color="error">{value.toLocaleString('en-US')}</Tag>;
            }
        },
        {
            title: 'Giảm khác (%)',
            dataIndex: 'discount',
            key: 'discount',
            align: 'right',
            width: 120,
            render: (value) => {
                return value >= 0 && value.toLocaleString('en-US');
            }
        },
        {
            title: 'Thanh toán/Hoàn trả',
            dataIndex: 'paid',
            key: 'paid',
            align: 'right',
            width: 120,
            render: (value) => {
                return value > 0 ? <Tag color="green">{value.toLocaleString('en-US')}</Tag> : value === 0 ? value : <Tag color="error">{value.toLocaleString('en-US')}</Tag>;
            }
        },
        {
            title: 'Hình thức',
            dataIndex: 'arrTypePayment',
            key: 'arrTypePayment',
            align: 'left',
            width: 120,
        },
        ...((!isnotnull(siteIds) || siteIds.length <= 1) ? [] : [{
            title: 'Chi nhánh',
            dataIndex: 'chiNhanh',
            key: 'chiNhanh',
            align: 'left',
            width: 190,
        }]),
    ]
    
    const getPaymentsList = () => {
        VPService.lstLoaiThanhToan().then(result =>{
            if(result.isSuccess){
                const PaymentsList = result.data;
                
                // if (PaymentsList.length > 0) {
                //     PaymentsList.sort(function(a, b) {
                //         return a.stt - b.stt;
                //     }).forEach(payment => {
                //         var item = {
                //             title: payment.ten,
                //             key: payment.viettat,
                //             align: 'right',
                //             width: 120,
                //             render: (row) => {
                //                 if (row.listpayment && row.listpayment.length > 0) {
                //                     const listPayment = row.listpayment;
                //                     var found = listPayment.find(x => x.name === payment.viettat);
                //                     if (found !== undefined) {
                //                         return found.total > 0 ? <Tag color="green">{found.total.toLocaleString('en-US')}</Tag> : found.total === 0 ? found.total : <Tag color="error">{found.total.toLocaleString('en-US')}</Tag>;
                //                     }
                //                 }
                //             }
                //         }
                //         columns.push(item);
                //     });
                //     setColums(columns);
                // }
            }
        }).catch(err => { })
    }

    const LoadThongKe = (page = currentPage, size = pageSize) => {
        Actions.setLoading(true);
        ReportPaymentService.LoadDoanhThuTheoHinhThucThanhToan(page, size, selectedDate[0].format('YYYY-MM-DD'), selectedDate[1].format('YYYY-MM-DD'), siteIds, coporator).then(res => {
            if (res.isSuccess) {
                setDataSource(res.data);
                setTotalRecords(res.totalRecords);
            }
        }).catch(() => { })
        .finally(() => {
            Actions.setLoading(false);
        })
    }

    const ExportThongKe = () => {
        Actions.setLoading(true);
        ReportPaymentService.ExportBaoCaoDoanhThuTheoPhieuThanhToan(selectedDate[0].format('YYYY-MM-DD'), selectedDate[1].format('YYYY-MM-DD'), siteIds, coporator)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    let fileName = `${moment().format(FORMAT_PREFIX_EXCEL)}_BCDoanhThu_PhieuTT_HinhThucTT`; 
                    _hiddenLink.current.download = fileName;
                    _hiddenLink.current.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }
    
    const LoadThongKeTheoDichVu = (page = currentPageSec, size = pageSizeSec) => {
        Actions.setLoading(true);
        ReportPaymentService.LoadDoanhThuTheoHinhThucThanhToanLoaiDV(page, size, selectedDate[0].format('YYYY-MM-DD'), selectedDate[1].format('YYYY-MM-DD'), siteIds, coporator).then(res => {
            if (res.isSuccess) {
                setDataSourceSec(res.data);
                setTotalRecordsSec(res.totalRecords);
            }
        }).catch(() => { })
        .finally(() => {
            Actions.setLoading(false);
        })
    }

    const ExportThongKeTheoDichVu = () => {
        Actions.setLoading(true);
        ReportPaymentService.ExportBaoCaoDoanhThuTheoPhieuThanhToanLoaiDV(selectedDate[0].format('YYYY-MM-DD'), selectedDate[1].format('YYYY-MM-DD'), siteIds, coporator)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    _hiddenLink.current.href = url;
                    let fileName = `${moment().format(FORMAT_PREFIX_EXCEL)}_BCDoanhThu_PhieuTT_DichVu`; 
                    _hiddenLink.current.download = fileName;
                    _hiddenLink.current.click();
                })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }

    const loadCoporation = (key = "") => {
        CorporationService.GetAllCoporation(key, '', 1, 10).then(res => {
            if (res.isSuccess) {
                setListCoporators(res.data)
            }
        })
    }

    const PrintThongKeTheoDichVu = () => {
        Actions.setLoading(true);
        ReportPaymentService.PrintBaoCaoDoanhThuTheoPhieuThanhToanLoaiDV(selectedDate[0].format('YYYY-MM-DD'), selectedDate[1].format('YYYY-MM-DD'), site, coporator).then((result) => {
            if (result.isSuccess)
                Actions.DoPrint(result.item)
        }).catch({})
        .finally(() => {
            Actions.setLoading(false);
        })
    }

    return (
        <div className='containerChilderWrapper'>
            <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={_hiddenLink} ></a>
            <HeaderModal title="Thống kê phiếu thanh toán"/>
            <Tabs animated={true} style={{ height: "93%" }} onChange={(e) => setCurTab(Number(e))}>
                <TabPane tab={"Hình thức thanh toán"} key="1">
                <div style={{ height: '100%', overflow: 'auto' }}>
                        <div className="row no-gutters mb-2">
                            <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
                        </div>
                    <div className='row no-gutters'>
                        <div className='col-6'>
                            <label>Nguồn khách hàng</label>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                value={coporator}
                                onChange={(value => setCoporator(value))}
                                onSearch={(value) => loadCoporation(value)}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key="all" value={""}>Tất cả </Option>
                                {listCoporators.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                            </Select>
                        </div>
                        <div className="col-md-6">
                            <label>Ngày lập phiếu</label>
                            <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                placement="bottomRight"
                                allowClear={false} format="DD-MM-YYYY" value={selectedDate}
                                placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                onChange={value => setSelectedDate(value) }
                            />
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="ml-auto d-flex flex-direction-row align-items-center mt-2">
                            <div className="col-auto">
                                <button className="btn btn-color ml-2" onClick={() => { setCurrentPage(1); setCurrentPageSec(1);LoadThongKe() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                <button className="btn btn-color ml-2" onClick={() => { ExportThongKe() }}><i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 'calc(100% - 225px)', marginTop: 10 }}>
                        <Table
                            scroll={{ x: 'max-content', y: 0 }}
                            pagination={false}
                            dataSource={dataSource} columns={columns}
                            rowKey={(row, index) => index} />
                    </div>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}s
                        showTotal={total => `Tổng: ${total.toLocaleString('en-US')}`}
                        current={currentPage}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setPageSize(size);
                        }}
                        onShowSizeChange={(page, size) => {
                            setCurrentPage(1);
                            setPageSize(size);
                        }}
                        showSizeChanger={true}
                    />
                </div>
                </TabPane>
                <TabPane tab={"Dịch vụ"} key="2">
                <div style={{ height: '100%', overflow: 'auto' }}>
                        <div className="row no-gutters mb-2">
                            <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
                        </div>
                    <div className='row no-gutters'>
                            <div className='col-6'>
                                <label>Nguồn khách hàng</label>
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    value={coporator}
                                    onChange={(value => setCoporator(value))}
                                    onSearch={(value) => loadCoporation(value)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key="all" value={""}>Tất cả </Option>
                                    {listCoporators.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                                </Select>
                            </div>
                            <div className="col-md-6">
                                <label>Ngày lập phiếu</label>
                                <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                    placement="bottomRight"
                                    allowClear={false} format="DD-MM-YYYY" value={selectedDate}
                                    placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                    onChange={value => setSelectedDate(value) }
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="ml-auto d-flex flex-direction-row align-items-center mt-2">
                                <div className="col-auto">
                                    <button className="btn btn-color ml-2" onClick={() => { LoadThongKeTheoDichVu() }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                    <button className="btn btn-outline-secondary ml-2" onClick={() => { PrintThongKeTheoDichVu() }}><i style={{ marginRight: 5 }} className="fa fa-print" />In</button>
                                    <button className="btn btn-color ml-2" onClick={() => { ExportThongKeTheoDichVu() }}><i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                </div>
                            </div>
                        </div>
                        <label><i>Báo cáo doanh thu không bao gồm giảm trừ</i></label>
                        <div style={{ height: 'calc(100% - 250px)', marginTop: 10 }}>
                            <Table
                                scroll={{ x: 'max-content', y: 0 }}
                                pagination={false}
                                dataSource={dataSourceSec} columns={columnsSec}
                                rowKey={(row, index) => index} />
                        </div>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10 }}
                            total={totalRecordsSec}
                            showTotal={total => `Tổng: ${total.toLocaleString('en-US')}`}
                            current={currentPageSec}
                            onChange={(page, size) => {
                                setCurrentPageSec(page);
                                setPageSizeSec(size);
                            }}
                            onShowSizeChange={(page, size) => {
                                setCurrentPageSec(1);
                                setPageSizeSec(size);
                            }}
                            showSizeChanger={true}
                        />
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PhieuThanhToan);