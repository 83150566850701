import React, { useRef, Fragment, useState, useCallback, useImperativeHandle, forwardRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Button, InputNumber, Select } from "antd";
import * as VPService from '../../../../configureHIS/update_vp/services/VPService';
import Notify, { NotifyStatus } from "../../../../components/notify";
import * as InvoiceService from '../../../../services/invoiceService';
import { isNullOrEmpty } from "../../../../libs/util";
import { PAYMENTTYPE, USER_AUTH } from '../../../../configureHIS/common/constants';
import { setLoading } from "../../../../libs/actions";

/**
 * @param {object} props.data: data truyền vào (Bao gồm tổng tiền và hình thức đã thanh toán).
 * @param {object} props.showCancel: Ẩn/Hiện nút "Không áp dụng" (Do có trường hợp thay đổi HTTT hoặc chỉ để phân bổ HTTT).
 * @param {object} props.isPayment: Có ở màn hình thanh toán hay ko.
 *                                  True :  xài 3 hình thức (Tiền mặt, chuyển khoản, quẹt thẻ).
 *                                  False : thêm công nợ.
 * @param {object} props.isEdit: True: Thay đổi HTTT trực tiếp 
 *                               false : Truyền HTTT ra ngoài
 */

const SeparatePayment = forwardRef((props) => {
    // Ref
    const firstRender = useRef(true);
    const _notiRef = useRef();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    // Datasource
    const [listPaymentType, setListPaymentType] = useState([]);
    const [totalBill, setTotalBill] = useState(0);
    const [billNumber, setBillNumber] = useState(props.data.billNumber);

    const [showCancel, ] = useState(props.showCancel);
    const [isPayment, ] = useState(props.isPayment);
    const [isChangePayment, ] = useState(props.isChangePayment);

    useEffect(() => {
        if (firstRender.current && isNullOrEmpty(billNumber)) {
            firstRender.current = false;
            GetListPaymentType();
        }
    }, []);

    useEffect(() => {
        if(!isNullOrEmpty(billNumber)){
            GetListPaymentType();
        }
    }, [billNumber]);

    useEffect(() => {
        setBillNumber(props.data.billNumber);
        props.data.totalBill && setTotalBill(props.data.totalBill);
    }, [props.data]);

    const GetListPaymentType = (GetAll = true) => {
        VPService.GetListPaymentType(GetAll).then(result => {
            if (result.isSuccess) {
                let listPayment = result.data.filter(x => x.ma !== PAYMENTTYPE.CARD);
                if(isPayment){
                    listPayment = listPayment.filter(x => x.ma !== PAYMENTTYPE.DEBT);
                } 
                if(!isNullOrEmpty(billNumber)){
                    GetBillPaymentSelected(listPayment)
                } else {
                    setListPaymentType(listPayment);
                }
            } else {
                if(result.error && result.error.messageText){
                    _notiRef.current && _notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
                } else {
                    _notiRef.current && _notiRef.current.Show(result, NotifyStatus.Warning);
                }
            }
        }).catch(err => {
            if(err.error && err.error.messageText){
                _notiRef.current && _notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
            } else {
                _notiRef.current && _notiRef.current.Show(err, NotifyStatus.Warning);
            }
        })
    }

    const GetBillPaymentSelected = (listPayment) => {
        InvoiceService.GetBillPaymentType(billNumber).then(res => {
            if (res.isSuccess) {
                let listSelected = res.data.listPaymentMethod;
                listPayment.forEach(item => {
                    let payment = listSelected.find(x => x.ma === item.ma);
                    if(payment !== undefined)
                        item.amount = payment.tongTien
                });
                setListPaymentType(listPayment);
            } else {
                if(res.error && res.error.messageText){
                    _notiRef.current && _notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
                } else {
                    _notiRef.current && _notiRef.current.Show(res, NotifyStatus.Warning);
                }
            }
        }).catch(err => {
            if(err.error && err.error.messageText){
                _notiRef.current && _notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
            } else {
                _notiRef.current && _notiRef.current.Show(err, NotifyStatus.Warning);
            }
        })
    }

    const handleChangeAmount = (index, value)=>{
        const rowsInput = [...listPaymentType];
        rowsInput[index].amount = value;
        setListPaymentType(rowsInput);
    }

    const validateSeparate = () => {
        let totalSeparate = 0;
        for (var i = 0; i < listPaymentType.length; i++) {
            totalSeparate += listPaymentType[i].amount
        }
        if(totalSeparate !== totalBill){
            _notiRef.current && _notiRef.current.Show("Số tiền sau khi phân bổ không bằng số tiền cần thanh toán.", NotifyStatus.Warning);
            return false;
        }
        return true;
    }

    const onSubmit = () => {
        if(validateSeparate()){
            _notiRef.current && _notiRef.current.Close();
            let listPayment = [...listPaymentType];
            listPayment = listPayment.filter(x => x.amount > 0);
            if(isChangePayment){
                onChangePaymentType(listPayment)
            } else {
                props.onSubmit && props.onSubmit([...listPayment]);
            }
        }
    }

    const onChangePaymentType = (listPayment) => {
        setLoading(true);
        let listSelected = listPayment.map(x => {
            return ({
                ma : x.ma,
                ten : x.ten,
                tongtien : x.amount
            })
        })
        let data = {
            billNumber : Number(billNumber),
            totalBill : totalBill,
            listPaymentMethod : listSelected,
        }
        InvoiceService.ChangePaymentTypeNew(data).then(res => {
            if (res.isSuccess) {
                _notiRef.current && _notiRef.current.Show('Phân bổ hình thức thanh toán thành công.', NotifyStatus.Success);
                setTimeout(() => {
                    _notiRef.current && _notiRef.current.Close();
                    props.onSuccessChange && props.onSuccessChange();
                }, 500);
            } else {
                if(res.error && res.error.messageText){
                    _notiRef.current && _notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
                } else {
                    _notiRef.current && _notiRef.current.Show(res, NotifyStatus.Warning);
                }
            }
        }).catch(err => {
            if(err.error && err.error.messageText){
                _notiRef.current && _notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
            } else {
                _notiRef.current && _notiRef.current.Show(err, NotifyStatus.Warning);
            }
        })
        .finally(() => setLoading(false))
    }

    const onCancel = () => {
        _notiRef.current && _notiRef.current.Close();
        let listPayment = [...listPaymentType];
        listPayment = listPayment.map(x => ({...x, amount : 0}));
        setListPaymentType(listPayment);
        props.onSubmit([]);
    }
    
    const onClose = () => {
        _notiRef.current && _notiRef.current.Close();
        props.onClose();
    }

    return (
        <Fragment>
            <div className="list-page">
                <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
                    <h5>Phân bổ hình thức thanh toán {(billNumber && billNumber > 0) ? `- ${billNumber}` : ''}</h5>
                    <h4 onClick={() => { onClose() }}><i className='far fa-times-circle anchor-color' /></h4>
                </div>
                <Notify ref={_notiRef} />
                <div className="d-flex flex-column w-100">
                    <div className="d-flex flex-row justify-content-between">
                        <span style={{width : '50%'}}>THÀNH TIỀN</span>
                        <span className="d-flex justify-content-end" style={{width : '5%'}}>₫</span>
                        <span className="d-flex justify-content-end" style={{width : '35%'}}>{totalBill.toLocaleString('en-US')}</span>
                    </div>
                    { listPaymentType && listPaymentType.map((data, index) => {
                        return (
                            <div className="d-flex flex-row justify-content-between mt-2" key={data.ma}>
                                <div style={{width : '50%'}}>
                                    <span className="mita-title ml-2">{data.ten}</span>
                                </div>
                                <span className="d-flex justify-content-end" style={{width : '5%'}}>₫</span>
                                <div className="d-flex justify-content-end" style={{width : '35%'}}>
                                    <InputNumber
                                        className="w-75"
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        value={data.amount}
                                        onChange={(e) => handleChangeAmount(index, e)}
                                        min={0}
                                        max={totalBill}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    {
                        props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.ALLOCATION] &&
                        <div className="d-flex flex-row justify-content-between my-2">
                            {showCancel ?
                                <Button onClick={() => onCancel()} className="btn-danger">Hủy áp dụng</Button>
                                :
                                <div></div>
                            }
                            <Button onClick={() => onSubmit()} className="btn-color">Áp dụng</Button>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
});

const mapStateToProps = (state) => ({
    userScreenAuthorization: state.global.userScreenAuthorization,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(SeparatePayment);
