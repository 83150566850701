import * as ApiCaller from '../libs/httpRequests';
const prefix = 'ExtensionField';

export function getList(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/getlist`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListExtensionField(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListExtensionField`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function create(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/create`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function update(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/update`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function deleteData(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/delete?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};