import React, { useEffect, useState, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Table, Pagination, Spin, Tag } from 'antd';
import * as ReportServices from '../../../../services/ReportService';
import Notify, { NotifyStatus } from '../../../../components/notify';
import moment from 'moment';
import { FORMAT_DATE_TIME } from '../../../../configure/systemConfig';
import * as Actions from '../../../../libs/actions';

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        loadData: () => {
            resetToFirstPage();
        },
    }));

    const notiRef = useRef(null);
    const firstRender = useRef(true);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [listResult, setListResult] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        getData();
    }, [pageIndex]);

    useEffect(() => {
        if (firstRender.current) return;
        resetToFirstPage();
    }, [pageSize])

    const getData = () => {
        Actions.setLoading(true);
        setLoading(true);
        let filterModel = {
            siteIds: props.siteIds ?? [],
            accountIds: props.accountId ?? [],
            coporationIds: props.coporationIds,
            dateFrom: props.dateFrom ?? '',
            dateTo: props.dateTo ?? '',
            nhomMas: props.nhomMas ?? [],
            loaiIds: props.loaiIds ?? [],
            skipCount: (pageIndex - 1) * pageSize,
            maxResultCount: pageSize,
            IsGetAll: false
        }
        ReportServices.getPatientDetailServiceList(filterModel).then(result => {
            if (result.isSuccess) {
                setTotalRecord(result.data.totalCount);
                setListResult(result.data.items);
                notiRef && notiRef.current.Close();
            }
            else {
                notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            setLoading(false);
            Actions.setLoading(false);
            forceUpdate();
        })
    }

    const resetToFirstPage = () => {
        if (pageIndex != 1) setPageIndex(1);
        else getData();
    }


    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 30,
            render: (text, record, index) => {
                return <span>{(pageIndex - 1) * pageSize + (index + 1)}</span>;
            }
        },
        {
            title: 'Ngày đăng ký',
            dataIndex: 'ngay',
            key: 'ngay',
            align: 'center',
            width: 110,
            render: (val) => {
                return <span>{val && moment(val).format(FORMAT_DATE_TIME)}</span>;
            }
        },
        {
            title: 'Ngày thực hiện',
            dataIndex: 'ngayThucHien',
            key: 'ngayThucHien',
            align: 'center',
            width: 110,
            render: (val) => {
                return <span>{val && moment(val).format(FORMAT_DATE_TIME)}</span>;
            }
        },
        {
            title: 'Mã bệnh nhân',
            dataIndex: 'maBN',
            key: 'maBN',
            align: 'center',
            width: 115,
        },
        {
            title: 'Tên bệnh nhân',
            dataIndex: 'hoTen',
            key: 'hoTen',
            align: 'left',
            width: 200,
        },
        {
            title: 'Giới tính',
            dataIndex: 'phai',
            key: 'phai',
            align: 'center',
            width: 80,
            render: (value, record) => {
                return (
                    <span>{value == 0 ? "Nam" : "Nữ"}</span>
                )
            }
        },
        {
            title: 'Năm sinh',
            dataIndex: 'namSinh',
            key: 'namSinh',
            align: 'center',
            width: 80,
        },
        {
            title: 'Tên dịch vụ',
            dataIndex: 'vienPhiTen',
            key: 'vienPhiTen',
            align: 'left',
            width: 200,
            render: (value, record) => {
                return (
                  <>
                    <span>{value}</span>
                    {record.isCombo === true ? <Tag className='ml-1' color="volcano">Combo</Tag> : ""}
                  </>
                )
            }
        },
        {
            title: 'Nhóm dịch vụ',
            dataIndex: 'loaiTen',
            key: 'loaiTen',
            align: 'center',
            width: 130,
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
            width: 100,
            render: (value, record) => {
                return (
                    <span>{Number(value).toLocaleString('en-US')}</span>
                )
            }
        },
        {
            title: 'Thực thu',
            dataIndex: 'revenue',
            key: 'revenue',
            align: 'right',
            width: 100,
            render: (value, record) => {
                return (
                    <span>{Number(value).toLocaleString('en-US')}</span>
                )
            }
        },
        {
            title: 'Người thực hiện',
            dataIndex: 'bacSiThucHien',
            key: 'bacSiThucHien',
            align: 'right',
            width: 150,
        },
        {
            title: 'Nguồn khách hàng',
            dataIndex: 'tenNguonKhachHang',
            key: 'tenNguonKhachHang',
            align: 'right',
            width: 150,
        },
    ];

    return (
        <React.Fragment>
            <Spin spinning={loading} className='spin-color'>
                <div className="list-page" >
                    <div className="list-page-body">
                        <div className="row">
                            <div className="col-md-12"><Notify ref={notiRef} /></div>
                        </div>
                        <div className="list-page-table mt-2">
                            <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                                className="table-min-h-0 table-header-1row"
                                dataSource={listResult}
                                columns={columns}
                                locale={{ emptyText: 'Không có dữ liệu' }}
                                scroll={{ x: 1200, y: 0 }}
                                rowKey={(e) => e.maBN + e.vienPhiMa + e.ngay.toString()}
                                pagination={false}
                            />
                            <Pagination
                                current={pageIndex}
                                size={pageSize}
                                style={{ textAlignLast: "center", marginTop: "10px" }}
                                total={totalRecord}
                                showTotal={total => `Tổng: ${total}`}
                                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                                showSizeChanger={true}
                            />
                        </div>
                    </div>
                </div>
            </Spin>
        </React.Fragment>
    );
})