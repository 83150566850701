import { Button, DatePicker, Form, Pagination, Input, Select, Tabs, Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import * as Actions from '../../../../libs/actions';
import * as StatisticService from '../../../../services/statisticService';
import * as ConfigService from '../../../../services/configService';
import * as UsersService from '../../../../services/usersService';
import { FORMAT_PREFIX_EXCEL } from '../../../../configure/systemConfig';

export default forwardRef((props, ref) => {
    const _hiddenLink = useRef()
    const [date, setDate] = useState(props.ngay);
    const [dataSource, setDataSource] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [key, setKey] = useState('');
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [filter, setFilter] = useState(props.filter);
    const [isMultisite, setIsMultisite] = useState(false);
    const [listSelected, setListSelected] = useState(props.listSelected);
    const [site, setSite] = useState(props.siteid);

    useImperativeHandle(ref, () => ({
        reload: () => {
            ThongKeChiTietNguoiTiepDon();
        }
    }));

    // useEffect(() => {
    //     GetListSite();
    //     UsersService.CheckPremissionMultisite().then(result => {
    //         if (result.isSuccess) {
    //             setIsMultisite(result.item)
    //         }
    //     });
    // }, [])

    useEffect(() => {
        ThongKeChiTietNguoiTiepDon();
    },[page, size])

    useEffect(() => {
        setDate(props.ngay);
        setFilter(props.filter);
        setSite(props.siteid);
        setListSelected(props.listSelected);
    }, [props]);

    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }

    function ThongKeChiTietNguoiTiepDon(){
        Actions.setLoading(true);
        let filterModel = {
            //common: paging & sorting
            skipCount: (page - 1) * size,
            maxResultCount: size,
            //riêng theo module
            dateFrom: date[0],
            dateTo: date[1],
            referenceIds: listSelected,
            siteIds : site,
            filter : filter,
            isGetAll : false
        }
        StatisticService.ReceptionistRevenueStatistic(filterModel).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data.items);
                setTotalRecord(result.data.totalCount);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Lỗi", err);
        })
    }
    function ExportThongKeDoanhSoBS() {
        Actions.setLoading(true);
        let filterModel = {
            //common: paging & sorting
            skipCount: (page - 1) * size,
            maxResultCount: size,
            //riêng theo module
            dateFrom: date[0],
            dateTo: date[1],
            referenceIds: listSelected,
            siteIds : site,
            filter : filter,
            isGetAll : true
        }
        StatisticService.ExportReceptionistRevenueStatistic(filterModel)
            .then((response) => {
                let res = response.clone();
                res.json().then((t) => {
                    if (!t.isSuccess) {
                        props.showNotice(`${t.error.messageText}. ${t.error.extensionData}.`);
                    }
                }).catch(err => {
                    response.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        _hiddenLink.current.href = url;
                        let fileName = `${moment().format(FORMAT_PREFIX_EXCEL)}_DSKH_NVDangKy`; 
                        _hiddenLink.current.download = fileName;
                        _hiddenLink.current.click();
                        props.hideNotice();
                    })
                })

                // response.blob().then((blob) => {
                //     let url = window.URL.createObjectURL(blob);
                //     _hiddenLink.current.href = url;
                //     let fileName = `${moment().format(FORMAT_PREFIX_EXCEL)}_DSKH_NVDangKy`; 
                //     _hiddenLink.current.download = fileName;
                //     _hiddenLink.current.click();
                // })
            }).catch(err => {
                Actions.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
            }).finally(() => {
                Actions.setLoading(false);
            })
    }

    const columns = [
        {
            title: '#',
            width: 60,
            align: 'Center',
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
        },
        {
            title: 'Ngày lập phiếu',
            dataIndex: 'date',
            key: 'date',
            width: 120,
            render: (value) => (<span>{value ? moment(value).format('DD/MM/YYYY') : ''} </span>)
        },
        {
            title: 'Họ và tên',
            dataIndex: 'name',
            key: 'name',
            width: 180,
        },
        {
            title: 'Mã khách hàng',
            dataIndex: 'patientId',
            key: 'patientId',
            width: 150
        },
        {
            title: 'Khách hàng',
            dataIndex: 'patientName',
            key: 'patientName',
            width: 200
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            align: 'center',
            width: 100,
            render : (row) => {
                return <span>{ row === "0" ? "Nam" : "Nữ" }</span>
            }
        },
        {
            title: 'Năm sinh',
            dataIndex: 'birthday',
            key: 'birthday',
            align: 'center',
            width: 100
        },
        {
            title: 'SL phiếu',
            children:[
                {
                    title: 'TT',
                    dataIndex: 'totalBill',
                    key: 'totalBill',
                    align: 'center',
                    width: 120
                },
                {
                    title: 'HT',
                    dataIndex: 'totalBillRefund',
                    key: 'totalBillRefund',
                    align: 'center',
                    width: 120
                }
            ]
            
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            width: 150,
            render: (text) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
        },
        {
            title: 'Tổng giảm',
            dataIndex: 'discount',
            key: 'discount',
            align: 'right',
            width: 150,
            render: (text) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
        },
        {
            title: 'Hoàn trả',
            dataIndex: 'amountRefund',
            key: 'amountRefund',
            align: 'right',
            width: 150,
            render: (text) => {
                    return <span>{text.toLocaleString('en-US')}</span>;
                },
        },
        {
            title: 'Thực thu',
            align: 'right',
            width: 150,
            render: (row, record) => {
                    return <span>{(record.amount - record.discount - record.amountRefund).toLocaleString('en-US')}</span>;
                },
        },
        
    ];
    return (
        <>
        <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
        <div className='row '>
            <div className='col-12 d-flex justify-content-end py-2'>
                <Button className="btn btn-color" onClick={() => { ExportThongKeDoanhSoBS() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</Button>
            </div>
        </div>
        <div style={{height: 'calc(100% - 315px)'}}>
            <Table 
                scroll={{ x: 'max-content', y: 0 }}
                dataSource={dataSource}
                pagination={false}
                columns={columns} 
                rowKey={(e, index) => index}
            />
        </div>
        <div>

        </div>
            <Pagination
                style={{ textAlignLast: 'center', marginTop: 30, marginBottom: 10 }}
                total={totalRecord}
                showTotal={total => `Tổng: ${total}`}
                size={size}
                current={page}
                onChange={(page, size) => {
                    setPage(page);
                    setSize(size)
                }}
                onShowSizeChange={(page, size) => {
                    setPage(1);
                    setSize(size)
                }
                }
                showSizeChanger={true}
                />
        </>
    )
}
)
