import React, { Fragment, useRef, useState, useEffect, useCallback } from "react";
import * as SystemConfig from '../../../configure/systemConfig';
import moment from "moment";
import viVN from 'antd/es/date-picker/locale/vi_VN';
import HeaderModal from "../../../configureHIS/common/headerModal";
import Notify, { NotifyStatus } from "../../../components/notify";
import { Select, Input, Popover, DatePicker, Button, List } from "antd";
import ListPayment from "./components/ListPayment";
import DetailPayment from "./components/DetailPayment";
import * as PrintService from '../../../services/printService';
import * as Actions from '../../../libs/actions';
import Handlebars from 'handlebars';
import handlebarHelper from "../../../libs/handlebarHelper";
import { groupBy, isNullOrEmpty } from "../../../libs/util";
import { connect } from 'react-redux';
import * as ConfigService from '../../../services/configService';

const PaymentIndex = (props) => {
    const _detailPayment = useRef();
    const _listPayment = useRef();
    const [patientId, setPatientId] = useState('');
    const [managementId, setManagementId] = useState('');
    const [billType, setBillType] = useState(0);
    const [processStatus, setProcessStatus] = useState(1);
    const [visiblePopover, setVisiblePopover] = useState(false);

    useEffect(() => {
        _detailPayment.current && _detailPayment.current.onChangeSite();
    }, [props.siteid]);

    useEffect(() => {
        getUserScreenAuthorization();
    }, []);

    const getUserScreenAuthorization = () => {
        let feature = ""; 
        ConfigService.getUserScreenAuthorization(feature).then(res => {
            if (res.isSuccess) {
                Actions.setUserScreenAuthorization(res.data);
            } else { }
        }).catch((err) => {
        }).finally(() => { })
    }

    const onSelectedBill = (data) => {
        setPatientId(data.patientCode);
        setManagementId(data.managementId);
        _detailPayment.current && _detailPayment.current.reloadUnpayService(data.managementId, data.patientCode);
    }

    const onSuccessPayment = () => {
        _listPayment.current && _listPayment.current.reloadListPayment();
    }

    const onChangeBillType = (e) => {
        setBillType(e.billType); 
        setProcessStatus(e.processStatus);
    }

    return (
        <Fragment>
            <div className="list-page">
                {/* <HeaderModal title='Thanh toán'/> */}
                <div className="list-page-body flex-row">{/* body */}
                    <div className="list-page-body left-panel containerChilderWrapper" style={{width : '30%'}}>{/* panel-left */}
                        <ListPayment 
                            ref={_listPayment}
                            onSelected={(data) => { onSelectedBill(data) }} 
                            onChangeBillType = {(e) => {onChangeBillType(e)}}
                        />
                    </div>
                    <div className="list-page-body right-panel containerChilderWrapper ml-1" style={{width : '70%'}}>{/* panel-right */}
                        <div className="list-page-body overflow-auto">{/* Payment Information */}
                            <DetailPayment 
                                ref={_detailPayment}
                                billType={billType} 
                                processStatus={processStatus}
                                patientId={patientId} 
                                managementId={managementId}
                                onSuccessPayment={() => {onSuccessPayment()}} /> 
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        print80mm: state.global.sysConfig.print80mm ? state.global.sysConfig.print80mm : false,
    })
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserScreenAuthorization: (auth) => dispatch({ type: 'SET_USER_SCREEN_AUTHORIZATION', userScreenAuthorization: auth }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentIndex);