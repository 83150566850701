import { DatePicker, Form, Input, Modal, notification, Pagination, Popconfirm, Table, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../../../libs/actions';
import * as PrintService from '../../../../services/printService';
import * as XuatKhoService from '../../../../services/xuatkhoService';
import * as KhoService from '../../../../services/KhoService';
import PhieuXuat from './phieuxuat';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from "../../../../configure/systemConfig";
import { USER_AUTH } from '../../../../configureHIS/common/constants';
import { isnotnull } from '../../../../libs/util';
import HeaderModal from '../../../../configureHIS/common/headerModal';
import { UserPermission } from '../../../../commons/utils';

const { Option } = Select;
const { RangePicker } = DatePicker;
const initialState = {
    ngay: moment(),
    ngayUpdate: moment(),
    ngayrange: [moment(), moment()],
    key: '',
    id: '',
    totalRecords: 0,
    totalRecords1: 0,
    makho: null,
    // soluong: 0,
    // soluongton: 0,
    // mathuoc: 0,
    isSuperAdmin: false,
}
const FEATURE_NAME = "warehouse";
const SCREEN_NAME = "WarehouseOut";
export default class XuatKho extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            // lstThuoc: [],
            lstXuat: [],
            lstDetail: [],
            lstKho: [],
            visible: false,
            visibleDetail: false,
            currentPage: 1,
            currentSize: 10,
            isUpdate: false,
            listWarehousePermission: [],
        };
    }
    componentDidMount() {
        this.getPermission();
    }

    getPermission() {
        const dataPermission = UserPermission(FEATURE_NAME);
        this.setState({
            listWarehousePermission: dataPermission.listWarehousePermission,
            isSuperAdmin: dataPermission.isSuperAdmin,
        });
        this.loadKho();
    }

    loadKho() {
        KhoService.GetListKho('all').then(result => {
            let listOwnWarehouse = [...new Set((this.state.listWarehousePermission ?? []).filter(x => x.permission.includes(`${SCREEN_NAME}_`)).map(x => (x.warehouse)))];
            if (result.isSuccess) {
                const owner = this.state.isSuperAdmin ? result.data : result.data.filter(x => listOwnWarehouse.includes(x.value));
                this.setState({
                    lstKho: owner,
                    makho: owner[0]?.value ?? null
                }, () => this.loadDanhSachXuatKho());
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
        })
    }
    showModal = () => {
        this.setState({
            isPreview: false,
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
            visibleDetail: false
        });
    };
    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    handleCancel = e => {
        this.setState({
            visible: false,
            visibleDetail: false,
            isUpdate: false,
        });
        this.loadDanhSachXuatKho();
    };

    getUpdate(row) {
        XuatKhoService.ThongTinHoaDonXuat(moment(row.ngayxuat).format('YYYY-MM-DD'), row.id).then(result => {
            if (result.isSuccess) {
                this.setState({ visible: true })
                let data = result.item
                data.idPhieu = row.id;
                for (let item of data.lstAddThuoc) {
                    if (item.nghh) {
                        item.nghh = moment(item.nghh).format("YYYY-MM-DD");
                    }
                    if (item.ngsx) {
                        item.ngsx = moment(item.ngsx).format("YYYY-MM-DD");
                    }
                }

                setTimeout(() => {
                    this.Nhap.setData(result.item);
                }, 100);


            }
            else {
                this.setState({
                    lstNhap: [],
                    totalRecords: 0
                })
            }

        }).catch(err => {
        })
    }


    XoaDon(row) {
        XuatKhoService.deleteXuatkho(row.id, moment(row.ngayxuat).format("YYYY-MM-DD")).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công!" });
                this.loadDanhSachXuatKho();
            }
            else {
                this.setState({
                    lstNhap: [],
                    totalRecords: 0
                })
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {
        })
    }

    loadDanhSachXuatKho() {
        if (this.state.ngay === null || this.state.ngay === undefined) {
            notification.warning({ message: 'Vui lòng chọn thời gian!' });
            return;
        }
        XuatKhoService.DanhSachXuatKho(this.state.ngayrange[0].format('YYYY-MM-DD'), this.state.ngayrange[1].format('YYYY-MM-DD'), this.state.key, this.state.makho, this.state.currentPage, this.state.currentSize).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstXuat: result.data,
                    totalRecords: result.totalRecords
                });
            }
            else {
                this.setState({
                    lstXuat: [],
                    totalRecords: 0
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu danh sách xuất kho bị lỗi!' })
        })
    }
    thongtinHD(row, page = 1, size = 10) {
        XuatKhoService.ThongTinHoaDonXuat(moment(row.ngayxuat).format('YYYY-MM-DD'), row.id, page, size).then(result => {
            if (result.isSuccess) {
                this.setState({
                    details: result.item,
                    // totalRecords1: result.totalRecords
                    visible: true,
                    isUpdate: true,
                    isPreview: true,
                }, () => this.Nhap && this.Nhap.setUpdateState(result.item));
            }
            else {
                this.setState({
                    lstNhap: [],
                    totalRecords: 0
                })
            }
        }).catch(err => {
        })
    }
    PrintPhieuXuat(id, ngay) {
        PrintService.PrintPhieuXuatKho(id, moment(ngay).format('YYYY-MM-DD')).then((result) => {
            Actions.DoPrint(result.item)
        })
    }

    hasPermission(action) {
        if (this.state.isSuperAdmin) return true;
        let auth = false;
        const found = (this.state.listWarehousePermission ?? []).find(x => x.warehouse.toString() == this.state.makho && x.permission == action);
        if (isnotnull(found)) auth = true;
        return auth;
    }

    render() {
        const columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                align: "center",
                render: (text, record, index) => (this.state.currentPage - 1) * this.state.currentSize + Number(index) + 1,
                width: 70,
            },
            {
                title: 'Ngày xuất',
                dataIndex: 'ngayxuat',
                key: 'ngayxuat',
                align: 'left',
                render: (value) => {
                    return <span>{moment(value).format('DD-MM-YYYY')}</span>;
                }
            },
            {
                title: 'Số phiếu',
                dataIndex: 'sophieu',
                key: 'sophieu',
                align: 'left',
                render: (sophieu, record) => {
                    return <a className="anchor-color" href='#'
                        onClick={() => {
                            this.thongtinHD(record);
                        }}>{sophieu}</a>
                }
            },
            {
                title: 'Kho xuất',
                dataIndex: 'khoxuat',
                key: 'khoxuat',
                align: 'left'
            },
            // {
            //     title: 'Chi nhánh',
            //     dataIndex: 'chinhanh',
            //     key: 'chinhanh',
            //     align: 'center',
            //     width: 90,
            // },
            {
                title: 'Lý do xuất',
                dataIndex: 'lydo',
                key: 'lydo',
                align: 'left',
                width: 400,
            },

            {
                title: 'Thao tác',
                align: 'center',
                render: (row) => {
                    return (
                        <div>
                            {this.hasPermission(USER_AUTH.WarehouseOut.View) && <button className="btn btn-color"
                                title="Chi tiết hóa đơn!"
                                onClick={() => {
                                    this.thongtinHD(row);
                                }}>
                                <i className="fa fa-eye" style={{ marginRight: 5 }} />Xem
                            </button>}
                            {/* <button className="btn btn-color"
                                style={{ marginLeft: 10 }}
                                title="cập nhật hóa đơn!"
                                onClick={() => { this.getUpdate(row) }} >
                                <i className="fa fa-edit" style={{ marginRight: 5 }} />Cập nhật
                            </button> */}

                            {this.hasPermission(USER_AUTH.WarehouseOut.Delete) && <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                onConfirm={() => { this.XoaDon(row) }}
                                okText="Đồng ý" cancelText="Hủy">
                                <button style={{ marginLeft: "10px" }} className="btn btn-danger"><i className="fa fa-trash" style={{ marginRight: 5 }} />Xóa</button>
                            </Popconfirm>}
                            <button className="btn btn-color"
                                style={{ marginLeft: 10 }} onClick={() => { this.PrintPhieuXuat(row.id, row.ngayxuat) }} >
                                <i className="fa fa-print" style={{ marginRight: 5 }}></i>In </button>
                        </div>
                    )
                }
            }
        ];
        let dataSource1 = this.state.lstXuat.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        const columnsDetail1 = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                render: (text, record, index) => Number(index) + 1,
                width: 80,
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
                width: 100,
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                align: 'left',
                width: 150,
            },
            {
                title: 'Hàm lượng',
                dataIndex: 'hamluong',
                key: 'hamluong',
                align: 'left',
                width: 100,
            },
            {
                title: 'Hợp chất',
                dataIndex: 'hopchat',
                key: 'hopchat',
                align: 'left',
                width: 100,
            },
            {
                title: 'ĐVT',
                dataIndex: 'donvi',
                key: 'donvi',
                align: 'left',
                width: 90,
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
                align: 'center',
                width: 100,
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },

        ];

        return (
            <React.Fragment>
                <div className='containerChilderWrapper'>
                    <HeaderModal title="Xuất kho" />
                    <div className="row justify-content-center align-items-center">
                        <div className="col-auto mb-2">
                            <RangePicker format="DD-MM-YYYY" value={this.state.ngayrange} allowClear={false} disabledDate={this.disabledDate}
                                locale={viVN}
                                onChange={(value => this.setState({ ngayrange: value, currentPage: 1 }, () => { this.loadDanhSachXuatKho() }))}
                            />
                        </div>
                        <div className="col-auto mb-2">
                            <Select style={{ width: '200px' }} onChange={(value) => { this.setState({ makho: value }, () => { this.loadDanhSachXuatKho() }) }}
                                value={this.state.makho}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Chọn kho"
                            >
                                {this.state.lstKho.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="col-auto mb-2">
                            <Input
                                value={this.state.key}
                                style={{ width: 500 }}
                                placeholder="Nhập số phiếu hoặc lý do và bấm enter để tìm"
                                onChange={(e) => this.setState({ key: e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.setState({ currentPage: 1 }, () => this.loadDanhSachXuatKho())
                                    }
                                }}
                            />
                        </div>
                        <div className="col-auto mb-2">
                            <div className="row no-gutters">
                                <button className="btn btn-color"
                                    onClick={() => this.setState({ currentPage: 1 }, () => this.loadDanhSachXuatKho())}><i style={{ marginRight: 5 }}
                                        className="fa fa-search" />Tìm</button>
                            </div>
                        </div>
                    </div>
                    {this.hasPermission(USER_AUTH.WarehouseOut.Create) && <div className='row'>
                        <div className="col-auto">
                            <button className="btn btn-color"
                                onClick={() => {
                                    if (this.Nhap) {
                                        this.Nhap.setCreateState()
                                    }
                                    this.showModal();
                                }}><i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                        </div>
                    </div>}
                    <div className='mt-2' style={{ width: '100%', height: 'calc(100% - 180px)' }}>
                        <Table dataSource={dataSource1}
                            pagination={false}
                            scroll={{ x: 'max-content', y: 0 }}
                            columns={columns} rowKey={(e, index) => index} />
                    </div>
                    {(dataSource1 ?? []).length > 0 && <Pagination
                        current={this.state.currentPage}
                        size={this.state.currentSize}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={this.state.totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { this.setState({ currentPage: page, currentSize: size }, () => { this.loadDanhSachXuatKho() }) }}
                        onShowSizeChange={(page, size) => { this.setState({ currentPage: 1, currentSize: size }, () => { this.loadDanhSachXuatKho() }) }}
                        showSizeChanger={true}
                    />}
                </div>
                {
                    this.state.visible &&
                    <Modal maskClosable={false}
                        width={1400}
                        visible={this.state.visible}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        <PhieuXuat compprops={this.props.compprops} ref={(c) => this.Nhap = c} isUpdate={this.state.isUpdate} isPreview={this.state.isPreview} onCancel={this.handleCancel} />
                    </Modal>
                }
                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visibleDetail}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    destroyOnClose>
                    <div className='modal-header d-flex'>
                        <h3>Thông tin phiếu xuất kho số {this.state.sophieu}</h3>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 mt-2'>
                            <label>ID Phiếu</label>
                            <Input readOnly
                                value={this.state.id}
                            />
                        </div>
                        <div className='col-md-6 mt-2'>
                            <label>Kho xuất</label>
                            <Input readOnly
                                value={this.state.details != null ? this.state.details.hoadonxuat.tenkho : ''}
                            />
                        </div>
                        <div className='col-md-6 mt-2'>
                            <label>Ngày xuất</label>
                            <Input readOnly
                                value={this.state.details != null ? moment(this.state.details.hoadonxuat.ngay).format(SystemConfig.FORMAT_DATE) : ''}
                            />
                        </div>
                        <div className='col-md-6 mt-2'>
                            <label>Người xuất</label>
                            <Input readOnly
                                value={this.state.details != null ? this.state.details.hoadonxuat.nguoinhap : ''}
                            />
                        </div>
                    </div>
                    <Table
                        className='mt-2' scroll={{ x: 'max-content', y: "max-content" }}
                        dataSource={this.state.details ? this.state.details.lstAddThuoc : []}
                        pagination={false}
                        columns={columnsDetail1} rowKey={(e, index) => index} />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, alignItems: 'center' }}>
                        <button className="btn btn-danger" style={{ marginLeft: 5 }}
                            onClick={() => {
                                this.handleCancel()
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                            /> Đóng
                        </button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}


