import React, { Component } from 'react'
import { Input, Form, InputNumber, notification, Select, Checkbox, Tooltip, Popover } from 'antd';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as VPService from '../services/VPService';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import { FEATURE, COMBO } from '../../../configureHIS/common/constants';
import { FeatureDeactive } from '../../../configure/featureConfig';
import * as PackageService from '../../../services/packageService';
import { groupBy } from '../../../libs/util';

const { Option } = Select;
const initialState = {
    mavp: '',
    tenvp: '',
    maloai: 0,
    manhomvp: null,
    maloaivp: null,
    giabhyt: 0,
    giatien: 0
}
export default class CreateNewVP extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            lstVP: [],
            lstNhomVP: [],
            lstLoaiVP: [],
            value: [],
            options: this.props.options,
            autoGenerate: false,
            lstServicePackage : [],
            selectedPackage : '',
        };
    }

    componentDidMount() {
        this.loadNhomVP();
        this.GetListServicePackage();
    }
    loadNhomVP() {
        VPService.GetNhomVP().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNhomVP: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm viện phí bị lỗi' });
        })
    }
    loadDanhSachLoaiVP(key) {
        VPService.GetLoaiVPByNhom(Number(key)).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiVP: result.data,
                });
                this.form.setFieldsValue({
                    maloaivp: null,
                    lstVP: null
                });
            }
        }).catch(err => {
        })
    }
    resetState() {
        this.validator.hideMessages();
        this.notiRef.Close();
        this.setState({
            giatien: 0,
            giabhyt: 0,
            value: [],
            tenvp: null,
            maloaivp: null,
            mavp: null,
            manhomvp: null,
            autoGenerate : false
        })
    }
    CreateVP() {
        if (this.validator.allValid()) {
            const data = {
                ma: this.state.autoGenerate ? "" : this.state.mavp,
                tenvp: this.state.tenvp,
                loai: Number(this.state.maloaivp),
                gia_th: Number(this.state.giatien),
                gia_bh: 0,
                lstbanggia: this.state.value,
                servicepackage : this.state.selectedPackage,
            }
            VPService.TaoMoiVP(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Tạo thành công!' });
                    this.notiRef.Close();
                    this.resetState();
                }
                else {
                    this.notiRef.Show(result.err.msgString, NotifyStatus.Warning);
                    // notification.warning({ message: this.getErrorMessage(result.err.msgString) });
                    notification.warning({ message: 'Tạo thất bại!' });
                    this.setState({
                        mavp: "",
                        selectedPackage : ''
                    })
                }
            }).catch(err => {
                notification.warning({ message: 'Tạo thất bại!' });
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    getErrorMessage(message){
        if (message) {
            let errMsg = message.split("\r\n").find(x => x.includes("HIS.Commons.HisException:"));
            return errMsg ? errMsg.trim().replace("HIS.Commons.HisException:", "") : message;
        } return "";
    }

    GetListServicePackage = () => {
        let filterModel = {
            keyword : '',
        }
        PackageService.ListServicePackageForSelected(filterModel).then(result => {
            if (result.isSuccess) {
                this.setState({ lstServicePackage : result.data})
            }
            else {
                notification.warning({ message: result.error.messageText });
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.warning({ message: err.error.messageText });
            else notification.warning({ message: err });
        }).finally(() => {
        })
    }

    renderDetailPackage = (value = this.state.selectedPackage) => {
        if(value && value !== ''){
            let item = groupBy(this.state.lstServicePackage.find(x => x.id === value)?.listServicePackageItem, 'groupName');
            return (
                Object.keys(item).map(key => ({key, value : item[key]})).map(x => (
                    <div className="d-flex flex-column w-100" key={`pk-${x.key}`}>{/* 1 item */}
                        <div className="d-flex justify-content-between w-100">{/* 1 row in item */}
                            <div className=''>{/* column 1 */}
                                <label className='mita-title'>{x.key}</label>
                            </div>
                        </div>
                        {x.value.map(c => (
                            <div className='ml-2'>{/* column 1 */}
                                <label className='mita-value'>{c.name}</label>
                            </div>
                        ))}
                    </div>
                ))
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Thêm viện phí</h3>
                    </div>
                    <Notify ref={(c) => this.notiRef = c}  />
                    <div className="row no-gutters">
                        <div className="col-md-6">
                            <Form.Item label="Nhóm:" name="manhomvp">
                                <Select
                                    value={this.state.manhomvp}
                                    onChange={(e) => {
                                        e === 4 ? this.setState({ manhomvp: 4, maloaivp: 11 }, this.loadDanhSachLoaiVP(e)) :
                                        this.setState({ manhomvp: e, maloaivp: null }, this.loadDanhSachLoaiVP(e));
                                    }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstNhomVP.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                                {this.validator.message("Nhóm viện phí", this.state.manhomvp, "selection")}
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item label="Loại:">
                                <Select
                                    value={this.state.manhomvp === 4 ? 11 : this.state.maloaivp}
                                    onChange={(e) => this.setState({ maloaivp: e })}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstLoaiVP.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                                {this.validator.message("Loại viện phí", this.state.maloaivp, "selection")}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row no-gutters mt-2" style={{ display: 'flex' }}>
                        <div className="col-md-6">
                            <Form.Item style={{ flex: 1 }} label="Mã viện phí:" name="mavp" >
                                <Input
                                    disabled={this.state.autoGenerate}
                                    value={this.state.autoGenerate ? "" : this.state.mavp}
                                    onChange={(e) => this.setState({ mavp: e.target.value })}
                                    suffix={<Tooltip title="Tự động tạo mã"><Checkbox checked={this.state.autoGenerate} onChange={(e) => { this.setState({ autoGenerate: e.target.checked }); this.validator.purgeFields() }}></Checkbox></Tooltip>}
                                />
                                {!this.state.autoGenerate && this.validator.message("Mã viện phí", this.state.mavp, "required")}
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item label="Giá tiền:">
                                <InputNumber
                                    style={{ width: "100%" }}
                                    maxLength={10}
                                    min={0}
                                    value={this.state.giatien}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    onKeyDown={(e) => {
                                        if (e.key === 'e') {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        this.setState({ giatien: e })
                                    }} />
                                {this.validator.message("Giá tiền", this.state.giatien, "required")}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row no-gutters mt-2">
                        <div className="col-md-12">
                            <Form.Item label="Tên viện phí:">
                                <Input
                                    value={this.state.tenvp}
                                    onChange={(e) => this.setState({ tenvp: e.target.value })}
                                />
                                {this.validator.message("Tên viện phí", this.state.tenvp, "required")}
                            </Form.Item>
                        </div>
                    </div>
                    {FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 &&
                        <div className="row no-gutters mt-2" style={{ display: 'flex' }}>
                            <div className="col-md-12">
                                <Form.Item>
                                    <label>Áp dụng đồng thời cho bảng giá</label>
                                    <Select
                                        mode='multiple'
                                        style={{ width: '100%' }}
                                        value={this.state.value}
                                        options={this.state.options}
                                        onChange={(newValue) => {
                                            this.setState({
                                                value: newValue
                                            });
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </div>}
                    {FeatureDeactive().indexOf(FEATURE.COMBO) == -1 && this.state.manhomvp === COMBO &&
                    <>
                        <div className="row no-gutters mt-2" style={{ display: 'flex' }}>
                            <div className="col-md-12">
                                <Form.Item>
                                    <label>
                                        Gói dịch vụ 
                                        {this.state.selectedPackage !== '' && 
                                            <Popover placement="top" content={ this.renderDetailPackage() }>
                                                <span><i className="fas fa-info-circle ml-1"></i></span>
                                            </Popover>
                                        }
                                    </label>
                                    <Select
                                        style={{ width: '100%' }}
                                        value={this.state.selectedPackage}
                                        onChange={(value, record) => {
                                            this.setState({
                                                selectedPackage : value,
                                            });
                                        }}
                                        allowClear
                                        onClear={() => this.setState({ selectedPackage : ''})}
                                    >
                                        {this.state.lstServicePackage.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.id}>{row.name}</Option>
                                        )
                                    })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        
                    </>}
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}>
                        <button className="btn btn-success"
                            onClick={() => { this.CreateVP() }}>
                            <i className="fa fa-plus" style={{ marginRight: 5 }} />Thêm</button>
                        <button className="btn btn-warning" style={{ marginLeft: 10 }}
                            onClick={() => this.resetState()}> <i style={{ marginRight: 5 }} className="fa fa-eraser" />Nhập mới</button>
                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                            onClick={() => {
                                this.resetState();
                                this.props.onCancel();
                                this.props.onReload();
                            }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} /> Đóng
                        </button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}
