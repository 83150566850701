import React from 'react';
import { Tree } from 'antd';
export default class TreeRender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            expandedKeys: [],
            checkedKeys: [],
            selectedKeys: [],
            autoExpandParent: true,
            halfCheckedKeys: [],
        }
    }

    onExpand = expandedKeys => {
        this.setState({ expandedKeys }, () => this.reRenderTreeData());
    };
    getSelected() {
        if (this.state.halfCheckedKeys) {
            return this.state.checkedKeys.concat(this.state.halfCheckedKeys).filter(x => x != "");
        } else {
            return this.state.checkedKeys.filter(x => x != "");
        }
    }
    setelected(checkedKeys) {
        this.setState({ checkedKeys });
    }
    onCheck = (checkedKeys, e) => {
        this.setState({ checkedKeys: checkedKeys.checked, halfCheckedKeys: e.halfCheckedKeys }, () => this.reRenderTreeData(e));
    };

    onSelect = (selectedKeys, info) => {
        this.setState({ selectedKeys });
    };
    reRenderTreeData(currentNode) {
        let { data, checkedKeys } = this.state;
        if (currentNode) checkedKeys = this.getListCheckedAfterUncheckParent(currentNode);
        data = this.createNewTreeData(data, checkedKeys);
        this.setState({ data, checkedKeys });
    }

    createNewTreeData(data, checkedKeys) {
        if (data)
            data = data.map(obj => ({
                ...obj,
                children: obj.children && obj.children.length > 0 ? this.createChildren(obj.children, checkedKeys, obj.key) : [],
            }))
        return data;
    }

    createChildren(data, checkedKeys, parentKey) {
        if (data)
            data = data.map(obj => ({
                ...obj,
                checkable: checkedKeys.find(x => x == parentKey) != undefined,
                children: obj.children && obj.children.length > 0 ? this.createChildren(obj.children, checkedKeys, obj.key) : [],
            }))
        return data;
    }

    getListCheckedAfterUncheckParent(currentNode) {
        const node = currentNode.node;
        let { checkedKeys } = this.state;
        if (node.key && !currentNode.checked) {
            let listRemove = this.pushChildrenToRemove(node);
            checkedKeys = checkedKeys.filter(x => !listRemove.includes(x));
        }
        return checkedKeys;
    }

    pushChildrenToRemove(node, listRemove = []) {
        node.key && listRemove.push(node.key);
        if (node.children && node.children.length > 0) {
            node.children.forEach((child) => {
                listRemove.push(child.key);
                if (child.children && child.children.length > 0) {
                    this.pushChildrenToRemove(child, listRemove);
                }
            })
        }
        return listRemove;
    }

    render() {

        return (
            <Tree
                checkable
                onExpand={this.onExpand}
                expandedKeys={this.state.expandedKeys}
                autoExpandParent={this.state.autoExpandParent}
                onCheck={this.onCheck}
                checkedKeys={this.state.checkedKeys}
                onSelect={this.onSelect}
                selectedKeys={this.state.selectedKeys}
                treeData={this.state.data}
                checkStrictly={true}
            />
        );
    }
};
