import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../../configureHIS/common/headerModal';
import { Button, DatePicker, Form, Input, Radio, Checkbox, Select, Table, Tooltip, notification, Alert, Modal } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../../configureHIS/common/constants';
import * as Actions from '../../libs/actions';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import { FORMAT_DATE_TIME } from '../../configure/systemConfig';
import Administrative from '../recept/components/administrative';
import NguonKhachHang from "../../screens/recept/register/nguonkhachhang";
import * as ConfigService from '../../services/configService';
import { IsActive_FeatureLCTV } from '../../configure/featureConfig';
import * as DistributeServices from '../dieuphoi/distributeservices';
import SelectProductAutocomplete from '../../components/MDCLS/selectProductAutocomplete';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from '../../services/commonService';
import Notify, { NotifyStatus } from '../../components/notify';
import ModalCLS from '../../components/MDCLS';
import * as CollaboratorsServices from '../nhomctv/nhomctvservices';

const { Option } = Select;

const TaoLichLayMauTaiNha = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        getListSite();
        getListGiaLayMau();
        getListCollaboratorsGroup();
    }, []);

    const _notify = useRef();
    const _form = useRef();
    const _administrative = useRef();
    const _nguonKhachHang = useRef();
    const _selectProductAutocompleteRef = useRef();
    const _orderRef = useRef();
    const [listChiDinh, setListChiDinh] = useState([]);
    const [maBS, setMaBS] = useState();
    const [maPhongKham, setMaPhongKham] = useState("");
    const [visible, setVisible] = useState(false);
    const [maCTV, setMaCTV] = useState("");
    const [newAddress, setNewAddress] = useState("");
    const [diffAddr, setDiffAddr] = useState(false);
    const [dataOrder, setDataOrder] = useState([]);
    const [maBN, setMaBN] = useState("");
    const [maChiDinh, setMaChiDinh] = useState("");
    const [ngayHen, setNgayHen] = useState(moment());
    const [loaiChiDinh, setLoaiChiDinh] = useState("");
    const [tenChiDinh, setTenChiDinh] = useState("");
    const [hoTen, setHoTen] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [bangGiaSite, setBangGiaSite] = useState("");
    const [bangGiaNguonKhacHang, setBangGiaNguonKhacHang] = useState();
    const [site, setSite] = useState("");
    const [listSite, setListSite] = useState([]);
    const [listCTV, setListCTV] = useState([]);
    const [getType, setGetType] = useState(IsActive_FeatureLCTV() ? 2 : 1);
    const [nhomCTV, setNhomCTV] = useState("all");
    const [listNhomCTV, setListNhomCTV] = useState([]);
    const [chiNhanh, setChiNhanh] = useState(props.chiNhanh);
    const [vatInvoice, setVatInvoice] = useState(false);
    const [ghiChu, setGhiChu] = useState("");
    const [address, setAddress] = useState("");
    const [managementID, setManagementID] = useState("");
    const [code, setCode] = useState("");
    const [total, setTotal] = useState(0);
    const [serKey, setSerKey] = useState("");
    const [visibleOrder, setVisibleOrder] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [idGiaLayMau, setIdGiaLayMau] = useState(0);
    const [listGiaLayMau, setListGiaLayMau] = useState([]);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [bangGia, setBangGia] = useState('default');

    const onCloseModal = () => {
        props.onCancel && props.onCancel();
        reset();
        _notify && _notify.current && _notify.current.Close();
    }

    const reset = () => {
        if (_form && _form.current) {
            _form.current.resetFields();
            _form.current.setFieldsValue({
                maBN: null,
                maBS: null,
                phongKham: null,
                ngayHen: null,
                maPhongKham: null,
                maCTV: null
            });
        }
        setListChiDinh([]);
        setMaBS("");
        setMaPhongKham("");
        setVisible(false);
        setMaCTV("");
        setNewAddress("");
        setDiffAddr(false);
        setDataOrder([]);
        setMaChiDinh('');
        setNgayHen(moment());
        setMaBN("");
        setLoaiChiDinh("");
        setTenChiDinh("");
        setHoTen("");
        Actions.ResetBarCode(true);
        _administrative && _administrative.current && _administrative.current.resetState();
    }

    const disabledDate = (current) => {
        return current.valueOf() < Date.now();
    }

    const getListCollaborators = (type = getType, group = nhomCTV, chiNhanh) => {
        var getAll = type === 1 ? true : false;
        if (!getAll) {
            var date = moment(ngayHen).format('YYYY/MM/DD HH:mm:ss');
            DistributeServices.GetListCollaborators(date, true, group, chiNhanh).then(res => {
                if (res.isSuccess) {
                    setListCTV(res.data);
                }
            }).catch(() => { })
        }
        else {
            CommonService.GetListCollaborators(1, 10, false, group, chiNhanh).then(res => {
                if (res.isSuccess) {
                    setListCTV(res.data);
                }
            }).catch(() => { })
        }
    }

    const getListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => { })
    }

    const settingBangGia = (bangGiaNguonKH, bangGiaSite) => {
        let banggiatemp = bangGiaNguonKH;
        if (banggiatemp === undefined || banggiatemp === null || banggiatemp === '')
            banggiatemp = bangGiaSite;
        if (banggiatemp === undefined || banggiatemp === null || banggiatemp === '')
            banggiatemp = 'default';
        if (banggiatemp !== bangGia)
            checkBangGia({ banggia: banggiatemp });
    }

    const checkBangGia = (info) => {
        if (info.banggia !== bangGia) {
            if (dataOrder.length > 0) {
                Actions.openMessageDialog("Cảnh báo",
                    `Việc thay đổi bảng giá mới (<b>${info.banggia === "default" ? "Mặc định" : info.banggia}</b>) sẽ xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${bangGia === "default" ? "Mặc định" : bangGia}</b>)`)
                setDataOrder([]);
                _orderRef && _orderRef.current && _orderRef.current.UpdateSelectedFromProps([]);
            }
            setBangGia(info.banggia);
        }
    }

    const onSelectIndications = (products = []) => {
        let listSelectedServices = [...dataOrder];
        let listAll = getAllService();
        let total = 0;
        let isUpdateList = true;
        products.filter(x => x.isInBangGia).forEach(item => {
            var existed = listAll.find((x) => x.id === item.id);
            if (existed === undefined) {
                let itemConvert = convertToModelSaveNew(item);
                if (item.isCombo) {
                    itemConvert.listChild = [];
                    item.listChild.forEach(xChild => {
                        let itemChild = convertToModelSaveNew(xChild);
                        var existedChild = listAll.find((x) => x.id === itemChild.id);
                        if (existedChild !== undefined) {
                            if (existedChild.isChild)
                                notification.warning({ message: `Dịch vụ [${existedChild.ten}] đã tồn tại trong combo [<b>]${existedChild.vienPhiTenOfCombo}]` });
                            else notification.warning({ message: `Dịch vụ [${existedChild.ten}] đã tồn tại` });
                            isUpdateList = false;
                            return;
                        }
                        itemChild.vienPhiIdOfCombo = item.id;
                        itemChild.isChild = true;
                        itemChild.key = `${item.id}-${itemChild.id}`;
                        itemConvert.listChild.push(itemChild);
                    });
                }
                listSelectedServices.push(itemConvert);
            }
            else {
                if (existed.isChild)
                    notification.warning({ message: `Dịch vụ [${existed.ten}] đã tồn tại trong combo [${existed.vienPhiTenOfCombo}]` });
                else notification.warning({ message: `Dịch vụ [${existed.ten}] đã tồn tại` });
                isUpdateList = false;
                return;
            }
        })
        listSelectedServices.map(
            (x) => (total += (x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
        );

        if (isUpdateList) {
            setDataOrder(listSelectedServices);
            setTotal(total);
        }
    }

    const getAllService = () => {
        let list = [];
        (dataOrder ?? []).forEach(item => {
            list.push(item);
            if (item.isCombo) {
                item.listChild.forEach(xChild => {
                    xChild.vienPhiTenOfCombo = item.ten;
                    xChild.key = `${item.id}-${xChild.id}`;
                    list.push(xChild);
                });
            }
        });
        return list;
    }

    const getListGiaLayMau = () => {
        AppointmentService.GetListGiaLayMau(true).then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    _form && _form.current && _form.current.setFieldsValue({ idGiaLayMau: result.data[0].id })
                    setIdGiaLayMau(result.data[0].id);
                }
                setListGiaLayMau(result.data);
            }
            else {
                notification.error({ message: result.err.msgString });
            }
        }).catch(err => {
            notification.error({ message: err });
        })
    }

    const getListCollaboratorsGroup = () => {
        CollaboratorsServices.GetListGroup("", 1, -1).then(res => {
            if (res.isSuccess) {
                setListNhomCTV(res.data);
            } else {
                notification.warning({ message: "Không thể lấy thông tin nhóm" })
            }
        }).catch(err => { })
    }

    const convertToModelSaveNew = (x) => {
        return {
            banggia: bangGia,
            soluong: 1,
            idChiDinh: "0",
            madoituong: "2",
            giaaTH: x.gia_Th.toString(),
            dongia: x.gia_Th,
            ngay: moment().format('YYYY/MM/DD HH:mm'),
            maql: managementID,
            mabn: code,
            id: x.id,
            mavp: x.ma,
            ten: x.ten,
            groupId: x.nhomId,
            listChild: x.listChild,
            typeId: x.loaiId,
            typeName: x.loaiTen,
            isCombo: x.isCombo,
            dongiakm: null,
            discount: 0,
        };
    }

    let dataSource = dataOrder.map(function (e, index) {
        let o = Object.assign({}, e);
        o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString() + '-') : "") + o.id.toString();
        o.children = o.listChild;
        return o;
    })

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            align: 'center',
            render: (text, record, index) => {
                return (record.isChild ? "" : (Number(index) + 1));
            },
        },
        {
            title: 'Mã DV',
            dataIndex: 'mavp',
            key: 'mavp',
            width: 100,

        },
        {
            title: 'Tên DV',
            dataIndex: 'ten',
            key: 'ten',
            width: 240,

        },
        {
            title: 'Đơn giá',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            width: 120,
            render: (value, row) => {
                return (row.isChild ? "" : (<span >{value.toLocaleString('en-US')}</span>))
            },
        },
        {
            title: '',
            key: '',
            align: 'center',
            render: (text, record) => {
                return (
                    record.isChild ? "" : (
                        <div style={{ cursor: 'pointer' }}
                            className="text-danger"
                            onClick={() => {
                                xoaChiDinh(record);
                                if (_orderRef && _orderRef.current)
                                    _orderRef.current.XoaChiDinh(record);
                            }} >
                            <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                        </div>
                    )
                )
            }
        }
    ]

    const xoaChiDinh = (row) => {
        let data = dataOrder;
        data = data.filter(x => x.id !== row.id);
        setDataOrder(data.map(x => ({ ...x })));
    }

    const saveLichHen = async () => {
        if (ngayHen === null || ngayHen === undefined) {
            notification.warning({ message: 'Chưa chọn ngày hẹn khám!' });
            return;
        }
        let numberError = 0;
        await _form.current.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        let newPatient = (maBN === null || maBN === undefined || maBN === "");
        if (newPatient) {
            await _administrative.current.getForm().validateFields().then(values => {
                if (values.errorFields)
                    numberError += values.errorFields.length

            }).catch((ex) => {
                if (ex.errorFields)
                    numberError += ex.errorFields.length
            })
        }
        if (numberError > 0) {
            validator.current.showMessages();
            _notify && _notify.current && _notify.current.Show(`Có trường dữ liệu cần nhập`, NotifyStatus.Warning, 3);
            return;
        }
        Actions.setLoading(true);
        let dataAdministrative = {};
        dataAdministrative = _administrative && _administrative.current && _administrative.current.getState();
        let dataNguonKhachHang = _nguonKhachHang && _nguonKhachHang.current && _nguonKhachHang.current.getState();
        let lstSelect = [...dataOrder];
        let listChiDinh = [];
        for (let item of lstSelect) {
            item.ma = "0";
            item.madoituong = "2";
            item.giaaTH = item.dongia.toString();
            item.dongia = item.dongia;
            item.soluong = item.soluong;
            item.ngay = moment().format("YYYY-MM-DD HH:mm")
            if (listChiDinh.filter(x => x.id == item.id).length > 0) {
                notification.warning({ message: `Dịch vụ [${item.ten}] bị trùng` });
                return;
            }
            listChiDinh.push(item);
            if (item.isCombo)
                item.listChild.forEach(xChild => {
                    let itemChild = xChild;
                    itemChild.giaaTH = xChild.dongia.toString();
                    itemChild.dongia = xChild.dongia;
                    itemChild.soluong = xChild.soluong;
                    itemChild.ngay = moment().format("YYYY-MM-DD HH:mm");
                    itemChild.vienPhiIdOfCombo = item.id;
                    if (listChiDinh.filter(x => x.id == itemChild.id).length > 0) {
                        notification.warning({ message: `Dịch vụ [${itemChild.ten}] nằm trong combo [${item.ten}] bị trùng` });
                        return;
                    }
                    listChiDinh.push(itemChild);
                });
        }
        const data = {
            mabn: maBN.toString(),
            mabs: maBS,
            machidinh: Number(maChiDinh),
            phongkham: maPhongKham,
            tenchidinh: tenChiDinh.toString(),
            ngayhen: ngayHen,
            newPatient: newPatient,
            patient: {
                'name': dataAdministrative.name.toUpperCase(),
                'dob': moment(dataAdministrative.dob).format('YYYY-MM-DD'),
                'age': dataAdministrative.age,
                'gender': dataAdministrative.gender,
                'people': "25",
                'cmnd': dataAdministrative.cmnd,
                'phone': dataAdministrative.phone,
                'email': dataAdministrative.email,
                'address': dataAdministrative.address,
                'city': dataAdministrative.city ? dataAdministrative.city.toString() : '',
                'district': dataAdministrative.district ? dataAdministrative.district.toString() : '',
                'ward': dataAdministrative.ward ? dataAdministrative.ward.toString() : '',
                'jobAddress': dataAdministrative.jobAddress,
                'village': dataAdministrative.village !== undefined ? dataAdministrative.village : '',
                'country': dataAdministrative.nation,
                'dateInHospital': moment().format('YYYY/MM/DD'),
                'timeInHospital': moment().format('HH:mm'),
                'department': maPhongKham,
                'subjectCode': "2",
                'coporator': dataAdministrative.coporator,
                'sendby': dataAdministrative.sendby,
                'onlyyear': dataAdministrative.onlyyear,
                'year': dataAdministrative.year,
            },
            lstChiDinh: listChiDinh,
            mactv: maCTV,
            idGiaLayMau: idGiaLayMau,
            newAddress: newAddress,
            vatinvoice: vatInvoice,
            ghichu: ghiChu,
            idsale: dataNguonKhachHang.idsale ? dataNguonKhachHang.idsale.toString() : "",
            otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
            manguoigioithieu: dataNguonKhachHang.idngt ? dataNguonKhachHang.idngt.toString() : "",
            site: site.toString(),
        }
        AppointmentService.LuuLichHenLayMau(data, chiNhanh).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu thành công!' })
                onCloseModal();
            } else {
                Actions.openMessageDialog("Lỗi", result.err.msgString);
            }
        }).catch(err => {
            notification.warning({ message: 'Xảy ra lỗi khi lưu, vui lòng thử lại!' });
        }).finally(() => Actions.setLoading(false))
    }

    return (
        <Fragment>
            <HeaderModal title="Tạo lịch hẹn lấy mẫu tại nhà" onClose={() => { onCloseModal() }} />
            <Notify ref={_notify} />
            <Form className='mt-2' ref={_form} style={{ height: 'calc(100% - 145px)' }}>
                <div className="h-100 d-flex">
                    <div className="warrperStyleGroup" style={{ height: '100%', marginRight: 20, flex: 3 }} >
                        <div className="grouplabel">
                            <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Thông tin bệnh nhân</h4>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: 5 }}>
                            <div style={{ flex: 2, textAlign: 'left', marginRight: '40px' }}><strong>Mã bệnh nhân: {maBN}</strong></div>
                            <div style={{ display: 'flex', alignItems: "center" }}>
                                <label style={{ marginRight: 5 }}>Ngày hẹn:</label>
                                <Form.Item name="ngayHen" rules={[{ required: true, message: 'Vui lòng chọn ngày hẹn!' }]} >
                                    <DatePicker
                                        locale={viVN}
                                        showTime
                                        format={"DD-MM-YYYY HH:mm"}
                                        className="w-100"
                                        disabledDate={disabledDate}
                                        onChange={(e) => { setNgayHen(e); getListCollaborators() }}
                                        value={moment(ngayHen)} placeholder={FORMAT_DATE_TIME} />
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto', padding: 0, overflowX: 'hidden' }}>
                            <Administrative ref={_administrative}
                                onSeached={(patientID) => {
                                    setMaBN(patientID);
                                }}
                                isUpdate={isUpdate}
                                onReplaceCoporation={() => { }}
                            />
                            <Form.Item>
                                <Alert type='warning' className='mt-2'
                                    message={<i>* Chỉ có thể thêm/cập nhật nguồn khách hàng khi có thông tin chỉ định</i>}
                                ></Alert>
                            </Form.Item>
                            <div className='row'>
                                <NguonKhachHang ref={_nguonKhachHang}
                                    updateInfoBangGia={(info) => {
                                        settingBangGia(info.onClear ? null : info.banggia, bangGiaSite);
                                        setBangGiaNguonKhacHang(info.onClear ? null : info.banggia);
                                    }}
                                />
                            </div>
                            <div className='mt-2'>
                                <Form.Item>
                                    <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle' /></Tooltip></label>
                                    <Select
                                        placeholder="Chọn chi nhánh"
                                        style={{ width: '100%' }}
                                        value={site}
                                        onChange={(value, record) => {
                                            setSite(value);
                                            setBangGiaSite(record.item.banggia);
                                            settingBangGia(bangGiaNguonKhacHang, record.item.banggia);
                                        }}
                                    >
                                        {listSite && listSite.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id} item={item}>{item.ten}</option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='mt-2'>
                                <label>Nhóm cộng tác viên</label>
                                <Form.Item name="nhomCTV" rules={[{ required: true, message: 'Vui lòng chọn nhóm cộng tác viên!' }]} >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Chọn nhóm cộng tác viên"
                                        onChange={(e) => { setNhomCTV(e); getListCollaborators(getType, e); _form.current.setFieldsValue({ maCTV: null }); }}>
                                        <Option value="all">Tất cả nhóm</Option>
                                        {listNhomCTV && listNhomCTV.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.label}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='mt-2'>
                                <label>
                                    <Radio.Group onChange={(e) => { setGetType(e.target.value); getListCollaborators(e.target.value, nhomCTV, chiNhanh) }} value={getType}>
                                        <Radio value={1}>Tất cả CTV</Radio>
                                        {IsActive_FeatureLCTV() &&
                                            <Radio value={2}>CTV đã đăng ký lịch</Radio>
                                        }
                                    </Radio.Group>
                                </label>
                                <Form.Item name="maCTV" rules={[{ required: true, message: 'Vui lòng chọn cộng tác viên!' }]} >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Chọn cộng tác viên"
                                        allowClear={true}
                                        onChange={(e) => { setMaCTV(e) }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        {listCTV && listCTV.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='mt-2'>
                                <Checkbox
                                    checked={vatInvoice}
                                    onChange={() => setVatInvoice(!vatInvoice)}
                                >Xuất hóa đơn đỏ</Checkbox>
                            </div>
                            <div className='mt-2'>
                                <label>Ghi chú</label>
                                <Form.Item>
                                    <Input
                                        placeholder={"Nhập ghi chú"}
                                        value={ghiChu}
                                        onChange={(e) => setGhiChu(e.target.value)}
                                    />
                                </Form.Item>
                            </div>
                            <div className='mt-2'>
                                <label>Địa chỉ lấy mẫu</label>&nbsp;<i style={{ color: "#ccc" }}>{diffAddr ? "[Bấm vào \"Giống địa chỉ liên hệ\" để nhập địa chỉ mới]" : "[Bấm vào \"Địa chỉ mới\" để chọn địa chỉ liên hệ]"}</i>
                                <Form.Item name="newAddress" rules={[{ required: !diffAddr, message: 'Vui lòng nhập địa chỉ lấy mẫu' }]} >
                                    <Input
                                        placeholder={diffAddr ? address : "Nhập địa chỉ lấy mẫu"}
                                        value={newAddress}
                                        disabled={diffAddr}
                                        prefix={
                                            <button className={diffAddr ? "btn btn-secondary btn-sm mr-2" : "btn btn-color btn-sm mr-2"}
                                                onClick={(e) => { e.preventDefault(); setDiffAddr(!diffAddr); setNewAddress("") }}>
                                                {diffAddr ? "Giống địa chỉ liên hệ" : "Địa chỉ mới"}
                                            </button>}
                                        onChange={(e) => setNewAddress(e.target.value)}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="warrperStyleGroup" style={{ height: '100%', width: '40%' }} >
                        <div className="grouplabel">
                            <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Dịch vụ</h4>
                        </div>
                        <div style={{ display: 'flex', width: "100%" }}>
                            <div style={{ width: "60%" }}>
                                <Tooltip placement="top" title={`Bảng giá: ${bangGia === 'default' ? "Mặc định" : bangGia}`}>
                                    <Form.Item label="Chọn dịch vụ">
                                        <SelectProductAutocomplete
                                            ref={_selectProductAutocompleteRef}
                                            showBangGia={true}
                                            bangGia={bangGia}
                                            selectedListServices={[
                                                ...(dataOrder ?? []),
                                            ]}
                                            className="w-100"
                                            dropdownMatchSelectWidth={700}
                                            onSelect={(items) => {
                                                onSelectIndications(items);
                                            }}
                                            value={serKey}
                                            keyValue={'id'}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </div>
                            <div>
                                <Form.Item>
                                    <Tooltip title="Mở danh sách dịch vụ">
                                        <div style={{}}>
                                            <Button className={`${BUTTON.SECONDARY} ml-auto`} onClick={e => {
                                                e.stopPropagation();
                                                setVisibleOrder(true);
                                                setTimeout(() => {
                                                    _orderRef && _orderRef.current && _orderRef.current.UpdateSelectedFromProps(dataOrder)
                                                }, 200)
                                            }}><i className={'fa fa-plus-circle'}></i></Button>
                                        </div>
                                    </Tooltip>
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 65px)' }}>
                            <Table dataSource={dataSource}
                                scroll={{ y: 0 }}
                                pagination={{
                                    total: dataOrder.length,
                                    current: currentPage,
                                    pageSize: pageSize,
                                    onChange: (page, size) => { setCurrentPage(page); setPageSize(size) },
                                    onShowSizeChange: (page, size) => {
                                        setCurrentPage(1); setPageSize(size)
                                    },
                                    showSizeChanger: true,
                                }}
                                columns={columns} rowKey={(e) => e.key} />
                        </div>
                    </div>
                </div>
            </Form>
            <div className='custom-hr'></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Form.Item style={{ marginRight: 10 }}>
                    <button className="btn btn-color" onClick={() => saveLichHen()}> <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                </Form.Item>
                <Form.Item>
                    <button className="btn btn-danger" onClick={() => {
                        onCloseModal();
                    }}><i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng</button>
                </Form.Item>
            </div>
            <Modal
                width={1400}
                visible={visibleOrder}
                onCancel={() => setVisibleOrder(false)}>
                <ModalCLS
                    showGoiKham={false}
                    maskClosable={false}
                    ref={_orderRef}
                    chinhanh={chiNhanh}
                    onClose={(listSelectedServices, state) => {
                        if (listSelectedServices === null) listSelectedServices = [];
                        if (state == 'save')
                            setDataOrder(listSelectedServices);
                        setVisibleOrder(false);
                    }}
                    onReplaceBangGia={(tenBangGia) => {
                        if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
                            setBangGia(tenBangGia);
                        }
                    }}
                    banggia={bangGia}
                    editAfterRegister={true}
                />
            </Modal>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TaoLichLayMauTaiNha)