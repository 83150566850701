import * as ApiCaller from '../../libs/httpRequests';
const prefix = 'ReportPayment';

export function LoadDoanhThuTheoHinhThucThanhToan(page = 1, size = 10, from, to, site, copor) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LoadReportPaymentByType?page=${page}&size=${size}&from=${from}&to=${to}&site=${site}&idcopor=${copor}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportBaoCaoDoanhThuTheoPhieuThanhToan(from, to, site, copor) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportPaymentReportByType?from=${from}&to=${to}&site=${site}&idcopor=${copor}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LoadDoanhThuTheoHinhThucThanhToanLoaiDV(page = 1, size = 10, from, to, site, copor) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LoadReportPaymentByService?page=${page}&size=${size}&from=${from}&to=${to}&site=${site}&idcopor=${copor}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ExportBaoCaoDoanhThuTheoPhieuThanhToanLoaiDV(from, to, site, copor) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGetDownload(`${prefix}/ExportPaymentReportByService?from=${from}&to=${to}&site=${site}&idcopor=${copor}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function PrintBaoCaoDoanhThuTheoPhieuThanhToanLoaiDV(from, to, site,copor) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PrintPaymentReportByService?from=${from}&to=${to}&site=${site}&idcopor=${copor}`).then((respone) => {
            return resolve(respone)
        }).catch((err) => {
            return reject(err)
        })
    });
};