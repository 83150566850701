import { PlusOutlined } from '@ant-design/icons';
import { Modal, notification, Pagination, Table, Switch, Tooltip } from 'antd';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as ManagegerService from '../../services/ManageService';
import * as UsersService from '../../services/usersService';
import CreateAccount from './createAccount';
import TreeRenders from './treeRender';
import * as Actions from '../../libs/actions';
import ChangePassword from './changepassword';
import { BUTTON } from '../../configureHIS/common/constants';
import { connect } from 'react-redux';
import { IsSuperAdmin } from '../../commons/utils';

let task = null
class NguoiDung extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            lstUser: [],
            lstColums: [],
            userName: '',
            hoten: '',
            visibleCreate: false,
            visibleUpdate: false,
            visible: true,
            disabledUpdate: false,
            hidePassword: true,
            selectedEditUserID: 0,
            lstGroup: [],
            group: 1,
            totalRecords: 0,
            currentNumber: 1,
            listPermission: null,
            currentPage: 1,
            currentSize: 10,
            loadingSwitch: false,
            ChangePasswordModal: false,
            isKyThuatVien: false,
            isAdmin: false,
            signPath: '',
        };
    }

    componentWillMount() {
        this.loadListUser();
        this.GetListGroupUser();
    }

    confirm(e) {
        notification.warning({ message: 'Xóa thành công!' })
    }

    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }


    handleCancel = e => {
        this.setState({
            visibleCreate: false,
            userName: '',
            hoten: '',
            selectedEditUserID: 0,
            hidePassword: true,
            chinhanh: [],
            signPath: ''
        });
        this.loadListUser();
    };

    deleteAccount(id) {
        ManagegerService.DeleteAccount(id).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Tài khoản ' + id + ' đã bị xóa' })
                this.setState({ currentPage: 1 }, () => this.loadListUser())

            }
        }).catch(err => {
            notification.warning({ message: 'Xóa tài khoản bị lỗi, vui lòng thử lại!' })
            this.setState({ currentPage: 1 }, () => this.loadListUser())
        })
    }

    loadListUser() {
        ManagegerService.GetListAccount(this.state.key, this.state.currentPage, this.state.currentSize).then(results => {
            if (results.isSuccess) {
                this.setState({
                    lstUser: results.data,
                    totalRecords: results.totalRecords
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Có lỗi xảy ra! Vui lòng thử lại.' });
        })
    }

    GetListGroupUser() {
        ManagegerService.GetListGroupUser().then(result => {
            if (result.isSuccess) {
                let listPermission = []
                for (let i = 0; i < result.data.length; i++) {
                    let temp = {
                        title: result.data[i].label,
                        key: result.data[i].value,
                        children: [
                        ]
                    }
                    listPermission.push(temp)
                }

                this.setState({ listPermission, lstGroup: result.data });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải danh sách nhóm bị lỗi' })
        })
    }

    getGroupUser(id) {
        UsersService.getGroupUser(id).then((res) => {

            if (res.isSuccess === true) {
                this.tree.setelected(res.data)
            } else {
                // notification.warning({message:"Lưu Thất bại"});
            }
        })
    }

    UpdateGroupuser() {
        if (this.state.selectedUserID === undefined) {
            notification.error({ message: 'Chưa chọn tài khoản!' });
            return;
        }
        let data = this.tree.getSelected();
        UsersService.UpdateUserGroup({ userID: this.state.selectedUserID, groupids: data }).then((res) => {
            if (res.isSuccess === true) {
                notification.success({ message: 'Lưu thành công!' });
                this.GetListGroupUser();
            } else {
                notification.warning({ message: "Lưu Thất bại" });
            }
        })

    }

    updateCheckSwitch(row, check) {
        this.setState({ loadingSwitch: true });
        UsersService.UpdateHienThiUser(row.id, check).then(result => {
            let data = this.state.lstUser;
            let item = data.find(x => x.id === row.id);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const newcheck = item.hide;
                    item.hide = !newcheck;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hide;
                    item.hide = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstUser: data.map(x => ({ ...x })),
                loadingSwitch: false,
            })
        }).catch(err => {
            let data = this.state.lstPK;
            let item = data.find(x => x.id === row.id);
            if (item !== undefined) {
                const newcheck = item.hide;
                item.hide = newcheck;
            }
            this.setState({ loadingSwitch: false, lstUser: data.map(x => ({ ...x })) })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }

    clearToken(userID) {
        UsersService.ClearToken(userID)
        .then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Đã đặt thời gian xóa token thành công" });
            } else {
                notification.error({ message: "Không thể đặt thời gian xóa token" });
            }
        })
        .catch(() => {
            notification.error({ message: "Đã xảy ra lỗi đặt thời gian xóa token" });
        })
    }

    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'enterNumber',
                key: 'enterNumber',
                width: 50,
                render: (text, record, index) => ((dataSource.indexOf(record) + 1) + ((this.state.currentPage - 1) * 10)),
            },
            {
                title: 'Tài khoản',
                dataIndex: 'userName',
                key: 'userName',
                width: 110,
            },
            {
                title: 'Họ tên',
                dataIndex: 'name',
                key: 'name',
                width: 170,
                render: (value) => {
                    return <b>{value}</b>;
                },
            },

            {
                title: 'Ngày tạo',
                dataIndex: 'createdTime',
                key: 'createdTime',
                width: 90,
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                },
            },
            {
                title: 'Hiển thị',
                width: 80,
                render: (row) => {
                    return (
                        <Switch loading={this.state.loadingSwitch} checkedChildren="Có" unCheckedChildren="Không" checked={!(row.hide === 1 || row.hide === true)}
                            onChange={(e) => { this.updateCheckSwitch(row, e) }} />
                    )
                }
            },
            {
                title: 'Chi nhánh',
                dataIndex: 'siteName',
                key: 'siteName',
                width: 100,
            },
            {
                title: 'Hub',
                dataIndex: 'hubname',
                key: 'hubname',
                width: 100,
            },
            {
                title: 'Worldfone',
                dataIndex: 'worldfone',
                key: 'worldfone',
                width: 120,
            },
            {
                title: 'Thao tác',
                align: "center",
                render: (row) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-color" onClick={() => {
                                this.setState({
                                    selectedEditUserID: row.id, userName: row.userName, hoten: row.name, group: row.groupID,
                                    visibleCreate: true, hidePassword: true, chinhanh: row.chinhanh, worldfone: row.worldfone,
                                    isKyThuatVien: row.isKyThuatVien, isAdmin: row.isAdmin && Number(row.isAdmin) === 1 ? true : false,
                                    signPath: row.signPath
                                });
                            }}
                                title="Cập nhật tài khoản">
                                <i className='fas fa-edit'></i></button>
                            <button className="btn btn-danger" style={{ marginLeft: 5 }} onClick={() => {
                                this.setState({
                                    selectedEditUserID: row.id, ChangePasswordModal: true,
                                });
                            }}
                                title="Cập nhật mật khẩu">
                                <i className='fas fa-key'></i></button>
                            {IsSuperAdmin() &&
                                <Tooltip title="Xóa token, đăng nhập lại">
                                    <button className={`${BUTTON.CUSTOM} ml-1`}
                                        title={`Token đăng nhập cũ sẽ bị xóa nếu kể từ thời điểm thao tác`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.clearToken(row.id);
                                        }}
                                    ><i className='fas fa-sign-out-alt' /></button>
                                </Tooltip>}
                        </React.Fragment>
                    )
                }

            }
        ];
        let dataSource = this.state.lstUser.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        })
        return (
            <>
                <div className="containerChilderWrapper">
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div className="warrperStyleGroup" style={{ marginRight: 20, height: '100%', width: '65%' }}>
                            <div className="grouplabel">
                                <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Tài khoản</h4>
                            </div>
                            <div className='mt-2' style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <input className="form-control" placeholder="Tìm kiếm tài khoản" style={{ width: "60%" }} onChange={(e) => {
                                        if (task)
                                            clearTimeout(task);
                                        task = setTimeout(() => { this.setState({ key: e.target.value, currentPage: 1 }, () => this.loadListUser()) }, 600)
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            this.setState({ key: e.target.value, currentPage: 1 }, () => this.loadListUser());
                                        }
                                    }} />
                                </div>
                                <button className="btn btn-color ml-auto" style={{ width: 120 }}
                                    onClick={() => {
                                        this.setState({
                                            userName: '',
                                            hoten: '',
                                            worldfone: '',
                                            selectedEditUserID: 0,
                                            visibleCreate: true,
                                        });
                                    }} ><i className='fa fa-plus mr-2'/>Tạo mới</button>
                                {IsSuperAdmin() &&
                                    <Fragment>
                                        <button className={`${BUTTON.CUSTOM} ml-1`}
                                            onClick={(e) => {
                                                this.clearToken(1);
                                            }}
                                        >Xóa token SuperAdmin</button>
                                        <button className={`${BUTTON.CUSTOM} ml-1`}
                                            onClick={(e) => {
                                                this.clearToken("all");
                                            }}
                                        >Xóa tất cả token</button>
                                    </Fragment>}
                            </div>
                            <div style={{ height: 'calc(100% - 90px)', marginTop: 10 }}>
                                <Table dataSource={dataSource}
                                    scroll={{ x: 'max-content', y: 0 }}
                                    pagination={false}
                                    columns={columns} rowKey={(e, index) => index}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: e => {
                                                this.setState({
                                                    selectedUserID: record.id
                                                });
                                                this.getGroupUser(record.id)
                                            }
                                        };
                                    }}
                                    rowClassName={(record, rowIndex) => {
                                        if (record.id === this.state.selectedUserID) {
                                            return "row-color"
                                        }
                                    }} />
                            </div>
                            <Pagination
                                current={this.state.currentPage}
                                size={this.state.currentSize}
                                style={{ textAlignLast: "center", marginTop: "10px" }}
                                total={this.state.totalRecords}
                                showTotal={total => `Tổng: ${total}`}
                                onChange={(page, size) => { this.setState({ currentSize: size, currentPage: page }, () => this.loadListUser(page, size)) }}
                                onShowSizeChange={(page, size) => this.setState({ currentSize: size, currentPage: page }, () => this.loadListUser(page, size))}
                                showSizeChanger={true}
                            />

                        </div>
                        {this.state.lstGroup != null &&
                            <div className="warrperStyleGroup" style={{ height: '100%', width: '32%', position: 'relative' }}>
                                <div className="grouplabel">
                                    <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Nhóm</h4>
                                </div>
                                <div style={{ position: 'absolute', top: 12, right: 0 , zIndex:10 }}>
                                    <button className="btn btn-color" onClick={() => { this.UpdateGroupuser() }}>
                                        <i className="fa fa-save" style={{ marginRight: 5 }}></i>Lưu</button>
                                </div>
                                <div style={{ height: 'calc(100% - 20px)', overflow: 'auto' }}>
                                    {this.state.listPermission &&
                                        <TreeRenders ref={(c) => this.tree = c} style={{
                                            width: '100%', height: '100%',
                                            padding: '20px', display: 'grid !important', gridT0emplateColumns: 'auto auto'
                                        }}
                                            data={this.state.listPermission} />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <Modal maskClosable={false}
                        visible={this.state.visibleCreate}
                        destroyOnClose={true}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}>
                        {this.state.visibleCreate === true &&
                            <CreateAccount
                                hidePassword={this.state.hidePassword}
                                onCancel={this.handleCancel}
                                selectedEditUserID={this.state.selectedEditUserID}
                                userName={this.state.userName} hoten={this.state.hoten}
                                worldfone={this.state.worldfone}
                                chinhanh={this.state.chinhanh}
                                isKyThuatVien={this.state.isKyThuatVien}
                                isAdmin={this.state.isAdmin}
                                signPath ={this.state.signPath}
                            />
                        }
                    </Modal>
                    {
                        this.state.ChangePasswordModal === true &&
                        <Modal maskClosable={false}
                            visible={this.state.ChangePasswordModal}
                            onOk={() => { this.setState({ ChangePasswordModal: false }) }}
                            onCancel={() => { this.setState({ ChangePasswordModal: false }) }}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okButtonProps={{ style: { display: 'none' } }}>
                            {this.state.ChangePasswordModal === true &&
                                <ChangePassword
                                    onCancel={() => { this.setState({ ChangePasswordModal: false }) }}
                                    selectedEditUserID={this.state.selectedEditUserID}
                                    onOk={() => { this.setState({ ChangePasswordModal: false }) }}
                                />
                            }
                        </Modal>
                    }
                </div>
            </>
        )

    }
}

const mapStateToProps = (state) => {
    return ({
        userID: state.global.userID,
    })
  };
export default connect(mapStateToProps, null, null, { forwardRef: true })(NguoiDung);