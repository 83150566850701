import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, useCallback } from 'react';
import HeaderModal from '../common/headerModal';
import { Alert, Checkbox, Input, Pagination, Popover, Table } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import NoContent from "../../components/loading/noContent";
import * as Actions from '../../libs/actions';
import * as StorageService from '../../services/KhoService';
import { capitalizeFirstLeter } from '../../commons/utils';
import * as StoragePermissionService from '../../services/StoragePermissionService';
import { isnotnull } from '../../libs/util';
import { Fragment } from 'react';

var task = null;

const StoragePermission = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const _notify = useRef();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listDataUser, setListDataUser] = useState([]);
    const [listDataPermission, setListDataPermission] = useState([]);
    const [key, setKey] = useState();
    const [listStorage, setListStorage] = useState([]);
    const [selectedUserName, setSelectedUserName] = useState();

    useEffect(() => {
        getListStorage();
    }, []);

    useEffect(() => {
        getListUser();
    }, [page, size]);

    const leftColumns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Tài khoản',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
            width: 100,
        },
        {
            title: 'Họ và tên',
            dataIndex: 'userFullName',
            key: 'userFullName',
            align: 'center',
            width: 150,
            render: (name) => {
                return (
                    <label className='w-100 text-left'>{name}</label>
                )
            }
        },
    ];

    const onDelaySearch = (key) => {
        setKey(key);
        setPage(1);
        if (task) clearTimeout(task)
        task = setTimeout(() => {
            getListUser(key);
        }, 700)
    }

    const rightColumns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{index + 1}</span>;
            }
        },
        {
            title: 'Chức năng',
            dataIndex: 'displayName',
            key: 'displayName',
            align: 'center',
            width: 200,
            render: (func) => {
                return (
                    <label className='w-100 text-left'>{func}</label>
                )
            }
        },
        ...listStorage && listStorage.map((warehouse, index) => {
            return (
                {
                    title: <label>{capitalizeFirstLeter(warehouse.label)}</label> ?? "KHÔNG XÁC ĐỊNH",
                    align: 'center',
                    render: (val, record) => {
                        return generateCheckBoxPermission(record, warehouse);
                    }
                }
            )
        }),
    ]

    const generateCheckBoxPermission = (recordPermission, warehouseInfo) => {
        let hasAuth = false;
        if (isnotnull(recordPermission.permission)) {
            for (let i = 0; i < recordPermission.permission.length; i++) {
                if (recordPermission.permission[i].warehouseId == warehouseInfo.value) {
                    hasAuth = true;
                    break;
                }
            };
        }
        return (
            <Checkbox checked={hasAuth} onChange={(e) => { onUpdateAuth(recordPermission, warehouseInfo, e.target.checked) }}></Checkbox>
        )
    }

    const onUpdateAuth = (recordPermission, warehouseInfo, checked) => {
        let listPermission = [...listDataPermission];
        if (listPermission) {
            let foundIndexFunc = listPermission.findIndex(f => f.name === recordPermission.name);
            if (foundIndexFunc > -1) {
                if (checked) {
                    const newPermission = {
                        warehouseId: warehouseInfo.value,
                        warehouseName: warehouseInfo.label,
                    }
                    listPermission[foundIndexFunc].permission.push(newPermission);
                } else {
                    listPermission[foundIndexFunc].permission = listPermission[foundIndexFunc].permission.filter(p => p.warehouseId !== warehouseInfo.value);
                }
            }
        }
        setListDataPermission(listPermission);
    }

    const getListStorage = (key = "all") => {
        Actions.setLoading(true);
        StorageService.GetListKho(key).then((respone) => {
            if (respone.isSuccess === true) {
                setListStorage(respone.data);
            } else {
                Actions.openMessageDialog("Thông báo", respone.err.msgString.toString());
            }
        }).catch((ex) => {
            Actions.openMessageDialog("Lỗi", ex.toString());
        }).finally(() => Actions.setLoading(false))
    }

    const onRowLeftClickHandler = (record, index, event) => {
        setSelectedUserName(record.username);
        getUserPermission(record.username);
    }

    const getListUser = (keyword = key) => {
        setListDataPermission([]);
        setSelectedUserName();
        Actions.setLoading(true);
        let filterModel = {
            skipCount: (page - 1) * size,
            maxResultCount: size,
            keyword: keyword ?? "",
        }
        StoragePermissionService.getListUser(filterModel).then(res => {
            if (res.isSuccess) {
                setListDataUser(res.data.items);
                setSelectedUserName();
                setTotalRecords(res.data.totalCount);
                if (res.data.items.length == 1) {
                    setSelectedUserName(res.data.items[0].username);
                    getUserPermission(res.data.items[0].username);
                }
                _notify && _notify.current && _notify.current.Close();
            } else {
                _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
            }
        }).catch((err) => {
            _notify && _notify.current && _notify.current.Show(err.error?.messageText ?? "", NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const getUserPermission = (username = selectedUserName, isLoading = true, hideNoti = true) => {
        Actions.setLoading(isLoading);
        StoragePermissionService.getUserPermission(username).then(res => {
            if (res.isSuccess) {
                setListDataPermission(res.data);
                hideNoti && _notify && _notify.current && _notify.current.Close();
            } else {
                _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
            }
        }).catch((err) => {
            _notify && _notify.current && _notify.current.Show(err.error?.messageText ?? "", NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const cancelUpdate = () => {
        getUserPermission(selectedUserName, true);
    }

    const savePermission = () => {
        let permissionArray = [];
        const listPermission = [...listDataPermission];
        listPermission && listPermission.map(x => {
            if (x.permission && x.permission.length > 0) {
                x.permission.map(p => {
                    permissionArray.push(`${x.name}-${p.warehouseId}`);
                })
            }
        })
        const data = {
            permission: permissionArray.join(","),
            username: selectedUserName
        }
        Actions.setLoading(true);
        StoragePermissionService.savePermission(data).then(res => {
            if (res.isSuccess) {
                _notify && _notify.current && _notify.current.Show("Lưu thành công", NotifyStatus.Success);
                let hideNoti = false;
                getUserPermission(selectedUserName, true, hideNoti);
            } else {
                _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
            }
        }).catch((err) => {
            _notify && _notify.current && _notify.current.Show(err.error?.messageText ?? "", NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        });
    }

    const getRowClassName = (data) => {
        let cName = "rowCustom";
        if (data.name.includes("Create")) {
            cName += " bg-success";
        } else if (data.name.includes("Edit")) {
            cName += " bg-warning";
        } else if (data.name.includes("Delete")) {
            cName += " bg-danger";
        }
        return cName;
    }

    return (
        <div className="list-page containerChilderWrapper">
            <HeaderModal title="Phân quyền chức năng kho" />
            <div className="list-page-body" style={{ overflowY: "auto" }}>
                <Notify ref={_notify} />
                <div className='d-flex justify-content-between h-100' style={{ gap: 10 }}>
                    <div style={{ width: 400, maxWidth: "100%", height: "100%" }}>
                        <div className="list-page-body warrperStyleGroup">
                            <Alert className='mt-2' type='info'
                                message={
                                    <Fragment>
                                        <span>Trường hợp tài khoản thuộc nhiều nhóm có phân quyền màn hình giống nhau, hệ thống sẽ lấy tất cả quyền của các nhóm.</span>&nbsp;
                                        <Popover title="Thông tin chú thích" placement='right'
                                            content={
                                                <div style={{ whiteSpace: "pre-line", wordWrap: "break-word", }}>
                                                    Ví dụ: Nguyễn Văn A được phân vào 2 nhóm: Nhóm A và Nhóm B.<br/>
                                                    Cả 2 nhóm đều được phân quyền màn hình KHO<br/>
                                                    - Nhóm A được xem phiếu xuất kho<br/>
                                                    - Nhóm B được tạo phiếu xuất kho.<br/>
                                                    Hệ thống sẽ liệt kê các quyền có thể của Nguyễn Văn A là: Xem phiếu xuất kho, tạo phiếu xuất kho.
                                                </div>
                                            }>
                                            <i className='far fa-question-circle'></i>
                                        </Popover>
                                    </Fragment>}>
                            </Alert>
                            <div className="list-page-search">
                                <div className='d-flex justify-content-between align-items-end' style={{ gap: 20 }}>
                                    <div className='mt-2'>
                                        <label className='mita-title'>Tài khoản</label>
                                        <Input value={key} placeholder='Nhập tài khoản hoặc tên để tìm'
                                            onChange={(e) => { onDelaySearch(e.target.value) }}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <button className={`${BUTTON.THEME}`} onClick={() => { getListUser() }}><i className='fas fa-search mr-1' />Tìm</button>
                                    </div>
                                </div>
                            </div>
                            <div className="list-page-table mt-2">
                                <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                                    className="table-min-h-0"
                                    dataSource={listDataUser}
                                    columns={leftColumns}
                                    locale={{ emptyText: <NoContent /> }}
                                    scroll={{ x: 0, y: 0 }}
                                    rowKey={(e) => e.id}
                                    pagination={false}
                                    rowClassName={(record, rowIndex) => {
                                        return selectedUserName == record.username ? "rowCustomSelectActive" : "rowCustom"
                                    }}
                                    onRow={(record, index) => {
                                        return {
                                            onClick: e => {
                                                onRowLeftClickHandler(record, index, e);
                                            }
                                        };
                                    }}
                                />
                                <Pagination
                                    current={page}
                                    pageSize={size}
                                    size="small"
                                    style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
                                    total={totalRecords}
                                    showTotal={total => `Tổng: ${total}`}
                                    onChange={(page, size) => { setPage(page); setSize(size) }}
                                    onShowSizeChange={(page, size) => { setPage(1); setSize(size) }}
                                    showSizeChanger={true}
                                    locale={{ items_per_page: "kết quả / trang" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "calc(100% - 410px)", maxWidth: "100%", height: "100%" }}>
                        <div className="list-page-body warrperStyleGroup">
                            <div className="list-page-search">
                                {selectedUserName && <div className='d-flex justify-content-between align-items-center'>
                                    <div className='mt-2'>
                                        Phân quyền cho <b>{listDataUser.find(x => x.username == selectedUserName)?.userFullName} ({selectedUserName})</b>
                                    </div>
                                    <div className='d-flex justify-self-end align-items-center ml-auto'>
                                        <div className='mt-2'>
                                            <button className={`${BUTTON.THEME}`} onClick={() => { savePermission() }}>Lưu</button>
                                        </div>
                                        {/* <div className='mt-2'>
                                            <button className={`${BUTTON.DANGER} ml-2`} onClick={() => { cancelUpdate() }}>Hủy bỏ thay đổi</button>
                                        </div> */}
                                    </div>
                                </div>}
                            </div>
                            <div className="list-page-table mt-2">
                                <Table style={{ height: "100%", width: "100%" }}
                                    className="table-min-h-0"
                                    dataSource={listDataPermission}
                                    columns={rightColumns}
                                    locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp, vui lòng chọn tài khoản trước nếu chưa chọn" /> }}
                                    scroll={{ x: 0, y: 0 }}
                                    rowKey={(e) => e.id}
                                    pagination={false}
                                    rowClassName={(record, rowIndex) => {
                                        return getRowClassName(record);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return ({
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(StoragePermission)