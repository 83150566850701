import { Input, DatePicker, Form, notification, Select, InputNumber } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../commons/validationCustom';
import * as NhanVienService from '../../services/employeeService';
import * as ConfigService from '../../services/configService';
import * as Actions from '../../libs/actions';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from "../../configure/systemConfig";
import { isnotnull } from '../../libs/util';
const { Option } = Select;
const initialState = {
    role: null,
    cmnd: '',
    vitri: '',
    id: '',
    code: null,
    hoten: '',
    diachi: '',
    phone: '',
    chucvu: '',
    key: '',
    ngaysinh: null,
    lineManager : null,
}
export default class TaoNhanVien extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            lstNhanVien : [],
            lstViTri: [],
            lstChinhanh: [],
            chinhanh: '',
            hub: '',
            lstHub: [],
            isUpdate: false,
        };
    }

    componentWillMount() {
        this.loadViTri();
        this.GetListSite();
        this.loadNhanVien();
    }

    loadNhanVien() {
        let filterModel = {
            //common: paging & sorting
            skipCount: 0,
            maxResultCount: 10,
            //riêng theo module
            keyword: this.state.key
        }
        NhanVienService.GetListEmployee(filterModel).then(result => {
            if (result.isSuccess) {
                let data = result.data.items.filter(x => x.id !== this.state.id);
                if (isnotnull(this.state.lineManager) && data.find(x => x.uuid == this.state.lineManager) == undefined) {
                    let emp = {
                        uuid: this.state.lineManager,
                        hoTen: this.state.parentName,
                        code: this.state.parentCode,
                    };
                    data.push(emp);
                }
                this.setState({
                    lstNhanVien: data,
                });
            }
        }).catch(err => {
        })
    }

    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ lstChinhanh: result.data })
            }
            else {
                this.setState({ lstChinhanh: [] })
            }
        }).catch(err => {
        })
    }

    loadViTri() {
        NhanVienService.DanhSachVitri().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstViTri: result.data,
                });
            }
        }).catch(err => {
        })
    }


    disabledDate(current) {
        return current && current > moment().endOf('day');
    }

    async TaoNhanVien() {
        if (this.validator.allValid()) {
            let data = {
                hoten: this.state.hoten,
                role: Number(this.state.role),
                phone: this.state.phone,
                diachi: this.state.diachi,
                vitri: this.state.vitri,
                cmnd: this.state.cmnd !== null ? this.state.cmnd.toString() : null,
                ngaysinh: this.state.ngaysinh != null ? moment(this.state.ngaysinh).format(SystemConfig.FORMAT_DATE) : null,
                chinhanh: this.state.chinhanh,
                linemanager : this.state.lineManager,
                code : this.state.code
            }
            if (this.state.isUpdate) {
                data.id = this.state.id;
                NhanVienService.CapNhatNhanVien(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Cập nhật thành công!' });
                        this.props.onOK && this.props.onOK(this.state.isUpdate);
                        this.reset();
                    }
                    else {
                        notification.warning({ message: result.err.msgString });
                    }
                }).catch(() => { })
            } else {
                NhanVienService.ThemNhanVien(data).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Lưu thành công!' });
                        this.reset();
                        this.props.onOK && this.props.onOK();
                    }
                    else {
                        notification.warning({ message: result.err.msgString });
                    }
                }).catch(err => { })
            } 
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    DeleteNhanVien(row) {
        const data = {
            id: row.id,
        }
        NhanVienService.XoaNhanVien(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Xóa thành công!' });
                this.loadNhanVien();
            }

        }).catch(err => {
        })
    }

    reset() {
        this.validator.hideMessages();
        this.setState({
            vitri: '',
            role: null,
            cmnd: '',
            hoten: '',
            diachi: '',
            phone: '',
            chucvu: '',
            key: '',
            ngaysinh: null,
            chinhanh: '',
            hub: "",
            isUpdate: false,
            id: null,
            lineManager : null,
            code : null,
        });
    }

    setUpdateInfo(updateInfo) {
        if (updateInfo) {
            this.setState({
                isUpdate: true,
                id: updateInfo.id,
                vitri: updateInfo.viTri,
                role: updateInfo.roleId,
                cmnd: updateInfo.cmnd,
                hoten: updateInfo.hoTen,
                diachi: updateInfo.diaChi,
                phone: updateInfo.phone,
                chucvu: updateInfo.role,
                key: updateInfo.key,
                ngaysinh: updateInfo.ngaySinh && updateInfo.ngaySinh !== "" ? moment(updateInfo.ngaySinh, SystemConfig.FORMAT_DATE) : null,
                chinhanh: updateInfo.chiNhanh,
                lineManager : updateInfo.parentCode,
                parentName : updateInfo.parentName,
                parentCode : updateInfo.parentId,
                code : updateInfo.code
            }, () => this.loadNhanVien());
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal-header" style={{ display: 'flex', justifyContent: 'left' }}>
                    <h3>{this.state.isUpdate ? "Cập nhật thông tin nhân viên" : "Thêm nhân viên"}</h3>
                </div>
                <div>
                    <div className="row">
                        <div className="col-6 mb-2">
                            <label className='required-field'>Mã nhân viên</label>
                            <Input placeholder="Nhập mã nhân viên"
                                style={{ height: "32px" }}
                                className="w-100"
                                maxLength={10}
                                value={this.state.code}
                                onChange={(e) => { this.setState({ code: e.target.value }) }} />
                            {this.validator.message("Mã nhân viên", this.state.code, "required|maxLen:40")}
                        </div>
                        <div className="col-6 mb-2">
                            <label className='required-field'>Họ tên</label>
                            <Input placeholder="Nhập họ tên"
                                style={{ height: "32px" }}
                                className="w-100"
                                value={this.state.hoten}
                                maxLength={40}
                                onChange={(e) => { this.setState({ hoten: e.target.value }) }} />
                            {this.validator.message("Họ tên", this.state.hoten, "required|maxLen:40")}
                        </div>
                        <div className="col-6 mb-2">
                            <label>Ngày sinh</label>
                            <DatePicker locale={viVN}
                                format={"DD-MM-YYYY"}
                                allowClear={true}
                                value={this.state.ngaysinh}
                                className="w-100"
                                disabledDate={this.disabledDate} style={{ width: "150px" }}
                                onChange={(e) => this.setState({ ngaysinh: e })} placeholder="DD-MM-YYYY" />
                        </div>
                        <div className="col-6 mb-2">
                            <label>Điện thoại</label>
                            <Input placeholder="Nhập số điện thoại" className="w-100"
                                style={{ height: "32px" }}
                                maxLength={11}
                                value={this.state.phone}
                                onKeyPress={(e) => {
                                    if (e.key === " " || isNaN(e.key) === true)
                                        e.preventDefault();
                                }}
                                onChange={(e) => { this.setState({ phone: e.target.value }); this.validator.showMessageFor("Số điện thoại"); }} />
                            {this.validator.message("Số điện thoại", this.state.phone, 'phonenumber')}
                        </div>
                        <div className="col-6 mb-2">
                            <label>CMND/CCCD</label>
                            <Input
                                style={{ height: "32px" }}
                                placeholder="Tối đa 20 số"
                                className="w-100"
                                maxLength={20}
                                value={this.state.cmnd}
                                onKeyPress={(e) => {
                                    if (e.key === " " || isNaN(e.key) === true)
                                        e.preventDefault();
                                }}
                                onChange={(e) => { this.setState({ cmnd: e.target.value }); this.validator.showMessageFor("CMND/CCCD") }}
                            />
                            {this.validator.message("CMND/CCCD", this.state.cmnd, 'cmnd')}
                        </div>
                        <div className="col-6 mb-2">
                            <label className='required-field'>Chức vụ</label>
                            <Select className="w-100"
                                showSearch
                                value={this.state.role}
                                onChange={(value) => { this.setState({ role: value }) }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstViTri.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                            {this.validator.message("Chức vụ làm việc", this.state.role, "selection")}
                        </div>
                        <div className="col-6 mb-2">
                            <label>Vị trí</label>
                            <Input placeholder="Nhập vị trí"
                                style={{ height: "32px" }}
                                className="w-100"
                                value={this.state.vitri}
                                onChange={(e) => { this.setState({ vitri: e.target.value }) }} />
                        </div>
                        <div className="col-6 mb-2">
                            <label>Địa chỉ</label>
                            <Input placeholder="Nhập địa chỉ"
                                style={{ height: "32px" }}
                                maxLength={40}
                                className="w-100"
                                value={this.state.diachi}
                                onChange={(e) => { this.setState({ diachi: e.target.value }) }} />
                        </div>
                        <div className="col-6 mb-2">
                            <label className='required-field'>Chi nhánh</label>
                            <Select
                                className='w-100'
                                showSearch
                                allowClear={false}
                                value={this.state.chinhanh}
                                onChange={(e) => {
                                    this.setState({ chinhanh: e }, () => {
                                        this.setState({ hub: '' });
                                    })
                                }}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstChinhanh.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.ten}</Option>
                                    )
                                })}
                            </Select>
                            {this.validator.message("Chi nhánh", this.state.chinhanh, "selection")}
                        </div>
                        <div className="col-6 mb-2">
                            <label>Người quản lý trực tiếp</label>
                            <Select
                                className='w-100'
                                showSearch
                                allowClear={true}
                                value={this.state.lineManager}
                                onSelect={(e) => {
                                    this.setState({ lineManager: e })
                                }}
                                onClear= {() => {this.setState({lineManager : null})}}
                                onSearch={(e)=> {this.setState({key : e},() => this.loadNhanVien())}}
                                filterOption={(input, option) =>{
                                    return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                                           option.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                }}
                            >
                                {this.state.lstNhanVien && this.state.lstNhanVien.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.uuid}>{row.code} - {row.hoTen}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className='custom-hr'></div>
                <div className="form-group" style={{ display: 'flex', justifyContent: 'end' }}>
                    <button className="btn btn-color" onClick={() => this.TaoNhanVien()}><i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                    <button className="btn btn-danger" style={{ marginLeft: "10px" }}
                        onClick={() => {
                            this.props.onCancel()
                        }}>
                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                        /> Đóng
                    </button>
                </div>
            </React.Fragment>
        )
    }
}