import * as ApiCaller from '../libs/httpRequests';
const prefix = 'logaction';
export function getList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/list`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListReport(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/listreport`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getByCondition(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/getbycondition`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};