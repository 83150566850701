import * as ApiCaller from '../libs/httpRequests';

const prefix = 'Permission';

export function getListUser(filter)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListUser`, filter).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getUserPermission(username)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetUserPermission?username=${username}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function savePermission(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SavePermission`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function getListGroupPermission(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/list-permission-group`, data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};
