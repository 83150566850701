import moment from "moment";
import { connect } from 'react-redux';
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Table, Pagination, Select, DatePicker, notification } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Action from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import * as ReportServices from '../../../services/ReportService';
import * as CoporationServices from "../../../services/CorporationServices";
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import * as ProductServices from "../../../services/ProductService";
import SiteFilter from "../../../configureHIS/common/siteFilter";
import Notify, { NotifyStatus } from '../../../components/notify';

const { Option } = Select;
const ThongKeSoLuongDichVu = forwardRef((props, ref) => {
// export default forwardRef((props, ref) => {
  //#region biến cục bộ
  const now = new Date();
  //#endregion
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState('');
  //search/filter
  const [siteIds, setSiteIds] = useState([]);
  const [coporationId, setCoporationId] = useState();
  const [dateFrom, setDateFrom] = useState(moment());//(new moment((now).setMonth(now.getMonth() - 1)));
  const [dateTo, setDateTo] = useState(new moment());
  const [nhomIds, setNhomIds] = useState([]);
  const [loaiIds, setLoaiIds] = useState([]);
  const [vienPhiIds, setVienPhiIds] = useState([]);
  //model result
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  //other
  const [listCoporations, setListCoporations] = useState([]);
  const [listNhomVPs, setListNhomVPs] = useState([]);
  const [listLoaiVPs, setListLoaiVPs] = useState([]);
  const [listVPs, setListVPs] = useState([]);
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    getListCoporations();
    getListNhomVP();
  }, []);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    getData();
  }, [pageIndex, sorting]);

  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [pageSize])
  
  useEffect(() => {
    getListLoaiVP(nhomIds);
    // nhomIds.forEach(x=>{
    //   getListLoaiVP(x);
    // })
  }, [nhomIds])
  
  useEffect(() => {
    setListVPs([]);
    setVienPhiIds([]);
    if (loaiIds.length > 0) getListVP();
    // loaiIds.forEach(x=>{
    //   getListVP(x);
    // })
  }, [loaiIds])
  //#endregion 
  
  //#region get list data cho bộ lọc
  function getListCoporations() {
    CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
      if (result.isSuccess) {
        setListCoporations(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        forceUpdate();
    })
  }
  function getListNhomVP() {
    VPService.GetNhomVP().then(result => {
      if (result.isSuccess) {
        // let dt = [...listNhomVPs,...result.data];
        setListNhomVPs(result.data);
      }
    }).catch(err => {
      notification.warning({ message: 'Tải dữ liệu nhóm dịch vụ bị lỗi' });
    })
  }
  function getListLoaiVP(key) {
      VPService.GetLoaiVPByNhomVP(key).then(result => {
          if (result.isSuccess) {
            // let dt = [...listLoaiVPs,...result.data];
            // setListLoaiVPs(dt);
            // setListLoaiVPs([]);
            setLoaiIds([]);
            setListLoaiVPs(result.data);
          }
      }).catch(err => {
      })
  }
  function getListVP() {
    let filterModel = {
      skipCount: 0,
      maxResultCount: 0,
      nhomMas: nhomIds,
      loaiIds: loaiIds,
      hides: [0],
    }
    ProductServices.getList(filterModel).then(result => {
      if (result.isSuccess) {
        // let dt = [...listVPs,...result.data.items];
        // setListVPs(dt);
        setVienPhiIds([]);
        setListVPs(result.data.items);
      }
      else {
      }
    }).catch(err => {
    }).finally(() => {
    })
  }
  //#endregion 
  //#region get data
  const getData = () => {
    Action.setLoading(true);
    let filterModel = {
      siteIds: siteIds,
      coporationIds: coporationId ? [coporationId] : [],
      nhomIds: nhomIds,
      loaiIds: loaiIds,
      vienPhiIds: vienPhiIds,
      dateFrom: dateFrom,
      dateTo: dateTo,
      
      skipCount: (pageIndex - 1) * pageSize,
      maxResultCount: pageSize,
      sorting: sorting,
    }
    ReportServices.getServiceQuantityList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        let dataSourceNew = result.data.items.map(function (e, index) {
          let o = Object.assign({}, e);
          let key = (o.isChild ? (o.isChildInCombo ? 'childc-':'child-') : "") + o.vienPhiId.toString();
          o.key = key;
          o.children = o.listChild;
          return o;
        });
        setListResult(dataSourceNew);
        notiRef && notiRef.current.Close();
        forceUpdate();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Action.setLoading(false);
    })
  }
  //#endregion 

  const onExportExcelClinkHandle = () => {
    Action.setLoading(true);
    let filterModel = {
      siteIds: siteIds,
      coporationIds: coporationId ? [coporationId] : [],
      nhomIds: nhomIds,
      loaiIds: loaiIds,
      vienPhiIds: vienPhiIds,
      dateFrom: dateFrom,
      dateTo: dateTo,
      
      // skipCount: (pageIndex - 1) * pageSize,
      // maxResultCount: pageSize,
      sorting: sorting,
    }
    ReportServices.getServiceQuantityListExport(filterModel).then(result => {
      let fileName = `${moment().format(SystemConfig.FORMAT_PREFIX_EXCEL)}_ThongKeSoLuongDichVu`; 
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        _hiddenLink.current.href = url;
        _hiddenLink.current.download = fileName;
        _hiddenLink.current.click();
      })
    }).catch(err => {
      if (err.error && err.error.messageText) {
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
        Action.openMessageDialog("Thông báo", err?.error?.extensionData ?? "Đã xảy ra lỗi tải xuống");
      }
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
      Action.setLoading(false);
      forceUpdate();
    })
  }

  const resetToFirstPage = () => {
    if (pageIndex != 1) setPageIndex(1);
    else getData();
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter.column && sorter.order) {
      let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
      setSorting(sort);
    }
    else {
      setSorting('');
    }
  };

  const columns = [
    {
        title: 'STT',
        dataIndex: 'stt',
        key: 'stt',
        align: 'center',
        width: 60,
        render: (text, record, index) => {
          return <span>{ record.isChild ? '' : (pageIndex-1)*pageSize + (index + 1) }</span>;
        }
    },
    // Table.SELECTION_COLUMN,
    // {
    //   title: 'Mã dịch vụ',
    //   dataIndex: 'vienPhiMa',
    //   key: 'vienPhiMa',
    //   align: 'left',
    //   width: 170,
    //   sorter: () => {},
    //   // render: (text, record, index) => {
    //   //   return <span>{ `${text}${(record.isChildInCombo ? " (combo)" : "")}` }</span>;
    //   // }
    // },
    {
        title: 'Tên dịch vụ',
        dataIndex: 'vienPhiTen',
        key: 'vienPhiTen',
        align: 'left',
        // width: 120,
        sorter: () => {},
        render: (text, record, index) => {
          return <span>{ `${text}${(record.isChildInCombo ? " (combo)" : "")}` }</span>;
        }
    },
    // Table.EXPAND_COLUMN, //không dùng được
    {
        title: 'Nhóm dịch vụ',
        dataIndex: 'nhomTen',
        key: 'nhomTen',
        align: 'left',
        width: 170,
        sorter: () => {},
        render: (text, record, index) => {
          return <span>{ `${(record.isChild ? "" : text)}` }</span>;
        }
    },
    {
        title: 'Loại dịch vụ',
        dataIndex: 'loaiTen',
        key: 'loaiTen',
        align: 'left',
        width: 170,
        sorter: () => {},
        render: (text, record, index) => {
          return <span>{ `${(record.isChild ? "" : text)}` }</span>;
        }
    },
    {
        title: 'Số lượng dịch vụ',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'right',
        width: 150,
        render: (value) => (<span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>),
    },
    {
        title: 'Tổng tiền',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        align: 'right',
        width: 110,
        render: (value) => (<span>{isNaN(value) ? '' :Number(value).toLocaleString('en-US')}</span>),
    },
  ];
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Thống kê số lượng dịch vụ</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body overflow-auto">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">{/* Chi nhánh */}
                <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
              </div>
              <div className="row mt-2">{/* Nguồn khách hàng | Ngày lập phiếu | Hình thức thanh toán */}
                  <div className='col-md-6 d-flex flex-column'>{/* Ngày lập phiếu */}
                    <div>
                      <label>Ngày đăng ký</label>
                    </div>
                    <div className="d-flex">
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Từ ngày"}
                        value={dateFrom}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateFrom(e ? moment(e) : null);
                        }}
                      />
                      <div className="m-auto">&#160;-&#160;</div>
                      <DatePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        placeholder={"Đến ngày"}
                        value={dateTo}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDateTo(e ? moment(e) : null);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-6 d-flex flex-column'>{/* Nguồn khách hàng */}
                    <div>
                      <label>Nguồn khách hàng</label>
                    </div>
                    <Select
                      allowClear
                      showSearch
                      className="w-100"
                      placeholder="Chọn nguồn khách hàng"
                      maxTagCount= 'responsive'
                      value={ coporationId }
                      filterOption={(input, option) => {
                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) || (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                      }}
                      onChange={(value) => {setCoporationId(value);}}
                    >
                    {(listCoporations ?? []).map((record, index) => {
                      return (
                        <Option key={record.ma} value={record.ma} label={record.ten}>
                          <div className="d-flex justify-content-between">
                            <span>{record.ten}</span>
                            <span>{record.ma}</span>
                          </div>
                        </Option>
                      )
                    })}
                    </Select>
                  </div>
              </div>
              <div className="row mt-2">{/* Nhóm VP | loại Vp | VP */}
                  <div className='col-md-4 d-flex flex-column'>{/* Nhóm dịch vụ */}
                    <div>
                      <label>Nhóm dịch vụ</label>
                    </div>
                    <Select
                      allowClear
                      mode="multiple"
                      className="w-100"
                      maxTagCount= 'responsive'
                      placeholder="Chọn nhóm dịch vụ"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={ nhomIds }
                      onChange={(values) => { setNhomIds(values);}}
                    >
                    {(listNhomVPs ?? []).map((record, index) => {
                      return (
                        <Option key={record.value} value={record.value}>{record.label}</Option>
                      )
                    })}
                    </Select>
                  </div>
                  <div className='col-md-4 d-flex flex-column'>{/* Loại dịch vụ */}
                    <div>
                      <label>Loại dịch vụ</label>
                    </div>
                    <Select
                      allowClear
                      mode="multiple"
                      className="w-100"
                      maxTagCount= 'responsive'
                      placeholder="Chọn loại dịch vụ"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={ loaiIds }
                      onChange={(values) => {setLoaiIds(values);}}
                    >
                    {(listLoaiVPs ?? []).map((record, index) => {
                      return (
                        <Option key={record.value} value={record.value}>{record.label}</Option>
                      )
                    })}
                    </Select>
                  </div>
                  <div className='col-md-4 d-flex flex-column'>{/* Tên dịch vụ */}
                    <div>
                      <label>Tên dịch vụ</label>
                    </div>
                    <Select
                      allowClear
                      mode="multiple"
                      className="w-100"
                      maxTagCount= 'responsive'
                      placeholder="Chọn tên dịch vụ"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={ vienPhiIds }
                      onChange={(values) => {setVienPhiIds(values);}}
                    >
                    {(listVPs ?? []).map((record, index) => {
                      return (
                        <Option key={record.id} value={record.id}>{record.ten}</Option>
                      )
                    })}
                    </Select>
                  </div>
              </div>
              <div className="row mt-2">{/* button */}
                <div className='col-md-8'>{/* */}
                </div>
                <div className='col-md-4 d-flex justify-content-end'>{/* button thống kê | xuất excel */}
                  <button 
                    className='btn btn-color ml-2' 
                    onClick={() => {
                      resetToFirstPage();
                    }}
                  >
                    <i className='far fa-chart-bar mr-2'></i>
                    Thống kê
                  </button>
                  <button className="btn btn-color ml-2" type="button" 
                      onClick={() => { onExportExcelClinkHandle(); }}
                    ><i style={{ marginRight: 5 }} className="fas fa-file-excel" />Xuất excel
                  </button>
                </div>
              </div>
            </div>
            <div className="list-page-table">
              <Table style={{ height: "calc(100% - 44px)", width: "100%" }}
                className="table-min-h-0"
                dataSource={listResult} columns={columns}
                locale={{ emptyText: 'Không có dữ liệu' }} 
                scroll={{ x: 0, y: 0 }}
                rowKey={(e) => e.key}
                // rowSelection={{
                //   type: 'checkbox',
                //   ...rowSelection,
                // }}
                rowClassName={(record, rowIndex) => {
                    // return "rowCustom"
                }}
                pagination={false}
                onChange={onTableChange} 
              />
              <Pagination
                current={pageIndex}
                size={pageSize}
                style={{ textAlignLast: "center", marginTop: "10px" }}
                total={totalRecord}
                showTotal={total => `Tổng: ${total}`}
                onChange={(page, size) => { setPageIndex(page); setPageSize(size) }}
                onShowSizeChange={(page, size) => { setPageIndex(1); setPageSize(size) }}
                showSizeChanger={true}
              />
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
});
const mapStateToProps = (state) => {
    return ({
        // siteid: state.global.siteid,
        // siteCode: state.global.siteCode
    })
};
export default connect(mapStateToProps)(ThongKeSoLuongDichVu)