/**Screen: Tìm kiếm và chọn dịch vụ */
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback, useImperativeHandle } from 'react';
import { Input, Button, Table, List, Radio, Select, Popconfirm, Tooltip, notification } from 'antd';
import * as Actions from '../../libs/actions';
import Notify, { NotifyStatus } from '../../components/notify';
import * as ProductServices from "../../services/ProductService";
import * as VPService from '../../configureHIS/update_vp/services/VPService';
import { COMBO } from '../../configureHIS/common/constants'

const { Option } = Select;
const uiStatusEnum = {selected: 'selected', new: 'new'}
/**Hướng dẫn sử dụng component
 ** Các component đang sử dụng:
    - src\screens\exam\examination\detail-components\clinical-exam-indication.js
    - src\screens\cards\cardtype\detail.js
 ** Các props nhận vào:
 * @param {boolean} props.isDialog : (common) nhận biết mở screen này kiểu dialog (popup) //common là dùng chung, hầu như screen nào cũng dùng
 * @param {Array} props.selectedListServices: list dịch vụ đã được chọn trước đó. 
 * @param {string} props.bangGia : (Bắt buộc) Bảng giá
 * @param {string} props.btnConfirmText : label cho button xác nhận
 * @param {Function} props.onCancel() : (common) hàm đóng screen. Để screen cha xử lý sự kiện khi đóng component này
 * @param {Function} props.onConfirm(arrayResult) : hàm xác nhận chọn các dịch vụ. arrayResult là list dịch vụ (đã được chọn) được trả về.
 * @param {Function} props.onDeleteItem(record): hàm delete từng dịch vụ. Ở component cha, hàm này phải trả về Promise: Nếu không thành công thì reject chuỗi để thông báo lỗi
 * //Các props liên quan hiển thị
 * @param {boolean} props.showBangGia : hiện bảng giá (combobox, giá trên dịch vụ, tổng tiền). Mặc định = false
 * @param {boolean} props.disableBangGia : disable bảng giá combobox banggia. Mặc định = false. 
 * Cái này dùng cho 1 số component truyền vào. list dịch vụ bên phải không có item thì bảng giá cũng bị disable nếu props.disableBangGia này true
 * 
    *theo model (dùng tên field product không dùng service để tránh nhầm lẫn các từ khóa service trong code)
  ** Model của product (dùng cho props.selectedListServices & arrayResult):
     ** {
      ** id: number (bắt buộc)
      ** loaiId: 15
      ** loaiTen: "HOÁ SINH MIỄN DỊCH"
      ** ma: "2-1496" (bắt buộc)
      ** maDv: null
      ** nhomId: 2
      ** nhomTen: "Xét nghiệm"
      ** ten: "A/G"  (bắt buộc)
      ** idChiDinh: null (không bắt buộc, dành cho những chỗ có dùng idChiDinh)
      ** allowDelete: true | false (bắt buộc)
      ** //dành cho liên quan đến giá
      ** gia_Th: (không bắt buộc) đơn giá
      ** paid: (không bắt buộc) =1: đã thanh toán. =0: chưa thanh toán
      ** tenKhoaPhong: (không bắt buộc)
      ** // extend:2 cái dưới dùng nội bộ trong component này & trả về cho component cha biết status
      ** uiStatus: selected | new 
      **  - selected : từ component cha truyền vào (đã có trong db) 
      **  - new: đang được chọn
      ** uiDeleted: true | false //field này chỉ dành cho uiStatus=selected. 
      **  - true: trả lại component cha lả bị xóa. (để dành cho sau này có trường hợp: từ component cha click reset (không thay đổi list)).
    ** }
    ** cái props.selectedListServices với uiStatus = selected hoặc undefined thì khi trả về arrayResult (trong hàm props.onConfirm) sẽ giữ nguyên các phần tử.
    ** Nếu có xóa trong props.selectedListServices thì sẽ có uiDeleted = true.
    ** (*) Trả như vậy cho component cha tự xử, muốn làm gì làm. Ví dụ có trường hợp: từ component cha click reset (không thay đổi list) -> trả về list như ban đầu.
    Note (*): còn tùy, component cha mà có props.onDeleteItem thì sẽ xóa luôn item, chứ không chuyển uiDeleted=true.
 */
export default forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showNotiRef: (message) => {
      notiRef.current.Show(message, NotifyStatus.Warning);
    },
    hideNotiRef: () => {
      notiRef && notiRef.current.Close();
    },
  }));
  const notiRef = useRef(null);
  const scrollRef = useRef();
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [listResultLoading, setListResultLoading] = useState(false);
  //state theo props
  const [btnConfirmText, setBtnConfirmText] = useState(props.btnConfirmText ?? "Xác nhận");
  const [showBangGia, setShowBangGia] = useState(props.showBangGia ?? false);
  const [disableBangGia, setDisableBangGia] = useState(props.disableBangGia ?? false);//state này theo props.disableBangGia
  const [showCombo, setShowCombo] = useState(props.showCombo ?? true);
  //state paging và sorting
  const [skipCount, setSkipCount] = useState(0);
  const [maxResultCount, setMaxResultCount] = useState(20);
  const [sorting, setSorting] = useState('');
  //search/filter model
  const [keyword, setKeyword] = useState('');
  const [nhomMas, setNhomMas] = useState([]);
  const [loaiIds, setLoaiIds] = useState([]);
  const [bangGia, setBangGia] = useState(props.bangGia?.toLowerCase() == 'default' ? 'Mặc định' : props.bangGia);
  //result model
  const [totalRecord, setTotalRecord] = useState(0);
  const [listResult, setListResult] = useState([]);
  // const [listResultTotal, setListResultTotal] = useState([]);
  //result nhóm
  const [listNhomShortcut, setListNhomShortcut] = useState([]);
  const [selectedNhomShortcutKey, setSelectedNhomShortcutKey] = useState('');
  //table
  /*selectedRowKeysTotal: chúa id của selectedRowTotals */
  const [selectedRowKeysTotal, setSelectedRowKeysTotal] = useState([]);
  /*selectedRowTotals: Tổng các row được chọn (gồm 2 state bên dưới cộng lại).
  Nhưng chỉ dành check các row được chọn & hiện bên panel "Dịch vụ đã chọn".
  Khi trả lại component cha thì lấy hợp [...selectedRows, ...selectingRows].
  - selectedRows: sẽ trả tất cả row cũ (uiStatus = selected). Các selectedRows mà bị xóa sẽ có uiDeleted = true
  - selectingRows: là các row mới được add vào (uiStatus=new)
  */
  const [selectedRowsTotal, setSelectedRowsTotal] = useState([]); 
  const [selectedRows, setSelectedRows] = useState([]); //Các row đã được chọn trước đó (từ component cha truyền vào)
  const [selectingRows, setSelectingRows] = useState([]); //Các row mới chọn hiện tại
  const [listBangGia, setListBangGia] = useState([]); //list bảng giá
  const [totalAmount, setTotalAmount] = useState(0);//tổng tiền chưa thanh toán
  const [totalAmountPaid, setTotalAmountPaid] = useState(0); //tổng tiền đã thanh toán
  //#endregion
  useEffect(() => {
    getListNhomShortcut();
    if (showBangGia && !disableBangGia) getListBangGia();
    if (props.selectedListServices && props.selectedListServices.length > 0) {
       /* Có trường hợp xác nhận & đóng screen "Chọn dịch vụ" này xong mở lên chọn lại.
       Khi đó props.selectedListServices truyền vào sẽ có selected or new lẫn lộn.
       Tách cái new ra selectingRows riêng cho dễ xử */
      setSelectedRows(props.selectedListServices.filter(x => x.uiStatus != uiStatusEnum.new).map(x => { 
          return {
              // id: x.id,
              // loaiId: x.loaiId,
              // loaiTen: x.loaiTen,
              // ma: x.ma,
              // maDv: x.maDv,
              // nhomId: x.nhomId,
              // nhomTen: x.nhomTen,
              // ten: x.ten,
              // allowDelete: x.allowDelete,
              // idChiDinh: x.idChiDinh,
              // gia_Th: x.gia_Th,
              // paid: x.paid ?? 0,
              ...x,
              uiStatus: uiStatusEnum.selected,
              uiDeleted: x.uiDeleted ?? false,
          }
      }));
      setSelectingRows(props.selectedListServices.filter(x => x.uiStatus === uiStatusEnum.new).map(x => { 
          return {
              // id: x.id,
              // loaiId: x.loaiId,
              // loaiTen: x.loaiTen,
              // ma: x.ma,
              // maDv: x.maDv,
              // nhomId: x.nhomId,
              // nhomTen: x.nhomTen,
              // ten: x.ten,
              // gia_Th: x.gia_Th,
              // paid: x.paid ?? 0,
              // uiStatus: x.uiStatus,
              ...x,
              uiDeleted: false
          }
      }));
    }
  }, []);

  useEffect(() => {
    if (firstRender.current) return;
    search();
  }, [skipCount])
  
  useEffect(() => {
    if (firstRender.current) return;
    resetToFirstPage();
  }, [nhomMas, loaiIds, bangGia])

  useEffect(() => {
    setSelectedRowKeysTotal(selectedRowsTotal.map(x => x.id));
    
    let amount = 0;
    let amountPaid = 0;
    amount = selectedRowsTotal.filter(x=>(x.paid ?? 0) == 0).map(x=>x.gia_Th).reduce((a, b) => a + b, 0);
    amountPaid = selectedRowsTotal.filter(x=>x.paid == 1).map(x=>x.gia_Th).reduce((a, b) => a + b, 0);
    setTotalAmount(amount);
    setTotalAmountPaid(amountPaid);
  }, [selectedRowsTotal])

  useEffect(() => {
    setSelectedRowsTotal([...selectedRows.filter(x => !x.uiDeleted), ...selectingRows]);
  }, [selectedRows, selectingRows])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
    let nhom = listNhomShortcut.filter(x => x.keyValue == selectedNhomShortcutKey);
    if (nhom.length > 0) {
      let selectedNhomShortcut = nhom[0];
      setNhomMas([selectedNhomShortcut.nhomMa]);
      if (selectedNhomShortcut.loaiId > 0) setLoaiIds([selectedNhomShortcut.loaiId]);
      else setLoaiIds([]);
    }
  }, [selectedNhomShortcutKey])
  //#endregion 
  //#region search & filter
  const getListNhomShortcut = () => {
    // Actions.setLoading(true);
    ProductServices.nhomGetListShortcut().then(result => {
      if (result.isSuccess) {
        let data = result.data;
        if(!showCombo) data = data.filter(x => x.nhomMa !== COMBO);
        setListNhomShortcut(data);
        if (result.data && result.data.length > 0) {
          setSelectedNhomShortcutKey(data[0].keyValue);
        }
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        // Actions.setLoading(false);
        forceUpdate();
    })
  }

  const getListBangGia = () => {
    // Actions.setLoading(true);
    VPService.GetListBangGia().then(result => {
      if (result.isSuccess) {
        setListBangGia(result.data);
      }
    }).catch(err => {
    })
  }
  
  const resetToFirstPage = () => {
    // if (pageIndex != 1) setPageIndex(1);
    // else search();
    if (skipCount > 0) setSkipCount(0);
    else search();
  }

  const enterToSearch = (e) => {
    if (e.keyCode == 13) resetToFirstPage();
  }
  const search = () => {
    // Actions.setLoading(true);
    setListResultLoading(true);
    let filterModel = {
      //common: paging & sorting
      skipCount: skipCount,
      maxResultCount: maxResultCount,
      sorting: sorting,
      //riêng theo module
      keyword: keyword,
      nhomMas: nhomMas,
      loaiIds: loaiIds,
      bangGia: bangGia,
      hides: [0],
    }
    ProductServices.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setTotalRecord(result.data.totalCount);
        if (skipCount > 0) setListResult([...listResult, ...result.data.items]);
        else setListResult(result.data.items);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        // Actions.setLoading(false);
      setListResultLoading(false);
    })
  }

  //#endregion 
  //#region table info & event
  const onRowLeftClickHandler = (record, index, e) => {
    /**Các trường hợp:
     1. Row chưa được chọn: 
        1. 1. Không có trong selectedRows (row mới hoản toàn): add vào selectingRows | selectedRowsTotal
        1. 2. Có trong selectedRows (row được cha truyền vào (đang có trong database))
              1. 2. 1. Được xóa: sửa lại uiDeleted = false
              1. 2. 2. Không được xóa: KHÔNG có trường hợp này xảy ra (nó nó luôn nằm trong selectedRows) -> không làm gì
     2. Row đã được chọn
        2. 1. Không có trong selectedRows (row mới hoản toàn): remove khỏi selectingRows | selectedRowsTotal
        2. 2. Có trong selectedRows (row được cha truyền vào (đang có trong database))
              2. 2. 1. Được xóa: sửa lại uiDeleted = true
              2. 2. 2. Không được xóa: KHÔNG làm gì cả vì không được xóa nó
     */
    //  1. Row chưa được chọn: 
    if (selectedRowKeysTotal.indexOf(record.id) < 0) {
      // 1. 1. Không có trong selectedRows (row mới hoản toàn): add vào selectingRows | selectedRowsTotal
      let rowInSelected = selectedRows.filter(x => x.id === record.id)[0];
      if (!rowInSelected) {
        record.uiStatus = uiStatusEnum.new;
        setSelectingRows([...selectingRows, ...[record]]);
        // setSelectedTotalRows([...selectedRowsTotal, ...[record]]);
      }
      // 1. 2. Có trong selectedRows (row được cha truyền vào (đang có trong database))
      else {
        //       1. 2. 1. Được xóa: sửa lại uiDeleted = false
        let findIndex = selectedRows.findIndex((obj => obj.id == record.id));
        let fakeSelectedRows = selectedRows;
        fakeSelectedRows[findIndex].uiDeleted = false;
        setSelectedRows([...fakeSelectedRows]);
        // setSelectedTotalRows([...fakeSelectedRows, ...selectingRows]);
        //       1. 2. 2. Không được xóa: KHÔNG có trường hợp này xảy ra (nó nó luôn nằm trong selectedRows) -> không làm gì
      }
    }
    // 2. Row đã được chọn
    else {
      // 2. 1. Không có trong selectedRows (row mới hoản toàn): remove khỏi selectingRows | selectedRowsTotal
      let rowInSelected = selectedRows.filter(x => x.id === record.id)[0];
      if (!rowInSelected) {
        setSelectingRows([...selectingRows.filter(x => x.id != record.id)]);
      }
      // 2. 2. Có trong selectedRows (row được cha truyền vào (đang có trong database))
      else {
        //       2. 2. 1. Được xóa: sửa lại uiDeleted = true
        if (rowInSelected.allowDelete) {
          if (props.onDeleteItem) { // nếu component cha có props để callback hàm delete trên item thì call hàm đó
            props.onDeleteItem(record).then(result => {
              setSelectedRows(selectedRows.filter(x => x.id != record.id));
              forceUpdate();
            }).catch(err => {
              notification.error({ message: err })
          })}
          else {
            let findIndex = selectedRows.findIndex((obj => obj.id == record.id));
            let fakeSelectedRows = selectedRows;
            fakeSelectedRows[findIndex].uiDeleted = true;
            setSelectedRows([...fakeSelectedRows]);
          }
        }
        //       2. 2. 2. Không được xóa: KHÔNG làm gì cả vì không được xóa nó
      }
    }

    // if (selectedRowKeys.indexOf(record.id) > -1) setSelectedRows(selectedRows.filter(x => x.id != record.id))
    // else setSelectedRows([...selectedRows, ...[record]]);
  }

  const onWheel = e => {
    e.preventDefault();
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;
    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
    });
  };

  const columns = [
      {
          title: '#',
          dataIndex: 'stt',
          key: 'stt',
          align: 'center',
          width: 30,
          className:'align-top',
          render: (value, record, index) => {
            return <span>{ index + 1 }</span>;
          }
      },
      {
          title: 'selected',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 25,
          className:'align-top',
          render: (value, record, index) => {
              return (selectedRowKeysTotal.indexOf(value) > -1) ? <span><i className="fa fa-check"></i></span> : <span></span>
          }
      },
      {
          title: 'Mã',
          dataIndex: 'ma',
          key: 'ma',
          align: 'left',
          // width: 100,
          // sorter: () => {},
          className:'align-top',
          render: (value, record) => {
            return (
              <div className="d-flex flex-column">
                <div className="font-weight-bold">
                  <span>{record.ten}</span>
                  <span> - </span>
                  <span>{record.ma}</span>
                </div>
                <div>
                  <span>{record.loaiTen}</span>
                </div>
                <div>
                  {record.isCombo && record.listChild.map(x=> {
                    return (
                      <div className="pl-2">
                        <span>{x.ten}</span>
                        <span> - </span>
                        <span>{x.ma}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            );
          }
      },
      {
          title: 'Giá',
          dataIndex: 'gia_Th',
          key: 'gia_Th',
          align: 'right',
          width: 80,
          // sorter: () => {},
          className:'align-top',
          render: (value, record) => {
            return (
              <div className="d-flex flex-column">
                <div className="font-weight-bold">
                </div>
                <div>{showBangGia &&
                  <span>{record.gia_Th.toLocaleString('en-US')}</span>
                }
                </div>
              </div>
            );
          }
      },
  ];

  //#endregion 
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Tìm kiếm và chọn dịch vụ</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => { props.onCancel() }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="h-100 w-100 d-flex justify-content-between overflow-hidden">{/* body */}
            <div className="h-100 w-50 mr-1 border border-dark rounded p-2 d-flex flex-column overflow-hidden">{/* panel-left */}
              <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
                  <h5>Tìm kiếm</h5>
              </div>
              <div className="list-page-body">{/* body */}
                <div className="row">{/* notification */}
                  <div className="col-md-12"><Notify ref={notiRef} /></div>
                </div>
                <div className="list-page-search">{/* search/filter */}
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-between w-100 max-w-100"
                      style={{ overflowX: 'hidden' }}
                    >
                      <Button className="p-0"
                        type="link"
                        size="large"
                        onClick={() => {
                          let i = 60;
                          let t = setInterval(function() {
                            scrollRef.current.scrollLeft -= 1;
                            i--;
                            if (i <= 0) clearInterval(t);
                          }, 7);
                        }}
                      ><i className="fa fa-chevron-left mr-1" /></Button>
                      <div ref={scrollRef} className="w-100 d-flex flex-column justify-content-center overflow-hidden"
                        onWheel={onWheel}
                      >
                        <Radio.Group
                          className="d-flex flex-nowrap justify-content-center m-auto"
                          style={{ width: "max-content" }}
                          optionType="button"
                          buttonStyle="solid"
                          options={[
                            ...listNhomShortcut?.map(x=> {
                              return {label: x.label, value: x.keyValue};
                            }),
                          ]
                          }
                          value={selectedNhomShortcutKey}
                          onChange={(e) => {
                            setSelectedNhomShortcutKey(e.target.value);
                          }}
                        >
                        </Radio.Group>
                      </div>
                      <Button className="p-0"
                        type="link"
                        size="large"
                        onClick={() => {
                          let i = 60;
                          let t = setInterval(function() {
                            scrollRef.current.scrollLeft += 1;
                            i--;
                            if (i <= 0) clearInterval(t);
                          }, 7);
                        }}
                      ><i className="fa fa-chevron-right mr-1" /></Button>
                    </div>
                  </div>
                  <div className="row">{/* chọn bảng giá */}
                    <div className="col-md-12">
                    {showBangGia &&
                      <div className="w-100 d-flex">
                        <label className='mita-title m-auto pr-2' style={{minWidth:"fit-content"}}>Chọn bảng giá</label>
                        <Select
                          // mode="multiple"
                          // allowClear
                          disabled={disableBangGia || (selectedRowsTotal && selectedRowsTotal.length>0)}
                          showSearch
                          className="w-100"
                          placeholder="Chọn bảng giá"
                          maxTagCount= 'responsive'
                          value={ bangGia }
                          onChange={(value) => {setBangGia(value);}}
                        >
                        {(listBangGia ?? []).map((record, index) => {
                          return (
                              <Option key={record.value} value={record.value}>{record.label}</Option>
                          )
                        })}
                        </Select>
                      </div>
                    }
                    </div>
                  </div>
                  <div className="row mt-2">{/* Input search mã, tên dịch vụ */}
                    <div className="col-md-12 d-flex">
                      <Input
                        placeholder="Nhập mã, tên dịch vụ để tìm kiếm"
                        prefix={<i style={{ marginRight: 5 }} className="fa fa-search" />}
                        value={keyword} 
                        onChange={(e) => { setKeyword(e.target.value); }}
                        onKeyUp={(e) => { enterToSearch(e); }}
                      ></Input>
                    </div>
                  </div>
                </div>
                <div className="list-page-table">
                  <Table 
                    className="table-full-height table-min-h-0 none-border-bottom w-100 h-100 overflow-hidden"
                    showHeader={false}
                    dataSource={listResult} columns={columns}
                    loading={listResultLoading}
                    locale={{ emptyText: 'Không có dữ liệu' }} 
                    scroll={{ x: 0, y: 0 }}
                    rowKey={(e) => e.id}
                    rowClassName={(record, rowIndex) => {
                      return "rowCustom"
                    }}
                    onRow={(record, index) => {
                      return {
                        onClick: e => {
                          onRowLeftClickHandler(record, index, e);
                        }
                      };
                    }}
                    pagination={false}
                  />
                  <Button className="mt-1"
                      onClick={() => { setSkipCount(listResult.length); }}
                  >
                    {/* <i className="fa fa-external-link-alt mr-1"></i> */}
                    Hiển thị thêm {(totalRecord - listResult.length).toLocaleString('en-US') } dịch vụ
                  </Button>
                </div>
              </div>
            </div>
            <div className="h-100 w-50 ml-1 border border-dark rounded p-2 d-flex flex-column overflow-hidden">{/* panel-right */}
              <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
                  <h5>Dịch vụ đã chọn</h5>
              </div>
              <div className="list-page-body">{/* body */}
                <div className="list-page-search">{/* search/filter */}
                  <div className="row" hidden={!(selectedRowsTotal && selectedRowsTotal.length>0)}> {/* ghi chú màu */}
                    <div className="col-md-12 d-flex justify-content-between">
                      <div>
                        <div style={{display:'inline-block', width:14, height:7, background:'red', marginRight:2}}></div>
                        <span>Không được phép xóa</span>
                      </div>
                      <div>
                        <div style={{display:'inline-block', width:14, height:7, background:'blue', marginRight:2}}></div>
                        <span>Đã chọn</span>
                      </div>
                      <div>
                        <div style={{display:'inline-block', width:14, height:7, background:'green', marginRight:2}}></div>
                        <span>Đang chọn</span>
                      </div>
                    </div>
                  </div>
                  <div className="row"> {/* Tổng tiền gì đó, là sau cho screen Đăng ký */}
                  </div>
                </div>
                <div className="list-page-table mt-2">{/* list */}
                  <List className="none-border-bottom none-border-bottom-item h-100 overflow-auto"
                    bordered={false}
                    dataSource={[
                      ...selectedRowsTotal.filter(x=>x.uiStatus===uiStatusEnum.selected && !x.allowDelete),
                      ...selectedRowsTotal.filter(x=>x.uiStatus===uiStatusEnum.selected && x.allowDelete),
                      ...selectedRowsTotal.filter(x=>x.uiStatus===uiStatusEnum.new)
                    ]}
                    renderItem={(record) => {
                      let className = "p-1 border-2 border-top";
                      if (record.uiStatus == uiStatusEnum.new) className += " border-green";
                      else if (record.uiStatus == uiStatusEnum.selected && record.allowDelete) className += " border-blue";
                      else className += " border-red";
                      return (
                        <List.Item key={record.id}
                          className={className}
                          style={{ background:'#f3f3f3' }}
                        >
                          <div className="d-flex flex-column w-100">
                              <div className="d-flex justify-content-between w-100">
                                  <div className="d-flex" style={{width: 'calc(100% - 216px)'}}> {/* column 1 */}
                                      <label className="mita-title h-auto">
                                        {record.ten} - {record.ma}
                                      </label>
                                  </div>
                                  <div style={{width: 110}}> {/* column 2 */}
                                      <label className="mita-value">{record.statusText}</label>
                                  </div>
                                  <div className="text-right d-flex justify-content-end" style={{width: 106}}> {/* column 3 */}
                                      {showBangGia &&
                                      <div className="text-right w-100">
                                          {record.paid == 1 ?
                                          <label className="mita-value">Đã thanh toán</label>
                                          :
                                          <label className="mita-value">{record.gia_Th.toLocaleString('en-US')}</label>
                                        }
                                      </div>
                                      }
                                      {record.allowDelete && record.uiStatus===uiStatusEnum.selected ?
                                      <Popconfirm
                                        title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{record.ten}</b>?</span>)}}
                                        onConfirm={() => onRowLeftClickHandler(record)}
                                        onCancel={() => {}}
                                        okText="Đồng ý"
                                        cancelText="Hủy"
                                      >
                                        <Button type="link" shape="circle" style={{ height: 'min-content', padding: 0, minWidth: 0, width: 16 }}
                                          hidden={!(record.allowDelete ?? true)}
                                          onClick={() => {}}
                                        ><i className="fa fa-trash-alt text-danger"></i></Button>
                                      </Popconfirm>
                                      :
                                      <Button type="link" shape="circle" style={{ height: 'min-content', padding: 0, minWidth: 0, width: 16 }}
                                        hidden={!(record.allowDelete ?? true)}
                                        onClick={() => {onRowLeftClickHandler(record);}}
                                      ><i className="fa fa-trash-alt text-danger"></i></Button>
                                      }
                                  </div>
                              </div>
                              <div className="d-flex justify-content-stretch">
                                  {(record.tenKhoaPhong && record.tenKhoaPhong.length > 0) &&
                                  <div className="" style={{width: '55%'}}>
                                      <label className="mita-value">{record.tenKhoaPhong}</label>
                                  </div>
                                  }
                              </div>
                              {(record.listChild && record.listChild.length > 0) &&
                              <div className="d-flex flex-column justify-content-stretch">
                                  {record.listChild.map((dvConCombo, index) => {
                                      return (<>
                                      <div className="w-100 d-flex justify-content-between pl-2">{/* pl-2" = 0.5rem. 0.5rem = 8px. nên width ở dưới trừ thêm 8px */}
                                          <div className="d-flex" style={{width: 'calc(100% - 240px)'}}>
                                              <label className="mita-title h-auto">{dvConCombo.ten} - {dvConCombo.ma}</label>
                                          </div>
                                          <div style={{width: 110}}>
                                              <label className="mita-value">{dvConCombo.statusText}</label>
                                          </div>
                                          <div style={{width: 16}}></div>
                                      </div>
                                      {(dvConCombo.tenKhoaPhong && dvConCombo.tenKhoaPhong.length > 0) &&
                                      <div className="pl-3" style={{width: '55%'}}>
                                        <label className="mita-value">{dvConCombo.tenKhoaPhong}</label>
                                      </div>
                                      }
                                      </>
                                      )
                                  })}
                              </div>
                              }
                          </div>

                          {/* <div className="d-flex flex-column w-100">
                            <div className="d-flex justify-content-between">
                              <div className="">
                                <b>{record.ten} - {record.ma}</b>
                              </div>
                              {showBangGia &&
                              <div className="text-right" style={{width: '15%', minWidth:100}}>
                                  {record.paid == 1 ?
                                  <label className="mita-value">Đã thanh toán</label>
                                  :
                                  <label className="mita-value">{record.gia_Th.toLocaleString('en-US')}</label>
                                }
                              </div>
                              }
                              {record.allowDelete && record.uiStatus===uiStatusEnum.selected ?
                              <Popconfirm
                                title={() => {return (<span>Bạn chắc chắn muốn xóa <b>{record.ten}</b>?</span>)}}
                                onConfirm={() => onRowLeftClickHandler(record)}
                                onCancel={() => {}}
                                okText="Đồng ý"
                                cancelText="Hủy"
                              >
                                <Button type="link" shape="circle" style={{ height: 'min-content', padding: 0, minWidth: 0 }}
                                  hidden={!(record.allowDelete ?? true)}
                                  onClick={() => {}}
                                ><i className="fa fa-trash-alt text-danger"></i></Button>
                              </Popconfirm>
                              :
                              <Button type="link" shape="circle" style={{ height: 'min-content', padding: 0, minWidth: 0 }}
                                hidden={!(record.allowDelete ?? true)}
                                onClick={() => {onRowLeftClickHandler(record);}}
                              ><i className="fa fa-trash-alt text-danger"></i></Button>
                              }
                            </div>
                            {!record.isCombo &&
                            <div className="d-flex justify-content-stretch">
                                <div className="" style={{width: '55%'}}>
                                    <label className="mita-value">{record.tenKhoaPhong}</label>
                                </div>
                                <div style={{width: '30%', minWidth:100}}>
                                    <label className="mita-value">{record.statusText}</label>
                                </div>
                            </div>
                            }
                            {record.isCombo && record.listChild.map(x => {
                              return (
                              <div className="d-flex justify-content-stretch pl-2">
                                <div className=""><b>{x.ten} - {x.ma}</b></div>
                              </div>
                              )})
                            }
                          </div> */}
                        </List.Item>
                    )}}
                  >{showBangGia &&
                    <List.Item key={"total-amount"} className='p-1'>
                        <div className="d-flex flex-column w-100">
                            {/* <div className="d-flex justify-content-end">
                                <div className=""><label className="mita-value">Đã thanh toán</label></div>
                                <div className="text-right" style={{ width: '15%' }}>
                                    <label className="mita-title"><u>đ</u> {totalAmountPaid.toLocaleString('en-US')}</label>
                                </div>
                            </div> */}
                            <div className="d-flex justify-content-end">
                                <div className="text-right">
                                  <label className="mita-value mr-2">Chưa thanh toán</label>
                                  <label className="mita-title"><u>đ</u> {totalAmount.toLocaleString('en-US')}</label>
                                </div>
                            </div>
                        </div>
                    </List.Item>
                  }
                  </List>
                </div>
              </div>
              {/* footer (nếu có) */}
              {/* <div className="list-page-footer d-flex justify-content-between mt-2">
                <div>
                </div>
                <div className='d-flex justify-content-end' >
                </div>
              </div> */}
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
                <button 
                  className='btn btn-color mr-2' 
                  onClick={() => {
                    let t = props.onConfirm([...selectedRows,...selectingRows]);
                  }}
                >
                  <i className='fa fa-check-square mr-2'></i>{btnConfirmText}
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => props.onCancel()}
                >
                    <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})