import React, { useEffect, useRef, useState } from 'react';
import HeaderModal from '../common/headerModal';
import { Alert, Checkbox, Input, Modal, Pagination, Popconfirm, Select, Switch, Table, notification } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import * as Actions from '../../libs/actions';
import NoContent from "../../components/loading/noContent";
import * as ConfigService from '../../services/configService';
import * as ManagegerService from '../../services/ManageService';
import { IsSuperAdmin } from '../../commons/utils';
import CreateConfig from './createConfig';
import { isNullOrEmpty } from '../../libs/util';

let task = null;

function PhanQuyenChucNangTrenManHinh(props) {
    const _notify = useRef();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [key, setKey] = useState();
    const [keyUserGroup, setKeyUserGroup] = useState("");
    const [userGroup, setUserGroup] = useState(null);
    const [listUserGroup, setListUserGroup] = useState([
        {
            value: 0,
            label: "Tất cả"
        }
    ]);
    const [feature, setFeature] = useState();
    const [listData, setListData] = useState([]);

    const [listFunction, setListFunction] = useState([]);
    const [visibleConfig, setVisibleConfig] = useState(false);
    const [visibleAddConfig, setVisibleAddConfig] = useState(false);
    const [screen, setScreen] = useState("");

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 35,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Tài khoản',
            dataIndex: 'account',
            key: 'account',
            align: 'center',
            width: 150,
        },
        {
            title: 'Họ và tên',
            dataIndex: 'username',
            key: 'username',
            align: 'left',
            width: 250,
        },
        ...listFunction && listFunction.map((x, index) => {
            return (
                {
                    title: x.title ?? "Chưa có tiêu đề",
                    dataIndex: "authorization",
                    key: "authorization",
                    align: 'center',
                    width: 150,
                    render: (val, record) => {
                        return <Checkbox disabled={!x.active} checked={val.auth[x.key] ?? false} onChange={(e) => { onUpdateAuth(record, x.key, e.target.checked) }}></Checkbox>
                    }
                }
            )
        }),
    ];
    const [screenAuth, setScreenAuth] = useState([]);

    useEffect(() => {
        getUserGroup();
        getScreenAuth();
    }, []);

    useEffect(() => {
        setPage(1);
        onSearch(1);
        getScreenFunctions();
    }, [feature]);

    const onDelaySearchUserGroup = (key) => {
        setKeyUserGroup(key);
        if (task) clearTimeout(task)
        task = setTimeout(() => {
            getUserGroup(key);
        }, 500)
    }

    const getUserGroup = (key = keyUserGroup) => {
        ManagegerService.GetListGroupUser(key).then(result => {
            if (result.isSuccess) {
                setListUserGroup(result.data);
            }
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show('Lỗi tải danh sách nhóm người dùng', NotifyStatus.Error);
        })
    }

    const onSelectUserGroup = (group) => {
        setUserGroup(group);
        setPage(1);
        onSearch(1, size, key, group);
    }

    const onUpdateAuth = (record, field, checkVal) => {
        let data = [...listData];
        let indexOfRecord = data.findIndex(x => x.account == record.account);
        if (indexOfRecord > -1) {
            data[indexOfRecord].authorization.auth[field] = checkVal;
            const dataUpdate = data[indexOfRecord];
            dataUpdate.feature = feature;
            ConfigService.updateScreenAuthorization(dataUpdate).then(res => {
                if (res.isSuccess) {
                    onSearch();
                    _notify && _notify.current && _notify.current.Close();
                } else {
                    _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
                }
            }).catch((err) => {
                _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
            }).finally(() => {
                Actions.setLoading(false);
            })
        } else {
            _notify && _notify.current && _notify.current.Show("Không tìm thấy dữ liệu cần cập nhật", NotifyStatus.Warning);
        }
    }

    const onDelaySearch = (key) => {
        setKey(key);
        setPage(1);
        if (task) clearTimeout(task)
        task = setTimeout(() => {
            onSearch(1, size, key);
        }, 700)
    }

    const onSearch = (p = page, s = size, k = key, group = userGroup) => {
        Actions.setLoading(true);
        let filterModel = {
            skipCount: (p - 1) * s,
            maxResultCount: s,
            keyword: k ?? "",
            userGroup: group,
            feature: feature,
        }
        ConfigService.searchUserScreenAuthorize(filterModel).then(res => {
            if (res.isSuccess) {
                setListData(res.data.items);
                setTotalRecords(res.data.totalCount);
                _notify && _notify.current && _notify.current.Close();
            } else {
                _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
            }
        }).catch((err) => {
            _notify && _notify.current && _notify.current.Show(err.error?.messageText ?? "", NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const getScreenFunctions = () => {
        Actions.setLoading(true);
        ConfigService.getListScreenFunctions(feature ?? "").then(res => {
            if (res.isSuccess) {
                setListFunction(res.data);
            } else {
                _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
            }
        }).catch((err) => {
            _notify && _notify.current && _notify.current.Show(err.error?.messageText ?? "", NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const updateScreenFunction = (uid, checked) => {
        Actions.setLoading(true);
        ConfigService.updateScreenFunction(uid, checked).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Cập nhật thành công" });
                let listfunc = [...listFunction];
                var found = listfunc.find(x => x.uid == uid);
                if (found != undefined) {
                    found.active = checked;
                    setListFunction(listfunc);
                }
            } else {
                notification.warning({ message: res.error.messageText });
            }
        }).catch((err) => {
            notification.error({ message: err.error?.messageText ?? "Đã xảy ra lỗi" });
        }).finally(() => {
            Actions.setLoading(false);
        })
    }

    const getScreenAuth = () => {
        ConfigService.GetScreenAuth().then(res => {
            if (res.isSuccess) {
                setScreenAuth(res.data);
            }
        })
    }

    const renderScreenList = (menuData = props.menuData) => {
        let listOpts = [];
        menuData && menuData.length > 0 && menuData.map((x, index) => {
            if (x.nodes && x.nodes.length > 0) {
                x.nodes.map((n, nInd) => {
                    if (n.nodes && n.nodes.length > 0) {
                        n.nodes.map((nc, ncInd) => {
                            listOpts.push(getOption(nc));
                        })
                    } else listOpts.push(getOption(n));
                })
            } else listOpts.push(getOption(x));
        })
        return listOpts.filter(x => (props.permission ?? []).includes("@@@") ? x : (screenAuth ?? []).includes(x.props.value));
    }

    const getOption = (opt) => {
        return <Select.Option key={`${opt.menuKey}`} value={opt.code}>{opt.label}</Select.Option>
    }

    const handleDeleteSreenFunction = (id) => {
        Actions.setLoading(true);
        ConfigService.DeleteScreenFunction(id)
            .then(res => {
                if (res.isSuccess) {
                    getScreenFunctions();
                    notification.success({ message: "Xóa thành công" });
                } else {
                    notification.err({ message: res.error.messageText });
                }
            }).catch((err) => {
                notification.err({ message: err.error.messageText });
            }).finally(() => {
                Actions.setLoading(false);
            })
    }

    return (
        <div className="list-page containerChilderWrapper">
            <HeaderModal title="Phân quyền chức năng trên màn hình" />
            <div className="list-page-body">
                <Notify ref={_notify} />
                <div className="list-page-search">
                    <div className='d-flex justify-content-start align-items-end' style={{ gap: 20 }}>
                        <div className='mt-2' style={{ flexGrow: 1, flexBasis: 200 }}>
                            <label className='mita-title'>Màn hình - phân hệ</label>
                            <Select
                                className='w-100'
                                placeholder="Chọn màn hình"
                                onChange={(val, e) => { setFeature(val); setScreen(e.children) }}
                                value={feature}
                                showSearch defaultActiveFirstOption
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderScreenList()}
                            </Select>
                        </div>
                        <div className='mt-2' style={{ flexGrow: 1, flexBasis: 200 }}>
                            <label className='mita-title'>Nhóm người dùng</label>
                            <Select value={userGroup} className='w-100' allowClear showSearch
                                onChange={(e) => onSelectUserGroup(e)} placeholder="Chọn nhóm người dùng"
                                onSearch={(e) => { onDelaySearchUserGroup(e); }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {
                                    listUserGroup && listUserGroup.map((g, index) => {
                                        return <Select.Option key={index} value={g.value}>{g.label}</Select.Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='mt-2' style={{ flexGrow: 1, flexBasis: 200 }}>
                            <label className='mita-title'>Người dùng</label>
                            <Input value={key} placeholder='Nhập tài khoản hoặc tên để tìm'
                                onChange={(e) => { onDelaySearch(e.target.value) }}
                            />
                        </div>
                        <div className='mt-2' style={{ flexGrow: 1, flexBasis: 200 }}>
                            <button className={`${BUTTON.THEME}`} onClick={() => { setPage(1); onSearch(1) }}><i className='fas fa-search mr-1' />Tìm</button>
                        </div>
                    </div>
                    {IsSuperAdmin() && !isNullOrEmpty(feature) &&
                        <div className='d-flex justify-content-between align-item-center'>
                            <button className={`${BUTTON.THEME} mt-2`} onClick={() => { setVisibleConfig(true) }}>Danh sách chức năng</button>
                        </div>}
                </div>
                <div className="list-page-table mt-2">
                    <Table style={{ height: "calc(100% - 55px)", width: "100%" }}
                        className="table-min-h-0"
                        dataSource={listData}
                        columns={columns}
                        locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                        scroll={{ x: 0, y: 0 }}
                        rowKey={(e) => e.id}
                        pagination={false}
                    />
                    <Pagination
                        current={page}
                        pageSize={size}
                        style={{ textAlignLast: "center", marginTop: "10px" }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        onChange={(page, size) => { setPage(page); setSize(size); onSearch(page, size); }}
                        onShowSizeChange={(page, size) => { setPage(1); setSize(size); onSearch(1, size); }}
                        showSizeChanger={true}
                        locale={{ items_per_page: "kết quả / trang" }}
                    />
                </div>
            </div>
            <Modal
                open={visibleConfig}
                maskClosable
                onCancel={() => setVisibleConfig(false)}
                destroyOnClose>
                <HeaderModal title="Danh sách chức năng của màn hình" onClose={() => setVisibleConfig(false)}></HeaderModal>
                <div className='list-page'>
                    <div className='list-page-body'>
                        <div className='mt-2'>
                            <Alert type='info' message={`Màn hình: ${screen ?? ""}`} />
                        </div>
                        <div className='mt-2 d-flex justify-content-end'>
                            <button className={`${BUTTON.THEME} mb-2`} onClick={() => setVisibleAddConfig(true)}>Thêm chức năng</button>
                        </div>
                        {listFunction && listFunction.map((x, index) => {
                            return (
                                <div className='row mt-2 border-bottom' key={index}>
                                    <div className='col-7'>{x.title}</div>
                                    <div className='col-5 d-flex justify-content-end align-items-center'>
                                        <Switch checkedChildren="Đang dùng" unCheckedChildren="Không dùng" checked={x.active} onChange={(e) => updateScreenFunction(x.uid, e)}></Switch>
                                        <Popconfirm okText="Đồng ý" cancelText="Hủy bỏ" onConfirm={() => handleDeleteSreenFunction(x.uid)}
                                            title="Bạn có chắc chắn muốn xóa?">
                                            <button className={`${BUTTON.DANGER} ml-2`}><i className='fas fa-trash' /></button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='list-page-footer'>
                        <div className='d-flex justify-content-end'>
                            <button className={`${BUTTON.DANGER}`} onClick={() => setVisibleConfig(false)}><i className='fas fa-times mr-1' />Đóng</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={visibleAddConfig}
                maskClosable
                onCancel={() => visibleAddConfig(false)}
                destroyOnClose>
                <CreateConfig
                    onClose={() => { setVisibleAddConfig(false); }}
                    onReload={() => { getScreenFunctions() }}
                    feature={feature}
                    screen={screen}
                />
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return ({
        menuData: state.global.menuData,
        permission: state.global.permission,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PhanQuyenChucNangTrenManHinh)