import * as ApiCaller from '../libs/httpRequests';
import { FORMAT_YYYYMMDD } from '../configure/systemConfig';
import moment from 'moment';

const prefix = 'Invoice';
export function GetListInvoice(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/chodongtientructiep`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListInvoiceBHYT(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/chodongtienbhyt`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetDetailInvoice(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/chitiettiendong`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
// cái function LuuInvoice(data) này có xài nữa không??? 
// src/screen/invoice/index.js có gọi. Nhưng cái src/screen/invoice/index.js còn xài không???
export function LuuInvoice(data) { 
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPutFormData(`${prefix}/luu`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetLoaiBN() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/loaibn`,).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetSoHieu(value) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/sohieu?key=${value}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetLoaiVP() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/loaivp`,).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongTinHoanTraHD(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/thongtin`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThongTinVienPhiHoaDon(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/detailhoadon`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function HoanTraHoaDon(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/delhd`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function DSHoanTraHoaDon(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/dshoadonhoantra`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XoaHDHoanTra(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(`${prefix}/delhoadontra`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function LoaiThuTamUng() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LoaiThuTamUng`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function DanhSachKyHieu(loai, locthe, loctheoso) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachKyHieu?loai=${loai}&locthe=${locthe}&loctheoso=${loctheoso}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function LoaiBenhNhan() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/LoaiBenhNhan`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachDaTamUng(mabn, mavaovien, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachDaTamUng?mabn=${mabn}&mavaovien=${mavaovien}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachThuTrongNgay(dateFrom, dateTo, key, page = 1, size = 10) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachThuTrongNgay?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachCanThanhToan(mabn, maql, dateFrom) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachCanThanhToan?mabn=${mabn}&maql=${maql}&dateFrom=${dateFrom}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachNgayVaoVien(ngaythu, mabn, loaibn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachNgayVaoVien?ngaythu=${ngaythu}&mabn=${mabn}&loaibn=${loaibn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachNgayVaoKham(ngaythu, mabn, loaibn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachNgayVaoKham?ngaythu=${ngaythu}&mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuThuTamUng(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuThuTamUng`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachCanHoanTra(dateFrom, dateTo, mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachCanHoanTra?dateFrom=${dateFrom}&dateTo=${dateTo}&mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachCanHoanTraTamUng(dateFrom, dateTo, mabn) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachCanHoanTraTamUng?dateFrom=${dateFrom}&dateTo=${dateTo}&mabn=${mabn}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachCanHoanTraChiTiet(dateFrom, dateTo, mabn, maql, banle = false, sohoadon = "") {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachCanHoanTraChiTiet?dateFrom=${dateFrom}&dateTo=${dateTo}&mabn=${mabn}&maql=${maql}&banle=${banle}&sohoadon=${sohoadon}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachCanHoanTraChiTietTamUng(dateFrom, dateTo, mabn, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachCanHoanTraChiTietTamUng?dateFrom=${dateFrom}&dateTo=${dateTo}&mabn=${mabn}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuHoanTraTatCaTrucTiep(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuHoanTraTatCaTrucTiep`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};


export function LuuHoanTraTatCaTamUng(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuHoanTraTatCaTamUng`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuHoanTraTrucTiep(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuHoanTraTrucTiep`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function LuuHoanTraTamUng(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/LuuHoanTraTamUng`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachHoaDonDaDong(mabn, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachHoaDonDaDong?mabn=${mabn}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function TienTamUngTienChiDinh(mabn, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/TienTamUngTienChiDinh?mabn=${mabn}&maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DanhSachDaHoanTraTheoNgay(key, dateFrom, dateTo, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/DanhSachDaHoanTraTheoNgay?key=${key}&dateFrom=${dateFrom}&dateTo=${dateTo}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ListPatientPayment(key, dateFrom, dateTo, isPaid, type, chinhanh='', trangThaiThanhToan = 1) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ListPatientPayment?key=${key}&dateFrom=${dateFrom}&dateTo=${dateTo}&isPaid=${isPaid}&type=${type}&chinhanh=${chinhanh}&statuspayment=${trangThaiThanhToan}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ListPatientPaymentInBill(key, dateFrom, dateTo, isPaid, type, trangThaiThanhToan = 1, page, size, nguonKhachHang, sorting) {
    if (page === undefined || size === undefined) {
        page = 0;
        size = 0;
    }
    let coporatorFilter = "";
    if (nguonKhachHang !== undefined && nguonKhachHang !== "" && nguonKhachHang !== null) {
        coporatorFilter += `&corp=${nguonKhachHang}`;
    }
    if (sorting === undefined || sorting === null || sorting === "")
        sorting = "";
    sorting = `&sorting=${sorting}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/ListPatientPaymentInBill?key=${key}&dateFrom=${dateFrom}&dateTo=${dateTo}&isPaid=${isPaid}&type=${type}&statuspayment=${trangThaiThanhToan}&page=${page}&size=${size}${coporatorFilter}${sorting}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SavePayMent(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SavePayMent`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SavePayMentRetail(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SavePayMentRetail`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetPaymentType(sohoadon){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetInvoicePayMentType?sohoadon=${sohoadon}`).then(respone => {
            return resolve(respone)
        }).catch(err =>{
            return reject(err)
        })
    })
}

export function CheckPaymentType(sohoadon){
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/CheckPaymentType?sohoadon=${sohoadon}`).then(respone => {
            return resolve(respone)
        }).catch(err =>{
            return reject(err)
        })
    })
}

export function ChangePaymentType(sohoadon, loaithanhtoan, checkvat){
    return new Promise((resolve, reject) =>{
        return ApiCaller.httpPut(`${prefix}/ChangePayMentType?sohoadon=${sohoadon}&loaithanhtoan=${loaithanhtoan}&checkvat=${checkvat}`).then(respone =>{
            return resolve(respone)
        }).catch(err=>{
            return reject(err)
        })
    })
}

export function PatientPaymentInvoiceDetail(sohoadon, ngay, banle) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/PatientPaymentInvoiceDetail?sohoadon=${sohoadon}&ngay=${ngay}&banle=${banle}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteTamUng(id) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteTamUng?id=${id}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListVATInvoice(key = "", dateFrom, dateTo, page = 1, size = 10, chinhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListVATInvoice?key=${key}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}&chinhanh=${chinhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetDetailVATInvoice(maql, chiNhanh) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetDetailVATInvoice?maql=${maql}&chinhanh=${chiNhanh}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListBangGia() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachbanggiavp`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function XuatKhoKhauHaoKhamBenh(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/XuatKhoKhauHaoKhamBenh?maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function saveCustomerVATInfo(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SaveCustomerVAT`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getCustomerVATInfo(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetCustomerVATInfo?maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function RefundByList(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/RefundByList`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CheckTestResult(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CheckTestResult`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SubmitServiceVoucher(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SubmitServiceVoucher`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListPaidBill(filterModel) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListPaidBill`, filterModel).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function HoanTraCongNo(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/HoanTraCongNo`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DieuChinhMienGiam(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/DieuChinhMienGiam`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ThanhToanHangLoat(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ThanhToanHangLoat`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetListPayment(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListPayment`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function GetPaymentDetail(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetPaymentDetail`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function SavePayment(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SavePaymentNew`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListPaid(mabn, maql, billType, rept) {
    let link = `GetListPaid?pid=${mabn}&mid=${maql}&billType=${billType}`;
    if (rept) link += `&rept=${rept}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${link}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function geDetailBill(mabn, maql, rept) {
    let link = `GeDetailBill?pid=${mabn}&mid=${maql}`;
    if (rept) link += `&rept=${rept}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${link}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

//Hàm này giống hàm trên, nhưng chỉ cần truyền billNumber. Từ từ bỏ hàm trên
export function getBillDetail(billNumber) {
    let link = `GetBillDetail?billNumber=${billNumber}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${link}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getBillListByMaQl(maql, getDetails) {
    let link = `GetListBillByMaQl?maql=${maql}${getDetails ? ('&getDetails='+getDetails):''}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${link}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getBillListByListMaQl(listMaql) {
    let link = `GetListBillByListMaQl`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/${link}`, listMaql).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function updateVatInvoice(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/UpdateVatInvoice`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function refund(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/Refund`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getListRefund(mabn, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListRefund?pid=${mabn}&mid=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

// Lấy danh sách hình thức đã thanh toán của bill
export function GetBillPaymentType(billNumber) {
    let link = `GetBillPaymentType?billNumber=${billNumber}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${link}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
export function ChangePaymentTypeNew(data){
    return new Promise((resolve, reject) =>{
        return ApiCaller.httpPost(`${prefix}/ChangePayMentTypeNew`, data).then(respone =>{
            return resolve(respone)
        }).catch(err=>{
            return reject(err)
        })
    })
}
export function SaveMassPayment(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/SaveMassPayment`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ModifyDiscountInvoice(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/ModifyDiscountInvoice`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeletePaidAndDoneInvoice(maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/DeletePaidAndDoneInvoice?maql=${maql}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};