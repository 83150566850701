import React, { Fragment, useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { Form, notification, Select, Tooltip } from 'antd';
import * as CorporationServices from '../../corporation/CorporationServices';
import * as PatientService from "../../../services/patientService";
import * as NhanVienSerivce from '../../../services/employeeService';
import * as ConfigService from '../../../services/configService';
import AutocompletetableNoGroup from '../../../components/autocompletetable/autocompletetableNoGroup';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import { isNullOrEmpty, isnotnull } from '../../../libs/util';
import * as Actions from "../../../libs/actions";
import { connect } from 'react-redux';
import { USER_AUTH } from '../../../configureHIS/common/constants';
const { Option } = Select;
/*2024.08.21: Thêm các props sau: 
- disabledCoporation: true: thì disabled cái chọn Nguồn KH | false: thì endable Nguồn KH
- disabledNguoiGioiThieu: true: thì disabled cái chọn Người giới thiệu | false:thì endable Người giới thiệu
- disabledSale: true: thì disabled cái chọn Sale | false:thì endable Sale
Những cái disable trên khác với cái props.disable được gán cho state disableCopor bên dưới.
Cái props.disable là áp dụng all cho cả 3 field [Nguồn KH, Người giới thiệu, Sale]. Còn mấy cái disabled trên là áp dụng cho từng field.
Tùy từng màn hình sử dụng component này mà truyền cho phù hợp (liên quan permission, view, modify,...)
*/
const NguonKhachHang = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        updateInfo: () => {
            updateInfo();
        },
        updateBangGiaSite: (newBangGiaSite) => {
            setBanggiaSite(newBangGiaSite);
        },
        getState: () => {
            let info = {};
            info.idsale = idsale;
            info.idngt = idngt;
            info.coporator = coporatorSec;
            return info;
        },
        resetState: () => {
            setListCoporators([]);
            setCoporatorSec(null);
            setInitCoporatorSec("");
            setIdngt("");
            setTenngt("");
            setIdsale("");
            setSaleName("");
            setBanggia(props.setBangGiaDefault == false ? '' : 'default');
            setDefaultpayment(null);
            setMaxCall(1);
            setBanggiaSite("");
        },
    }));
    const _AutocompletePatient = useRef();
    const _AutocompleteSale = useRef();
    const [coporatorSec, setCoporatorSec] = useState(null);
    const [initCoporatorSec, setInitCoporatorSec] = useState("");
    const [idsale, setIdsale] = useState("");
    const [saleName, setSaleName] = useState("");
    const [idngt, setIdngt] = useState("");
    const [tenngt, setTenngt] = useState("");
    const [banggia, setBanggia] = useState(props.setBangGiaDefault == false ? '' : 'default');
    const [disableCopor, setDisableCopor] = useState(props.disable ? props.disable : false);
    const [defaultpayment, setDefaultpayment] = useState(null);
    const [listCoporators, setListCoporators] = useState([]);
    const [key, setKey] = useState("");
    const [keySale, setKeySale] = useState("");
    const [managermentId, setManagermentId] = useState("");
    const [lstLoaiTT, setlstLoaiTT] = useState([]);
    const [maxCall, setMaxCall] = useState(1);
    const [banggiaSite, setBanggiaSite] = useState(props.banggiaSite ? props.banggiaSite :'');

    useEffect(() => {
        loadCoporation();
    },[])

    useEffect(() => {
        let checkMaxCall = false;
        if (props.managermentId === '') checkMaxCall = true;
        else {
            setMaxCall(maxCall - 1);
            checkMaxCall = maxCall - 1 >= 0;
        }
        
        if (checkMaxCall) {
            let id = props.id;
            let name = props.name;
            let managermentIdProps = props.managermentId;
            if (id && name) {
                let found = listCoporators.find(x => x.ma.toString() === id.toString());
                if (found === undefined) {
                    setListCoporators([...listCoporators, { ma: id, ten: name }]);
                }
                
                loadCoporationDetail(id);
                setCoporatorSec(id);
                setInitCoporatorSec(id);
            } else {
                if (managermentIdProps) {
                    setManagermentId(managermentIdProps);
                    loadCoporationDetail("", managermentIdProps);
                }
            }
        }
    }, [props])

    useEffect(() => {
        getPaymentMethod();
    }, [props.id, props.managermentId])

    useEffect(() => {
        setDisableCopor(props.disable);
    }, [props.disable])

    const loadCoporationDetail = (id, managermentId) => {
        CorporationServices.CoporationDetailByManagermentId(id, managermentId, props.chiNhanhDatLich).then(result => {
            if (result.isSuccess) {
                if (!props.disabledNguoiGioiThieu && result.item.tennguoigioithieu !== '') {
                    setIdngt(result.item.nguoigioithieu);
                    setTenngt(result.item.tennguoigioithieu);
                }
                if (result.item.banggia && result.item.banggia !== '') {
                    updateBangGiaProps(result.item.banggia);
                } else if (!isNullOrEmpty(result.item.bangGiaChiDinh)) {
                    updateBangGiaProps(result.item.bangGiaChiDinh);
                } else {
                    updateBangGiaProps(banggiaSite);
                }
                if (!props.disabledSale && result.item.idsale !== '' && result.item.idsale !== 0) {
                    setIdsale(result.item.sale);
                    setSaleName(result.item.saleName);
                }
                if (result.item.paymenttype !== '' && result.item.paymenttype !== 0 && result.item.paymenttype !== undefined) {
                    setDefaultpayment(result.item.paymenttype);
                } else {
                    setDefaultpayment(null);
                }
                let foundCopor = listCoporators.find(x => !isnotnull(x.ma)&& x.ma.toString() === result.item.ma.toString() && x.ten.toString() === result.item.ten.toString());
                if (foundCopor === undefined) {
                    if (result.item.ma !== "" && result.item.ma !== null && result.item.ten !== null){
                        setListCoporators([...listCoporators, { ma: result.item.ma, ten: result.item.ten }]);
                    }   
                    setInitCoporatorSec(result.item.ma);
                }
                if (result.item.ma !== "") {
                    setCoporatorSec(result.item.ma);
                    updateCoporator(result.item.ma);
                }
                else {
                    setCoporatorSec(null);
                    updateCoporator("");
                };
                initCoporatorSec !== "" && initCoporatorSec === result.item.ma && setInitCoporatorSec(result.item.ma);
            }
        })
    }

    const loadCoporation = (key = "", page = 1, size = 10) => {
        CorporationServices.GetList(key, page, size).then(res => {
            if (res.isSuccess) {
                setListCoporators(res.data);
            }
        })
    }

    const loadNguoiGioiThieu = (data) => {
        setKey(data);
        return PatientService.GetListNguoiGioiThieu(data, false, 1, 20);
    }

    const selectNguoiGioiThieu = (data) => {
        setIdngt(data.id);
        setTenngt(data.hoten);
    }

    const loadSale = (data) => {
        setKeySale(data);
        return NhanVienSerivce.DanhSachNhanVien(data, 1, 100, '26');
    }

    const selectSale = (data) => {
        setIdsale(data.id);
        setSaleName(data.hoten);
    }

    const updateInfo = () => {
        if (managermentId) {
            // if (coporatorSec !== initCoporatorSec) {
                let data = {
                    ma: coporatorSec,
                    sale: idsale !== "" && idsale !== null && idsale !== undefined ? idsale : 0,
                    nguoigioithieu: idngt !== "" && idngt !== null && idngt !== undefined ? Number(idngt) : 0,
                    parentScreen: props.parentScreen
                }
                CorporationServices.UpdateRegistedCoporation(data, managermentId).then(res => {
                    if (!res.isSuccess) {
                        if (!isNullOrEmpty(res.err.msgString))
                            Actions.openMessageDialog(res.err.msgCode, res.err.msgString);
                        else notification.warning({ message: "Lỗi cập nhật nguồn khách hàng" })
                    }
                }).catch(() => { })
            // }
        }
    }

    const getPaymentMethod = () => {
        VPService.GetDanhSachLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                let res = result.data;
                setlstLoaiTT(res)
            }
        }).catch(err => {
        })
    }

    const columnNguoiGioiThieu = [
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code',
            width: 80,
        },
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 250,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            width: 100,
        },
        {
            title: 'Giới tính',
            dataIndex: 'gioitinh',
            key: 'gioitinh',
            width: 80,
        },
    ];

    const columnSale = [
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code',
            width: 80,
        },
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 250,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
        },
    ];
    
    //2023.07.09: thêm onClear để biết là clear (xóa chọn nguồn KH). Vì trong component này khi clear thì lại set banggia = 'default'. 
    //Nhưng cái logic chọn banggia là theo nguồn KH -> site -> mặc định.
    //Vì lý do có thể component này có dùng nhiều chỗ. Nên tự ý sửa updateBangGiaProps(null) trong hàm onClear thì không nên.
    const updateBangGiaProps = (banggia, onClear = false) => {
        setBanggia(banggia);
        props.updateInfoBangGia && props.updateInfoBangGia({ banggia, onClear });
    }

    const updateCoporator = (coporatorID) => {
        props.updateCoporator && props.updateCoporator({ coporatorID });
    }

    const onClear = () => {
        setCoporatorSec(null);
        updateBangGiaProps("default", true);
    }

    return (
        <Fragment>
            <div className="col-3 mt-1">
                <label className={props.coporationRequired ? "required-field" : ""}>Nguồn khách hàng</label>&nbsp;<Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle'></i></Tooltip>
                <Form.Item>
                    <Select
                        value={coporatorSec} placeholder="Chọn nguồn khách hàng"
                        onChange={(e) => {
                            if (e !== '' && e !== null && e !== undefined) {
                                setCoporatorSec(e);
                                loadCoporationDetail(e);
                            } else {
                                // setIdsale('');
                                // setIdngt('');
                                // setTenngt('');
                                // setSaleName('');
                                setBanggia(props.setBangGiaDefault == false ? '' : 'default');
                                setDefaultpayment(null);
                                setCoporatorSec(null);
                                updateCoporator("");
                            }
                        }}
                        showSearch
                        allowClear
                        onClear={() => onClear()}
                        onSearch={(value) => loadCoporation(value)}
                        disabled={disableCopor || props.disabledCoporation}
                        // disabled={disableCopor || !props.userScreenAuthorization?.auth[USER_AUTH.REGISTER.UpdateCoporation]}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {listCoporators && listCoporators.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                    </Select>
                </Form.Item>
            </div>
            <div className="col-3 mt-1">
                <label>Người giới thiệu</label>
                <Form.Item>
                    <AutocompletetableNoGroup
                        ref={_AutocompletePatient}
                        placeholder={"Nhập thông tin người giới thiệu"}
                        width='100%'
                        tablewidth={500}
                        onSearch={(data) => loadNguoiGioiThieu(data)}
                        onSelect={(data) => selectNguoiGioiThieu(data)}
                        headers={columnNguoiGioiThieu}
                        keyValue={'id'}
                        value={tenngt}
                        showRemove={tenngt !== '' && tenngt !== undefined && tenngt !== null && !disableCopor}
                        CleanText={() => {
                            setIdngt('');
                            setTenngt('');
                        }}
                        disabled={disableCopor || props.disabledNguoiGioiThieu}
                    />
                </Form.Item>
            </div>
            <div className="col-3 mt-1">
                <label>Sale</label>
                <Form.Item>
                    <AutocompletetableNoGroup
                        ref={_AutocompleteSale}
                        placeholder={"Nhập thông tin sale"}
                        width='100%'
                        tablewidth={500}
                        onSearch={(e) => loadSale(e)}
                        onSelect={(e) => selectSale(e)}
                        headers={columnSale}
                        keyValue={'id'}
                        value={saleName}
                        showRemove={saleName !== '' && saleName !== undefined && saleName !== null && !disableCopor}
                        CleanText={() => {
                            setIdsale('');
                            setSaleName('');
                        }}
                        disabled={disableCopor || props.disabledSale}
                    />
                </Form.Item>
            </div>
            {/* <div className="col-3 mt-1">
                <label>Bảng giá</label>
                <Form.Item>
                    <Select
                        label="Bang gia"
                        className="w-100"
                        showSearch
                        allowClear={true}
                        value={banggia}
                        disabled={true}
                    />
                </Form.Item>
            </div> */}
            <div className="col-3 mt-1">
                <label>Hình thức thanh toán mặc định</label>
                <Form.Item>
                    <Select className="w-100" value={defaultpayment !== 0 ? defaultpayment : null} disabled={true}>
                        {lstLoaiTT && lstLoaiTT.map((hinhthuc, index) =>
                            <Option key={index} value={hinhthuc.ma}>{hinhthuc.ten}</Option>
                        )}
                    </Select>
                </Form.Item>
            </div>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
        coporationRequired: state.global.sysConfig.coporationRequired ? state.global.sysConfig.coporationRequired : false,
        marketingRequired: state.global.sysConfig.marketingRequired ? state.global.sysConfig.marketingRequired : false,
        administrativeRequired: state.global.sysConfig.administrativeRequired ? state.global.sysConfig.administrativeRequired : false,
        userScreenAuthorization: state.global.userScreenAuthorization,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(NguonKhachHang);