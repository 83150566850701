import { DatePicker, Form, notification, Table, Tabs, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../libs/actions';
import * as PatientStaticService from '../../../services/patientStaticService';
import Chart from './chart';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as ConfigService from '../../../services/configService';
import * as UsersService from '../../../services/usersService';
import * as SystemConfig from '../../../configure/systemConfig';

const { RangePicker } = DatePicker;
const { Option } = Select;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            totalRecords: 0,
            ngay: [moment().subtract(7, "days"), moment()],
            currentPage: 1,
            currentSize: 10,
            listSite: [],
            chinhanh: '',
            isMultisite: false,
        };
    }
    componentDidMount() {
        // UsersService.CheckPremissionMultisite().then(result => {
        //     if (result.isSuccess) {
        //         this.setState({
        //             isMultisite: result.item
        //         });
        //     }
        // });
        // this.GetListSite();
        this.loadnewPatient();
    }

    loadnewPatient() {
        Actions.setLoading(true);
        PatientStaticService.PatientStatistic(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),this.state.chinhanh, this.state.currentPage, this.state.currentSize).then(result => {
            if (result.isSuccess) {
                this.setState({
                    dataSource: result.data,
                    totalRecords: result.totalRecords,
                });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            notification.warning({ message: 'Tải dữ liệu bị lỗi' })
        })
    }
    ExportPatientStatistic() {
        Actions.setLoading(true);
        PatientStaticService.ExportPatientStatistic(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),this.state.chinhanh)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    let fileName = `${moment().format(SystemConfig.FORMAT_PREFIX_EXCEL)}_DSKH_ThamKham`; 
                    this._hiddenLink.download = fileName;
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    render() {
        const columns = [
            {
                title: 'Ngày tiếp đón',
                dataIndex: 'ngay',
                key: 'ngay',
                width:200,
                render: (date) => {
                    if (date)
                        return <span>{moment(date).format(SystemConfig.FORMAT_DATE)}</span>;
                    else return "Không xác định"
                },
            },
            {
                title: 'Mã BN',
                dataIndex: 'mabn',
                key: 'mabn',
                width:150,
            },
            {
                title: 'Tên BN',
                dataIndex: 'tenbn',
                key: 'tenbn',
                width:250,
            },
            {
                title: 'Tiếp đón',
                dataIndex: 'isNew',
                key: 'isNew',
                width: 100,
                render: (isNew) => {
                    return <span>{isNew ? "Bệnh nhân mới" : "Bệnh nhân tái khám"}</span>;
                },
            },
            // {
            //     title: 'Ngày đăng ký lần đầu',
            //     dataIndex: 'firstMet',
            //     key: 'firstMet',
            //     width: 150,
            //     render: (date) => {
            //         return <span>{date ? moment(date).format(SystemConfig.FORMAT_DATE_TIME) : "Không xác định"}</span>;
            //     },
            // },
            {
                title: 'Ngày sinh',
                dataIndex: 'ngaysinh',
                key: 'ngaysinh',
                width: 150,
                render: (text, record) => {
                    return <span>{record.onlyyear ? moment(text).format("YYYY") : moment(text).format(SystemConfig.FORMAT_DATE)}</span>;
                },
            },
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                width: 100,
            },
            {
                title: 'CMND',
                dataIndex: 'cmnd',
                key: 'cmnd',
                width: 100,
                render: (text, record, index) => {
                    return <span>{text.toString() === "0" ? '' : text}</span>;
                },
            },



        ]
        return (
            <div className='containerChilderWrapper'>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                <Tabs animated={true}
                    style={{ height: "100%" }}>
                    <Tabs.TabPane tab={"Biểu Đồ"} key="1" style={{ height: '100%' }}>
                        <Chart ref={(c) => this.chart = c}></Chart>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"Danh sách"} key="2">
                        <div style={{ height:'100%' }}>
                            <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, paddingTop: 10 }}>
                                <div className="col-auto">
                                    <Form.Item label="Từ ngày:">
                                        <RangePicker locale={viVN} disabledDate={current => { return current && current >= moment() }}
                                            allowClear={false} format="DD-MM-YYYY" value={this.state.ngay}
                                            placeholder={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                            onChange={(value => this.setState({ ngay: value }, () =>
                                                this.setState({ currentPage: 1 }, () => {
                                                    this.loadnewPatient()
                                                })
                                            ))}
                                            renderExtraFooter={() =>
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.setState({ ngay: [moment().subtract(7, 'd'), moment(new Date())] })
                                                            setTimeout(() => {
                                                                this.setState({ currentPage: 1 }, () => {
                                                                    this.loadnewPatient()
                                                                })
                                                            }, 100)
                                                        }}>
                                                        7  Ngày
                                                    </div>
                                                    <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                        onClick={() => {

                                                            this.setState({ ngay: [moment().startOf('month'), moment(new Date())] })
                                                            setTimeout(() => {
                                                                this.setState({ currentPage: 1 }, () => {
                                                                    this.loadnewPatient()
                                                                })
                                                            }, 100)
                                                        }}>
                                                        Tháng hiện tại
                                                    </div>
                                                    <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.setState({ ngay: [moment().startOf('quarter'), moment(new Date())] })
                                                            setTimeout(() => {
                                                                this.setState({ currentPage: 1 }, () => {
                                                                    this.loadnewPatient()
                                                                })
                                                            }, 100)
                                                        }}>
                                                        Quý hiện tại
                                                    </div>
                                                    <div style={{ textAlign: 'center', padding: "2px 5px", cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.setState({ ngay: [moment().startOf('year'), moment(new Date())] })
                                                            setTimeout(() => {
                                                                this.setState({ currentPage: 1 }, () => {
                                                                    this.loadnewPatient()
                                                                })
                                                            }, 100)
                                                        }}>
                                                        Năm hiện tại
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-auto" style={{ display: 'flex' }}>
                                    <Form.Item>
                                        <button className="btn btn-color" style={{ margin: "0px 5px" }} onClick={() => {
                                            this.setState({ currentPage: 1 }, () => {
                                                this.loadnewPatient()
                                            })
                                        }}><i style={{ marginRight: 5 }} className="fa fa-search" />Tìm</button>
                                    </Form.Item>
                                    <Form.Item>
                                        <button className="btn btn-color" onClick={() => { this.ExportPatientStatistic() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Xuất excel</button>
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ marginTop: 10, height: 'calc(100% - 100px)' }}>
                                <Table
                                    scroll={{ x: 'max-content', y: 0 }}
                                    pagination={{
                                        position: ["bottomCenter"],
                                        showSizeChanger: true,
                                        style: { textAlignLast: "center" },
                                        total: this.state.totalRecords,
                                        showTotal: (total) => (`Tổng: ${total}`),
                                        size: this.state.currentSize,
                                        current: this.state.currentPage,
                                        onChange: (page, size) => {
                                            this.setState({ currentPage: page, currentSize: size }, () => {
                                                this.loadnewPatient()
                                            })
                                        },
                                        onShowSizeChange: (page, size) => {
                                            this.setState({ currentPage: page, currentSize: size }, () => {
                                                this.loadnewPatient()
                                            })
                                        }
                                    }}
                                    dataSource={this.state.dataSource} columns={columns} rowKey={(row, index) => index} />
                            </div>
                        </div>
                    </Tabs.TabPane>
                </Tabs>

            </div>
        )
    }
}
