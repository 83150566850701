import React from 'react';
import * as SpeakerService from '../../services/speakerService';
import * as Actions from '../../libs/actions';
import { Tooltip } from 'antd';
var buffer;
const voices = [
    {
        name: "Lê Minh (Nam miền Bắc)",
        key: "leminh"
    },
    {
        name: "Ban Mai (Nữ miền Bắc)",
        key: "banmai"
    },
    {
        name: "Lê Minh (Nữ miền Bắc)",
        key: "thuminh"
    },
    {
        name: "Gia Huy (Nam miền Trung)",
        key: "giahuy"
    },
    {
        name: "Mỹ An (Nữ miền Trung)",
        key: "myan"
    },
    {
        name: "Lan Nhi (Nữ miền Nam)",
        key: "lannhi"
    },
    {
        name: "Linh San (Nữ miền Nam)",
        key: "linhsan"
    },

]

const speed = [-3, -2, -1, 0, 1, 2, 3]

export default class Speaker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keySelect: "",
            text: "",
            speed: 0,
            source: "",
            pitch: 100,
            editPitch: 100,
            files: [],
            urlPlay: null,
            sentence:'',
        };
    }


    componentDidMount() {

    }


    handleCrateFile() {
        // var model = { speed: this.state.speed, voice: this.state.keySelect, text: this.state.text }

        // ApiCaller.httpPost(`api/voices`, model).then(respone => {
        //     store.dispatch({
        //         type: LOADING,
        //         status,
        //     });
        //     this.audio.pause();
        //     setTimeout(() => {

        //         store.dispatch({
        //             type: STOP_LOADING,
        //             status,
        //         });

        //         this.bufferSound(audioContext, 'audio/' + respone.data.fileName).then((buffer) => {

        //             var src = audioContext.createBufferSource();
        //             src.buffer = buffer;
        //             src.detune.value = this.state.pitch

        //             src.connect(audioContext.destination);
        //             src.start();
        //             this.startrecording(this.audio);
        //             src.connect(recordingstream);
        //             src.onended = ((e) => {
        //                 recorder.stop();
        //             })

        //         })
        //         store.dispatch({
        //             type: STOP_LOADING,
        //             status,
        //         });
        //     }, 4000);

        // }).catch((e) => {
        //     console.log(e)
        //     store.dispatch({
        //         type: STOP_LOADING,
        //         status,
        //     });
        // })
    }
    reset(){
        this.setState({urlPlay:null});
    }


    static getDerivedStateFromProps(props, state) {

        if(props.sentence !== state.sentence)
        return {
            sentence: props.sentence,
            urlPlay:null         
        }
    }
    speak() {
        if (this.state.urlPlay == null && this.playaudio == null) {
            Actions.setLoading(true);
            SpeakerService.speak(this.state.sentence
            , "myan", -2).then(respone => {
                if (respone.isSuccess) {
                    setTimeout(() => {
                        this.setState({ urlPlay: respone.item },
                            () => {
                                this.playaudio.load();
                                this.playaudio.play();
                            });
                    },200)
                }

            }).catch(ex => {
            }).finally(() => {
                Actions.setLoading(false);
            })
        }
        else {
            this.playaudio.load();
            this.playaudio.play();
        }

    }


    render() {
        return (
            <React.Fragment>
                {this.state.urlPlay !== null &&
                    <audio ref={(c) => this.playaudio = c} controls
                        style={{ marginLeft: 50, visibility: 'hidden', position: 'absolute', display: "none" }}
                    >
                        <source src={this.state.urlPlay == null ? "" : this.state.urlPlay} id="src" />
                    </audio>
                }
                {
                    this.props.notShowButton ?
                        <Tooltip title="Phát loa, gọi tên">
                            <span className='anchor-color' style={{ color: '#fff' }} onClick={() => { this.speak() }}><i className='fas fa-bullhorn mr-1' /></span>
                        </Tooltip>
                        :
                        <button className="btn btn-color" style={{ color: '#fff' }}
                            onClick={() => { this.speak() }}>
                            <i className="fas fa-volume-up" style={{ color: 'white' }}></i>
                        </button>
                }
            </React.Fragment>


        );
    }
}

