import { isnotnull } from "../libs/util";
import jwt_decode from "jwt-decode";

export  function formatNumber (value) {
    value += '';
    const list = value.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  }

export function capitalizeFirstLeter (value) {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function isValidPhone(phone) {
  const regEx = new RegExp('^(\\+84|02[0-9]|0[3|5|7|8|9])+([0-9]{8})\\b');
  return regEx.test(phone);
}

export function isValidEmail(email) {
  const regEx = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
  return regEx.test(email);
}

export function capitalizeEveryFirstLeter (value) {
  const finalSentence = value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  return finalSentence;
}

export function roundTo(value, suff = 0) {
  if (value != null && value != undefined) {
    return Number(Number(value).toFixed(suff));
  }
  else return 0;
}

export function UserPermission(FEATURE_NAME) {
  const token = localStorage.getItem("token");
  let listWarehousePermission = [];
  let isSuperAdmin = false;
  if (token) {
      let jsonToken = jwt_decode(token);
      let permissions = jsonToken.Permission ?? "";
      isSuperAdmin = jsonToken.role == "SuperAdmin";
      if (permissions != "") {
          const listPermissions = permissions.split(",");
          let warehousePermission = listPermissions.filter(x => x.toLowerCase().includes(FEATURE_NAME));
          if (warehousePermission && warehousePermission.length > 0) {
              warehousePermission.map((x) => {
                  listWarehousePermission.push({
                      warehouse: Number(x.split("-")[1]),
                      permission: x.split("-")[0]
                  })
              })
          }
      }
  }
  const data = {
    listWarehousePermission,
    isSuperAdmin
  }
  return data;
}

export function IsSuperAdmin() {
  const token = localStorage.getItem("token");
  let isSuperAdmin = false;
  if (token) {
      let jsonToken = jwt_decode(token);
      isSuperAdmin = jsonToken.role == "SuperAdmin";
  }
  return isSuperAdmin;
}

export function GetUserId() {
  const token = localStorage.getItem("token");
  let userId = null;
  if (token) {
      let jsonToken = jwt_decode(token);
      userId = jsonToken.nameid;
  }
  return userId;
}

export function GetUsername() {
  const token = localStorage.getItem("token");
  let username = "";
  if (token) {
      let jsonToken = jwt_decode(token);
      username = jsonToken.unique_name;
  }
  return username;
}