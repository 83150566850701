import * as React from 'react';
import "./style.scss";

const ScreenSaver = (props) => {
    return (
        props.show
            ?
            <div className="mita-screen-saver">
                <div className='mita-intro'>
                    <span>Một sản phẩm của <b>Smart Solutions</b></span>
                </div>
                <div className='mita-glassmorphism'>
                    <img className="logo" alt={""} src="./images/logo.png"></img>
                </div>
            </div>
            : <div style={{ display: 'none', visibility: "hidden" }}></div>
    )
}

export default (ScreenSaver);
