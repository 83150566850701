import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Input, Collapse, Button, Menu, Dropdown, notification } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import { ValidationCustom } from '../../commons/validationCustom';
import { detailScreenModeEnum } from "../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../components/notify';
import * as CommonService from "../../services/commonService";
import { testReceiving } from "../../commons/enums/testReceiving";
import { connect } from 'react-redux';
import { HISTORYFEATURE } from '../../configureHIS/common/constants';
import PatientInfo from '../patient/component/patientInfo';
import VitalSign from '../patient/component/vitalSign';
import HistoryExam from '../../components/history/indexNew';
import TestResultBegin from './detail-components/testresult-begin';
import TestResultDetail from './detail-components/testresult-detail';
import TestResultBottom from './detail-components/testresult-bottom';

const { Panel } = Collapse;
const listSendResultTypes = [ // lấy theo SendMessageType trong HIS.Resource/Enum
  // Sms,
  // Zalo,
  // Email
  {value: 0, text: 'Sms'},
  {value: 1, text: 'Zalo'},
  {value: 2, text: 'Email'},
]
const DownLoadTemplate = React.forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const beginRef = useRef();
  const detailRef = useRef();
  const bottomRef = useRef();
  const upload = useRef();
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  //state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [detailScreenMode, setDetailScreenMode] = useState(props.detailScreenMode);
  //model
  const [maQL, setMaQL] = useState(props.maQL);
  const [sid, setSid] = useState(props.sid);
  const [maBN, setMaBN] = useState(props.maBN);
  //#region useEffect
  useEffect(() => {
  }, []);
  //#endregion 
  const reload = () => {
    beginRef.current.reload();
    detailRef.current.reload();
    bottomRef.current.reload();
  }
  
  const updateLisResult = (row) => {
    Actions.setLoading(true);
    CommonService.GetDataFromLisByOrderId(row.maQL, row.orderId, row.siteId).then(result => {
      if (result.isSuccess) {
        notification.success({ message: "Thành công" });
        notiRef && notiRef.current && notiRef.current.Close();
        reload();
        props.onReload();
      }
      else {
        // Actions.openMessageDialog("Lỗi", result.err.msgString);
        if (result.err.msgString)
          notiRef.current.Show(result.err.msgString , NotifyStatus.Warning)
        else notiRef.current.Show(result.err, NotifyStatus.Warning)
      }
    }).catch(result => {
      if (result.err.msgString)
        notiRef.current.Show(result.err.msgString , NotifyStatus.Warning)
      else notiRef.current.Show(result.err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  // const updateLisResult = (row) => {
  //   CommonService.GetDataFromLis(row.maQL, row.sid, row.siteId).then(result => {
  //     if (result.isSuccess) {
  //       notification.success({ message: "Thành công" });
  //       props.onReload();
  //       reload();
  //     }
  //     else {
  //         Actions.openMessageDialog("Lỗi", result.err.msgString);
  //     }
  //     Actions.setLoading(false);
  //   }).catch(err => {
  //       Actions.openMessageDialog("Lỗi", err);
  //       Actions.setLoading(false);
  //   })
  // }
  const actionOptions = (record) => {
    let items = [
      // {
      //   label: 
      //     <a href="#" onClick={() => {
      //     }}><i className='fa fa-print mr-2'/> In kết quả</a>,
      //   key: 'printresult',
      // },
      // {
      //   label: 
      //     <a href="#" onClick={() => {
      //     }}><i className="fas fa-reply mr-2"></i> Trả kết quả</a>,// trả cho site chính (nếu là mẫu gửi)
      //   key: 'replyresult',
      // },
      {
        label: 
          <a href="#" onClick={() => {
            props.onSendResultClick(record);
          }}><i className="fas fa-paper-plane mr-2"></i> Gửi kết quả</a>,
        key: 'sendresult',
      },
      {
        label: 
          <a href="#" onClick={() => {
            props.onModifiedClick();
          }}><i className='fas fa-pencil-alt mr-2'/> Cập nhật</a>,
        key: 'edit',
      },
    ];
    if (record.configTuongTacVoiLab && record.configQuyTrinhXacNhanMau == testReceiving.HIS) {
      let tuongTac = {
        label: 
          <a href="#" onClick={() => {
          }}><i className="fas fa-exchange-alt mr-2"></i> Tương tác</a>,
        key: 'interactive',
        children: [
          {
            label: 
              <a href="#" onClick={() => {
                updateLisResult(record);
              }}><i className='fas fa-compress-alt mr-2'/> Lấy kết quả</a>,
            key: 'getresult',
          },
        ]
      };
      if (record.sidSentLabStatus == 0) 
        tuongTac.children.push(
          {
            label: 
              <a href="#" onClick={() => {
              }}><i className='fas fa-expand-alt mr-2'/> Gửi lại chỉ số</a>,
            key: 'send',
          },
        );
        items.push({
          label: <hr style={{margin: '-4px 0'}}></hr>,
          key: 'hr',
        });
      items.push(tuongTac);
    }
    return (
      <Menu items={items} />
    )
  }
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Xem chi tiết kết quả - {props.record.hoTen} - {props.record.sid}</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel();
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="w-100 h-100 d-flex justify-content-between">
              <div className="border left-panel">{/**Left Panel */}
                <Collapse defaultActiveKey={['1','2','3']}
                  expandIconPosition="right"
                >
                  <Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="1">
                    <PatientInfo patientId={maBN}/> 
                  </Panel>
                  <Panel header={<label className='mita-header-title'>Thông tin dấu hiệu sinh tồn</label>} key="2">
                    <VitalSign maql={maQL}/> 
                  </Panel>
                  <Panel header={<label className='mita-header-title'>Lịch sử khám</label>} key="3">
                    <div className='col-12 my-2'>
                      <HistoryExam 
                        patientID={maBN}
                        feature= {[HISTORYFEATURE.XETNGHIEM]}
                      /> 
                    </div>
                  </Panel>
                </Collapse>
              </div>
              <div className="border h-100 d-flex flex-column right-panel">{/**Right Panel */}
                <div className="row mt-2">
                  <div className="col-md-12 d-flex flex-column">
                    <label className='mita-title'>Chi nhánh</label>
                    <label className='mita-value'>{props.record.registedSiteName ?? <i>Chưa có thông tin</i>}</label>
                  </div>
                </div>
                <div>{/**Left Panel - top: thông tin phía trên*/}
                  <TestResultBegin ref={beginRef}
                    sid={sid}
                  />
                </div>
                <div>{/**Left Panel - body: chi tiết kết quả*/} 
                  <TestResultDetail ref={detailRef}
                    maQL={maQL}
                    sid={sid}
                  />
                </div>
                <div>{/**Left Panel - bottom: thông tin phía dưới*/} 
                  <TestResultBottom ref={bottomRef}
                    sid={sid}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <Dropdown overlay={actionOptions(props.record)} trigger={['click']}>
                <Button type="link" className="p-0"
                  onClick={() => {  }}
                >
                  Hành động
                  <i className="fa fa-chevron-right ml-2"></i>
                </Button>
              </Dropdown>
              <button
                  className='btn btn-danger ml-3'
                  onClick={() => props.onCancel()}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Đóng
              </button>
            </div>
          </div>
      </div>
    </React.Fragment>
    // <React.Fragment>
    //   <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
    //   <div className='list-page'>
    //     <div style={{width:'30%'}}>{/**Left Panel */}

    //     </div>
    //     <div style={{width:'70%'}}>{/**Left Panel */}
    //       <TestResult></TestResult>
    //     </div>
    //   </div>
    // </React.Fragment>
  );
})

const mapStateToProps = (state) => {
  return ({
      siteid: state.global.siteid,
      siteCode: state.global.siteCode,
      siteName: state.global.siteName
  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DownLoadTemplate);