import React, {Fragment ,useEffect, useRef, useState } from 'react';
import { Input, Switch, Select, Tag, Button, Modal, Popover, Tooltip, notification} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import HeaderModal from '../../../configureHIS/common/headerModal';
import Notify, {NotifyStatus} from '../../../components/notify';
import SelectProduct from '../../../components/MDCLS/selectProduct';
import MitaDisplayListElement from '../../../components/MitaComponents/mitaDisplayListElement';
import * as Actions from '../../../libs/actions';
import * as PackageService from '../../../services/packageService';
import * as ProductServices from "../../../services/ProductService";
import * as FeatureConfig from '../../../configure/featureConfig';

const { Option } = Select;
const { TextArea } = Input;
const showMoreText = "...Xem thêm";
const showLessText = "\nRút gọn";
export default (props) => {
    //#region khai báo ref
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const notiRef = useRef();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    //#region khai báo state
    const [isEdit, setIsEdit] = useState(props.isEdit ?? false);
    const [code, setCode] = useState(null);
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [isActive, setIsActive] = useState(true);
    const [selectedPackageId, setSelectedPackageId] = useState(props.selectedPackageId ?? null);
    const [comboServiceId, setComboServiceId] = useState(null);
    // Service
    const [visibleSelectProduct, setVisibleSelectProduct] = useState(false);
    const [productSelected, setProductSelected] = useState([]);
    const [selectedListServices, setSelectedListServices] = useState();
    const [listComboService, setListComboService] = useState([]);
    const [fieldSelectProduct, setFieldSelectProduct] = useState();
    const [disableFeatureComhbo, setDisableFeatureComhbo] = useState(FeatureConfig.FeatureCodeDeactive().indexOf('COMBO') > -1);

    useEffect(() => {
        GetComboService();
    },[])

    useEffect(() => {
        if(isEdit && selectedPackageId)
            GetPackageDetail(selectedPackageId);
    },[isEdit])

    const GetPackageDetail = (packageId) => {
        PackageService.ServicePackageDetail(packageId).then(result => {
            if (result.isSuccess) {
                setCode(result.data.code);
                setName(result.data.name);
                setComboServiceId(result.data.serviceLinked);
                setIsActive(result.data.isActive);
                setDescription(result.data.description);
                let productSelected = result.data.listServicePackageItem.map(x => (
                    {
                        productId: x.idvp,
                        productCode: x.maVP,
                        productName: x.name,
                    }
                ))
                console.log(productSelected);
                setProductSelected(productSelected);
            } else {
                Actions.openMessageDialog('Lỗi', result.error.messageText);
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        }).finally(() => {
            forceUpdate();
        })
    }

    const handleSubmit = () => {
        if(isEdit) {
            SubmitUpdate()
        } else {
            SubmitCreate()
        }
    }

    const SubmitCreate = () => {
        if(validator.current.allValid()){
            //Actions.setLoading(true);
            let servicePackageItems = productSelected.map(e => ({ 
                idvp : e.productId.toString(),
                mavp : e.productCode.toString(),
                name : e.productName.toString(),
                quantity : 1,
            }));
            let data = {
                code,
                name, 
                description,
                serviceLinked : comboServiceId,
                isActive : isActive,
                ListServicePackageItem : servicePackageItems
            }
            PackageService.CreateServicePackage(data).then(result => {
                if (result.isSuccess) {
                    notiRef.current.Show("Tạo gói thành công.", NotifyStatus.Success);
                    setTimeout(() => {
                        props.onClose(true);
                    }, 500)
                } else {
                    notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
                }
                }).catch(err => {
                    notiRef.current.Show(err, NotifyStatus.Warning);
                }).finally(() => {
                    Actions.setLoading(false);
                    forceUpdate();
                })
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const SubmitUpdate = () => {
        if(validator.current.allValid()){
            let servicePackageItems = productSelected.map(e => ({ 
                idvp : e.productId.toString(),
                mavp : e.productCode.toString(),
                name : e.productName.toString(),
                quantity : 1,
            }));
            let data = {
                id : selectedPackageId,
                code,
                name, 
                description,
                serviceLinked : comboServiceId,
                isActive : isActive,
                ListServicePackageItem : servicePackageItems
            }
            if(selectedPackageId){
                PackageService.UpdateServicePackage(data).then(result => {
                if (result.isSuccess) {
                    notiRef.current.Show("Cập nhật gói thành công.", NotifyStatus.Success);
                    setTimeout(() => {
                        props.onClose(true);
                    }, 500)
                } else {
                    notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
                }
                }).catch(err => {
                    notiRef.current.Show(err, NotifyStatus.Warning);
                }).finally(() => {
                    forceUpdate();
                })
            }
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const GetComboService = () => {
        let filterModel = {
            //common: paging & sorting
            skipCount: 0,
            maxResultCount: 100,
            sorting: '',
            //riêng theo module
            keyword: '',
            nhomMas: [9],
            loaiIds: [],
            bangGia: '',
            hides: [0],
            }
        ProductServices.getList(filterModel).then(result => {
        if (result.isSuccess) {
            setListComboService(result.data.items);
            notiRef && notiRef.current.Close();
        }
        else {
            notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
        }
        }).catch(err => {
        if (err.error && err.error.messageText)
            notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
        else notiRef.current.Show(err, NotifyStatus.Warning);
        }).finally(() => {
        })
    }

    const convertProductToSelectComponent = (arrProduct) => {
        return arrProduct?.map(x => {
            return {
                id: Number(x.productId),
                ma: x.productCode,
                ten: x.productName,
                allowDelete: true,
                uiStatus: 'selected',
                uiDeleted: false,
            }
        })
    }

    const convertSelectComponentToProduct = (arrProductFromSelectComponent) => {
        return arrProductFromSelectComponent.map(x => {
            return {
                productId: x.id,
                productCode: x.ma,
                productName: x.ten,
            }
        })
    }

    const onConfirmSelectProductHandle = (arrayResult = []) => {
        let arrProductSelected = convertSelectComponentToProduct(arrayResult.filter(x => !x.uiDeleted));
        setProductSelected(arrProductSelected ?? []);
        setVisibleSelectProduct(false);
        notification.success({ message: "Xác nhận thành công" });
    }

    const geneListStringToTag = (list = []) => {
        return list?.map((x, index) =>
            <Tag key={x.productId} style={{whiteSpace: "pre-line"}}
                closable
                onClose={() => {/**screen create & edit (chưa có account) thì được sửa */
                    setProductSelected(productSelected.filter(c => c.productId !== x.productId))
                }}
            >{x.productName}
            </Tag>
        )
    }

    const showSelectProductComponent = (e) => {
        setVisibleSelectProduct(true); 
        setSelectedListServices(convertProductToSelectComponent(productSelected));
    }

    return (
    <Fragment>
        <div className='h-100 d-flex flex-column justify-content-between' >
            <HeaderModal title={isEdit ? `Cập nhật - ${name}` : "Tạo mới - Gói dịch vụ"} onClose={() => {props.onClose()}} />
            <div className='overflow-auto max-h-100 h-100 px-3 d-flex flex-column justify-content-start'>{/* body */}
                <div className='d-flex flex-column justify-content-start'> {/* Info of Promotion */}
                    <Notify ref={notiRef} />
                    <div className="row">
                        <div className="col-md-8 mt-2">{/* Tên chương trỉnh */}
                            <div>
                                <label className='required-field'>Mã gói </label>
                            </div>
                            <div>
                                <Input value={code} 
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder='Nhập mã gói'/>
                                {validator.current.message("Mã gói", code, "required")}
                            </div>
                        </div>
                        <div className="col-md-4 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Hoạt động */}
                            <label>Hoạt động </label>
                            <div>
                                <Switch checked={isActive} onChange={(checked, e) => { setIsActive(checked) }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Tỷ lệ khuyến mại */}
                            <div>
                                <label className='required-field'>Tên gói </label>
                            </div>
                            <div>
                                <Input value={name} 
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder='Nhập tên gói'>
                                </Input>
                                {validator.current.message("Tên gói", name, "required")}
                            </div>
                        </div>
                        <div className="col-md-12 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Ghi chú */}
                            <label className='required-field'>Danh sách dịch vụ</label>
                            <div style={{ maxHeight: 250, overflow: "auto", padding: "2px 0" }}>
                                <MitaDisplayListElement
                                content={() => geneListStringToTag(productSelected)}
                                maxLength={5} // mặc định 3
                                showMoreText={ showMoreText }
                                showLessText={ showLessText }
                                // displayMode="popup"
                                // elementDisplay="block"
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Ghi chú */}
                            <Button className="mt-1"
                                onClick={() => { showSelectProductComponent(true); }}>
                                <i className="fa fa-external-link-alt mr-1"></i> 
                                Tìm kiếm và chọn dịch vụ
                            </Button>
                        </div>
                        <div className="col-md-12 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Ghi chú */}
                            <label>Ghi chú</label>
                            <div>
                                <TextArea 
                                    className="w-100"
                                    value={description}
                                    rows={3}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mt-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>{/* Tỷ lệ khuyến mại */}
                            <div>
                                <label>Liên kết dịch vụ combo</label>
                                { disableFeatureComhbo && 
                                <Popover placement="right" content={<span style={{whiteSpace: "pre-line"}}>{"Tính năng combo chưa được phép sử dụng"}</span>}>
                                    <label><i className="fas fa-info-circle ml-1"></i></label>
                                </Popover>}
                            </div>
                            <div>
                                <Select  
                                    className="w-100"
                                    disabled={disableFeatureComhbo}
                                    value={comboServiceId}
                                    allowClear
                                    onClear={() => {setComboServiceId(null)}}
                                    onChange={(e) => setComboServiceId(e)}
                                    placeholder='Dịch vụ liên kết'>
                                    {listComboService.map((record, index) => {
                                        return (
                                        <Option key={record.id} value={record.id}>{record.ten}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='custom-hr'></div>
            <div className="d-flex justify-content-end mt-1">{/* footer */}
                <button 
                    className='btn btn-color mr-2' 
                    onClick={() => {handleSubmit()}}
                >
                    <i className='fa fa-save mr-2'></i>
                    Lưu
                </button>
                <button
                    className='btn btn-danger'
                    onClick={() => props.onClose(false)}
                >
                    <i className='fa fa-sign-out-alt mr-2'></i>
                    Đóng
                </button>
            </div>
        </div>

        <Modal maskClosable={false}
            width={"80%"}
            height="calc(100% - 10px)"
            visible={visibleSelectProduct}
            onCancel={() => setVisibleSelectProduct(false) }
            destroyOnClose={true}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}>
            <SelectProduct
                isDialog={ true } 
                showCombo={ false }
                selectedListServices={[
                ...(selectedListServices ?? []),
                ]}
                btnConfirmText="Lưu"
                onCancel={() => {setVisibleSelectProduct(false)}}
                onConfirm={(arrayResult) => { onConfirmSelectProductHandle(arrayResult); }}
                />
        </Modal>
    </Fragment>
    )
};
