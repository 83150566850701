import React, { Component } from 'react';
import {
    InputNumber, Select, Input, Form, Pagination, notification,
    Table, Modal, Switch, AutoComplete, Popconfirm, Collapse, Tooltip, Tag, Upload,
    Menu, Dropdown, Checkbox, Popover
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import * as VPService from '../services/VPService';
import CreateNew from '../components/createNewVP';
import Notify, { NotifyStatus } from '../../../components/notify';
import * as Actions from '../../../libs/actions';
import * as StatisticService from '../../../services/statisticService';
import * as PackageService from '../../../services/packageService';
import moment from 'moment';
import { ValidationCustom } from '../../../commons/validationCustom';
import SunEditor from 'suneditor-react';
import { FEATURE, COMBO } from '../../../configureHIS/common/constants';
import { FeatureDeactive } from '../../../configure/featureConfig';
import suneditorViLang from '../../../libs/suneditor-vi-lang';
import HeaderModal from '../../common/headerModal';
import { Fragment } from 'react';
import { groupBy } from '../../../libs/util';

const { Option } = Select;
const { Panel } = Collapse;
const { Dragger } = Upload;
let task = null;

const initialState = {
    ngay: moment(),
    key: '',
    tenvp: '',
    id: 0,
    mavp: '',
    maloai: 0,
    manhomvp: 0, // Nhóm xét nghiệm
    maloaivp: null,
    totalRecords: 0,
    giatien: 0,
    visible: false,
    visibleCreate: false,
    tylekhuyenmai: 0,
}
export default class DanhSachUpdateVP extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstVP: [],
            lstNhomVP: [],
            lstNhomVPAll: [],
            lstLoaiVP: [],
            loadingHide: false,
            giabhyt: 0,
            maloaivp: null,
            currentPage: 1,
            pageSize: 10,

            banggia: "default",
            kwd: "",
            lstBangGia: [],
            tenbanggia: "",
            vsbCrBangGia: false,
            vsbSetPrice: false,
            congtru: "",
            phantram: 0,
            lstBangGiaVP: [],
            lstFeeByGroup: [],
            lstUpdate: [],
            quytac: "",
            partialUpdate: false,

            fileList: [],
            listDelete: [],
            visibleImport: false,

            insertRow: 0,
            updateRow: 0,
            rejectRow: 0,
            rejectList: "",
            template: "",
            ketluan : "",
            order: 1,
            lstLoaiVP: [],
            manhomvp_update: null,
            maloaivp_update: null,

            lstServicePackage : [],
            selectedPackage : '',
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }
    componentDidMount() {
        this.GetListBangGia();
        this.GetListServicePackage();
    }
    componentWillMount() {
        this.loadNhomVP();
        this.loadNhomVPAll();
        setTimeout(() => {
            this.loadDanhSachVP(1, this.state.pageSize);
        }, 200);
        //  this.loadDanhSachVP();
    }
    componentWillUnmount() {
        if (task)
            clearTimeout(task)
    }

    loadNhomVP() {
        const getAll = true;
        VPService.GetNhomVP(getAll).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstNhomVP: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm viện phí bị lỗi' });
        })
    }
    loadNhomVPAll() {
        VPService.GetNhomVPAll().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstNhomVPAll: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nhóm viện phí bị lỗi' });
        })
    }
    // loadDanhSachLoaiVP(key) {
    //     VPService.GetLoaiVPByNhom(Number(key)).then(result => {
    //         if (result.isSuccess && result.data.length > 0) {
    //             this.setState({
    //                 lstLoaiVP: result.data,
    //                 maloaivp: null,
    //             });
    //             setTimeout(() => { this.loadDanhSachVP() }, 200)
    //         }
    //     }).catch(err => {
    //     })

    // }
    onReload() {
        this.loadDanhSachVP();
    }
    async loadDanhSachVP(page = this.state.currentPage, size = this.state.pageSize) {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0) {
            VPService.GetDanhSachVP(false, this.state.key, this.state.banggia, this.state.manhomvp, 0, page, size).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        lstVP: result.data,
                        totalRecords: result.totalRecords
                    });
                    this.notify.Close();
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }

    }
    loadDanhSachVPWithRenew(searchBydate, page = 1, size = 50) {
        let numberError = 0;

        this.setState({ currentPage: 1, pageSize: size })
        if (numberError === 0) {
            VPService.GetDanhSachVP(searchBydate, this.state.key, this.state.banggia, this.state.manhomvp, this.state.maloaivp, page, size).then(result => {
                if (result.isSuccess) {
                    this.setState({
                        lstVP: result.data,
                        totalRecords: result.totalRecords
                    });
                    this.notify.Close();
                }
            }).catch(err => {
            })
        } else {
            this.validator.showMessages();
        }

    }

    async updateGiaTien() {
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (this.validator.fieldValid("Nhóm viện phí") && this.validator.fieldValid("Loại viện phí")) {
            if (numberError === 0) {
                const data = {
                    id: Number(this.state.id),
                    ma : this.state.mavp,
                    gia_th: Number(this.state.giatien),
                    tylekhuyenmai: Number(this.state.tylekhuyenmai),
                    ten: this.state.tenvp,
                    gia_bh: Number(this.state.giabhyt),
                    template: this.state.template,
                    thutu: this.state.order,
                    loai: this.state.maloaivp_update,
                    ketluan : this.state.ketluan,
                    packageid : this.state.selectedPackage
                }
                if (this.state.tenvp === '') {
                    notification.warning({ message: 'Chưa nhập tên!' });
                    return;
                }
                VPService.CapNhatGiaTienVP(data, this.state.banggia).then(result => {
                    if (result.isSuccess) {
                        notification.success({ message: 'Cập nhật thành công!' });
                        let list = this.state.lstVP;
                        let item = list.find(x => x.id === this.state.id);
                        item.gia_th = this.state.giatien
                        item.tylekhuyenmai = this.state.tylekhuyenmai
                        item.ten = this.state.tenvp
                        this.setState({
                            visible: false,
                            order: 0,
                        })
                        this.onReload();
                        this.handleCancel();
                    }
                    else {
                        notification.warning({ message: this.getErrorMessage(result.err.msgString) });
                    }
                }).catch(err => {
                    notification.warning({ message: err });
                })
            };
            this.validator.purgeFields();
        } else {
            this.validator.showMessageFor("Nhóm viện phí");
            this.validator.showMessageFor("Loại viện phí");
            this.forceUpdate();
        }
    }

    getErrorMessage(message){
        if (message) {
            let errMsg = message.split("\r\n").find(x => x.includes("HIS.Commons.HisException:"));
            return errMsg ? errMsg.trim().replace("HIS.Commons.HisException:", "") : message;
        } return "";
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    showModalCreate = () => {
        this.setState({
            visibleCreate: true,
        });
    };
    showModalCrBangGia = () => {
        this.setState({
            vsbCrBangGia: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            visibleCreate: false,
            tylekhuyenmai: 0,
        });
    };

    updateHide(mabd, check) {
        this.setState({ loadingHide: true })
        let status = '0';
        if (check === false) {
            status = '1';
        }
        VPService.UpdateHideVP(mabd, status).then(result => {
            let data = this.state.lstVP;
            let item = data.find(x => x.id === mabd);
            if (result.isSuccess) {
                if (item !== undefined) {
                    const tmp = item.hienthi === '1' ? '0' : '1';
                    item.hienthi = tmp;
                }
                notification.success({ message: 'Cập nhật thành công!' })
            }
            else {
                if (item !== undefined) {
                    const newcheck = item.hienthi;
                    item.hienthi = newcheck;
                }
                Actions.openMessageDialog("Lỗi", result.err.msgString)
            }
            this.setState({
                lstVP: data,
                loadingHide: false,
            })
        }).catch(err => {
            let data = this.state.lstVP;
            let item = data.find(x => x.id === data);
            if (item !== undefined) {
                const newcheck = item.hienthi;
                item.hienthi = newcheck;
            }
            this.setState({ loadingHide: false, lstVP: data, })
            notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
        })
    }
    ExportDanhMucDichVu() {
        Actions.setLoading(true);
        StatisticService.ExportDanhMucDV(this.state.key, this.state.banggia, this.state.manhomvp, this.state.maloaivp)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDanhMucDichVu";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    GetListBangGia = () => {
        VPService.GetListBangGia().then(result => {
            if (result.isSuccess) {
                let lstBangGia = result.data.filter(x=>x.value.toLowerCase() != 'default')
                this.setState({
                    lstBangGia: lstBangGia
                });
            }
        }).catch(err => {
        })
    }
    ThemBangGia = () => {
        if (this.validator.fieldValid("Tên bảng giá")) {
            if ((this.state.kwd).toLowerCase() !== ("default").toLowerCase()) {
                this.state.lstBangGia.push({ value: this.state.kwd });
                this.setState({ lstBangGia: [...this.state.lstBangGia], lstBangGiaVP: [], quytac: "" });
            } else {
                notification.warning({ message: "Bạn không thể dùng tên \"Default\" để đặt cho bảng" })
            }
        } else {
            this.validator.showMessageFor("Tên bảng giá");
            this.forceUpdate();
        }
    }
    getChiTietBangGia = (tenbanggia) => {
        VPService.getChiTietBangGia(tenbanggia).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBangGiaVP: result.data
                });
                if (result.data.length > 0 && Math.abs(Number(result.data.find(x => x.quytac !== "").quytac)) > 0) {
                    this.setState({
                        quytac: result.data.find(x => x.quytac !== "").quytac
                    });
                } else {
                    this.setState({
                        quytac: ""
                    });
                }
                this.notifyBangGia.Close();
            }
        }).catch(err => {
        })
    }
    getAllFeeByGroup(id) {
        VPService.GetDanhSachVP(false, '', 'default', id, 0, 1, -1).then(result => {
            if (result.isSuccess) {
                this.setState({ lstFeeByGroup: result.data.filter(x => x.hienthi === "0") });
                this.notify.Close();
            }
        }).catch(err => {
        })
    }
    beforeDelGiaVP(data) {
        if(data.used){
            notification.warning({message : "Dịch vụ trong bảng giá đã được sử dụng. Không thể xóa!"});
            return
        }
        this.setState({
            lstBangGiaVP: this.state.lstBangGiaVP.filter(x => x.ma !== data.ma)
        });
        let record = this.state.lstBangGiaVP.find(x => x.ma === data.ma);
        if (record.tenbanggia !== undefined) {
            this.setState({
                listDelete: [...this.state.listDelete, record]
            });
        }
    }
    // xoaGiaVP(tenbanggia = this.state.tenbanggia) {
    //     let { listDelete } = this.state;
    //     const data = {
    //         lstDetails: listDelete
    //     }
    //     if (tenbanggia !== undefined) {
    //         VPService.delChiTietBangGia(tenbanggia, data).then(result => {
    //             if (result.isSuccess) {
    //                 this.setState({
    //                     listDelete: []
    //                 })
    //             }
    //         }).catch(err => {
    //         })
    //     }
    // }
    xoaGiaVP(id, tenbanggia = this.state.tenbanggia) {
        // let { listDelete } = this.state;
        // const data = {
        //     lstDetails: listDelete
        // }
        if (tenbanggia !== undefined) {
            VPService.deleteVienPhi(id, tenbanggia).then(res => {
                if (res.isSuccess) {
                    notification.success({ message: "Đã xóa thành công" });
                    this.notifyBangGia.Close();
                    this.getChiTietBangGia(tenbanggia);
                } else {
                    this.notifyBangGia.Show(res.err.msgString, NotifyStatus.Warning);
                    notification.error({ message: "Xóa không thành công" });
                }
            }).catch(err => {
            })
        }
    }
    setCauHinhBangGia = (updateAll) => {
        var { lstBangGiaVP, quytac } = this.state;
        var lstUsing = (!updateAll ? lstBangGiaVP.filter(x => x.tenbanggia === undefined) : lstBangGiaVP);
        if (updateAll) {
            var quytacsudung = this.state.congtru === "cộng" ? "+" + this.state.phantram : "-" + this.state.phantram
            for (var i = 0; i < lstUsing.length; i++) {
                if (this.state.congtru === "cộng") {
                    lstUsing[i].giatien = Number((lstUsing[i].gia_th * (1 + this.state.phantram / 100)));
                } else {
                    lstUsing[i].giatien = Number((lstUsing[i].gia_th * (1 - this.state.phantram / 100)));
                }
            }
            let updateList = lstBangGiaVP.filter(x => x.tenbanggia !== undefined)
            if (updateList.length > 0 && quytac !== quytacsudung) {
                this.setState({
                    lstUpdate: updateList
                })
            }
            this.setState({
                quytac: quytacsudung
            });
        } else {
            for (var i = 0; i < lstUsing.length; i++) {
                lstUsing[i].giatien = Number((lstUsing[i].gia_th * (1 + Number(this.state.quytac) / 100)));
            }
        }
    }
    addAllFee = () => {
        const data = {
            lstDetails: this.state.lstBangGiaVP.filter(x => x.tenbanggia === undefined),
            lstUpdate: this.state.lstUpdate,
            quytac: this.state.quytac
        };
        if (this.state.listDelete.length > 0) this.xoaGiaVP();
        VPService.addAllFee(data, this.state.tenbanggia).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Thao tác thành công" });
                this.getChiTietBangGia(this.state.tenbanggia)
                this.setState({
                    congtru: "",
                    phantram: 0,
                    lstUpdate: [],
                    kwd: "",
                    tenbanggia: "",
                    vsbCrBangGia: false,
                    listDelete: []
                });
                this.loadDanhSachVP(this.state.currentPage, this.state.pageSize);
            } else {
                if (result.err && result.err.msgString) Actions.openMessageDialog('Lỗi', result.err.msgString)
                else if (result.error && result.error.messageText) Actions.openMessageDialog('Lỗi', result.error.messageText)
                else Actions.openMessageDialog('Lỗi', "Đã xảy ra lỗi, vui lòng liên hệ nhà cung cấp phần mềm để được xử lý");
            }
        }).catch(err => {
        })
    }
    saveBangGiaVP = () => {
        if (this.validator.fieldValid("Giá bán")) {
            if (this.state.lstBangGiaVP.find(x => x.giatien === undefined || x.giatien === '')) {
                notification.warning({ message: "Vui lòng nhập quy tắc tính giá" })
            } else {
                this.addAllFee()
            }
        } else {
            this.validator.showMessageFor("Giá bán");
            this.forceUpdate();
        }
    }
    delBangGiaVP = () => {
        VPService.delBangGiaVP(this.state.tenbanggia).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Đã xóa thành công" });
                this.setState({
                    lstBangGiaVP: [],
                    lstDetails: "",
                    kwd: "",
                    congtru: "",
                    phantram: 0
                });
                this.GetListBangGia();
            } else {
                if (result.err && result.err.msgString) Actions.openMessageDialog('Lỗi', result.err.msgString)
                else if (result.error && result.error.messageText) Actions.openMessageDialog('Lỗi', result.error.messageText)
                else Actions.openMessageDialog('Lỗi', "Đã xảy ra lỗi, vui lòng liên hệ nhà cung cấp phần mềm để được xử lý");
            }
        }).catch(err => {
        })
    }
    addGroupService = (list) => {
        if (list.length > 0) {
            let listAdd = [];
            list.forEach(ser => {
                if (!this.state.lstBangGiaVP.find(x => x.ma === ser.ma)) {
                    this.state.quytac !== "" && (ser.giatien = Number((ser.gia_th * (1 + Number(this.state.quytac) / 100))));
                    listAdd.push(ser);
                }
            });
            this.setState({
                lstBangGiaVP: this.state.lstBangGiaVP.concat(listAdd)
            });
        }
    }
    getAllFees() {
        VPService.GetDanhSachVP(false, '', 'default', 0, 0, 1, -1).then(result => {
            if (result.isSuccess) {
                this.setState({ lstFeeByGroup: result.data.filter(x => x.hienthi === "0") });
                this.notify.Close();
            }
        }).catch(err => {
        })
    }
    addAllGroupService = () => {
        this.getAllFees();
        task = setTimeout(() => {
            this.addGroupService(this.state.lstFeeByGroup);
        }, 100);
    }
    loadQuyTac = () => {
        let quytac = "";
        if (this.state.lstBangGiaVP.length > 0 && this.state.lstBangGiaVP.find(x => x.quytac !== "")) {
            quytac = this.state.lstBangGiaVP.find(x => x.quytac !== "").quytac;
        }
        if (quytac !== undefined && Math.abs(Number(quytac)) > 0) {
            return (
                <p className="text-center">
                    Đang áp dụng:&nbsp;
                    {quytac.substring(0, 1) === "+" ?
                        <Tag color="cyan">{"Cộng " + Math.abs(Number(quytac)) + "%"}</Tag>
                        :
                        <Tag color="cyan">{"Trừ " + Math.abs(Number(quytac)) + "%"}</Tag>}
                </p>
            )
        }
    }
    deleteVienPhi = (id) => {
        VPService.deleteVienPhi(id, this.state.banggia).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Đã xóa thành công" });
                this.notify.Close();
                this.loadDanhSachVP();
            } else {
                this.notify.Show(res.err.msgString, NotifyStatus.Warning);
                notification.error({ message: "Xóa không thành công" });
            }
        }).catch(err => {
        })
    }
    handleUpload = () => {
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);
        VPService.importDanhSach(formData, this.state.tenbanggia).then(res => {
            if (res.isSuccess) {
                var mesg = "Hoàn tất";
                if (res.returnObject.insertRow > 0) mesg += ` thêm: ${res.returnObject.insertRow} dữ liệu.`;
                if (res.returnObject.updateRow > 0) { mesg += mesg.length > 8 ? " Cập nhật: " : " cập nhật: "; mesg += `${res.returnObject.updateRow} dữ liệu.` };
                if (res.returnObject.rejectRow > 0) { mesg += mesg.length > 8 ? " Bỏ qua " : " bỏ qua "; mesg += `mã không tồn tại: ${res.returnObject.rejectRow} dữ liệu.` };
                notification.success({ message: mesg });
                this.setState({
                    fileList: []
                });
                this.getChiTietBangGia(this.state.tenbanggia);
            } else {
                if (res.err && res.err.msgString) Actions.openMessageDialog('Lỗi', res.err.msgString)
                else if (res.error && res.error.messageText) Actions.openMessageDialog('Lỗi', res.error.messageText)
                else Actions.openMessageDialog('Lỗi', "Đã xảy ra lỗi, vui lòng liên hệ nhà cung cấp phần mềm để được xử lý");
                this.setState({
                    fileList: []
                });
            }
        }).catch(err => {
            this.setState({
                fileList: []
            });
        })
    }
    getTemplate = () => {
        Actions.setLoading(true);
        VPService.TemplateImportBangGia().then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "Mau-Import-Bang-Gia";
                this._hiddenLink.click();
            })
        })
        .catch(err => {})
        .finally(() => {
            Actions.setLoading(false);
        })
    }
    updateDetailBangGiaVP = (valueUpdate, ma, type) => {
        valueUpdate === null ? valueUpdate = 0 : valueUpdate = valueUpdate;
        let { lstBangGiaVP, lstUpdate } = this.state;
        if (type == "giaban") {
            lstBangGiaVP.find(x => x.ma === ma).giatien = valueUpdate;
        } else if (type == "giabh") {
            lstBangGiaVP.find(x => x.ma === ma).gia_bh = valueUpdate;

        } else if (type == "guimau")
            lstBangGiaVP.find(x => x.ma === ma).guimau = valueUpdate;
        else {
            lstBangGiaVP.find(x => x.ma === ma).tylekhuyenmai = valueUpdate;
        }
        if (lstUpdate.find(x => x.ma == ma)) {
            switch (type) {
                case "giaban":
                    lstUpdate.find(x => x.ma == ma).giatien = valueUpdate;
                    break;
                case "giabh":
                    lstUpdate.find(x => x.ma == ma).gia_bh = valueUpdate;
                    break;
                case "giamgia":
                    lstUpdate.find(x => x.ma == ma).tylekhuyenmai = valueUpdate;
                    break;
                default:
                    break;
            }
        } else {
            lstUpdate.push(lstBangGiaVP.find(x => x.ma === ma));
        }
        this.setState({ lstUpdate });
    }
    MauImportGiaVienPhi() {
        Actions.setLoading(true);
        VPService.MauImportGiaVienPhi().then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                this._hiddenLink.href = url;
                this._hiddenLink.download = "Mau-Import-Gia-Vien-Phi";
                this._hiddenLink.click();
            })
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    importBangGiaMacDinh = () => {
        Actions.setLoading(true);
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('file', fileList[0]);
        VPService.importBangGiaMacDinh(formData, this.state.tenbanggia).then(res => {
            if (res.isSuccess) {
                this.setState({
                    fileList: [],
                    insertRow: res.returnObject.insertRow,
                    updateRow: res.returnObject.updateRow,
                    rejectRow: res.returnObject.rejectRow,
                    rejectList: res.returnObject.rejectList
                });
            }
            else {
                notification.warning({ message: res.err.msgString });
                this.resetImportResult();
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
            this.resetImportResult();
        })
    }
    resetImportResult = () => {
        this.setState({
            fileList: [],
            insertRow: 0,
            updateRow: 0,
            rejectRow: 0,
            rejectList: ""
        });
    }
    
    loadDanhSachLoaiVP(key) {
        VPService.GetLoaiVPByNhom(Number(key)).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstLoaiVP: result.data,
                });
            }
        }).catch(err => {
        })
    }
    
    addNotExsitNhomVp(id){
        let dataOld = this.state.lstNhomVP;
        let data = this.state.lstNhomVPAll;
        let found = data.find(x=> x.value === id);
        let foundOld = dataOld.find(x => x.value === id);
        if(foundOld === undefined){
            dataOld.unshift(found);
            this.setState({
                lstNhomVP : dataOld
            })
        }
    }

    GetListServicePackage = () => {
        let filterModel = {
            keyword : '',
        }
        PackageService.ListServicePackageForSelected(filterModel).then(result => {
            if (result.isSuccess) {
                this.setState({ lstServicePackage : result.data})
            }
            else {
                notification.warning({ message: result.error.messageText });
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.warning({ message: err.error.messageText });
            else notification.warning({ message: err });
        }).finally(() => {
        })
    }

    renderDetailPackage = (value = this.state.selectedPackage) => {
        if(value && value !== ''){
            let item = groupBy(this.state.lstServicePackage.find(x => x.id === value)?.listServicePackageItem, 'groupName');
            return (
                Object.keys(item).map(key => ({key, value : item[key]})).map(x => (
                    <div className="d-flex flex-column w-100" key={`pk-${x.key}`}>{/* 1 item */}
                        <div className="d-flex justify-content-between w-100">{/* 1 row in item */}
                            <div className=''>{/* column 1 */}
                                <label className='mita-title'>{x.key}</label>
                            </div>
                        </div>
                        {x.value.map(c => (
                            <div className='ml-2'>{/* column 1 */}
                                <label className='mita-value'>{c.name}</label>
                            </div>
                        ))}
                    </div>
                ))
            )
        }
    }

    render() {
        const columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 90,
                render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1),
            },
            {
                title: 'Mã',
                dataIndex: 'ma',
                key: 'ma',
                align: 'center',
            },
            {
                title: 'Tên',
                dataIndex: 'ten',
                key: 'ten',
                width: '20%',
                align: 'left',
            },
            {
                title: 'Thứ tự hiển thị',
                dataIndex: 'thutu',
                key: 'thutu',
                width: '120',
                align: 'center',
            },
            {
                title: 'Giá bán',
                dataIndex: 'gia_th',
                key: 'gia_th',
                align: 'right',
                render: (value) => {
                    return <span>{value.toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Khuyến mại (%)',
                dataIndex: 'tylekhuyenmai',
                key: 'tylekhuyenmai',
                align: 'center',
                render: (value) => {
                    return <span>{value ? value.toLocaleString('en-US') : ''}</span>;
                },
            },
            {
                title: 'Giá khuyến mại',
                align: 'right',
                render: (row) => {
                    return <span>{Number((row.gia_th * (100 - row.tylekhuyenmai) / 100).toFixed(0)).toLocaleString('en-US')}</span>;
                },
            },
            {
                title: 'Hiển thị',
                align: 'center',
                render: (row) => {
                    return (
                        <Switch disabled={this.state.banggia !== 'default'} loading={this.state.loadingHide} checkedChildren="Hiển thị" unCheckedChildren="Ẩn" checked={row.hienthi === '0'}
                            onChange={(e) => {
                                this.updateHide(row.id, e)
                            }}
                        />
                    )
                }
            },
            {
                title: 'Action',
                align: 'center',
                render: (row) => {
                    return (
                        <div>
                            <button className="btn btn-warning"
                                title="Cập nhật viện phí!"
                                onClick={() => {
                                    this.setState({
                                        giatien: row.gia_th,
                                        giabhyt: row.gia_bh,
                                        tenvp: row.ten,
                                        id: row.id,
                                        tylekhuyenmai: row.tylekhuyenmai,
                                        visible: true,
                                        template: row.template,
                                        order: row.thutu,
                                        manhomvp_update: row.nhom,
                                        maloaivp_update: row.loai,
                                        ketluan : row.ketluan,
                                        mavp : row.ma,
                                        selectedPackage : row.packageid
                                    }, this.loadDanhSachLoaiVP(row.nhom), this.addNotExsitNhomVp(row.nhom));
                                    this.form.setFieldsValue({
                                        tenvienphi: row.ten,
                                        giatien: row.gia_th,
                                        giabhyt: row.gia_bh
                                    })
                                }}
                            >
                                <i className="fa fa-edit" />
                            </button>
                            <Popconfirm
                                title="Bạn có chắc muốn xóa?"
                                okText="Đồng ý" cancelText="Hủy"
                                onConfirm={() => this.deleteVienPhi(row.id)}>
                                <button className="btn btn-danger" style={{ marginLeft: 10 }}>
                                    <i className="fa fa-trash" />
                                </button>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
        let dataSource = this.state.lstVP.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = index;
            return o;
        });
        var { fileList } = this.state;
        const props = {
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: []
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }))
                return false;
            },
            fileList
        };
        const menu = (
            <Menu>
                <Menu.Item key="0">
                    <button className="btn" onClick={() => this.MauImportGiaVienPhi()}>Tải file excel mẫu</button>
                </Menu.Item>
                <Menu.Item key="1">
                    <button className="btn" onClick={() => this.setState({ visibleImport: true })}>Import dữ liệu</button>
                </Menu.Item>
                <Modal
                    maskClosable={true}
                    width={500}
                    visible={this.state.visibleImport}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div style={{ margin: 20, display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <div style={{ width: "100%" }}>
                            <Dragger
                                {...props}
                                accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                maxCount={1}
                                style={{ width: '100%' }}>
                                <div>
                                    <p><i className="fas fa-cloud-upload-alt" style={{ fontSize: '70px', color: 'cadetblue' }}></i></p>
                                    <p className="ant-upload-text">Bấm chọn file hoặc kéo thả file vào đây để upload</p>
                                    <p className="ant-upload-hint">
                                        Chỉ hỗ trợ các dịnh dạng file: *.xls, *.xlsx, *.csv
                                    </p>
                                </div>
                            </Dragger>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 25 }}>
                            <p style={{ textTransform: "uppercase", textAlign: "center", display: this.state.showImportResult ? 'flex' : 'none' }}>Kết quả import dữ liệu</p>
                            <p style={{ display: this.state.updateRow > 0 ? 'flex' : 'none' }}>Cập nhật:{'\u00A0'}<Tag color="green">{`${this.state.updateRow}`}</Tag> dịch vụ</p>
                            <p style={{ display: this.state.insertRow > 0 ? 'flex' : 'none' }}>Thêm:{'\u00A0'}<Tag color="blue">{`${this.state.insertRow}`}</Tag> dịch vụ</p>
                            <p style={{ display: this.state.rejectRow > 0 ? 'flex' : 'none' }}>Thiếu thông tin:{'\u00A0'}<Tag color="red">{`${this.state.rejectRow}`}</Tag> dịch vụ</p>
                            <p style={{ display: this.state.rejectList ? 'flex' : 'none' }}>Bao gồm:&nbsp; {this.state.rejectList}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}>
                            <button disabled={this.state.fileList ? false : true} className="btn btn-success" onClick={() => this.importBangGiaMacDinh()}>
                                <i className="fa fa-save" style={{ marginRight: 5 }}></i>Import dữ liệu</button>
                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.setState({
                                        visibleImport: false,
                                        showImportResult: false
                                    });
                                    this.resetImportResult();
                                    this.loadDanhSachVP();
                                    this.validator.hideMessages();
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Modal>
            </Menu>
        );
        return (
            <React.Fragment>
                <div className='containerChilderWrapper' >
                    <Form ref={(c) => this.form = c} style={{ height: 'calc(100% - 65px)' }}>
                        <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>
                        <HeaderModal title="Danh mục giá viện phí" />

                        <Notify ref={(c) => this.notify = c}  />

                        <div className="row no-gutters" style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
                            <div className="col-md-3">
                                <Form.Item label="Bảng giá:">
                                    <Select
                                        value={this.state.banggia}
                                        onChange={(e) => {
                                            this.setState({ banggia: e, currentPage: 1, pageSize: 10 })
                                            setTimeout(() => { this.loadDanhSachVP(1, this.state.pageSize) }, 100)
                                        }}
                                        showSearch
                                        // onSearch={(value) => this.loadCoporation(value)}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key={-1} value="default">Mặc định</Option>
                                        {this.state.lstBangGia.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.value}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-3">
                                <Form.Item label="Nhóm:">
                                    <Select
                                        value={this.state.manhomvp}
                                        onChange={(e) => {
                                            this.setState({ manhomvp: e })
                                            setTimeout(() => { this.loadDanhSachVP(1, this.state.pageSize) }, 100)
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key={-1} value={0}>Tất cả</Option>
                                        {this.state.lstNhomVP.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}{row.hide && <i className='text-danger'> (Nhóm đã bị ẩn)</i>}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-2">
                                <Form.Item shouldUpdate>
                                    <Input
                                        value={this.state.key}
                                        placeholder="Nhập mã, tên và bấm Enter để tìm"
                                        onChange={(e) => this.setState({ key: e.target.value, currentPage: 1, pageSize: 10 })}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault()
                                                this.loadDanhSachVP(1, this.state.pageSize);
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-4" style={{ display: 'flex' }}>
                                <Form.Item>
                                    <button className="btn btn-success"
                                        onClick={this.showModalCreate}
                                    ><i style={{ marginRight: 5 }} className="fa fa-plus" />Tạo mới</button>
                                    {FeatureDeactive().indexOf(FEATURE.MULTI_BANG_GIA) == -1 &&
                                        <button className="btn btn-success"
                                            onClick={this.showModalCrBangGia}
                                            style={{ marginLeft: 10 }}
                                        ><i style={{ marginRight: 5 }} className="fa fa-plus" />Bảng giá</button>}
                                    <Tooltip title={this.state.key && `Xuất excel kết quả tìm kiếm được với từ khóa "${this.state.key}"`}>
                                        <button className="btn btn-color" style={{ marginLeft: 10 }}
                                            onClick={() => { this.ExportDanhMucDichVu() }}> <i style={{ marginRight: 5 }} className="fas fa-download" />Excel</button>
                                    </Tooltip>
                                    <Dropdown
                                        overlay={menu} trigger={['click']}
                                        onClick={e => e.preventDefault()}>
                                        <button className="btn btn-primary" style={{ marginLeft: 10 }}><i style={{ marginRight: 5 }} className="fas fa-upload" />Import</button>
                                    </Dropdown>
                                </Form.Item>
                            </div>
                        </div>

                        <div style={{ paddingTop: 10, height: 'calc(100% - 65px)' }}>
                            <Table dataSource={dataSource}
                                scroll={{ y: 0 }}
                                pagination={false}
                                columns={columns} />
                        </div>
                        <Pagination
                            current={this.state.currentPage}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.state.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadDanhSachVP(page, size) }}
                            onShowSizeChange={(page, size) => { this.setState({ currentPage: page, pageSize: size }); this.loadDanhSachVP(page, size) }}
                            showSizeChanger={true}
                        />
                    </Form>
                </div>

                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="form-group">
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <h3>Cập nhật viện phí</h3>
                        </div>
                        <div className="row no-gutters mb-2">
                            <div className="col-md-6">
                                <Form.Item>
                                    <label>Nhóm</label>
                                    <Select
                                        disabled={this.state.banggia !== 'default'}
                                        value={this.state.manhomvp_update}
                                        onChange={(e) => {
                                            this.setState({ manhomvp_update: e, maloaivp_update: null }, this.loadDanhSachLoaiVP(e));
                                        }}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstNhomVP.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}{row.hide && <i className='text-danger'> (Nhóm đã bị ẩn)</i>}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Nhóm viện phí", this.state.manhomvp_update, "selection")}
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item>
                                    <label>Loại</label>
                                    <Select
                                        disabled={this.state.banggia !== 'default'}
                                        value={this.state.maloaivp_update}
                                        onChange={(e) => this.setState({ maloaivp_update: e })}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.lstLoaiVP.map((row, index) => {
                                            return (
                                                <Option key={index} value={row.value}>{row.label}</Option>
                                            )
                                        })}
                                    </Select>
                                    {this.validator.message("Loại viện phí", this.state.maloaivp_update, "selection")}
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-12" style={{ flex: 1 }} >
                                <Form.Item style={{ flex: 1 }} rules={[{ required: true, whitespace: true, message: 'Tên viện phí không được để trống!' }]}>
                                    <label>Tên viện phí</label>
                                    <Input
                                        disabled={this.state.banggia !== 'default'}
                                        value={this.state.tenvp}
                                        onChange={(e) => {
                                            this.setState({ tenvp: e.target.value })
                                        }} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters mt-2">
                            <div className="col-md-12" style={{ flex: 1 }}>
                                <Form.Item style={{ flex: 1 }} rules={[{ required: true, message: 'Giá tiền không được để trống!' }]}>
                                    <label>Giá tiền</label>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        maxLength={10}
                                        min={0}
                                        value={this.state.giatien}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onKeyDown={(e) => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            this.setState({ giatien: e })
                                        }} />
                                </Form.Item>
                            </div>
                            <div className="col-md-6" style={{ flex: 1 }}>
                                <Form.Item style={{ flex: 1 }}>
                                    <label>Khuyến mại</label>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        maxLength={4}
                                        min={0}
                                        max={100}
                                        onKeyDown={(e) => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={this.state.tylekhuyenmai}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={(e) => {
                                            this.setState({ tylekhuyenmai: e })
                                        }} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row no-gutters mt-2" style={{ display: 'flex', flexDirection: "row" }} >
                            <div className="col-md-6" style={{ marginBottom: '8px', color: 'red', fontWeight: 'bold' }}>
                                Giá sau khuyến mại: {Number((Number(this.state.giatien) * ((100 - this.state.tylekhuyenmai)) / 100).toFixed(0)).toLocaleString('en-US')}
                            </div>
                            <div className="col-md-12">
                                <Form.Item style={{ flex: 1 }}>
                                    <label>Thứ tự</label>
                                    <InputNumber
                                        disabled={this.state.banggia !== 'default'}
                                        style={{ width: "100%" }}
                                        min={0}
                                        onKeyDown={(e) => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={this.state.order}
                                        onChange={(e) => {
                                            this.setState({ order: e })
                                        }} />
                                </Form.Item>
                            </div>
                        </div>
                        {this.state.manhomvp_update === 8 &&
                        <>
                            <div className='row mt-2 ml-1 mr-2'>
                                <div className="modal-header fit-content" style={{ display: 'flex', justifyContent: 'left' }}>
                                    <h5>Template kết quả khám bệnh: {this.state.tenvp}</h5>
                                </div>
                                <SunEditor
                                    onBlur={(e, value) => {
                                        this.setState({ template: value })
                                    }}
                                    onChange={(e) => this.setState({ template: e })}
                                    lang={suneditorViLang}
                                    setDefaultStyle="font-size: 13px;paragraphStyles : normal;font-family : Arial;"
                                    setContents={this.state.template}
                                    setOptions={{
                                        height: 300,
                                        buttonList: [
                                            ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                            ['bold', 'underline', 'italic', 'subscript', 'superscript', 'removeFormat'],
                                            ['outdent', 'indent', 'align', 'horizontalRule', 'list', 'table', 'lineHeight'],
                                        ]
                                    }}
                                />
                            </div>
                            <div className='row mt-2 ml-1 mr-2'>
                                <div className="modal-header fit-content" style={{ display: 'flex', justifyContent: 'left' }}>
                                    <h5>Kết luận khám bệnh: {this.state.tenvp}</h5>
                                </div>
                                <Input.TextArea
                                    autoSize={{ minRows: 1, }}
                                    value={this.state.ketluan}
                                    placeholder="Nhập kết luận"
                                    onChange={(e) => this.setState({ ketluan : e.target.value })}
                                ></Input.TextArea>
                            </div>
                        </>
                        }
                        {FeatureDeactive().indexOf(FEATURE.COMBO) == -1 && this.state.manhomvp_update === COMBO &&
                        <>
                            <div className="row no-gutters mt-2" style={{ display: 'flex' }}>
                                <div className="col-md-12">
                                    <Form.Item>
                                        <label>
                                            Gói dịch vụ 
                                            {this.state.selectedPackage !== '' && 
                                                <Popover placement="top" content={ this.renderDetailPackage() }>
                                                    <span><i className="fas fa-info-circle ml-1"></i></span>
                                                </Popover>
                                            }
                                        </label>
                                        <Select
                                            style={{ width: '100%' }}
                                            value={this.state.selectedPackage}
                                            onChange={(value, record) => {
                                                this.setState({
                                                    selectedPackage : value,
                                                });
                                            }}
                                            allowClear
                                            onClear={() => this.setState({ selectedPackage : ''})}
                                        >
                                            {this.state.lstServicePackage.map((row, index) => {
                                                return (
                                                    <Option key={index} value={row.id}>{row.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </>}
                        <div className='custom-hr'></div>
                        <div className="row no-gutters mt-4">
                            <div className="col-md-12">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <button className="btn btn-success" onClick={() => this.updateGiaTien()}>
                                        <i className="fa fa-save" style={{ marginRight: 5 }} />Cập nhật
                                    </button>
                                    <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                        onClick={() => {
                                            this.setState({
                                                visible: false
                                            })
                                        }}>
                                        <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} /> Đóng
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal maskClosable={false}
                    width={1400}
                    visible={this.state.vsbCrBangGia}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <Fragment>
                        <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                            <h3>{this.state.lstBangGia.find(x => x.value === this.state.kwd) ? "Cập nhật " : "Thêm "} bảng giá viện phí</h3>
                        </div>
                        <Notify ref={(c) => this.notifyBangGia = c}  />
                        <div className="row">
                            <div className="col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                <span>Tên bảng giá<span style={{ marginLeft: 4, color: "#ff4d4f", fontSize: 14, fontFamily: "SimSun, sans-serif" }}>*</span></span>&nbsp;
                                <AutoComplete
                                    style={{ width: "40%" }}
                                    value={this.state.kwd}
                                    maxLength={25}
                                    options={this.state.lstBangGia}
                                    placeholder="Nhập tên bảng giá để tìm hoặc thêm"
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onSelect={e => {
                                        this.getChiTietBangGia(e);
                                        this.setState({ tenbanggia: e });
                                    }}
                                    onChange={e => this.setState({ kwd: e })}
                                />
                                {this.validator.message("Tên bảng giá", this.state.kwd, "required")}
                                {
                                    this.state.lstBangGia.find(x => x.value === this.state.kwd) ?
                                        <div style={{ display: "contents" }}>
                                            <Popconfirm
                                                title="Bạn có chắc muốn xóa bảng giá này?"
                                                onConfirm={() => { this.delBangGiaVP() }}
                                                okText="Đồng ý" cancelText="Hủy">
                                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                                ><i className="fa fa-trash" style={{ marginRight: 5 }} />Xóa</button>
                                            </Popconfirm>
                                            <Upload {...props}
                                                accept=".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                maxCount={1} showUploadList={false}>
                                                <Tooltip title={this.state.fileList.length > 0 && <span>Đang chọn: {this.state.fileList[0].name}</span>}>
                                                    <button className="btn btn-success" style={{ marginLeft: 10 }}>
                                                        {this.state.fileList.length > 0 ? "Chọn file khác" : "Chọn file import"}
                                                    </button>
                                                </Tooltip>
                                            </Upload>
                                            {this.state.fileList.length > 0 ?
                                                <button className="btn btn-primary" style={{ marginLeft: 10 }} onClick={() => this.handleUpload()}>
                                                    <i className="fas fa-upload" style={{ marginRight: 5 }} />Import
                                                </button>
                                                :
                                                <button className="btn btn-primary" style={{ marginLeft: 10 }} onClick={() => this.getTemplate()}>
                                                    <i className="fas fa-download" style={{ marginRight: 5 }} />Mẫu import
                                                </button>}
                                        </div>
                                        :
                                        <button disabled={this.state.kwd.length <= 0} className="btn btn-color" style={{ marginLeft: 10 }}
                                            onClick={() => { this.setState({ tenbanggia: this.state.kwd }); this.ThemBangGia() }}><i className="fas fa-plus" style={{ marginRight: 5 }} />Thêm</button>
                                }
                            </div>
                        </div>
                        {
                            this.state.lstBangGia.find(x => x.value === this.state.tenbanggia) &&
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <Popconfirm
                                        title={`Bạn có chắc thêm tất cả dữ liệu mặc định cho ${this.state.tenbanggia}?`}
                                        onConfirm={() => { this.addAllGroupService() }}
                                        okText="Đồng ý" cancelText="Hủy"
                                        disabled={this.state.tenbanggia.length <= 0} >
                                        <button className="btn btn-color w-100"><i className="fas fa-fill-drip" style={{ marginRight: 5 }}></i>Đổ tất cả dữ liệu mặc định</button>
                                    </Popconfirm>
                                    <Collapse accordion onChange={e => e !== undefined && this.getAllFeeByGroup(this.state.lstNhomVP[e].value)}>
                                        {this.state.lstNhomVP.map((row, index) => {
                                            return (
                                                <Panel key={index} header={row.label} value={row.value} >
                                                    <Table dataSource={this.state.lstFeeByGroup}
                                                        // scroll={{ y: 0 }}
                                                        rowKey={(e) => e.id}
                                                        columns={
                                                            [
                                                                {
                                                                    title:
                                                                        <Tooltip title="Thêm tất cả dịch vụ của nhóm" placement="topLeft">
                                                                            <i className="fas fa-tasks" style={{ color: "green" }} onClick={(e) => { this.addGroupService(this.state.lstFeeByGroup); e.stopPropagation(); }} />
                                                                        </Tooltip>,
                                                                    align: 'center',
                                                                    width: 40,
                                                                    render: (row) => (
                                                                        this.state.lstBangGiaVP.length > 0 && this.state.lstBangGiaVP.find(x => x.ma === row.ma) ? <i className="fa fa-check"></i> : ''
                                                                    )
                                                                },
                                                                {
                                                                    title: 'Mã',
                                                                    dataIndex: 'ma',
                                                                    key: 'ma',
                                                                    align: 'center',
                                                                    width: 50,
                                                                },
                                                                {
                                                                    title: 'Tên dịch vụ',
                                                                    dataIndex: 'ten',
                                                                    key: 'ten',
                                                                    align: 'left',
                                                                    width: 170,
                                                                }
                                                            ]
                                                        }
                                                        pagination={false}
                                                        scroll={{ y: '44vh', x: 'max-content' }}
                                                        style={{ overflow: "auto scroll", maxHeight: "50vh" }}
                                                        onRow={(record) => {
                                                            return {
                                                                onClick: e => {
                                                                    this.setState({ selectedServiceID: record.id, selectedItem: record });
                                                                    if (!this.state.lstBangGiaVP.find(x => x.ma === record.ma)) {
                                                                        this.state.quytac !== "" && (record.giatien = Number((record.gia_th * (1 + Number(this.state.quytac) / 100))));
                                                                        if (this.state.listDelete.find(x => x.ma === record.ma)) {
                                                                            this.setState({
                                                                                lstBangGiaVP: [...this.state.lstBangGiaVP, this.state.listDelete.find(x => x.ma === record.ma)],
                                                                                listDelete: this.state.listDelete.filter(x => x.ma !== record.ma)
                                                                            });
                                                                        } else {
                                                                            record.isnew = true;
                                                                            this.setState({
                                                                                lstBangGiaVP: [...this.state.lstBangGiaVP, record]
                                                                            });
                                                                        }
                                                                    } else {
                                                                        if (record.isnew) {
                                                                            this.setState({
                                                                                lstBangGiaVP: this.state.lstBangGiaVP.filter(x => x.ma !== record.ma)
                                                                            });
                                                                        }
                                                                        // this.beforeDelGiaVP(record.ma)
                                                                    }
                                                                }
                                                            };
                                                        }}
                                                        rowClassName={(record) => {
                                                            return `rowCustomSelect ${(this.state.selectedServiceID === record.id) ? 'rowCustomSelectActive' : ''}`
                                                        }} />
                                                </Panel>
                                            )
                                        })}
                                    </Collapse>
                                </div>
                                <div className="col-md-8" style={{ height: 'calc(100% - 120px)' }}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <span className="w-100">Bảng giá viện phí: <strong>{this.state.tenbanggia}</strong></span>
                                        </div>
                                        <div className="col-md-4">
                                            <button disabled={this.state.lstBangGiaVP.length <= 0} className="btn btn-color w-100"
                                                onClick={() => {
                                                    this.setState({ vsbSetPrice: true });
                                                    if (this.state.lstBangGiaVP.length > 0 && Math.abs(Number(this.state.lstBangGiaVP.find(x => x.quytac !== "").quytac)) > 0) {
                                                        this.setState({
                                                            partialUpdate: true,
                                                            quytac: this.state.lstBangGiaVP.find(x => x.quytac !== "").quytac
                                                        })
                                                    } else {
                                                        this.setState({
                                                            partialUpdate: false
                                                        })
                                                    }
                                                }}
                                            ><i className="fas fa-tools" style={{ marginRight: 5 }}></i>Quy tắc tính giá</button>
                                        </div>
                                    </div>
                                    <Table dataSource={this.state.lstBangGiaVP}
                                        rowKey={(e) => e.id}
                                        scroll={{ y: 0 }}
                                        pagination={{ showSizeChanger: true, showTotal: (total) => `Tổng: ${total}` }}
                                        columns={[
                                            // {
                                            //     title: 'STT',
                                            //     dataIndex: 'index',
                                            //     key: 'index',
                                            //     align: 'center',
                                            //     render: (text, record, index) => ((this.state.currentPage - 1) * this.state.pageSize + Number(index) + 1),
                                            // },
                                            {
                                                title: 'Mã',
                                                dataIndex: 'ma',
                                                key: 'ma',
                                                align: 'center',
                                                width: 100,
                                            },
                                            {
                                                title: 'Tên',
                                                dataIndex: 'ten',
                                                key: 'ten',
                                                width: '28%',
                                                align: 'left',
                                            },
                                            {
                                                title: 'Giá mặc định',
                                                dataIndex: 'gia_th',
                                                key: 'gia_th',
                                                align: 'right',
                                                width: 120,
                                                render: (value) => {
                                                    return <span>{value.toLocaleString('en-US')}</span>;
                                                },
                                            },
                                            {
                                                title: 'Giá bán',
                                                dataIndex: 'giatien',
                                                key: 'giatien',
                                                align: 'right',
                                                width: 110,
                                                render: (value, row) => {
                                                    return (
                                                        <div>
                                                            <InputNumber
                                                                min={0} max={99999999} step={500}
                                                                value={value}
                                                                formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={(e) => {
                                                                    this.updateDetailBangGiaVP(e, row.ma, "giaban");
                                                                    this.validator.showMessageFor("Giá bán");
                                                                }}>
                                                            </InputNumber>
                                                            {this.validator.message("Giá bán", value, "required")}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                title: 'KM(%)',
                                                dataIndex: 'tylekhuyenmai',
                                                key: 'tylekhuyenmai',
                                                align: 'center',
                                                width: 110,
                                                render: (value, row) => {
                                                    return <span>{value ? value.toLocaleString('en-US') : ''}</span>;

                                                },
                                            },
                                            {
                                                title: 'Giá KM',
                                                align: 'right',
                                                width: 110,
                                                render: (row) => {
                                                    return row.giatien !== undefined && <span>{Number((row.giatien * (100 - row.tylekhuyenmai) / 100).toFixed(0)).toLocaleString('en-US')}</span>;
                                                },
                                            },
                                            {
                                                title: 'Gửi mẫu',
                                                dataIndex: 'guimau',
                                                key: 'guimau',
                                                align: 'center',
                                                width: 80,
                                                render: (value, row) => {
                                                    return (
                                                        <div>
                                                            <Checkbox checked={value}
                                                                onChange={(e) => {
                                                                    this.updateDetailBangGiaVP(e.target.checked, row.ma, "guimau");
                                                                }}
                                                            ></Checkbox>
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                key: 'action',
                                                align: 'center',
                                                width: 80,
                                                render: (value, row) => {
                                                    return row.used ? 
                                                    (
                                                        <Tooltip title='Dịch vụ trong bảng giá đã được sử dụng. Không thể xóa!'>
                                                            <button className="btn btn-danger disabled" style={{ marginLeft: 10 }}>
                                                                <i className="fa fa-trash" />
                                                            </button>
                                                        </Tooltip>
                                                    ) :
                                                    (
                                                        <Popconfirm
                                                            title={<span>Việc bấm nút xóa sẽ xử lý xóa ngay lập tức mà không cần bấm Lưu.<br/>Bạn có chắc muốn xóa?</span>}
                                                            okText="Đồng ý" cancelText="Hủy"
                                                            onConfirm={() => this.xoaGiaVP(row.idvienphi)}
                                                            // open={!row.isnew}
                                                            trigger={'click'}
                                                        >
                                                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                                                onClick={() => { 
                                                                    if (row.isnew) {
                                                                        this.setState({
                                                                            lstBangGiaVP: this.state.lstBangGiaVP.filter(x => x.ma !== row.ma)
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fa fa-trash" />
                                                            </button>
                                                        </Popconfirm>
                                                        // <button className="btn btn-danger" 
                                                        //     onClick={() => this.beforeDelGiaVP(row)}
                                                        // >
                                                        //     <i className="fas fa-trash" />
                                                        // </button>
                                                    )
                                                }
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        }
                        <div className="row mt-4">
                            <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    this.state.lstBangGia.find(x => x.value === this.state.tenbanggia) && this.state.kwd.length > 0 &&
                                    <button className="btn btn-success"
                                        disabled={this.state.lstBangGiaVP.length <= 0}
                                        onClick={() => {
                                            this.saveBangGiaVP();
                                        }}>
                                        <i className="fa fa-save" style={{ marginRight: 5 }} />Lưu
                                    </button>
                                }
                                <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        this.setState({
                                            vsbCrBangGia: false,
                                            kwd: '',
                                            tenbanggia: '',
                                            listDelete: []
                                        });
                                        this.GetListBangGia();
                                    }}>
                                    <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                                </button>
                            </div>
                        </div>
                    </Fragment>
                </Modal>
                <Modal maskClosable={false}
                    width={600}
                    visible={this.state.vsbSetPrice}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <div className="ant-modal-header text-uppercase" style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                        <h3>Quy tắc tính bảng giá: {this.state.tenbanggia}</h3>
                    </div>
                    {this.loadQuyTac()}
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="bg-secondary" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 10, borderRadius: 5 }}>
                            Giá bán = Giá mặc định
                            &nbsp;
                            <span>
                                <Select style={{ width: 80 }} onChange={e => this.setState({ congtru: e })} value={this.state.congtru}>
                                    <Option key={1} value="cộng">cộng</Option>
                                    <Option key={0} value="trừ">trừ</Option>
                                </Select>
                            </span>
                            &nbsp;
                            <span>
                                <InputNumber
                                    value={this.state.phantram}
                                    min={0}
                                    max={100}
                                    maxLength={4}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                    onChange={(e) => this.setState({ phantram: e })}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className="btn btn-success"
                                onClick={() => {
                                    this.setCauHinhBangGia(true);
                                    this.setState({
                                        vsbSetPrice: false
                                    })
                                }}>
                                <i className="fas fa-check" style={{ marginRight: 5 }} />
                                {this.state.partialUpdate && this.state.lstBangGiaVP.filter(x => x.tenbanggia === undefined).length > 0 ?
                                    <Tooltip title="Thao tác sẽ cập nhật toàn bộ giá bán trong bảng">Áp dụng tất cả</Tooltip>
                                    : "Áp dụng"}
                            </button>

                            <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                onClick={() => {
                                    this.setState({
                                        vsbSetPrice: false
                                    })
                                }}>
                                <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />Đóng
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal maskClosable={false}
                    width={800}
                    visible={this.state.visibleCreate}
                    cancelText='Đóng'
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}>
                    <CreateNew onCancel={this.handleCancel} onReload={this.onReload.bind(this)} options={this.state.lstBangGia} />
                </Modal>
            </React.Fragment>
        )
    }
}