import React, { Fragment, useImperativeHandle, forwardRef, useRef, useState, useCallback, useEffect } from 'react';
import { Button, Checkbox, Collapse, Tabs, Popconfirm, List, Drawer, Popover, Select, notification, Tag, Modal } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import Handlebars from 'handlebars';
import Notify, { NotifyStatus } from '../../../components/notify';
import { groupBy, isNullOrEmpty, renumber } from '../../../libs/util';
import * as Action from '../../../libs/actions';
import handlebarHelper from "../../../libs/handlebarHelper";
import { BUTTON, PRODUCT_TYPE, SERVICE_TYPE, USER_AUTH } from '../../../configureHIS/common/constants';
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import HeaderModal from '../../../configureHIS/common/headerModal';
import { FORMAT_TIME_DATE } from '../../../configure/systemConfig';
import * as InvoiceService from '../../../services/invoiceService';
import * as PrintService from '../../../services/printService';
import PatientInfo from "../../../screens/patient/component/patientInfo";
import RegisterInfo from "../../../screens/patient/component/registerInfo";
import SmallShowSummaryAmount from '../components/smallshowsummaryamount';
import SummaryAmount from '../components/summaryamount';
import ActionRefund from '../components/actionrefund';
import CustomerVATInfo from '../../vatinvoice/customer';
import * as AuthServices from '../../../services/authServices';
import { PAYMENTTYPE } from '../../../configureHIS/common/constants';
import ModifyInvoice from '../components/ModifyInvoice';

/** Component này dùng để hiển thị chi tiết của hóa đơn/bill thanh toán/hoàn trả
 * Hướng dẫn sử dụng component
 * Các component đang sử dụng:
    - src\screens\invoice\trahoadon\paid.js
 ** Các props nhận vào:
* @param {Function} receiptNumber: //[require] số bill
* @param {Function} patientCode: //mã bệnh nhân
* @param {Function} managermentId: //mã quản lý

* @param {boolean} showBtnPrint: hiện / ẩn button in
* @param {boolean} showBtnRefund: hiện / ẩn button in
* @param {Function} enableEditPayment: hiện / ẩn button sửa phân bổ hình thức thanh toán (nếu ẩn đi đồng nghĩa với việc không cho phép sửa, nên dùng từ enable thay vì show)
 */
//tương ứng enum ReceiptType
const ReceiptType = {
    THANHTOAN: 0,
    TAMUNG: 2,
    HOANTRA: 3,
    VIENPHI_NOITRU: 4,
    HOANTRA_TAMUNG: 5
}
//tương ứng enum ProductTypeEnum
const ProductTypeEnum = {
    SERVICE: 0,
    MEDICALSUPPLIES: 1,
    MEDICINE: 2,
}
const Preview = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        reloadInfo: (pid, mid) => {
            getBillDetail();
            // setListReceipt([]);
            // getListPaid(pid, mid);
            notiRef && notiRef.current && notiRef.current.Close();
            notiRefSec && notiRefSec.current && notiRefSec.current.Close();
        },
    }));

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const notiRef = useRef();
    const notiRefSec = useRef();
    const notiRefUpdateVat = useRef();

    const [titleHeader, setTitleHeader] = useState('');
    const [billInfo, setBillInfo] = useState({});
    const [isBillRefund, setIsBillRefund] = useState(false);
    const [visibleVATInvoice, setVisibleVATInvoice] = useState(false);
    const [vatInvoice, setVatInvoice] = useState(false);
    const [listDV, setListDV] = useState([]);
    const [listVT, setListVT] = useState([]);
    const [listDetailSelected, setListDetailSelected] = useState([]);
    const [listDetailKeySelected, setListDetailKeySelected] = useState([]);

    const [ghiChu, setGhiChu] = useState();
    const [listMethod, setListMethod] = useState([]);
    const [refundMode, setRefundMode] = useState();
    const [listReceipt, setListReceipt] = useState([]);
    const [paymentType, setPaymentType] = useState(1);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isRecoverDebt, setIsRecoverDebt] = useState(false);
    const [onEditPaymentType, setOnEditPaymentType] = useState(false);
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [visiblePopupSec, setVisiblePopupSec] = useState(false);
    const [visibleModifyBill, setVisibleModifyBill] = useState(false);
    // const [disableButtonRefund, setDisableButtonRefund] = useState(false);
    // const [data,] = useState(props.data);
    //liên quan show/hide các tính năng
    const [showBtnPrint, setShowBtnPrint] = useState(false);
    const [showBtnRefund, setShowBtnRefund] = useState(false);

    useEffect(() => {
        // getListPaid();
        getBillDetail();
        getListPaymentType();
        checkIsAdmin();
    }, [])
    
    useEffect(() => {
        setTitleHeader(`Thông tin phiếu ${billInfo?.billTypeText} #${props.receiptNumber}`);
        setIsBillRefund(billInfo?.billType == ReceiptType.HOANTRA);
        setVatInvoice(billInfo.vatInvoice);
        setListDV((billInfo.listDetail ?? []).filter(x => x.type == ProductTypeEnum.SERVICE));
        setListVT((billInfo.listDetail ?? []).filter(x => x.type != ProductTypeEnum.SERVICE));
        setShowHide();
        if (billInfo.listDetail && billInfo.listDetail.length > 0) {
            let templist = [...billInfo.listDetail];
            templist.forEach(x => {
                x.key = `${(x.type == ProductTypeEnum.SERVICE ? 'dv' : 'vt')}${x.idChiDinh}`;
            })
        }
        forceUpdate();
    }, [billInfo])

    // const onSelectDeselect = (reptIndex, group, itemIndex, isSelected, itemData) => {
    //     if (reptIndex != undefined && reptIndex >= 0) {
    //         let list = listReceipt;
    //         let listServices = list[reptIndex].listServices;
    //         let listMedis = list[reptIndex].listMedis;
    //         if (itemIndex >= 0) {
    //             if (group == "service") {
    //                 let foundIndex = listServices.findIndex(x => x.name == itemData.name);
    //                 if (foundIndex && foundIndex >= 0);
    //                 listServices[foundIndex].isChecked = isSelected;
    //             } else if (group == "medicine") {
    //                 listMedis[itemIndex].isChecked = isSelected;
    //             }
    //             setListReceipt(list);
    //         }
    //         forceUpdate();
    //     }
    // }

    // const handleVisiblePopupRefund = (isOpen) => {
    //     notiRefSec && notiRefSec.current && notiRefSec.current.Close();
    //     if (!isOpen) {
    //         setRefundMode();
    //         setGhiChu();
    //     } else {
    //         notiRef && notiRef.current && notiRef.current.Close();
    //         getListPaymentType();
    //     }
    // }

    // const calcSpecial = (type = "CTKM") => {
    //     let total = 0;
    //     let list = listReceipt;
    //     // list.forEach(item => {
    //     //     let selectedSer = item.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? [];
    //     //     let selectedMedi = item.listMedis?.filter(x => x.isChecked) ?? [];
    //     //     if (type.toLocaleUpperCase() == "CTKM")
    //     //         total += selectedSer.reduce((tmp, cur) => tmp + cur.loyalty, 0);
    //     //     else if (type.toLocaleUpperCase() == "CARD")
    //     //         total += selectedSer.reduce((tmp, cur) => tmp + cur.card, 0);
    //     //     else if (type.toLocaleUpperCase() == "DISCOUNT")
    //     //         total += selectedSer.reduce((tmp, cur) => tmp + cur.discount, 0) + selectedMedi.reduce((tmp, cur) => tmp + cur.discount, 0);
    //     // });
    //     return Number(total);
    // }

    // const getListPaid = (pid = props.patientCode, mid = props.managermentId, rept = props.receiptNumber) => {
    //     Action.setLoading(true);
    //     InvoiceService.getListPaid(pid, mid, rept).then(res => {
    //         if (res.isSuccess) {
    //             setListReceipt(res.data);
    //         }
    //     }).catch(err => {

    //     }).finally(() => {
    //         Action.setLoading(false);
    //     })
    // }

    // const handleRefund = () => {
    //     let errMsg = "Vui lòng chọn hình thức hoàn trả";
    //     if (isNullOrEmpty(refundMode)) {
    //         notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMsg, NotifyStatus.Warning);
    //         return;
    //     }
    //     const list = listReceipt;
    //     let selectedSer = [];
    //     let selectedMedi = [];
    //     // list.forEach(item => {
    //     //     selectedSer = selectedSer.concat(item.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? []);
    //     //     selectedMedi = selectedMedi.concat(item.listMedis?.filter(x => x.isChecked) ?? []);
    //     // });
    //     if (selectedSer.concat(selectedMedi).length == 0) {
    //         errMsg = "Vui lòng chọn ít nhất 1 dịch vụ / thuốc / vật tư (Không bao gồm đã hoàn trả)";
    //         notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMsg, NotifyStatus.Warning);
    //         return;
    //     }
    //     if (isNullOrEmpty(ghiChu)) {
    //         errMsg = "Vui lòng nhập ghi chú";
    //         notiRefSec && notiRefSec.current && notiRefSec.current.Show(errMsg, NotifyStatus.Warning);
    //         return;
    //     }
    //     notiRefSec && notiRefSec.current && notiRefSec.current.Close();

    //     let refundList = [];
    //     // list.forEach(item => {
    //     //     let itemNew = item;
    //     //     itemNew.listServices = itemNew.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? [];
    //     //     itemNew.listMedis = itemNew.listMedis?.filter(x => x.isChecked) ?? [];
    //     //     if (itemNew.listServices.concat(itemNew.listMedis).length > 0) refundList.push(itemNew);
    //     // });
    //     const data = {
    //         "RefundList": refundList,
    //         "Mode": refundMode,
    //         "Comment": ghiChu,
    //         "PatientCode": props.patientCode,
    //         "ManagermentId": props.managermentId,
    //         "TotalRefund": calcRefund(),
    //     };
    //     InvoiceService.refund(data).then(res => {
    //         if (res.isSuccess) {
    //             let soHoaDon = refundList[0] ? `#${refundList[0].receiptNumber}` : "đã thanh toán";
    //             let notiInfo = visiblePopup ? "Hoàn trả thành công" : `Hoàn trả các dịch vụ trong phiếu ${soHoaDon} thành công`;
    //             notiRefSec && notiRefSec.current && notiRefSec.current.Show(notiInfo, NotifyStatus.Success);
    //             printRefund(res.data);
    //             setVisiblePopup(false);
    //             setVisiblePopupSec(false);
    //             setRefundMode();
    //             setGhiChu();
    //         } else {
    //             notiRefSec && notiRefSec.current && notiRefSec.current.Show(res.error.messageText, NotifyStatus.Warning);
    //         }
    //     }).catch(err => {
    //         notiRefSec && notiRefSec.current && notiRefSec.current.Show(err, NotifyStatus.Error);
    //     }).finally(() => {
    //         // getListPaid();
    //     })
    // }

    // const selectAllBillSer = (receiptNumber, check = true) => {
    //     if (!isNullOrEmpty(receiptNumber)) {
    //         getListPaymentType();
    //         const list = listReceipt;
    //         list.forEach(x => {
    //             if (x.receiptNumber == receiptNumber) {
    //                 if (x.listServices && x.listServices.length > 0) {
    //                     x.listServices.forEach(s => {
    //                         s.isChecked = s.isRefunded ? false : check;
    //                     })
    //                 }
    //                 if (x.listMedis && x.listMedis.length > 0) {
    //                     x.listMedis.forEach(s => {
    //                         s.isChecked = s.isRefunded ? false : check;
    //                     })
    //                 }
    //             }
    //         })
    //         setListReceipt(list);
    //         if (!check) {
    //             notiRefSec && notiRefSec.current && notiRefSec.current.Close();
    //             setRefundMode();
    //             setGhiChu();
    //         } else {
    //             notiRef && notiRef.current && notiRef.current.Close();
    //             getListPaymentType();
    //         }
    //         forceUpdate();
    //     }
    // }

    // const calcRefund = () => {
    //     let val = 0;
    //     let list = listReceipt;
    //     let totalSer = 0;
    //     let totalMedi = 0;
    //     // list.forEach(item => {
    //     //     let selectedSer = item.listServices?.filter(x => x.isChecked && x.isRefunded == false) ?? [];
    //     //     let selectedMedi = item.listMedis?.filter(x => x.isChecked) ?? [];
    //     //     if (selectedSer.length > 0) totalSer = selectedSer.reduce((tmp, cur) => tmp + cur.total, 0);
    //     //     else totalSer = 0;
    //     //     if (selectedMedi.length > 0) totalMedi = selectedMedi.reduce((tmp, cur) => tmp + cur.revenue - cur.refund, 0);
    //     //     else totalMedi = 0;
    //     //     val += totalSer + totalMedi;
    //     // });
    //     return Number(val);
    // }

    const getListPaymentType = () => {
        VPService.GetListPaymentType().then((res) => {
            setListMethod(res.data);
        }).catch((err) => { })
    }

    const printReceipt = (soHoaDon, ngayChiDinh, maQuanLy) => {
        Action.setLoading(true);
        let banLe = false;
        PrintService.PrintPayment(soHoaDon, moment(ngayChiDinh).format('YYYY-MM-DD'), maQuanLy, banLe).then((result) => {
            if (isNullOrEmpty(result.item.template)) {
                Action.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                return;
            } else {
                const template = Handlebars.compile(result.item.template);
                Handlebars.registerHelper(handlebarHelper);
                Action.DoPrint(template(result.item));
            }
        }).finally(() => {
            Action.setLoading(false);
        })
    }

    const printRefund = (refundNumber, managermentId = props.managermentId) => {
        Action.setLoading(true);
        PrintService.PrintRefund(refundNumber).then((result) => {
            if (result.isSuccess)
                if (isNullOrEmpty(result.data.template)) {
                    Action.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc chưa cấu hình mẫu in");
                    return;
                } else {
                    const template = Handlebars.compile(result.data.template);
                    Handlebars.registerHelper(handlebarHelper);
                    Action.DoPrint(template(result.data));
                }
            else Action.openMessageDialog("Đã xảy ra lỗi", "Không thể in phiếu hoàn trả");
        }).finally(() => {
            Action.setLoading(false);
        })
    }

    const refundPopoverContent = () => {
        let listDetail = billInfo.listDetail.filter(x => !x.isRefund);
        return (
            (listDetail == undefined || listDetail.length == 0)  ? 
                <div><i className="fas fa-exclamation-triangle text-warning mr-1"></i>Không còn dịch vụ để hoàn trả</div> 
            :
                <ActionRefund
                    managementId={props.managermentId}
                    patientId={props.patientCode}
                    listDetail={listDetailSelected}
                    listMaster={[billInfo]}
                    listMethod={listMethod}
                    refundSuccess={() => {
                        props.onReload && props.onReload();
                        props.onClose && props.onClose();
                    }}
                ></ActionRefund>
        )
    }

    const onCloseDrawer = () => {
        props.onClose && props.onClose();
    }

    function setShowHide() {
        // const [showBtnPrint, setShowBtnPrint] = useState(false);
        // const [showBtnRefund, setShowBtnRefund] = useState(false);
        //button print
        setShowBtnPrint(props.showBtnPrint ?? true);
        //button refund
        setShowBtnRefund((props.showBtnRefund ?? true) && (billInfo?.billType == ReceiptType.THANHTOAN));
    }

    function getBillDetail() {
        Action.setLoading(true);
        InvoiceService.getBillDetail(props.receiptNumber).then(res => {
            if (res.isSuccess) {
                setBillInfo(res.data);
                res.data.paymentTypes && setPaymentType(res.data.paymentTypes[0].ma)
                setVisiblePopupSec(false);
                setListDetailSelected(res.data.listDetail.filter(x => !x.isRefund));
                setListDetailKeySelected([]);
                notiRef && notiRef.current && notiRef.current.Close();
                forceUpdate();
            }
            else {
                notiRef && notiRef.current && notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
            }
        }).catch(err => {

        }).finally(() => {
            Action.setLoading(false);
        })
    }

    // function onClickItem(key, item) {
    //     if (billInfo.billType == ReceiptType.HOANTRA) return;
    //     if (item.isRefund) {
    //         notification.warning({ 
    //             message: <><b>{item.name}</b> đã được hoàn trả</>,
    //         });
    //         return;
    //     }
    //     if (listDetailKeySelected.indexOf(key) > -1) {
    //         setListDetailKeySelected(listDetailKeySelected.filter(x => x != key));
    //         setListDetailSelected(listDetailSelected.filter(x => x.key != key));
    //     }
    //     else {
    //         setListDetailKeySelected([...listDetailKeySelected, key]);
    //         setListDetailSelected([...listDetailSelected, item]);
    //     }
    // }

    function chkVatInvoice(chk) {
        Action.setLoading(true);
        // public int SiteId { get; set; }
        // /// <summary>
        // /// MaQL
        // /// </summary>
        // public string ManagementId { get; set; }
        // public string BillNumber { get; set; }
        // public bool VatInvoice { get; set; }
        let data = {
            siteId: billInfo.siteId,
            managementId: billInfo.managementId,
            billNumber: billInfo.billNumber,
            vatInvoice: chk,
        };
        InvoiceService.updateVatInvoice(data).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Cập nhật thành công" });
                setVatInvoice(chk);
                if(chk) { setVisibleVATInvoice(true) }
                notiRefUpdateVat && notiRefUpdateVat.current && notiRefUpdateVat.current.Close();
            }
            else {
                notification.error({ message: "Cập nhật không thành công" });
                notiRefUpdateVat && notiRefUpdateVat.current && notiRefUpdateVat.current.Show(res.error.messageText, NotifyStatus.Warning);
            }
        }).catch(err => {

        }).finally(() => {
            Action.setLoading(false);
        })
    }

    function checkIsAdmin() {
        AuthServices.IsAdmin().then(res => {
            if (res.isSuccess) {
                setIsAdmin(res.data)
            }
        }).catch(err => {
        })
    }

    const onChangePaymentType = () => {
        Action.setLoading(true);
        let totalBill = 0;
        billInfo.paymentTypes.map(x => totalBill += x.tongTien);
        let listSelected = listMethod.filter(x => x.ma === paymentType).map(x => {
            return ({
                ma : x.ma,
                ten : x.ten,
                tongtien : totalBill
            })
        })
        let data = {
            billNumber : Number(billInfo.billNumber),
            totalBill : totalBill,
            listPaymentMethod : listSelected,
            changeMethod: true,
        }
        InvoiceService.ChangePaymentTypeNew(data).then(res => {
            if (res.isSuccess) {
                notiRef.current && notiRef.current.Show('Cập nhật hình thức thanh toán thành công.', NotifyStatus.Success);
                setOnEditPaymentType(false);
                setTimeout(() => {
                    notiRef.current && notiRef.current.Close();
                    getBillDetail()
                }, 500);
            } else {
                if(res.error && res.error.messageText){
                    notiRef.current && notiRef.current.Show(res.error.messageText, NotifyStatus.Warning);
                } else {
                    notiRef.current && notiRef.current.Show(res, NotifyStatus.Warning);
                }
            }
        }).catch(err => {
            if(err.error && err.error.messageText){
                notiRef.current && notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
            } else {
                notiRef.current && notiRef.current.Show(err, NotifyStatus.Warning);
            }
        }).finally(() => {
            Action.setLoading(false);
        })
    }

	function renderListChild(row) {
		return (
		<div className='list-page' style={{ minWidth: "80vh" }}>
			<div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
				<h5>Danh sách dịch vụ con của [{row.name}]</h5>
			</div>
			<div className="list-page-body">{/* body */}
				<div className="list-page-table">
					<List className="none-border-bottom none-border-bottom-item"
						bordered={false}
						dataSource={row.listChild ?? []}
						header={
							<List.Item key={`header`}className='p-1'>
								<div className={`d-flex justify-content-between w-100 pl-2`}>{/* 1 item */}
									<div className="" style={{width: 'calc(50%)'}}>{/* column 1: tên - mã */}
										<span className={`mita-title`} style={{whiteSpace: "pre-line"}}>Tên dịch vụ</span>
									</div>
                                    <div className='' style={{width: '25%'}}>{/* column 2: nhóm/loại */}
                                        <span className='mita-title' style={{whiteSpace: "pre-line"}}>Loại</span>
                                    </div>
                                    <div className='' style={{width: '25%'}}>{/* column 2: nhóm/loại */}
                                        <span className='mita-title' style={{whiteSpace: "pre-line"}}>Trạng thái</span>
                                    </div>
								</div>
							</List.Item>
						}
						renderItem={(item) => {
							let className = "p-1 border-2 border-top";
							return (
								<List.Item key={`${row.id}-${item.id}`}
									className={className}
									// style={{backgroundColor:"#D8D8D8"}}
								>
									<div className={`d-flex justify-content-between w-100 pl-2`} key={`pr-${item.id}`}>{/* 1 item */}
										<div className="" style={{width: 'calc(50%)'}}>{/* column 1: tên - mã */}
											<span className={`mita-value`} style={{whiteSpace: "pre-line"}}>{item.name}</span>
										</div>
                                        <div className='' style={{width: '25%'}}>{/* column 2: nhóm/loại */}
                                                <span className='mita-value' style={{whiteSpace: "pre-line"}}>{item.groupName}</span>
                                            </div>
                                            <div className='' style={{width: '25%'}}>{/* column 2: nhóm/loại */}
                                                <span className='mita-value' style={{whiteSpace: "pre-line"}}>{item.status}</span>
                                            </div>
									</div>
								</List.Item>
							)
						}}
					>
					</List>
				</div>
			</div>
		</div>
		)
	}
    
    function renderDichVu(list) {
        let className = "p-1 border-2 border-bottom py-2 d-flex flex-column ant-list-item-hover";
        return (
            <List className="none-border-bottom none-border-bottom-item h-100 w-100" style={{ paddingRight:20 }}
                bordered={false}
                // dataSource={rept.listMedis}
                // renderItem={(record, index) => {
                // }}
            >
                {list && list.map((r, index) => {
                    let disabledCheck = (billInfo.billType == ReceiptType.HOANTRA || r.isRefund)
                    return (
                        <List.Item key={r.key}
                            className={className}
                            // onClick={() => { onClickItem(r.key, r); }}
                        >
                            <div className="w-100 d-flex justify-content-between">
                                <div className="d-flex">
                                    {/* {!isBillRefund && <Checkbox className="mr-2"
                                        disabled={disabledCheck}
                                        checked={listDetailKeySelected.indexOf(r.key) > -1}
                                        // onChange={(e) => {
                                        // }}
                                    ></Checkbox>} */}
                                    <label className="mita-value h-100">
                                        {r.name}
                                        {r.isCombo && 
                                            <Popover trigger="hover"
                                                content = {() => renderListChild(r)}
                                            >
                                                <span className='mr-1'><i className="fas fa-info-circle ml-1"></i></span>
                                            </Popover>
                                        }
                                    </label>
                                </div>
                                {
                                    !isBillRefund && r.type != PRODUCT_TYPE.Service &&
                                    <div>
                                        <label>Số phiếu: {r.idChiDinh}</label>
                                    </div>
                                }
                                <div className='d-flex' style={{textAlign: 'end'}}>
                                    {!isBillRefund && <div>
                                        {r.type == PRODUCT_TYPE.Service ?
                                            <Fragment>
                                                <label className='mr-2'>* Đơn giá</label>
                                                <label>{Number(r.price ?? 0).toLocaleString("en-US")}</label>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <label className='ml-2'>{Number(r.quantity ?? 0).toLocaleString("en-US")} x {Number(r.price ?? 0).toLocaleString("en-US")}</label>
                                            </Fragment>}
                                    </div>}
                                    {r.discount && Number(r.discount) > 0 ? 
                                    <div className='ml-2'>
                                        <label className='mr-2'>* Miễn giảm</label>
                                        <label className='text-warning'>{Number(r.discount ?? 0).toLocaleString("en-US")}</label>
                                    </div> : ""}
                                    {r.loyalty && Number(r.loyalty) > 0 ? 
                                    <div className='ml-2'>
                                        <label className='mr-2'>* CTKM
                                            <Popover trigger="hover" placement="top"
                                                content = {
                                                    <div>
                                                        <label className='mita-value'>Mã CTKM đã sử dụng:&nbsp;</label>
                                                        <label className='mita-title'>{r.voucher}</label>
                                                    </div>
                                                }
                                            >
                                                <span className='mr-1'><i className="fas fa-info-circle ml-1"></i></span>
                                            </Popover>
                                        </label>
                                        <label className='text-success'>{Number(r.loyalty ?? 0).toLocaleString("en-US")}</label>
                                    </div> : ""}
                                    {r.discountCard && Number(r.discountCard) > 0 ? 
                                    <div className='ml-2'>
                                        <label className='mr-2'>* Thẻ</label>
                                        <label className='text-success'>{Number(r.discountCard ?? 0).toLocaleString("en-US")}</label>
                                    </div> : ""}
                                    {/* {r.card && Number(r.card) > 0 ? <li className='ml-2'>
                                        <label className='mr-2'>Thẻ</label>
                                        <label style={{ color: "#57a127" }}>{Number(r.card ?? 0).toLocaleString("en-US")}</label>
                                    </li> : ""} */}
                                    <div className='ml-2'>
                                        {
                                            r.type == PRODUCT_TYPE.Service &&
                                            <label className='mr-2'>* {billInfo.billType == ReceiptType.HOANTRA ? "Hoàn trả" : "Thành tiền"}</label>
                                        }
                                        <label className='text-info'>{Number(r.total ?? 0).toLocaleString("en-US")}</label>
                                    </div>
                                </div>
                            </div>
                        </List.Item>
                    )
                })}
            </List>
        )
    }

    return (
        <Fragment>
            <div className="list-page">
                <HeaderModal title={titleHeader} onClose={() => onCloseDrawer()} />
                <div className="list-page-body">
                    <div className="w-100 h-100 d-flex justify-content-between" style={{ overflowY: "auto" }}>
                        <div className="border overflow-auto" style={{ width: '40%' }}>
                            <Collapse defaultActiveKey={['pif', 'oth']} expandIconPosition="right">
                                <Collapse.Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="pif">
                                    <PatientInfo patientId={props.patientCode} showContact={true} />
                                </Collapse.Panel>
                                <Collapse.Panel header={<label className='mita-header-title'>Thông tin khác</label>} key="oth">
                                    <RegisterInfo patientId={props.patientCode} managementId={props.managermentId} />
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                        <div className="list-page border w-100 pl-2 py-2">
                            <Notify ref={notiRef} />
                            <div className='list-page-body'>
                                <div className='row'>{/* Bill info */}
                                    <div className='col-4'>
                                        <label className='mita-title'>Số phiếu</label><br />
                                        <label className='mita-value'>{props.receiptNumber ?? ""}</label>
                                    </div>
                                    <div className='col-4'>
                                        <label className='mita-title'>Ngày giờ {billInfo.billTypeText}</label><br />
                                        <label className='mita-value'>{billInfo.paidDate && moment(billInfo.paidDate).format(FORMAT_TIME_DATE)}</label>
                                    </div>
                                    <div className='col-4'>
                                        <label className='mita-title'>Thu ngân</label><br />
                                        <label className='mita-value'>{billInfo.cashier ?? "Chưa có thông tin"}</label>
                                    </div>
                                    <div className='col-4'>
                                        <label className='mita-title'>Hình thức</label><br />
                                        <label className='mita-value w-100'>
                                            { (!isBillRefund && onEditPaymentType) ?
                                                <>
                                                    <Select
                                                        className="w-50 mb-2"
                                                        placeholder="Chọn trạng thái"
                                                        value={paymentType}
                                                        onChange={(value) => { setPaymentType(value); }}
                                                    >
                                                        {listMethod.map(x => (
                                                            <Select.Option key={x.ma} value={x.ma}>{x.ten}</Select.Option>
                                                        ))}
                                                    </Select>
                                                    {props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.METHOD] && <Popconfirm
                                                        title="Xác nhận thay đổi"
                                                        onConfirm={() => {onChangePaymentType()}}
                                                        okText="Đồng ý"
                                                        cancelText="Hủy">
                                                        <Button className='btn btn-color ml-2'>
                                                            Cập nhật
                                                        </Button>
                                                    </Popconfirm>}
                                                </>
                                            :
                                                <>
                                                    {(billInfo.paymentTypeName ?? ["Chưa có thông tin"]).join(', ')}
                                                    { billInfo.isRecoverDebt && <Tag className="ml-2" color="blue">Đã thu</Tag>}
                                                    {props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.METHOD] && !isBillRefund && !billInfo.isRecoverDebt &&
                                                        <span onClick={() => { setOnEditPaymentType(true); }}>
                                                            <i className="fas fa-pencil-alt anchor-color ml-2"></i>
                                                        </span>
                                                    }
                                                </>
                                            }
                                        </label>
                                    </div>
                                    {!isBillRefund &&
                                    <div className='col-4'>
                                        <label className='mita-title'>Mã tra cứu kết quả</label><br />
                                        <label className='mita-value'>{billInfo.resultCode ?? "Chưa có thông tin"}</label>
                                    </div>
                                    }
                                </div>
                                <div className="list-page-table overflow-auto">
                                    <div>{/* Bill detail (list dịch vụ | vật tư) */}
                                        { (listDV && listDV.length > 0) &&
                                        <div>{/* List dịch vụ */}
                                            <div className='row mt-4'>
                                                <div className='col-12'>
                                                    <h5 className='d-flex align-items-center'>
                                                        <i className="fas fa-medkit mr-3" style={{ fontSize: 20 }}></i>
                                                        <span style={{ color: "#636363" }}>DỊCH VỤ ({listDV.length})</span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {renderDichVu(listDV)}
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        { (listVT && listVT.length > 0) &&
                                        <div>{/* List vật tư & thuốc */}
                                            <div className='row mt-4'>
                                                <div className='col-12'>
                                                    <h5 className='d-flex align-items-center'>
                                                        <i className="fas fa-medkit mr-3" style={{ fontSize: 20 }}></i>
                                                        <span>THUỐC & VẬT TƯ ({listVT.length})</span>
                                                    </h5>
                                                    {/* <label className='d-flex align-items-center'>
                                                        <i className="fas fa-capsules mr-3" style={{ fontSize: 20 }}></i>
                                                        <span style={{ color: "#636363" }}>THUỐC & VẬT TƯ ({listDV.length})</span>
                                                    </label> */}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {renderDichVu(listVT)}
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className='py-2 d-flex justify-content-between mt-2' style={{borderTop: "3px dashed"}}>{/* Thông tin bottom */}
                                        <div className='pr-2' style={{width : '100%'}}>{/*  Bên trái */}
                                        {isBillRefund ?
                                            <div>
                                                <label className='mita-title'>Ghi chú</label><br />
                                                <label className='mita-value' style={{whiteSpace: "pre-line"}}>{billInfo.remark}</label>
                                            </div> :
                                            <div>
                                                <Tabs defaultActiveKey="1">
                                                    <Tabs.TabPane tab="Ghi chú" key="1">
                                                        <div style={{whiteSpace: "pre-line"}}>
                                                            {billInfo.remark}
                                                        </div>
                                                    </Tabs.TabPane>
                                                    <Tabs.TabPane tab="Ghi nhận xuất hóa đơn đỏ" key="2">
                                                        <Notify ref={notiRefUpdateVat} />
                                                        <Checkbox className="mr-2"
                                                            checked={vatInvoice}
                                                            onChange={(e) => { 
                                                                chkVatInvoice(e.target.checked);
                                                            }}
                                                        >Ghi nhận xuất hóa đơn đỏ</Checkbox>
                                                        </Tabs.TabPane>
                                                </Tabs>
                                            </div>
                                        }
                                        </div> 
                                        <div style={{width : '30%'}}>{/*  Bên phải */}
                                            <SummaryAmount 
                                                style={{ width:'max-content' }}
                                                isEdit={!billInfo.isRecoverDebt}
                                                data={billInfo.billNumber ? billInfo : {billNumber: props.receiptNumber}}
                                                // managementId={billInfo.managementId}
                                                // patientId={props.patientCode}
                                                // billNumber={billInfo.billNumber}
                                                // isBillRefund={isBillRefund}
                                                // data={{
                                                //     total: billInfo.total,
                                                //     discount: billInfo.discount,
                                                //     loyalty: billInfo.loyalty,
                                                //     refund: billInfo.refund,
                                                //     paid: billInfo.paid,
                                                //     revenue: billInfo.revenue,
                                                // }}
                                                onChangePayment={() => getBillDetail()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"list-page-footer d-flex justify-content-between mt-2 p-2 align-items-center"}>
                                <div>
                                    {(showBtnPrint) &&
                                    <a href='#' onClick={(e) => { 
                                        e.stopPropagation(); 
                                        billInfo?.billType == ReceiptType.HOANTRA ? printRefund(billInfo.billNumber, billInfo.managementId) : printReceipt(billInfo.billNumber, billInfo.paidDate, billInfo.managementId)
                                    }}
                                    ><i className='fas fa-print mr-1' />In phiếu</a>
                                    }
                                </div>
                                <div>
                                    {props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.DISCOUND] && billInfo?.billType == ReceiptType.THANHTOAN &&
                                    <a
                                        href='#'
                                        className='mr-2'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setVisibleModifyBill(true)
                                        }}
                                    >
                                        <i className='fas fa-pencil-alt mr-1' />Điều chỉnh
                                    </a>}
                                    {props.userScreenAuthorization?.auth[USER_AUTH.PAYMENT.REFUND] && (showBtnRefund) &&
                                    <Popover
                                        placement="topLeft"
                                        onVisibleChange={(isOpen) => {
                                            setVisiblePopupSec(isOpen);
                                            forceUpdate();
                                        }}
                                        destroyTooltipOnHide
                                        visible={visiblePopupSec}
                                        content={
                                            refundPopoverContent()
                                        }
                                        trigger="click"
                                    >
                                        <a href='#'><i className='fas fa-undo mr-1'></i>Hoàn trả</a>
                                    </Popover>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer 
                placement='right'
                key={'vat_invoice'}
                closable={false}
                visible={visibleVATInvoice}
                width={'40%'}
                className='custom-ant-drawer'
                maskClosable={false}
                destroyOnClose={true}
            >
                <HeaderModal title="Thông tin phiếu xuất hóa đơn đỏ" onClose={() => setVisibleVATInvoice(false)} />
                <CustomerVATInfo disableChange={false} info={{ name: props.patientName, maql: billInfo.managementId }}
                    onClose={() => setVisibleVATInvoice(false)} />
            </Drawer>
            <Modal
                width={1200}
                open={visibleModifyBill}
                visible={visibleModifyBill}
                destroyOnClose
                footer={null}
            >
                <ModifyInvoice 
                    receiptNumber={props.receiptNumber} 
                    onSubmit={() => { getBillDetail(); setVisibleModifyBill(false); }}
                    onClose={() => setVisibleModifyBill(false)} 
                />
            </Modal>
        </Fragment>
    );
})

const mapStateToProps = (state) => ({
    siteid: state.global.siteid,
    PAYMENTTYPE: state.global.PAYMENTTYPE,
    userScreenAuthorization: state.global.userScreenAuthorization,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Preview);