import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback  } from 'react';
import { Input, InputNumber, Button, Table, notification, Drawer, Popconfirm, Pagination, Dropdown, Menu, Select, DatePicker } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as SystemConfig from '../../../../configure/systemConfig';
import * as Actions from '../../../../libs/actions';
import * as AuthServices from '../../../../services/authServices';
import * as CoporationServices from "../../../../services/CorporationServices";
import * as VPService from '../../../../configureHIS/update_vp/services/VPService';
import * as AdministrativeService from '../../../../services/administrativeService';
import Notify, { NotifyStatus } from '../../../../components/notify';
import Summary from './summary';
import List from './list';
import AverageRevenueChart from './chart';
import SiteFilter from "../../../../configureHIS/common/siteFilter";
import { set } from "lodash";

export default forwardRef((props, ref) => {
  //#region biến cục bộ
  const { Option } = Select;
  const now = new Date();
  const viewTypeEnum = {
    chart: "chart",
    list: "list"
  };
  const { RangePicker } = DatePicker;
  //#endregion
  const summaryRef = useRef();
  const listRef = useRef();
  const chartRef = useRef();
  const notiRef = useRef(null);
  const _hiddenLink = useRef();
  const firstRender = useRef(true);
  //#region khai báo state
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //state paging và sorting
  //search/filter
  const [siteIds, setSiteIds] = useState([]);
  const [coporationId, setCoporationId] = useState();
  const [city, setCity] = useState(null);
  const [district, setDistrict] = useState(null);
  const [date, setDate] = useState([moment(now).add(-29,'days'), new moment()]);//(new moment((now).setMonth(now.getMonth() - 1)));
  const [keyword, setKeyword] = useState('');
  const [minSpent, setMinSpent] = useState(null);
  const [maxSpent, setMaxSpent] = useState(null);
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
  //other
  const [listCoporations, setListCoporations] = useState([]);
  const [listPaymentTypes, setListPaymentTypes] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [viewType, setViewType] = useState(viewTypeEnum.chart);
  //#endregion
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    loadChartOrList();
  }, [viewType]);
  
  useEffect(() => {
    console.clear();
    getListCoporations();
    getListPaymentTypes();
    onClickStatisticHandle();
    loadCity();
    firstRender.current = false;
  }, []);
  //#endregion 
  //#region get list data cho bộ lọc
  const getListCoporations = () => {
    CoporationServices.GetAllCoporation('', false, 1, 1000).then(result => {
      if (result.isSuccess) {
        setListCoporations(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }
  const loadCity = () => {
        AdministrativeService.GetAllCity().then(result => {
            if (result.isSuccess && result.data.length > 0) {
              setListCity(result.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

  const loadDistrict = (id) => {
      AdministrativeService.GetDistrictByCityId(id).then(result => {
          if (result.isSuccess && result.data.length > 0) {
              setListDistrict(result.data);
          }
          else {
              setListDistrict([]);
          }
      }).catch(err => {
          console.log(err);
      })
  }
  const getListPaymentTypes = () => {
    VPService.GetDanhSachLoaiThanhToan().then(result => {
      if (result.isSuccess) {
        setListPaymentTypes(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        Actions.setLoading(false);
        forceUpdate();
    })
  }
  //#endregion 
  const loadChartOrList = () => {
    if (viewType == viewTypeEnum.chart)
      chartRef.current.loadData();
    else listRef.current.loadData();
  }
  const validate = () => {
    let listErr = [];
    // if (!dateFrom) listErr.push('Phải nhập ngày lập phiếu');
    // if (dateFrom > dateTo) listErr.push('Ngày lập phiếu từ phải nhỏ hơn ngày lập phiếu đến');
    return listErr;
  }
  const onClickStatisticHandle = () => {
    let listErr = validate();
    if (listErr.length > 0) {
      let err = listErr.join('\n');
      notiRef.current.Show(err, NotifyStatus.Error);
      return;
    }
    summaryRef.current.loadData();
    loadChartOrList();
    notiRef && notiRef.current.Close();
  }

  const clearFilter = () => {
    setCoporationId(null);
    setKeyword("");
    setCity(null);
    setDistrict(null);
    setMinSpent(0);
    setMaxSpent(0);
  }
  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} >download</a>
      <div className={"list-page" + (props.isDialog ? "" : " containerChilderWrapper")} >
          <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}> {/* header */}
              <h3>Thống kê chi tiêu khách hàng</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div>
          <div className="list-page-body overflow-auto">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="list-page-search">{/* search/filter */}
              <div className="row">{/* Chi nhánh */}
                <SiteFilter updateSeletedSite={(listSite) => { setSiteIds(listSite) }} />
              </div>
              <div className="row mt-2">{/* Nguồn khách hàng | Ngày lập phiếu | Hình thức thanh toán */}
                  <div className='col-md-4 d-flex flex-column'>{/* Nguồn khách hàng */}
                    <div>
                      <label>Nguồn khách hàng</label>
                    </div>
                    <Select
                      allowClear
                      className="w-100"
                      placeholder="Chọn nguồn khách hàng"
                      maxTagCount= 'responsive'
                      value={ coporationId }
                      onChange={(value) => {setCoporationId(value);}}
                    >
                    {(listCoporations ?? []).map((record, index) => {
                      return (
                        <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                      )
                    })}
                    </Select>
                  </div>
                  <div className='col-md-4 d-flex flex-column'>{/* Ngày lập phiếu */}
                    <div>
                      <label>Khoảng thời gian</label>
                    </div>
                    <div className="d-flex">
                      <RangePicker 
                        allowClear={false}
                        locale={viVN}
                        showNow={false}
                        className="w-100"
                        value={date}
                        format = { SystemConfig.FORMAT_DATE }
                        onChange={(e) => { 
                          setDate(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 d-flex flex-column'>{/* Hình thức thanh toán */}
                    <div>
                      <label>Mức chi tiêu</label>
                    </div>
                    <div className="d-flex">
                      <InputNumber
                        prefix={<u>đ</u>}
                        className="w-100"
                        value={minSpent}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(e) => {setMinSpent(e)}}
                      />
                      <div className="m-auto">&#160;-&#160;</div>
                      <InputNumber
                        className="w-100"
                        value={maxSpent}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        min={minSpent}
                        onChange={(e) => {setMaxSpent(e)}}
                      />
                    </div>
                  </div>
              </div>
              {isAdvanceSearch && 
              <div className="row mt-2">{/* Nguồn khách hàng | Ngày lập phiếu | Hình thức thanh toán */}
                  <div className='col-md-4 d-flex flex-column'>{/* Nguồn khách hàng */}
                    <div>
                      <label>Từ khóa</label>
                    </div>
                    <Input
                        placeholder="Nhập mã, tên, số điện thoại khách hàng"
                        value={keyword}
                        onChange={(e) => {setKeyword(e.target.value)}}
                        onPressEnter={() => {}}
                    />
                  </div>
                  <div className='col-md-4 d-flex flex-column'>{/* Ngày lập phiếu */}
                    <div>
                      <label>Tỉnh / Thành phố</label>
                    </div>
                    <div className="d-flex">
                      <Select
                      allowClear
                      className="w-100"
                      placeholder="Chọn tỉnh , thành phố"
                      maxTagCount= 'responsive'
                      value={ city }
                      onChange={(value) => {setCity(value); loadDistrict(value);}}
                      >
                      {(listCity ?? []).map((record, index) => {
                        return (
                          <Option key={record.value} value={record.value}>{record.label}</Option>
                        )
                      })}
                      </Select>
                    </div>
                  </div>
                  <div className='col-md-4 d-flex flex-column'>{/* Hình thức thanh toán */}
                    <div>
                      <label>Quận / Huyện</label>
                    </div>
                    <Select
                      allowClear
                      className="w-100"
                      placeholder="Chọn quận, huyện"
                      maxTagCount= 'responsive'
                      value={ district }
                      onChange={(value) => {setDistrict(value);}}
                    >
                    {(listDistrict ?? []).map((record, index) => {
                      return (
                        <Option key={record.value} value={record.value}>{record.label}</Option>
                      )
                    })}
                    </Select>
                  </div>
              </div>
              }
              <div className="row mt-2">{/* button Thống kê */}
                  <div className='col-md-12 d-flex justify-content-between'>
                    <div><a href="#" onClick={() => setIsAdvanceSearch(!isAdvanceSearch)}><u>Tìm kiếm mở rộng</u></a></div>
                    <div>
                      <label className="my-auto">Hiển thị: </label>
                      <Select
                        // placeholder="Chọn hình thức thanh toán"
                        className="ml-2"
                        style={{ minWidth:"130px" }}
                        maxTagCount= 'responsive'
                        value={ viewType }
                        onChange={(value) => {setViewType(value);}}
                      >
                        <Option key="chart" value="chart"><i className='far fa-chart-bar mr-2'></i> Biểu đồ</Option>
                        <Option key="list" value="list"><i className='fas fa-list mr-2'></i> Danh sách</Option>
                      </Select>
                      <button 
                        className='btn btn-color ml-2' 
                        onClick={() => {
                          onClickStatisticHandle();
                        }}
                      >
                        <i className='far fa-chart-bar mr-2'></i>
                        Thống kê
                      </button>
                      <button 
                        className='btn btn-secondary ml-2' 
                        onClick={() => {
                          clearFilter();
                        }}
                      >
                        <i className='fa fa-eraser mr-2'></i>
                        Xóa dữ liệu nhập
                      </button>
                    </div>
                  </div>
              </div>
            </div>
            <div className="row mt-2">{/* Summary & [Chart | List] */}
              <div className='col-md-12'>
                <Summary ref={summaryRef}
                  siteIds={siteIds}
                  coporationIds={coporationId ? coporationId : null}
                  keyword={keyword}
                  city={city}
                  district={district}
                  minSpent={minSpent}
                  maxSpent={maxSpent}
                  dateFrom={date[0]}
                  dateTo={date[1]}
                ></Summary>
              </div>
            </div>
            <div className="row mt-2">{/* Dashboard & [Chart | List] */}
              <div key="chart" className='col-md-12' hidden={viewType != viewTypeEnum.chart}>
                <AverageRevenueChart ref={chartRef}
                  siteIds={siteIds}
                  coporationIds={coporationId ? coporationId : null}
                  keyword={keyword}
                  city={city}
                  district={district}
                  minSpent={minSpent}
                  maxSpent={maxSpent}
                  dateFrom={date[0]}
                  dateTo={date[1]}
                ></AverageRevenueChart>
              </div>
              <div key="list" className='col-md-12' hidden={viewType != viewTypeEnum.list}>
                <List ref={listRef}
                  siteIds={siteIds}
                  coporationIds={coporationId ? coporationId : null}
                  keyword={keyword}
                  city={city}
                  district={district}
                  minSpent={minSpent}
                  maxSpent={maxSpent}
                  dateFrom={date[0]}
                  dateTo={date[1]}
                ></List>
              </div>
            </div>
          </div>
          <div className={props.isDialog ? "list-page-footer d-flex justify-content-between mt-2" : "d-none"}
          >{/* footer (nếu có) */}
            <div>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
})