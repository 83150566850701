import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import { notification } from "antd";

export default forwardRef((props, ref) => {
    const [devices, setDevices] = useState([]);
    const [showBtnCapture, setShowBtnCapture] = useState(props.takePhoto ? props.takePhoto : false);
    const [capturing, setCapturing] = useState(props.capturing ? props.capturing : false);

    useImperativeHandle(ref, () => ({
        onChangeCapture: (status) => {
            setCapturing(status)
        }
    }));

    useEffect(() => {
        getDevices();
    }, [])

    const getDevices = () => {
        try {
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                devices && setDevices(devices.filter(({ kind }) => kind === "videoinput"));
            })
        } catch (ex) {
            // notification.warning({message : "Lỗi khi truy cập camera."})
        }
    }

    const handleStart = () => {
        setCapturing(true);
        props.handleStart()
    }

    const handleStop = () => {
        setCapturing(false);
        props.handleStop()
    }


    const handleUserKeyPress = useCallback(event => {
        const { key } = event;
        if (key == "F2") {
            props.onCapturePhoto && props.onCapturePhoto();
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <div className="col-12 d-flex justify-content-between">
            <div>
                <button className="btn btn-secondary" onClick={() => { getDevices() }}><i className="fas fa-sync-alt" /></button>
                {/* Capture Picture */}
                {showBtnCapture &&
                    <button
                        className="btn btn-secondary ml-2"
                        onClick={() => { props.onCapturePhoto() }}>
                        <i className="fas fa-camera mr-1" /><span className="text-danger">F2</span></button>}
                {/* Input Camera */}
                {devices.map((device, key) => (
                    <button
                        key={`Device ${key + 1}`}
                        className="btn btn-secondary ml-2"
                        onClick={() => {
                            props.onSelected(device);
                        }}>
                        <i className="fas fa-video" />
                    </button>
                ))}
            </div>
            {showBtnCapture &&
                <>
                    <div>
                        {capturing ? (
                            <>
                                <button className='btn btn-secondary' style={{ marginRight: 5 }} disabled={true} onClick={() => { handleStart() }}>Bắt đầu quay</button>
                                <button className='btn btn-danger' onClick={() => { handleStop() }}>Dừng quay</button>
                            </>
                        ) : (
                            <>
                                <button className='btn btn-success' style={{ marginRight: 5 }} onClick={() => { handleStart() }}>Bắt đầu quay</button>
                                <button className='btn btn-secondary' disabled={true} onClick={() => { handleStop() }}>Dừng quay</button>
                            </>
                        )}
                    </div>
                    <div>
                        <button className="btn btn-danger" onClick={() => { props.onClose(); }}> Đóng </button>
                    </div>
                </>
            }
        </div>
    )
});
