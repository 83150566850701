import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import HeaderModal from '../../configureHIS/common/headerModal';
import ActionControl from '../../libs/actionControl';
import { ValidationCustom } from '../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import { Collapse, Steps } from 'antd';
import MutilMedia from '../../components/MitaComponents/UploadFile/mitaUploadFile';
import Relationship from "./relationshipInfo";
import PatientInfo from './patientInfo';
import BasicInfo from './basicInfo';
import { NotifyStatus } from '../../components/notify';
import { groupBy, isNullOrEmpty, renumber, uppercaseFirstLetter } from '../../libs/util';
import { REGISTER_STEP } from '../../configureHIS/common/constants';
import * as PatientService from '../../services/patientService';
import VitalSign from '../patient/component/vitalSign';
import * as PrintService from "../../services/printService";
import * as Actions from "../../libs/actions";
import Handlebars from "handlebars";
import handlebarHelper from '../../libs/handlebarHelper';
import {default as num2words} from 'vn-num2words';

const { Step } = Steps;
const { Panel } = Collapse;

export default forwardRef((props, ref) => {
    const notiRef = useRef(null);
    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [listSteps,] = useState([
        "Thông tin tiếp đón",
        "Thông tin đăng ký dịch vụ",
        "Thông tin dấu hiệu sinh tồn",
    ])

    const [step, setStep] = useState(REGISTER_STEP.TIEPDON);
    const [isUpdate, setIsUpdate] = useState(false);
    const [maBN, setMaBN] = useState("");
    const [maQL, setMaQL] = useState();
    const [basicInfomation, setBasicInfomation] = useState([]);

    const _patientInfo = useRef();
    const _basicInfo = useRef();
    const _relationship = useRef();
    const _vitalSign = useRef();

    const handleSave = (isPrint, nextStep) => {
        let patientInfoError = _patientInfo && _patientInfo.current && _patientInfo.current.checkValidInfo();
        let patientRelaInfoError = _relationship && _relationship.current && _relationship.current.checkValidInfo();
        let relationshipInfo = _relationship && _relationship.current && _relationship.current.getInfo();
        if (patientInfoError || patientRelaInfoError) {
            onScrollToTop();
            return;
        }
        if (step == REGISTER_STEP.TIEPDON) {
            let data = _patientInfo.current.getPatientInfo ? _patientInfo.current.getPatientInfo() : [];
            if (relationshipInfo) data.NguoiThan = relationshipInfo;
            data.maQL = maQL;
            PatientService.saveOrUpdatePatientAndRegister(data).then((res) => {
                if (res.isSuccess) {
                    _patientInfo.current.setPatientInfo(res.data);
                    _basicInfo && _basicInfo.current && _basicInfo.current.setInfo(res.data);
                    setMaQL(res.data.managermentId);
                    setBasicInfomation(res.data);
                    _patientInfo.current.ShowMessage("Đăng ký thành công", NotifyStatus.Success);
                    goToNextStep(nextStep);
                } else _patientInfo.current.ShowMessage(res.error.messageText, NotifyStatus.Error);
            }).catch((error) => {
                _patientInfo.current.ShowMessage(error.messageText, NotifyStatus.Error);
            });
        } else if (step == REGISTER_STEP.DANGKY_DV) {
            goToNextStep(nextStep);
        } else if (step == REGISTER_STEP.DST) {
            if (_vitalSign && _vitalSign.current && _vitalSign.current.handleSave) {
                _vitalSign.current.handleSave();
            }
        }
        if (isPrint) {
            handlePrintRegister();
        }
    }

    const goToNextStep = (nextStep) => {
        if (nextStep) {
            if (nextStep >= listSteps.length) setStep(REGISTER_STEP.TIEPDON);
            else setStep(nextStep);
        }
        onScrollToTop();
    }

    const handleClose = () => {

    }

    const handleRetype = () => {
        if (step == REGISTER_STEP.TIEPDON) {
            setMaBN();
            setMaQL();
            setBasicInfomation([]);
            _patientInfo.current.clearData && _patientInfo.current.clearData();
            _relationship.current && _relationship.current.clearData && _relationship.current.clearData();
        }
        else if (step == REGISTER_STEP.DST) {
            _vitalSign && _vitalSign.current && _vitalSign.current.clearData();
        }
        onScrollToTop();
    }

    const handleBackward = () => {
        onChangeStep(step - 1);
    }

    const onChangeStep = (toStep) => {
        setStep(toStep);
        onScrollToTop();
    }

    const onScrollToTop = () => {
        var containerToScroll = document.getElementById('container');
        containerToScroll.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    const hideAllNotify = () => {
        _patientInfo.current && _patientInfo.current.clearData && _patientInfo.current.hideNotify();
        _relationship.current && _relationship.current.clearData && _relationship.current.hideNotify();
    }

    const handlePrintRegister = () => {
		if (isNullOrEmpty(maBN)) {
			Actions.openMessageDialog("Lỗi", "Chưa lưu đăng ký bệnh nhân!");
			return;
		}
		PrintService.printReceptionReceipt(maQL)
        .then((result) => {
            if (isNullOrEmpty(result.item.template)) {
                Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc các dịch vụ đã được thực hiện, lấy mẫu...");
                return;
            } else {
				let templateMaster = "";
				var finalData = result.item.data;
				const listOrderId = result.item.listOrderId; // Danh sách orderId
				var listTestGroupByOrderId = groupBy(result.item.data.listTest.listIndication, "orderid"); // Danh sách chỉ định xét nghiệm đã phân theo orderid
				Object.keys(listTestGroupByOrderId).forEach(orderId => {
					let listTestBelongToGroup = listTestGroupByOrderId[orderId];
					const listTestGroup = groupBy(listTestBelongToGroup, "tenloaiXN");
					const resultGroupArr = Object.keys(listTestGroup).map((key) => ({ key, value: listTestGroup[key] }));
					const resultGroupArrRenumber = renumber(resultGroupArr);
					let totalMoney = listTestBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
					let dataInfo = {
						listIndication: resultGroupArrRenumber,
						sidBarcode: listOrderId?.find(x => x.label == orderId)?.value,
						title: `${finalData.listTest.title?.toUpperCase() ?? "PHIẾU ĐĂNG KÝ XÉT NGHIỆM"}`,
						amount: totalMoney,
						amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
					};
					let keyName = `test${orderId}`;
					var tempData = {...finalData, [`${keyName}`]: dataInfo};
					finalData = tempData;
					templateMaster += result.item.subTemplate.replaceAll("data.listTest", `data.${keyName}`); // Thêm template chỉ định xét nghiệm phân theo orderId và theo loại
				});
				const template = Handlebars.compile(result.item.template.replace("#listDangKyXetNghiem", templateMaster));
				result.item.data = finalData;
                Handlebars.registerHelper(handlebarHelper);
                Actions.DoPrint(template(result.item));
            }
        })
    }

    useEffect(() => {
        setIsUpdate(!isNullOrEmpty(maQL))
    }, [maQL]);

    return (
        <Fragment>
            <div className='containerChilderWrapper'>
                <div className='list-page'>
                    <HeaderModal title={`${isUpdate && maQL ? `Cập nhật đăng ký - ${maQL}` : "Đăng ký mới"}`} onClose={() => { }} />
                    <div id="container" className='list-page-body' style={{ overflowY: "auto" }}>
                        <div className='w-100'>
                            <Steps
                                type="navigation"
                                current={step}
                                // onChange={onChangeStep}
                                className="site-navigation-steps"
                                labelPlacement="vertical">
                                {
                                    listSteps && listSteps.map((x, index) => {
                                        return (
                                            <Step key={index} title={x}></Step>
                                        )
                                    })
                                }
                            </Steps>
                        </div>
                        {step === REGISTER_STEP.TIEPDON && <div className='w-100'>
                            <Collapse defaultActiveKey={['ttbn', 'ttnt', 'tths']} expandIconPosition="end">
                                <Panel className='d-none' header={<label className='mita-header-title'>Thông tin chung</label>} key="ttc">
                                    <div className='mb-2'>
                                        <BasicInfo ref={_basicInfo} data={basicInfomation} />
                                    </div>
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Thông tin bệnh nhân</label>} key="ttbn">
                                    <div className='mb-2'>
                                        <PatientInfo maBN={maBN} ref={_patientInfo} updatePatientCode={(PID) => { setMaBN(PID); hideAllNotify(); }} />
                                    </div>
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Thông tin người thân bệnh nhân</label>} key="ttnt">
                                    <div className='mb-2'>
                                        <Relationship isUpdate={true} ref={_relationship} maBN={maBN} />
                                    </div>
                                </Panel>
                                <Panel header={<label className='mita-header-title'>Thông tin hồ sơ bệnh nhân</label>} key="tths">
                                    <div className='row mt-2'>
                                        <MutilMedia col={12}
                                            featureName="register"
                                            componentKey="Register-Attachment"
                                            isEdit={!isNullOrEmpty(maBN)}
                                            layout="Details"
                                            refId={maBN}
                                        >
                                        </MutilMedia>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>}
                        {
                            step == REGISTER_STEP.DST && maQL &&
                            <div className='w-100 mt-2'>
                                <VitalSign ref={_vitalSign} isEdit={true} maql={maQL} maBN={maBN}></VitalSign>
                            </div>
                        }
                    </div>
                    <div className="list-page-footer">
                        <ActionControl
                            activeRetype={step == REGISTER_STEP.TIEPDON || step == REGISTER_STEP.DST}
                            activeSaveAndNextStep={step != REGISTER_STEP.DST}
                            activeSavePrint={step != REGISTER_STEP.TIEPDON}
                            activeSave={step == REGISTER_STEP.DST}
                            activeBackward={step != REGISTER_STEP.TIEPDON}
                            handleClose={() => handleClose()}
                            handleSaveAndNextStep={() => {
                                let isPrint = false;
                                let nextStep = step + 1;
                                handleSave(isPrint, nextStep);
                            }}
                            handleRetype={() => {
                                handleRetype();
                            }}
                            handleSavePrint={() => {
                                let isPrint = true;
                                handleSave(isPrint)
                            }}
                            handleSave={() => {
                                handleSave();
                            }}
                            handleBackward={() => {
                                handleBackward();
                            }} />
                    </div>
                </div>
            </div>
        </Fragment >
    );
})