import { Timeline, notification, Modal, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState, Fragment, useEffect,useCallback } from 'react';
import { FORMAT_DATE } from '../../configure/systemConfig';
import * as PatientService from '../../services/patientService';
import HeaderModal from '../../configureHIS/common/headerModal'
import DetailsHistory from './historydetails';
import { groupBy, isNullOrEmpty } from '../../libs/util';

export default History = (props) => {
    // Info data
    const [patientId, setPatientId] = useState('');
    // Config
    const [maxResult, setMaxResult] = useState(props.maxResult ? props.maxResult : 3);
    const [selectTop, setSelectTop] = useState(props.maxResult ? props.maxResult : 3);
    // Danh sách NhomVP
    const [featureSelected, setFeatureSelected] = useState(props.feature ? props.feature : []);
    // Danh sách LoaiVP
    const [featureTypeSelected, setFeatureTypeSelected] = useState(props.type ? props.type : []);
    // Data Source
    const [totalRecords, setTotalRecords] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    // Modal
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [selectedTran, setSelectedTran] = useState(null);

    useEffect(() => {
        setPatientId(props.patientID);
    }, [props.patientID])   

    useEffect(() => {
        if(patientId !== '')
        GetHistory();
    }, [patientId, selectTop])

    const GetHistory = () => {
        setLoading(true);
        const data = {
            PatientId : patientId,
            MaxResultCount : selectTop,
            listFeatureSelected : featureSelected,
            listFeatureType : featureTypeSelected
        }
        PatientService.GetPatientHistory(data).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data.items);
                setTotalRecords(result.data.totalCount);
                forceUpdate();
            } else {
                notification.error({ message: result.error.messageText });
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
                notification.error({ message: err.error.messageText });
            else notification.error({ message: err });
        }).finally(e =>{
            setLoading(false);
        })
    }

    const ShowMore = () => {
        setSelectTop(c => (c + maxResult));
    }

    const showDetailHistory = (row) => {
        if (props.iShowResult == false) return;
        setSelectedTran({
            maql : row.maQL,
            time : row.ngay
        })
        setVisibleDetail(true);
    }
    return (
        <Fragment>
        {dataSource && dataSource.length > 0 ?
            <div className='col-12'>
                <Timeline>
                    {dataSource && dataSource.map(x => {
                        return (
                            <Timeline.Item>
                                <a href="#" onClick={() => {showDetailHistory(x)}}>
                                    <label className="mita-title">{moment(x.ngay).format(FORMAT_DATE)}</label>
                                    <Tooltip title="Nguồn khách hàng" placement='right'>
                                        <label className='ml-2'>{x.coporation}</label>
                                    </Tooltip>
                                </a>
                                    {x.historyItems && x.historyItems.length > 0 && Object.keys(groupBy(x.historyItems, "tenNhom")).map(key => ({key, value : groupBy(x.historyItems, "tenNhom")[key]})).map(c =>{
                                        return (
                                            <div className='d-flex justify-content-between flex-column'>
                                                <div><b>{c.key}</b></div>
                                                {c.value.length > 0 && c.value.map((serv) => {
                                                    return (
                                                        <div className='d-flex flex-row rowCustom align-items-center' style={{ gap: 10 }}>
                                                            <div style={{ flexBasis: 150, flexGrow: 3, paddingLeft: 10 }}>
                                                                <label>{serv.ten}</label>
                                                            </div>
                                                            <div style={{ flexBasis: 100, flexGrow: 2 }} className='text-right'>
                                                                <label>
                                                                    {!isNullOrEmpty(serv.status) && <Tag color={serv.color ?? "default"}>{serv.status ?? "Chờ thực hiện"}</Tag>}
                                                                </label>
                                                            </div>
                                                            <div style={{ flexBasis: 50, flexGrow: 1 }}>
                                                                <label className='ml-2'>{moment(serv.ngayDangKy).format('HH:mm')}</label>
                                                                {/* <i className='fa fa-ellipsis-v'/> */}
                                                            </div>
                                                            {
                                                                props.showPaidStatus &&
                                                                <div style={{ flexBasis: 100, flexGrow: 2 }} className='text-right'>
                                                                    <Tag color={serv.paymentStatus?.color ?? "default"}>{serv.paymentStatus?.status ?? "Chưa có thông tin"}</Tag>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                            </Timeline.Item>
                        )
                    })}
                </Timeline>
                {totalRecords > selectTop && 
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                            <button className='btn btn-secondary w-50' onClick={() => {ShowMore()}}>Hiển thị thêm</button>
                        </div>
                    </div>
                }
            </div>
        :
            <div className='col-12 d-flex justify-content-center'>
                <p className='text-body'>Không có thông tin</p>
            </div>
        }

        {visibleDetail &&
            <Modal 
                maskClosable={true} 
                visible={visibleDetail}
                width={'90%'}
                placement="top"
            >
                <React.Fragment>
                    <HeaderModal title="Lịch sử khám" onClose={() => { setVisibleDetail(false); setSelectedTran(null) }} />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ flex: 1 }}>
                            {selectedTran && <DetailsHistory mabn={patientId} selectedTran={selectedTran} />}
                        </div>
                    </div>
                </React.Fragment>
            </Modal>
        }
        </Fragment>
    )
};
