import * as ApiCaller from '../libs/httpRequests';
const prefix = 'Employee';

export function DanhSachNhanVien(key,page,size,role)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachnhanvien?key=${key}&page=${page}&size=${size}&role=${role}`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function DanhSachVitri()  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/danhsachvitri`).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function ThemNhanVien(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/themnhanvien`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function CapNhatNhanVien(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/capnhatnhanvien`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function XoaNhanVien(data)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/xoanhanvien`,data).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function GetListEmployee(filterModel)  {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/GetListEmployee`, filterModel).then(respone =>{
            return resolve(respone) 
        }).catch(err =>{
            return reject(err) 
        })
    });
};

export function EmployeeListExport() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/EmployeeListExport`)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};