import * as ApiCaller from '../../libs/httpRequests';
const prefix='Coporation';

export function GetList(key, page, size, getAll) {
    if (getAll === undefined || getAll === "") getAll = false;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetListCoporation?key=${key}&page=${page}&size=${size}&all=${getAll}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetAllCoporation(key, status, page, size, getAll) {
    if (getAll === undefined || getAll === "") getAll = false;
    key = encodeURIComponent(key);
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetAllCoporation?key=${key}&status=${status}&page=${page}&size=${size}&all=${getAll}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CoporationDetail(key, managermentId = "") {
    let more = "";
    key = encodeURIComponent(key);
    if (managermentId !== "") more = `&maql=${managermentId}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/GetCoporationDetail?ma=${key}${more}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CoporationDetailByManagermentId(coprId, managermentId = "", chinhanh = "") {
    let more = "";
    if (managermentId !== "") more = `?maql=${managermentId}`;
    if (coprId !== "" && coprId != undefined && coprId !== null) {
        if (more !== "") more += `&ma=${coprId}`;
        else more += `?ma=${coprId}`;
    }
    if (chinhanh !== "") more += `&chinhanh=${chinhanh}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/CoporationDetailByManagermentId${more}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CreateCoporation(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPost(`${prefix}/CreateCoporation`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateCoporation(data) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateCoporation`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function DeleteCoporation(ma) {
    ma = encodeURIComponent(ma);
    return new Promise((resolve, reject) => {
        return ApiCaller.httpDelete(`${prefix}/DeleteCoporation/${ma}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function ResetPWCoporation(ma) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/ResetPWCoporation/${ma}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function HideCoporation(ma,status) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/HideCoporation?ma=${ma}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListAsync(sortingAndPagingModel = {pageIndex: 1, pageSize: 10, sorting: ''}, filterModel = {Keyword: ''}) {
    let skipCount = (sortingAndPagingModel.pageIndex-1)*sortingAndPagingModel.pageSize;
    let sortingAndPaging = `?skipCount=${skipCount}&maxResultCount=${sortingAndPagingModel.pageSize}&sorting=${sortingAndPagingModel.sorting}`;
    let filter = `&Keyword=${filterModel.Keyword}`;
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${sortingAndPaging + filter}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetByIdAsync(coporationId) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(`${prefix}/${coporationId}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function UpdateRegistedCoporation(data, maql) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPut(`${prefix}/UpdateRegistedCoporation/${maql}`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function CoporationListExport() {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostDownload(`${prefix}/CoporationListExport`)
        .then(respone => {
            return resolve(respone)
        })
        .catch(err => {
            return reject(err)
        })
    });
};