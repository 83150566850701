import React, { useState, useEffect, useRef } from 'react';
import { Input, Table, notification, Pagination, DatePicker, Checkbox, Modal, Tag, Button, Popconfirm, Tooltip, Select, Drawer } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as signalR from '@aspnet/signalr';
import * as Configure from '../../configure/urlConfig';
import * as Actions from '../../libs/actions';
import * as ConsultServies from "./consultservices";
import * as HotlineServices from "../hotline/hotlineservices";
import * as ChiDinhService from "../../services/chiDinhService";
import * as AppointmentService from "../../services/appointmentService";
import ModalCLS from '../../components/MDCLS';
import AddOrder from '../lichhenlaymau/addOrder';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import * as ConfigService from '../../services/configService';
import * as UsersService from '../../services/usersService';
import * as WorldfoneService from '../../services/worldfoneService';
import LoginWF from '../hotline/loginWF';
import { connect } from 'react-redux';
import * as SystemConfig from "../../configure/systemConfig";
import { IsActive_FeatureLH } from '../../configure/featureConfig';

import "./style.css";
import HeaderModal from '../../configureHIS/common/headerModal';
import PatientInfo from '../../configureHIS/common/patientinfo';
import { isNullOrEmpty } from '../../libs/util';
import { Header } from 'antd/lib/layout/layout';
import { ALLOW_COMBO } from '../../configureHIS/common/constants';
const { Option } = Select;
const { RangePicker } = DatePicker;

function TuVan(props) {
    const { TextArea } = Input;
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [key, setKey] = useState("");
    const [uncompleted, setUncompleted] = useState(false);
    const [date, setDate] = useState([moment(), moment()]);
    const [ListConsults, setListConsults] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const [info, setInfo] = useState({});
    const [detail, setDetail] = useState("");
    const [initDetail, setInitDetail] = useState("");
    const [ngayhen, setNgayhen] = useState(new Date());
    const [diffAddr, setDiffAddr] = useState(false);
    const [newAddress, setNewAddress] = useState("");
    const [editable, setEditable] = useState(false);
    const [dataOrder, setDataOrder] = useState([]);
    const [visibleOrder, setVisibleOrder] = useState(false);
    const OrderRef = useRef();
    const [showMore, setShowMore] = useState('');
    const [listSite, setListSite] = useState([]);
    const [chinhanh, setChinhanh] = useState('');
    const [isMultisite, setIsMultisite] = useState(false);
    const [isLoginWF, setIsLoginWF] = useState(false);
    const [titleWF, setTitleWF] = useState('');
    const [username,] = useState(localStorage.getItem("userID"));
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => ((currentPage - 1) * pageSize + Number(index) + 1),
            align: 'center',
            width: 60,
        },
        // {
        //     title: 'Ngày tiếp nhận',
        //     dataIndex: 'ngay',
        //     key: 'ngay',
        //     align: 'right',
        //     render: (date) => moment(date, 'DD/MM/YYYYY HH:mm:ss').format('HH:mm, DD/MM/YYYY')
        // },
        {
            title: 'Họ và tên',
            dataIndex: 'hoten',
            key: 'hoten',
            align: 'center',
            width: 240,
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'ngaysinh',
            key: 'ngaysinh',
            align: 'right',
            width: 90,
            render: (date, row) => row.onlyyear ? moment(date, "DD/MM/YYYY HH:mm:ss").format("YYYY") : moment(date, 'DD/MM/YYYY HH:mm:ss').format(SystemConfig.FORMAT_DATE)
        },
        {
            title: 'Giới tính',
            dataIndex: 'gioitinh',
            key: 'gioitinh',
            align: 'center',
            width: 80,
        },
        {
            title: 'Điện thoại',
            dataIndex: 'dienthoai',
            key: 'dienthoai',
            align: 'center',
            width: 110,
            render: (phone) => {
                return (
                    phone &&
                    <div className="phonenumber border border-success rounded text-success"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            navigator.clipboard.writeText(phone);
                            notification.info({ message: "Đã sao chép vào bộ nhớ tạm" })
                        }}>{phone}
                    </div>
                )
            }
        },
        {
            title: 'Ghi chú',
            key: 'ghichu',
            align: 'left',
            width: 300,
            render: (row) => {
                const note = row.ghichu;
                var value = note.indexOf("\n") === 0 ? note.substring(1, note.length) : note;
                const maxLength = 100;
                return (
                    <div>
                        <span style={{ whiteSpace: "pre-wrap" }}>{showMore === row.id ? value : value.substring(0, maxLength)}</span>
                        {note.length > maxLength && <span value={row.id} style={{ color: "blue", cursor: "pointer" }}
                            onClick={(e) => showMore === row.id ? setShowMore('') : setShowMore(row.id)}>{showMore === row.id ? " Thu gọn" : "...Xem thêm"}</span>}
                    </div>
                )
            }
        },
        // {
        //     title: 'Mức ưu tiên',
        //     dataIndex: 'urgent',
        //     key: 'urgent',
        //     align: 'center',
        // },
        {
            title: 'Trạng thái',
            key: 'status',
            align: 'center',
            width: 180,
            render: (row) => {
                return (
                    <span>{row.thoigiantuvan && moment(row.thoigiantuvan).year() !== 1 ?
                        <Tag color="green">{row.lenlich === 1 ? "Đã tư vấn và đặt lịch" : "Đã tư vấn"}</Tag> : <Tag color="red">Chờ</Tag>}
                    </span>
                )
            }
        },
        {
            title: 'Thao tác',
            key: 'action',
            align: 'center',
            render: (row) => {
                return (
                    <div style={{ lineHeight: 3 }}>
                        {/* <button className="btn btn-outline-success"
                            disabled={row.thoigiantuvan && moment(row.thoigiantuvan).year() !== 1}
                            onClick={() => { setVisibleModal(true); setInfo(row); setDetail(row.ghichu) }}><i className="fas fa-check"
                                style={{ marginRight: 5 }} />{row.thoigiantuvan && moment(row.thoigiantuvan).year() !== 1 ? "Đã tư vấn" : "Tiếp nhận"}
                        </button> */}
                        <button className="btn btn-outline-success" style={{ marginLeft: 10 }}
                            onClick={() => {
                                setVisibleModal(true);
                                setInfo(row);
                                setInitDetail(row.ghichu);
                                GetListOrderById(row.idlichhen, row.chinhanhlaymau)
                                row.ngayhen && moment(row.ngayhen).year() !== 1 ? setNgayhen(moment(row.ngayhen).toDate()) : new Date();
                                setDiffAddr(row.diachihen === "" ? true : false);
                                setNewAddress(row.diachihen);
                            }}>{row.thoigiantuvan && moment(row.thoigiantuvan).year() !== 1 ?
                                <span><i className="fas fa-edit" style={{ marginRight: 5 }} />Cập nhật</span>
                                : <span><i className="fas fa-check" style={{ marginRight: 5 }} />Tiếp nhận</span>}
                        </button>
                        <button className="btn btn-outline-success" style={{ marginLeft: 10 }} onClick={() => {
                            setInfo(row);
                            MakeCall(row);
                        }}><i className="fas fa-phone" /></button>
                        <Popconfirm title="Tiếp nhận sẽ bị hủy bỏ?" onConfirm={() => deleteTiepNhan(row.id, row.idlichhen, row.idhenkham, row.chinhanh, true)} okText="Đồng ý" cancelText="Hủy">
                            <button className="btn btn-outline-danger" style={{ marginLeft: 10 }}><i className="fas fa-trash" /></button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ];
    useEffect(() => {
        GetListConsults();
    }, [uncompleted, chinhanh]);
    
    const GetListSite = () => {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                setListSite(result.data);
            } else {
                setListSite([]);
            }
        }).catch(err => {
        })
    }
    useEffect(() => {
        setChinhanh(props.chinhanh)
        UsersService.CheckPremissionMultisite().then(result => {
            if (result.isSuccess) {
                setIsMultisite(result.item)
            }
            if (result.item == true) {
                GetListSite();
            }
        });
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(Configure.BASE_NOTIFY_URL + "/notify")
            .build();

        connection.on("notify", data => {
            if (data === 'tuvan') {
                GetListConsults();
            }
        })
        connection.start();
    }, []);

    useEffect(() => {
        setChinhanh(props.siteid);
    }, [props.siteid])
    const GetListConsults = (page = currentPage, size = pageSize, status = uncompleted) => {
        Actions.setLoading(true);
        ConsultServies.GetListConsults(date[0].format("DD/MM/YYYY"), date[1].format("DD/MM/YYYY"), key, page, size, status, chinhanh).then(res => {
            if (res.isSuccess) {
                setListConsults(res.data);
                setTotalRecords(res.totalRecords);
                setCurrentPage(page);
                setPageSize(size);
                setDate(date);
            }
            Actions.setLoading(false);
        }).catch(() => {
            Actions.setLoading(false);
        })
    }
    const updateTiepNhan = () => {
        const data = {
            ghichu: initDetail,
            type: "tuvan",
        }
        HotlineServices.UpdateDetailArrangerment(info.id, data, info.chinhanh).then(res => {
            if (res.isSuccess) {
                !editable && notification.success({ message: "Thành công" });
                GetListConsults(1)
            } else {
                notification.warning({ message: "Xảy ra lỗi khi cập nhật, hãy thử lại sau" });
            }
        }).catch(() => { })
        if (editable) {
            const scheduleInfo = {
                mabn: info.mabn,
                ngayhen: ngayhen,
                idlichhen: info.idlichhen,
                newAddress: newAddress,
                site: info.chinhanh.toString(),
            }
            if (info.idlichhen > 0) {
                HotlineServices.updateLichHen(info.idlichhen, scheduleInfo, info.chinhanhlaymau).then(res => {
                    if (!res.isSuccess) {
                        if (res.err.msgString)
                            notification.warning({ message: res.err.msgString })
                        else notification.warning({ message: "Không thể cập nhật lịch hẹn, vui lòng thử lại sau" });
                    } else {
                        HotlineServices.updateArrangerment(info.id, "lenlich", info.idlichhen, info.chinhanh, info.chinhanhlaymau);
                    }
                }).catch(() => { })
            } else {
                HotlineServices.saveLichHen(scheduleInfo).then(res => {
                    if (!res.isSuccess) {
                        notification.warning({ message: "Không thể lưu lịch hẹn, vui lòng thử lại sau" });
                    } else {
                        HotlineServices.updateArrangerment(info.id, "lenlich", res.returnObject.id, info.chinhanh, res.returnObject.chinhanh);
                        UpdateChidinh(res.returnObject.id, res.returnObject.chinhanh);
                    }
                }).catch(() => { })
            }
        }
    }
    const UpdateChidinh = (orderid) => {
        let lstSelect = dataOrder;
        if (!lstSelect || lstSelect.length === 0)
            return;
        for (let item of lstSelect) {
            item.ma = "0";
            // item.ten = item.name;
            item.madoituong = "2";
            // item.mavp = item.id.toString();
            // if (item.type === 3) {
            //     item.mavp = item.idvp.toString();
            // }
            item.giaaTH = item.dongia.toString();
            item.dongia = item.dongia;
            item.soluong = item.soluong;
            item.ngay = moment().format("YYYY-MM-DD HH:mm")
        }
        const data = {
            orderid: orderid,
            listChiDinh: lstSelect,
            patient: {
                code: info.mabn,
                subjectCode: '2',
            }
        }
        ChiDinhService.LuuChiDinh(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Lưu chỉ định thành công!' });
            } else {
                notification.warning({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    const GetListOrderById = (id, chinhanh) => {
        if (id > 0) {
            AppointmentService.GetListOrderById(id, chinhanh).then(result => {
                if (result.isSuccess) {

                    let data = result.data;
                    for (let item of data) {
                        item.listChild = item.listChildCd;
                    }
                    setDataOrder(data)
                } else {
                    Actions.openMessageDialog("Error " + result.err.msgCode, result.err.msgString.toString());
                }
            }).catch(err => {
                Actions.openMessageDialog("Error ", err);
            })
        }
    }
    const XoaChiDinh = (row) => {
        Actions.setLoading(true);
        let data = dataOrder;
        data = data.filter(x => x.id !== row.id)
        setDataOrder(data.map(x => ({ ...x })));
        ChiDinhService.XoaChiDinh(row.idChiDinh, row.chinhanh).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công" })
                if (Number(row.type) === 1) {
                    // if (modalCLS)
                    //     modalCLS.current.GetServicesHaveBeenSelected(true);
                    // if (reLoad) {
                    //     reLoad()
                    // }
                }
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
                // if (modalCLS) {
                //     modalCLS.current.GetServicesHaveBeenSelected(true);
                // }
                // if (reLoad) {
                //     reLoad()
                // }
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
            Actions.setLoading(false);
        })
    }
    const onClose = () => {
        setVisibleModal(false);
        setInfo([]);
        setEditable(false);
        setDetail("");
        setInitDetail("");
        setNgayhen(moment());
    }
    const deleteTiepNhan = (id, idlichhen, idhenkham, chinhanh, fromTuVan = true) => {
        HotlineServices.deleteTiepNhan(id, idlichhen, idhenkham ?? "", chinhanh, fromTuVan).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Đã hủy bỏ tiếp nhận" });
            } else {
                if (res.err.msgString) notification.error({ message: res.err.msgString });
                else notification.error({ message: "Đã xảy ra lỗi, vui lòng thử lại sau" });
            }
        }).catch(() => { })
    }
    const MakeCall = (row) => {
        if (row.dienthoai === '' || row.dienthoai.length < 5) {
            notification.error({ message: "Bệnh nhân không có số điện thoại." })
            return;
        }
        let tokenWF = localStorage.getItem('tokenWF');
        if (!tokenWF || tokenWF === '') {
            setIsLoginWF(true)
            return;
        }
        Actions.setLoading(true);
        WorldfoneService.makecall(row.dienthoai, row.hoten, tokenWF).then(result => {
            if (result.status !== 1) {
                setTitleWF("Vui lòng đăng nhập Worldfone")
                setIsLoginWF(true);
            }
            Actions.setLoading(false);
            Actions.OpenCallWorldfone(true, 'NewCall')
        }).catch(err => {
            Actions.setLoading(false);
            Actions.openMessageDialog("Thông báo", "Vui lòng bật Worldfone.")
        })
    }

    const onChangeDetail = () => {
        if (detail !== undefined && detail !== null && detail !== "" && detail != " ") {
            let data = initDetail ? `${initDetail}\n[${username}]: ${detail}` : `[${username}]: ${detail}`;
            setInitDetail(data);
            setDetail("");
        }
    }

    let regiterServiceColumn = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            // render: (text, record, index) => (Number(index) + 1),
            align: 'center',
            width: '8%',
            render: (text, record, index) => {
                return (record.isChild ? "" : (Number(index) + 1));
            },
        },
        {
            title: 'Mã',
            dataIndex: 'mavp',
            key: 'mavp',
            width: '10%'
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
            width: '40%'
        },
        {
            title: 'Giá',
            dataIndex: 'dongia',
            key: 'dongia',
            align: 'right',
            // render: (value) => (<span>{value ? value.toLocaleString('en-US') : '0'}</span>),
            width: 120,
            render: (value, row) => {
                return (row.isChild ? "" : (<span >{value.toLocaleString('en-US')}</span>))
            },
        },
        {
            title: '',
            align: 'center',
            width: '10%',
            render: (text, row, index) => { return (
                row.isChild ? "" : (editable &&
                    <Popconfirm
                        title="Thao tác xóa sẽ thực hiện xóa dữ liệu ngay lập tức. Bạn chắc chắn muốn xóa?"
                        onConfirm={() => { XoaChiDinh(row, index) }}
                        okText="Đồng ý" cancelText="Hủy" 
                        disabled={!editable}
                    >
                        <div style={{ cursor: 'pointer' }}
                            className="text-danger"
                            disabled={row.status === 'Đã đóng tiền'}
                            // onClick={() => {
                            // }} 
                        >
                            <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                        </div>
                    </Popconfirm>
                )
            )}
        }
    ];
    let dataSource = dataOrder.map(function (e, index) {
        let o = Object.assign({}, e);
        // o.key = index;
        o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString()+'-') : "") + o.id.toString();
        o.children = o.listChild;
        return o;
    })

    return (
        <React.Fragment>
            <div style={{ height: '100%' }}>
                <div className='containerChilderWrapper'>
                    <HeaderModal title="Tư vấn khách hàng" />
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                        {/* {isMultisite === true &&
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                allowClear={false}
                                value={chinhanh}
                                onChange={(e) => {
                                    setChinhanh(e)
                                }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={-1} value={''}>Chi nhánh</Option>
                                {listSite.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.id}>{row.ma}</Option>
                                    )
                                })}
                            </Select>
                        } */}
                        <Input className='mt-2' value={key} onChange={(e) => setKey(e.target.value)}
                            placeholder="Nhập tên bệnh nhân hoặc số điện thoại để tìm kiếm" style={{ width: "50%" }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") GetListConsults(1);
                            }}
                            suffix={<Checkbox checked={uncompleted} onChange={(e) => { setUncompleted(e.target.checked); }}>Chưa xử lý</Checkbox>} />
                        <div className='mt-2' style={{ width: "300", marginRight: 10 }}>
                            <RangePicker allowClear={false} style={{ marginLeft: 10 }} format={[SystemConfig.FORMAT_DATE, SystemConfig.FORMAT_DATE]} locale={viVN} value={[moment(date[0], "DD/MM/YYYY"), moment(date[1], "DD/MM/YYYY")]} onChange={(e) => setDate(e)} />
                        </div>
                        <button className="btn btn-color mt-2" style={{ marginLeft: 10 }} onClick={() => { GetListConsults(1) }}> <i style={{ marginRight: 5 }} className="fa fa-refresh" />Tìm kiếm</button>
                    </div>
                    <div style={{ height: 'calc(100% - 170px)', marginTop: 20 }}>
                        <Table
                            scroll={{ x: 'max-content', y: 0 }}
                            dataSource={ListConsults}
                            columns={columns}
                            pagination={false}
                            rowClassName={(row, rowIndex) => {
                                // if (moment(row.thoigiantuvan).year() === 1 && moment().diff(moment(row.ngay), 'minutes') > 30)
                                if (moment(row.thoigiantuvan).year() === 1 && row.priority)
                                    return "row-alert"
                            }}
                        />
                    </div>
                    <Pagination
                        style={{ textAlignLast: 'center', marginTop: 10 }}
                        total={totalRecords}
                        showTotal={total => `Tổng: ${total}`}
                        current={currentPage}
                        onChange={(page, size) => {
                            setCurrentPage(page);
                            setPageSize(size);
                            GetListConsults(page, size);
                        }}
                        onShowSizeChange={() => { }}
                        showSizeChanger={true}
                    />
                </div>
            </div>
            {visibleModal &&
                <Modal
                    // zIndex={9999}
                    visible={visibleModal} 
                    maskClosable={false}
                    onCancel={() => setVisibleModal(false)}
                    width={1000}
                    destroyOnClose={true}
                >
                    <HeaderModal title={`Tiếp nhận tư vấn: ${info.hoten}`} onClose={() => { onClose() }} />
                    <PatientInfo
                        patientInfo={
                            [
                                {
                                    label: "Mã khách hàng",
                                    value: info.mabn,
                                    column: 6,
                                },
                                {
                                    label: "Họ và tên",
                                    value: info.hoten,
                                    column: 6,
                                }, 
                                {
                                    label: info.onlyyear ? "Năm sinh" : "Ngày sinh",
                                    value: info.onlyyear ? moment(info.ngaysinh, "DD/MM/YYYY HH:mm:ss").format("YYYY") : moment(info.ngaysinh, "DD/MM/YYYY HH:mm:ss").format(SystemConfig.FORMAT_DATE),
                                    column: 6,
                                },
                                {
                                    label: "Giới tính",
                                    value: info.gioitinh,
                                    column: 6,
                                }, 
                                {
                                    label: "Điện thoại",
                                    value: info.dienthoai,
                                    column: 6,
                                }, 
                                {
                                    label: "Email",
                                    value: info.email,
                                    column: 6,
                                },
                                {
                                    label: "Địa chỉ",
                                    value: info.sonha,
                                    column: 12,
                                },
                            ]
                        }    
                    />
                    <div className="mt-2" >
                        <label>Ghi chú</label>
                        <TextArea rows={5} onChange={(e) => setDetail(e.target.value)} value={initDetail}
                            readOnly={true}
                            ></TextArea>
                    </div>
                    <div className="d-flex mt-2" >
                        <TextArea autoSize={{ minRows: 1, maxRows: 5 }}
                            onChange={(e) => setDetail(e.target.value)} value={detail}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && e.ctrlKey) {
                                    onChangeDetail();
                                }
                            }}
                            placeholder="Nhập ghi chú và bấm thêm hoặc bấm Ctrl+Enter"></TextArea>
                        <button style={{ width: 90 }} className={`btn ${!isNullOrEmpty(detail) ? "btn-warning-sparkle" : "btn-color"} ml-2`} onClick={() => { onChangeDetail() }}><i className="fa fa-plus mr-1"></i>Thêm</button>
                    </div>
                    {IsActive_FeatureLH() && 
                    <div className="mt-2">
                        <div className="d-flex mt-2">
                            <Checkbox checked={editable} onChange={() => setEditable(!editable)}>{info.idlichhen > 0 ? "Cập nhật lịch hẹn lấy mẫu tại nhà" : "Tạo lịch hẹn lấy mẫu tại nhà"}</Checkbox>
                        </div>
                        <div>
                            <label>{info.idlichhen > 0 ? "Ngày hẹn đã tạo" : "Ngày hẹn"}</label>
                            <DateTimePicker
                                className="w-100"
                                disabled={!editable}
                                locale="vi"
                                calendarIcon={<Tooltip title="Mở lịch"><i className='fas fa-calendar-week customclearicon' /></Tooltip>}
                                clearIcon={<Tooltip title="Chọn ngày hiện tại"><i className='fas fa-broom customclearicon' /></Tooltip>}
                                format={'dd-MM-yyyy HH:mm'}
                                isClockOpen={false}
                                isCalendarOpen={false}
                                onChange={setNgayhen}
                                minDate={moment(ngayhen).year() > 1 && moment(ngayhen) < moment() ? moment(ngayhen).toDate() : moment().toDate()}
                                value={ngayhen ? moment(ngayhen).toDate() : new Date()}
                            />
                        </div>
                        <div className="mt-2">
                            <label>Địa chỉ lấy mẫu</label>&nbsp;<i style={{ color: "#ccc" }}>{diffAddr ? "[Bấm vào \"Giống địa chỉ liên hệ\" để nhập địa chỉ mới]" : "[Bấm vào \"Địa chỉ mới\" để chọn địa chỉ liên hệ]"}</i>
                            <Input
                                placeholder={diffAddr ? "" : "Nhập địa chỉ lấy mẫu"}
                                value={diffAddr ? "" : newAddress}
                                disabled={diffAddr || !editable}
                                prefix={<button disabled={!editable} className={diffAddr ? "btn btn-secondary btn-sm mr-2" : "btn btn-success btn-sm mr-2"}
                                    onClick={(e) => { e.preventDefault(); setDiffAddr(!diffAddr); setNewAddress(""); }}>{diffAddr ? "Giống địa chỉ liên hệ" : "Địa chỉ mới"}</button>}
                                onChange={(e) => setNewAddress(e.target.value)}
                            />
                        </div>
                        <div className="mb-2">
                            <div className="border-register" style={{ background: '#fff' }}>
                                <div data-toggle="collapse" data-target="#chidinh" className="row no-gutters align-items-center">
                                    <div className="h4 font-weight-bold">Chỉ định ({!dataOrder ? 0 : dataOrder.reduce((tmp, cur) =>
                                        tmp + Number(cur.dongia), 0).toLocaleString('en-US')})</div>
                                    <Button className="btn btn-secondary ml-auto" disabled={!editable} onClick={e => {
                                        e.stopPropagation();
                                        setVisibleOrder(true);
                                    }}>Thêm/Sửa chỉ định</Button>
                                </div>
                                <div class="collapse" id="chidinh">
                                    <div className="table-responsive">
                                        <Table dataSource={dataSource}
                                            rowKey={(e) => e.id}
                                            columns={regiterServiceColumn}
                                            pagination={false}
                                            scroll={{ y: 0 }}
                                        />
                                        {/* <table class="table table-striped table-inverse table-hover">
                                            <thead class="thead-inverse">
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Mã</th>
                                                    <th>Tên</th>
                                                    <th>Giá</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataOrder && dataOrder.map((row, index) =>
                                                    <tr key={index}>
                                                        {(Number(info.idlichhen) > 0) ?
                                                            <React.Fragment>
                                                                <td>{index + 1}</td>
                                                                <td>{row.mavp}</td>
                                                                <td>{row.ten}</td>
                                                                <td>{row.dongia?.toLocaleString('en-US')}</td>
                                                                <td></td>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <td>{index + 1}</td>
                                                                <td>{row.mavp}</td>
                                                                <td>{row.ten}</td>
                                                                <td>{row.dongia?.toLocaleString('en-US')}</td>
                                                                <td>
                                                                    <div style={{ cursor: 'pointer' }}
                                                                        className="text-danger"
                                                                        onClick={() => {
                                                                            XoaChiDinh(row);
                                                                            if (OrderRef && OrderRef.current) {
                                                                                OrderRef.current.XoaChiDinh(row);
                                                                            }
                                                                        }} >
                                                                        <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                                                                    </div>
                                                                </td>
                                                            </React.Fragment>
                                                        }
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="d-flex justify-content-center w-100 mt-4">
                        <button className="btn btn-color" onClick={() => { 
                            updateTiepNhan()
                            onClose()
                            }}><i className="mr-2 fa fa-save"></i>Lưu</button>
                        <button className="ml-2 btn btn-danger" onClick={() => onClose()}><i className="mr-2 fas fa-sign-out-alt"></i>Đóng</button>
                    </div>
                </Modal>
            }
            <Modal
                width={1400}
                // zIndex={10000}
                maskClosable={false}
                visible={visibleOrder}
                onCancel={() => setVisibleOrder(false)}
                destroyOnClose={true}
            >
            {(Number(info.idlichhen) > 0) ?
                <AddOrder
                    ref={OrderRef}
                    chinhanh={info.chinhanhlaymau}
                    selectedPatient={{
                        mabn: info.mabn,
                        maql: info.maql,
                        ngayhen: info.ngayhen,
                        id: info.idlichhen?.toString()
                    }}
                    reLoad={() => { 
                        GetListOrderById(info.idlichhen, info.chinhanhlaymau) ;
                    }}
                    onClose={() => {
                        setVisibleOrder(false);
                    }}
                    disableSelectBangGia={false} />
                :
                <ModalCLS
                    showGoiKham={false}
                    showCombo={ALLOW_COMBO}
                    chinhanh={props.siteid}
                    ref={OrderRef}
                    onClose={(data, state) => {
                        setVisibleOrder(false);
                        if (state == 'save')
                            setDataOrder(data);
                    }}
                    editAfterRegister={true}
                    disableSelectBangGia={false}
                />
            }
            </Modal>
            <Modal
                visible={isLoginWF}
                onCancel={() => { setIsLoginWF(false) }}
            > <LoginWF title={titleWF}
                onClose={() => setIsLoginWF(false)}
                /></Modal>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true})(TuVan)
