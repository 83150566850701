import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, Fragment } from 'react';
import HeaderModal from '../common/headerModal';
import { Input, Modal, Pagination, Popconfirm, Select, Switch, Table, Tooltip, notification } from 'antd';
import { connect } from 'react-redux';
import { BUTTON } from '../common/constants';
import Notify, { NotifyStatus } from '../../components/notify';
import NoContent from "../../components/loading/noContent";
import * as ExtensionFieldService from '../../services/extensionFieldService';
import { setLoading } from '../../libs/actions';
import { ExtensionFields } from "../../configureHIS/common/constants";
import Create from './create';
import Update from './update';

const ExtensionField = forwardRef((props, ref) => {
    const _notify = useRef();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listData, setListData] = useState([]);
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [keyword, setKeyword] = useState();
    const [screen, setScreen] = useState([]);
    const [menuOption, setMenuOption] = useState([]);
    const [selectedData, setSelectedData] = useState({});

    useImperativeHandle(ref, () => ({
        notifyUpdate: () => {
            notifyUpdate();
        }
    }));

    const notifyUpdate = () => {

    }

    useEffect(() => {
        loadList();
        renderScreenList();
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'stt',
            key: 'stt',
            align: 'center',
            width: 90,
            render: (text, record, index) => {
                return <span>{(page - 1) * size + (index + 1)}</span>;
            }
        },
        {
            title: 'Table name',
            dataIndex: 'tableName',
            key: 'tableName',
            align: 'left',
            width: 150,
        },
        {
            title: 'Field name',
            dataIndex: 'fieldName',
            key: 'fieldName',
            align: 'left',
            width: 150,
        },
        {
            title: 'Field label',
            dataIndex: 'fieldLabel',
            key: 'fieldLabel',
            align: 'left',
            width: 150,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            width: 150,
        },
        {
            title: 'Screen',
            dataIndex: 'menuKey',
            key: 'menuKey',
            align: 'left',
            width: 200,
            render: (key) => {
                return <Tooltip title={`MenuKey: ${key}`}>{menuOption.find(x => x.menuKey == key)?.label}</Tooltip>
            }
        },
        {
            title: 'Data type',
            dataIndex: 'data_Type',
            key: 'data_Type',
            align: 'left',
            width: 100,
            render: (val) => {
                return ExtensionFields.DataType.find(x => x.value == val)?.label
            }
        },
        {
            title: 'UI type',
            dataIndex: 'ui_Type',
            key: 'ui_Type',
            align: 'left',
            width: 100,
            render: (val) => {
                return ExtensionFields.UIType.find(x => x.value == val)?.label
            }
        },
        {
            title: 'Partner',
            dataIndex: 'partner',
            key: 'partner',
            align: 'left',
            width: 150,
        },
        {
            title: 'Sorting',
            dataIndex: 'sorting',
            key: 'sorting',
            align: 'left',
            width: 90,
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            align: 'center',
            width: 100,
            render: (val) => {
                return <Switch checked={val} />
            }
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 200,
            render: (val, record) => {
                return (
                    <div>
                        <button className={`${BUTTON.WARNING}`} onClick={() => { setSelectedData(record); setVisibleUpdate(true); }}><i className='fas fa-pen mr-1' />Cập nhật</button>
                        <Popconfirm title="Bạn có chắc muốn xóa?"
                            okText="Đồng ý" cancelText="Hủy"
                            onConfirm={() => {
                                onDelete(record.id);
                            }}>
                            <button className={`${BUTTON.DANGER} ml-2`}><i className='fas fa-trash mr-1' />Xóa</button>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ];

    const loadList = (key = keyword, p = page, s = size) => {
        setLoading(true);
        const data = {
            keyword: key,
            skipCount: (p - 1) * s,
            maxResultCount: s,
            menuKeys: screen
        }
        ExtensionFieldService.getListExtensionField(data).then(res => {
            if (res.isSuccess) {
                _notify && _notify.current && _notify.current.Close();
                setListData(res.data.items);
                setTotalRecords(res.data.totalCount);
            } else {
                _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => setLoading(false))
    }

    const renderScreenList = () => {
        let listOpts = [];
        props.menuData && props.menuData.length > 0 && props.menuData.map((x, index) => {
            if (x.nodes && x.nodes.length > 0) {
                x.nodes.map((n, nInd) => {
                    if (n.nodes && n.nodes.length > 0) {
                        n.nodes.map((nc, ncInd) => {
                            listOpts.push(nc);
                        })
                    } else listOpts.push(n);
                })
            } else listOpts.push(x);
        })
        setMenuOption(listOpts);
    }

    const onDelete = (id) => {
        setLoading(true);
        ExtensionFieldService.deleteData(id).then(res => {
            if (res.isSuccess) {
                notification.success({ message: "Xóa thành công." })
                loadList();
            }
            else _notify && _notify.current && _notify.current.Show(res.error.messageText, NotifyStatus.Error);
        }).catch(err => {
            _notify && _notify.current && _notify.current.Show(err.error.messageText, NotifyStatus.Error);
        }).finally(() => setLoading(false))
    }

    const onOpenCreate = () => {
        setVisibleCreate(true);
    }

    return (
        <Fragment>
            <div className="list-page containerChilderWrapper">
                <HeaderModal title="Extension Field" />
                <div className="list-page-body">
                    <Notify ref={_notify} />
                    <div className="list-page-search">
                        <div className='d-flex flex-wrap justify-content-start align-items-end' style={{ gap: 10 }}>
                            <Select
                                className='w-100 mt-2'
                                style={{ flexGrow: 1, flexBasis: 200 }}
                                placeholder="Chọn màn hình"
                                onChange={(val) => setScreen(val)}
                                value={screen}
                                onClear={() => {
                                    setScreen();
                                }}
                                allowClear
                                showSearch
                                mode='multiple'
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {menuOption && menuOption.map((opt) => {
                                    return <Select.Option key={`${opt.menuKey}`} value={opt.menuKey}>{opt.label}</Select.Option>
                                })}
                            </Select>
                            <Input className='mt-2' style={{ flexGrow: 2, flexBasis: 400 }}
                                placeholder='Nhập từ khóa' value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key == "Enter") {
                                        loadList(e.target.value);
                                    }
                                }}
                            ></Input>
                            <button className={`${BUTTON.THEME} mt-2`} onClick={() => { loadList() }}><i className='fas fa-search mr-1' />Tìm</button>
                        </div>
                        <div>
                            <button className={`${BUTTON.THEME} mt-2`} onClick={() => onOpenCreate()}><i className='fas fa-plus mr-1' />Tạo mới</button>
                        </div>
                    </div>
                    <div className="list-page-table mt-2">
                        <Table style={{ height: "calc(100% - 45px)", width: "100%" }}
                            className="table-min-h-0"
                            dataSource={listData}
                            columns={columns}
                            locale={{ emptyText: <NoContent title="Không có dữ liệu phù hợp" message="Không có dữ liệu" /> }}
                            scroll={{ x: 0, y: 0 }}
                            rowKey={(e) => e.id}
                            pagination={false}
                        // pagination={{ showTotal: (total, range) => `Hiển thị:${range[0]}-${range[1]} Trên: ${total}` }}
                        />
                        <Pagination
                            current={page}
                            size={size}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => { setPage(page); setSize(size); loadList(keyword, page, size); }}
                            onShowSizeChange={(page, size) => { setPage(1); setSize(size); loadList(keyword, 1, size); }}
                            showSizeChanger={true}
                            locale={{ items_per_page: "kết quả / trang" }}
                        />
                    </div>
                </div>
            </div>
            <Modal
                open={visibleCreate}
                onCancel={() => setVisibleCreate(false)}
                destroyOnClose
                maskClosable={false}
            >
                <Create onClose={() => setVisibleCreate(false)} onSuccess={() => loadList(keyword, 1)} />
            </Modal>
            <Modal
                open={visibleUpdate}
                onCancel={() => setVisibleUpdate(false)}
                destroyOnClose
                maskClosable={false}
            >
                <Update data={selectedData} onClose={() => setVisibleUpdate(false)} onSuccess={() => loadList()} />
            </Modal>
        </Fragment>
    );
})

const mapStateToProps = (state) => {
    return ({
        menuData: state.global.menuData,
    })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ExtensionField)