import { DatePicker, Input, InputNumber, Switch, Select } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import React, { useState } from 'react';
import moment from "moment";
import * as SystemConfig from './systemConfig';
import { isNullOrEmpty } from "../libs/util";
// const DATE_FORMAT_SYSTEM = localStorage.getItem("dateFormat");
// const TIME_FORMAT_SYSTEM = localStorage.getItem("timeFormat");
// const regex = /\w/g;

export let GENERATE_EXTRAFIELD = (extensionFieldModel = {}, extraFieldsDic, onChangeExtraFields) =>{
    const [value, setValue] = useState(extraFieldsDic[extensionFieldModel.fieldName]);
    switch (extensionFieldModel.ui_Type) {
        case ('date'):
            return <DatePicker 
                className="w-100" 
                allowClear={true} 
                locale={viVN}
                format={SystemConfig.FORMAT_DATE} 
                placeholder={extensionFieldModel.fieldLabel}
                // value={value ? moment(value) : null} 
                value={extraFieldsDic[extensionFieldModel.fieldName] ? moment(extraFieldsDic[extensionFieldModel.fieldName]) : null} 
                onChange={(e) => {
                    setValue(e);
                    if (onChangeExtraFields) onChangeExtraFields(extensionFieldModel.fieldName, e ? moment(e) : null, extensionFieldModel);}
                }
            />
        case ('datetime'):
            return <DatePicker 
                className="w-100"
                allowClear={true}
                locale={viVN}
                // showNow={false}
                format = { SystemConfig.FORMAT_DATE_TIME }
                placeholder={extensionFieldModel.fieldLabel}
                value={extraFieldsDic[extensionFieldModel.fieldName] ? moment(extraFieldsDic[extensionFieldModel.fieldName]) : null} 
                onChange={(e) => { onChangeExtraFields(extensionFieldModel.fieldName, e ? moment(e) : null, extensionFieldModel);
                }}
            />
            break;
        case ('select'):
            return <Select
                className="w-100"
                allowClear
                showSearch
                filterOption={(input, option) => {
                // return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                placeholder={extensionFieldModel.fieldLabel}
                maxTagCount= 'responsive'
                value={extraFieldsDic[extensionFieldModel.fieldName]} 
                onChange={(value) => {onChangeExtraFields(extensionFieldModel.fieldName,value,extensionFieldModel);}}
            >
              {/* {(listCoporations ?? []).map((record, index) => {
                return (
                  <Option key={record.ma} value={record.ma}>{record.ten}</Option>
                )
              })} */}
            </Select>
        case ('inputnumber'):
            return <InputNumber 
                className="w-100" 
                value={extraFieldsDic[extensionFieldModel.fieldName]} 
                onChange={(e) => {onChangeExtraFields(extensionFieldModel.fieldName,e.target.value,extensionFieldModel);}}
                // min={1} max={9999999}
                // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                // parser={value => value.replace(/\$\s?|(,*)/g, '')} 
            />
        case ('input'):
            return <Input className="w-100"
                value={extraFieldsDic[extensionFieldModel.fieldName]} 
                placeholder={extensionFieldModel.fieldLabel}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChangeExtraFields(extensionFieldModel.fieldName,e.target.value,extensionFieldModel);
                }}
            />
        case ('switch'):
            return <Switch 
                loading={false} 
                // checked={extraFieldsDic[extensionFieldModel.fieldName]}
                onChange={(e) => {onChangeExtraFields(extensionFieldModel.fieldName,e,extensionFieldModel); }} 
            />
        default: 
            break;
    }
    return '';
};
//extraFieldsNameFromModelResponse là cái tên field/property của extraFields mà BE trả về.
export let GENERATE_EXTRAFIELD_COLUMNS = (extentionFields, extraFieldsNameFromModelResponse = 'extraFieldsDic') =>{
    let dt = [];
    (extentionFields ?? []).forEach(item => {
        dt.push(
        {
            title: item.fieldLabel,
            dataIndex: item.fieldName,
            key: item.fieldName,
            width: 160,
            render: (value, field) => {
                let val = (field[extraFieldsNameFromModelResponse] ?? {})[item.fieldName];
                switch (item.ui_Type) {
                    case ('date'):
                        return <span>{(!isNullOrEmpty(val) ? moment(val).format(SystemConfig.FORMAT_DATE) : '')}</span>
                    case ('datetime'):
                        return <span>{(!isNullOrEmpty(val) ? moment(val).format(SystemConfig.FORMAT_DATE_TIME) : '')}</span>
                    //cái select hơi khó chút vì có lưu value và display nha. Sau này gặp thì sửa sau.
                    case ('select'):
                        return <span>{val}</span>
                    case ('inputnumber'):
                        return <span>{Number(val).toLocaleString('en-US')}</span>
                    case ('input'):
                        return <span>{val}</span>
                    case ('switch'):
                        return <Switch 
                            loading={false} 
                            checked={val}
                            readonly
                        />
                    default: 
                        return '';
                }
            }
        });
    });
    return dt;
};