import React, { Fragment, useRef, useEffect, useState, useCallback } from 'react';
import Notify, { NotifyStatus } from '../../components/notify';
import { Menu, Dropdown, Drawer, Steps, Checkbox, DatePicker, Tag } from 'antd';
import PrintSIDComp from "../invoice/paymentmulti/InSIDBarcode";
import SidChange from '../../screens/xacnhanmau/sidChange';
import * as Actions from '../../libs/actions';
import * as TestReceivedServices from '../../services/testReceivedService';
import { isNullOrEmpty, renumber } from '../../libs/util';
import * as SystemConfig from "../../configure/systemConfig";
import moment from 'moment';
import HeaderModal from '../../configureHIS/common/headerModal';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import ActionControl from '../../libs/actionControl';
var ExamStatusFromLIS = {
    RECEIVED: 0,
    CANCEL: 1
}
function ThongTinLayMau(props) {
    const notiRef = useRef();
    const notiRefSec = useRef();
    const _btnSubmit = useRef();
    const [visiblePrint, setVisiblePrint] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [dataUpdateSID, setDataUpdateSID] = useState([]);
    const [listSample, setListSample] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [receivingAllowed, setReceivingAllowed] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [checkAllComplete, setCheckAllComplete] = useState(false);
    const [visibleEditTime, setVisibleEditTime] = useState(false);
    const [timeGet, setTimeGet] = useState(moment());
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const onCancel = () => {
        props.onCancel && props.onCancel();
    }
    const handleCloseDrawer = (reload = false) => {
        setVisibleEdit(false);
        setVisiblePrint(false);
        if (reload) {
            props.reload();
        }
    }
    useEffect(() => {
        getListDetail(props.data.maQL, props.data.orderId, props.data.siteId);
    }, [props.data.maQL, props.data.orderId, props.data.siteId]);
    useEffect(() => {
        _btnSubmit && _btnSubmit.current && _btnSubmit.current.focus();
    }, [receivingAllowed])
    const SIDOption = () => {
        return (
            <Menu
                items={[
                    {
                        label:
                            <a href="#" onClick={() => {
                                handleOpenEditSID(props.data.maQL);
                                let data = {
                                    patientID: props.data.maBN,
                                    managementID: props.data.maQL,
                                    sid: props.data.sid,
                                    orderId: props.data.orderId,
                                }
                                setDataUpdateSID(data);
                            }}><i className="fas fa-pen mr-2"></i>{props.data.sid ? "Cập nhật SID" : "Tạo"}</a>,
                        key: 'updatesid',
                    },
                    {
                        label:
                            <a href="#" onClick={() => {
                                handleOpenPrintSID(props.data);
                            }}><i className='fa fa-print mr-2' />In</a>,
                        key: 'printsid',
                    },
                ]}
            />
        )
    }
    const handleOpenPrintSID = (data) => {
        if (data.sid != undefined) {
            setVisiblePrint(true);
            notiRef.current && notiRef.current.Close();
        }
        else notiRef.current.Show("Chưa chọn SID", NotifyStatus.Warning);
    }
    const handleOpenEditSID = (maql) => {
        if (maql != undefined) {
            setVisibleEdit(true);
            notiRef.current && notiRef.current.Close();
        }
        else notiRef.current.Show("Chưa chọn SID", NotifyStatus.Warning);
    }
    const getListDetail = (managementId = props.data.maQL, orderId = props.data.orderId, siteId = props.data.siteId) => {
        Actions.setLoading(true);
        if (managementId && siteId) {
            TestReceivedServices.getListConfirmTestReceived(managementId, orderId, siteId).then(result => {
                if (result.isSuccess && result.data.length > 0) {
                    setTotalCount(result.data.length);
                    setReceivingAllowed(result.data.find(x => x.receivingAllowed));
                    const resultGroup = result.data.reduce(function (r, a) {
                        r[a.loaiTen] = r[a.loaiTen] || [];
                        r[a.loaiTen].push(a);
                        return r;
                    }, Object.create(null));
                    const resultGroupArr = Object.keys(resultGroup).map((key) => ({ key, key, value: resultGroup[key] }));
                    setListSample(renumber(resultGroupArr));
                } else {
                    notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
                }
            }).catch(err => {
                if (err.error && err.error.messageText)
                    notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            }).finally(() => {
                forceUpdate();
                Actions.setLoading(false);
            });
        }
    }

    const stepItems = [
        {
            title: <label style={{ width: 135 }}>In tem & dán ống mẫu</label>,
            icon: <i className="fas fa-barcode"></i>,
        },
        {
            title: 'Lấy mẫu',
            icon: <i className="fas fa-tint"></i>,
        }
    ];

    const createUpdateThoiGianLayMau = (isUpdate) => {
        if (isUpdate && (timeGet == null || timeGet == undefined)) {
            notiRefSec && notiRefSec.current.Show("Chưa chọn thời gian", NotifyStatus.Warning);
            return;
        }
        Actions.setLoading(true);
        notiRef && notiRef.current && notiRef.current.Close();
        notiRefSec && notiRefSec.current && notiRefSec.current.Close();
        var danhSachUpdate = isUpdate ?
            listSample.flatMap(x => x.value).filter(x => x.checked).map(x => x.idChiDinh.toString())
            : !checkAll ? listSample.flatMap(x => x.value).filter(x => x.checked && isNullOrEmpty(x.ngayGioLayMau)).map(x => x.idChiDinh.toString()) : [];
        const data = {
            maQuanLy: props.data.maQL,
            chiNhanh: props.data.siteId,
            danhSachDichVu: danhSachUpdate,
            ngayGioLayMau: timeGet,
        }
        TestReceivedServices.createUpdateThoiGianLayMau(data).then(result => {
            if (result.isSuccess) {
                notiRef.current.Show(`${isUpdate ? "Cập nhật thời gian lấy mẫu" : "Lấy mẫu"} thành công`, NotifyStatus.Success);
                setVisibleEditTime(false);
                resetState();
            } else
                notiRef.current.Show(result.error.extensionData ?? result.error.msgString, NotifyStatus.Error);
        }).catch(err => {
            if (err.msgString && err.msgString)
                notiRef.current.Show(err.msgString, NotifyStatus.Error);
        }).finally(() => {
            Actions.setLoading(false);
            props.onScrollToTop && props.onScrollToTop();
        });
    }

    const onChangeSelectAll = (e, complete = false) => {
        setIndeterminate(false);
        if (complete) {
            const data = listSample.map(x => ({ ...x, value: x.value && x.value.map(i => ({ ...i, checked: !isNullOrEmpty(i.ngayGioLayMau) ? e.target.checked : false })) }));
            setListSample(data);
            setCheckAllComplete(e.target.checked);
            setIndeterminateStatus(data);
        } else {
            setCheckAllComplete(false);
            setCheckAll(e.target.checked);
            const data = listSample.map(x => ({ ...x, value: x.value && x.value.map(i => ({ ...i, checked: e.target.checked })) }));
            setListSample(data);
        }
        reRenderView();
    }

    const onChangeSelectItem = (e, indexParent, indexChild) => {
        setCheckAllComplete(false);
        if (listSample) {
            const isChecked = e.target.checked;
            const data = listSample;
            let foundItem = data[indexParent].value[indexChild];
            if (foundItem) {
                foundItem.checked = isChecked;
            }
            setListSample(data);
            setIndeterminateStatus(data);
            reRenderView();
        }
    }

    const setIndeterminateStatus = (data) => {
        const dataFlat = data.flatMap(x => x.value);
        const foundListCheck = dataFlat.filter(x => x.checked);
        const checkAll = foundListCheck && foundListCheck.length == dataFlat.length;
        setCheckAll(checkAll);
        const isExistUncheck = foundListCheck && foundListCheck.length > 0 && foundListCheck.length < dataFlat.length;
        setIndeterminate(isExistUncheck);
    }

    const reRenderView = () => {
        forceUpdate();
        notiRef && notiRef.current && notiRef.current.Close();
    }

    const onUpdateDateGet = () => {
        const isUpdate = true;
        createUpdateThoiGianLayMau(isUpdate);
    }

    const resetState = () => {
        setTimeGet(moment());
        setCheckAll(false);
        setCheckAllComplete(false);
        setIndeterminate(false);
        getListDetail();
        forceUpdate();
    }

    const getTrangThai = () => {
        let status = "Chờ lấy mẫu";
        const dataFlat = listSample.flatMap(x => x.value);
        const foundListDone = dataFlat.filter(x => !isNullOrEmpty(x.ngayGioLayMau));
        if (dataFlat.length == foundListDone.length)
            status = "Đã lấy mẫu"
        return status;
    }

    const atLeastOneChecked = () => {
        return listSample && listSample.flatMap(x => x.value).filter(x => x.checked).length > 0;
    }

    const atLeastOneGet = () => {
        return listSample && listSample.flatMap(x => x.value).filter(x => x.ngayGioLayMau).length > 0;
    }

    return (
        <Fragment>
            <div className='list-page'>
                <div className="list-page-body">
                    <div className="w-100 mt-2"><Notify ref={notiRef} /></div>
                    <div className='row d-flex flex-row'>
                        <div className='col-md-6 d-flex flex-column'>
                            <label className='mita-title'>Nguồn khách hàng</label>
                            <label className='mita-value'>{props.data?.tenNguonKhachHang ?? ""}</label>
                        </div>
                        <div className='col-md-6 d-flex flex-column'>
                            <label className='mita-title'>Trạng thái</label>
                            <label className='mita-value'>{getTrangThai()}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                            <Steps
                                style={{ width: "60%" }}
                                current={1}
                                labelPlacement="vertical">
                                {
                                    stepItems && stepItems.map((item, index) => {
                                        return (
                                            <Steps.Step key={index} title={item.title ?? ""} description={item.description ?? ""} icon={item.icon ?? ""}></Steps.Step>
                                        )
                                    })
                                }
                            </Steps>
                        </div>
                    </div>
                    <div className='row d-flex flex-column'>
                        <div className='col-12'>
                            <label className='mita-title'>Chi tiết các dịch vụ</label>
                            <div>
                                {/* <Popover title={`${!atLeastOneGet() ? "Không có dịch vụ nào đã lấy mẫu" : ""}`}>
                                    <Checkbox disabled={!atLeastOneGet()} checked={atLeastOneGet() && checkAllComplete} style={{ width: 30, }} onChange={(e) => { onChangeSelectAll(e, true) }}></Checkbox>
                                </Popover> */}
                                <a href='#' onClick={() => {
                                    if (atLeastOneChecked()) {
                                        setVisibleEditTime(true);
                                    } else {
                                        notiRef && notiRef.current.Show("Chưa chọn dịch vụ nào hoặc không có dịch vụ nào đã lấy mẫu", NotifyStatus.Warning);
                                    }
                                }}><i className='far fa-clock mr-1' />Cập nhật ngày giờ lấy mẫu</a>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive" style={{ overflowY: "auto", height: "calc(100vh - 365px)" }}>
                        <table className="table table-striped table-inverse table-hover">
                            <thead className="text-white">
                                <tr>
                                    <th style={{ width: 30 }}>
                                        <Checkbox
                                            checked={checkAll}
                                            indeterminate={indeterminate}
                                            onChange={(e) => {
                                                onChangeSelectAll(e);
                                            }}
                                        ></Checkbox>
                                    </th>
                                    <th style={{ width: 60 }}>#</th>
                                    <th>Tên dịch vụ</th>
                                    <th style={{ width: 150, textAlign: 'center' }}>Ngày giờ lấy mẫu</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listSample.map((row, index, arr) => {
                                    return <Fragment key={index}>
                                        <tr key={index} className='not-hover'>
                                            <td style={{ width: 30 }}></td>
                                            <td colSpan={4}
                                            >{row.key}</td>
                                        </tr>
                                        {
                                            row.value.map((r, ind) => {
                                                return (
                                                    <tr key={r.vienPhiId} onClick={() => onChangeSelectItem({ target: { checked: !r.checked ?? true } }, index, ind)}>
                                                        <td style={{ width: 30 }}>
                                                            {r.cancel == 0 &&
                                                            <Checkbox
                                                                checked={r.checked ?? false}
                                                                onChange={(e) => {
                                                                    onChangeSelectItem(e, index, ind);
                                                                }}
                                                            ></Checkbox>
                                                            }
                                                        </td>
                                                        <td style={{ width: 60 }}>{r.stt}</td>
                                                        <td>{r.vienPhiTen} 
                                                            {r.comboName && r.comboName !== '' && <Tag color="volcano">{r.comboName}</Tag>}
                                                            {r.cancel == 1 && <Tag className='ml-1' color="red">Hoàn trả</Tag>}
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {r.ngayGioLayMau ? moment(r.ngayGioLayMau).format(SystemConfig.FORMAT_DATE_TIME) : ""}
                                                        </td>
                                                    </tr>)
                                            })
                                        }
                                    </Fragment>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="list-page-footer mt-2">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                        <Dropdown overlay={() => SIDOption()} trigger={['click']} placement="topRight">
                            <button className='btn btn-secondary'><i className="fas fa-barcode mr-1" />SID<i className="fas fa-angle-right ml-1" /></button>
                        </Dropdown>
                        <div>
                            <button className="btn btn-color" ref={_btnSubmit} onClick={() => { createUpdateThoiGianLayMau() }}>Lấy mẫu</button>
                            <button className="btn btn-danger ml-2" onClick={() => onCancel()}><i className="fas fa-times-circle mr-1" />Đóng</button>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                closable={false}
                width={"40%"}
                placement="right"
                visible={visiblePrint}>
                <PrintSIDComp billSelected={props.data.sid}
                    patientInfo={{
                        patientId: props.data.maBN,
                        patientName: props.data.hoTen,
                        dob: props.data.onlyYear ? props.data.namSinh : moment(props.data.ngaySinh).format(SystemConfig.FORMAT_DATE),
                        gender: props.data.phai === 1 ? "Nữ" : "Nam",
                    }}
                    onSubmit={() => handleCloseDrawer()}
                    onCancel={() => handleCloseDrawer()} />
            </Drawer>
            <Drawer className='custom-ant-drawer'
                closable={false}
                width={"40%"}
                visible={visibleEdit}>
                <div style={{ lineHeight: "20px" }}>
                    <SidChange data={dataUpdateSID} onCancel={(reload) => handleCloseDrawer(reload)}></SidChange>
                </div>
            </Drawer>
            <Drawer
                className='custom-ant-drawer'
                closable={false}
                width={700}
                visible={visibleEditTime}>
                <div className='list-page'>
                    <div className="list-page-body">
                        <HeaderModal title="Cập nhật ngày giờ lấy mẫu" onClose={() => { setVisibleEditTime(false) }} />
                        <Notify ref={notiRefSec} />
                        <label className='mita-title'>Chọn ngày giờ</label>
                        <DatePicker
                            className='w-100'
                            locale={{
                                ...viVN,
                                lang: {
                                    ...viVN.lang,
                                    ok: "Xong",
                                }
                            }}
                            format={SystemConfig.FORMAT_DATE_TIME}
                            onChange={(e) => setTimeGet(e)}
                            value={timeGet}
                            defaultValue={moment()}
                            disabledDate={(date) => date && date > moment()}
                            showTime
                            getPopupContainer={tg => tg.parentNode}
                            allowClear={false}

                        ></DatePicker>
                        {listSample && <div className='mt-2'>
                            <label className='mita-title'>Đã chọn cập nhật cho:</label>
                            <ol type='1'>
                                {listSample.flatMap(x => x.value).filter(x => x.checked).map((x, index) => {
                                    return (
                                        <li key={`${index}-${x.vienPhiId}`}>{x.vienPhiTen}</li>
                                    )
                                })}
                            </ol>
                        </div>}
                    </div>
                    <div className="list-page-footer d-flex justify-content-between mt-2">
                        <div className="w-100">
                            <ActionControl
                                activeUpdate={true}
                                handleUpdate={() => onUpdateDateGet()}
                                handleClose={() => { setVisibleEditTime(false); }}
                            />
                        </div>
                    </div>
                </div>
            </Drawer>
        </Fragment >
    );
}

export default ThongTinLayMau;