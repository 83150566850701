import { Input, Tooltip, notification, Select, Checkbox, } from 'antd';
import React, { Fragment, useEffect, useRef, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import * as KhoService from '../../../../services/KhoService';
import AutocompletetableNoGroup from '../../../../components/autocompletetable/autocompletetableNoGroup';
import { DanhSachNhanVien } from '../../../../services/employeeService';
import * as XuatKhoService from '../../../../services/xuatkhoService';
import EditableTable from '../../../../commons/editableTable';
import { ValidationCustom } from '../../../../commons/validationCustom';
import SimpleReactValidator from 'simple-react-validator';
import * as PrintService from '../../../../services/printService';
import * as Actions from '../../../../libs/actions';
import * as SystemConfig from "../../../../configure/systemConfig";
import Notify, { NotifyStatus } from '../../../../components/notify';
import HeaderModal from '../../../../configureHIS/common/headerModal';
import { USER_AUTH } from '../../../../configureHIS/common/constants';
import { UserPermission } from '../../../../commons/utils';

const { Option } = Select;
const { TextArea } = Input;
const FEATURE_NAME = "warehouse";
const SCREEN_NAME = "WarehouseOut";

export default forwardRef((props, ref) => {
    const [isUpdate, setIsUpdate] = useState(props.isUpdate || false);
    const [isPreview, setIsPreview] = useState(props.isPreview || false);
    const [nguoiXuat, setNguoiXuat] = useState(localStorage.getItem("name"));
    const [ngayXuat, setNgayXuat] = useState(new Date());
    const [listKho, setListKho] = useState([]);
    const [kho, setKho] = useState(null);
    const [listXuat, setListXuat] = useState([]);
    const [mucDichXuat, setMucDichXuat] = useState(null);
    const [IDNguoiNhan, setIDNguoiNhan] = useState(null);
    const [tenNguoiNhan, setTenNguoiNhan] = useState(null);
    const [noiNhan, setNoiNhan] = useState(null);
    const [lyDoXuat, setLyDoXuat] = useState([]);
    const [IDPhieuXuat, setIDPhieuXuat] = useState(null);
    const [soPhieu, setSoPhieu] = useState(null);
    const [keySearch, setKeySearch] = useState("");
    const [listWarehousePermission, setListWarehousePermission] = useState([]);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const ThongTinNguoiNhan = useRef();
    const tableListXuat = useRef();
    const searchThuocVatTu = useRef();
    const notiRef = useRef(null);
    const notiRefSecond = useRef(null);

    const validator = useRef(new SimpleReactValidator(ValidationCustom));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
        setUpdateState: (data) => {
            setUpdateState(data);
        },
    }));

    const listMucDichXuat = [
        {
            index: 1,
            value: "noibo",
            label: "Nội bộ",
        },
        {
            index: 2,
            value: "khac",
            label: "Khác",
        }
    ]

    const columnsNguoiNhan = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: 'Tên',
            dataIndex: 'hoten',
            key: 'hoten',
            width: 200,
        },
        {
            title: 'Bộ phận',
            dataIndex: 'vitri',
            key: 'vitri',
            width: 180,
        },
    ]

    const columnsTableListXuat = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: 60,
            render: (text, record, index) => (Number(index) + 1),
        },
        ...(isPreview ? [] : [{
            title: '',
            align: 'center',
            width: 60,
            render: (item) => {
                return (
                    <Checkbox checked={item.selected} onChange={() => handleSelectItem(item)} />
                )
            }
        }]),
        {
            title: 'Mã thuốc - vật tư',
            dataIndex: 'ma',
            key: 'ma',
            align: 'center',
            width: 110
        },
        {
            title: 'Tên thuốc - vật tư',
            dataIndex: 'ten',
            key: 'ten',
            align: 'left',
            width: 180
        },
        {
            title: 'Hoạt chất',
            dataIndex: 'hopchat',
            key: 'hopchat',
            align: 'left',
            width: 100
        },
        {
            title: 'ĐVT',
            dataIndex: 'donvi',
            key: 'donvi',
            align: 'center',
            width: 80
        },
        {
            title: 'Quy cách',
            dataIndex: 'quycach',
            key: 'quycach',
            align: 'left',
            width: 100,
        },
        {
            title: 'Số lượng xuất',
            dataIndex: 'soluong',
            key: 'soluong',
            align: !isPreview ? 'right' : "center",
            editable: !isPreview ? true : false,
            type: 'number',
            width: 100,
            insideWidth: 80,
            maxLength: 10,
            min: 0,
            max: 'soluongton',
            render: (value) => {
                return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
            },
        },
        {
            title: 'Hết hạn',
            dataIndex: 'nghh',
            key: 'nghh',
            width: 80,
            render: (value) => {
                return <span>{(value !== "" && value != null && value !== undefined && moment(value).year() != 1) ? moment(value).format(SystemConfig.FORMAT_DATE) : ""}</span>;
            }
        },
        {
            title: 'Lô',
            dataIndex: 'losx',
            key: 'losx',
            align: 'left',
            width: 90,
        },
        {
            title: 'Bảo quản',
            dataIndex: 'baoquan',
            key: 'baoquan',
            align: 'left',
            width: 100,
        },
    ]

    const columnsThuocVatTu = [
        {
            title: 'Tên thuốc - vật tư',
            dataIndex: 'ten',
            key: 'ten',
            align: 'left',
            width: 300
        },
        {
            title: 'Mã thuốc',
            dataIndex: 'ma',
            key: 'ma',
            align: 'left',
            width: 100
        },
        {
            title: 'Hoạt chất',
            dataIndex: 'hopchat',
            key: 'hopchat',
            align: 'left',
            width: 100
        },
        {
            title: 'ĐVT',
            dataIndex: 'donvi',
            key: 'donvi',
            align: 'left',
            width: 80
        },
        {
            title: 'Nước sản xuất',
            dataIndex: 'nuocsanxuat',
            key: 'nuocsanxuat',
            align: 'left',
            width: 120
        },
        {
            title: 'Mã lô',
            dataIndex: 'losx',
            key: 'losx',
            align: 'left',
            width: 120,
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 'nghh',
            key: 'nghh',
            align: 'right',
            width: 100,
            render: (value) => value ? moment(value).format(SystemConfig.FORMAT_DATE) : ""
        },
        {
            title: 'Tồn kho',
            dataIndex: 'soluongton',
            key: 'soluongton',
            align: 'right',
            width: 90,
        },
    ]

    const loadlistKho = () => {
        KhoService.GetListKho("all").then(result => {
            let listOwnWarehouse = [...new Set((listWarehousePermission ?? []).filter(x => x.permission.includes(USER_AUTH.WarehouseOut.Create)).map(x => (x.warehouse)))];
            if (result.isSuccess) {
                const owner = isSuperAdmin ? result.data : result.data.filter(x => listOwnWarehouse.includes(x.value));
                setListKho(owner);
                !isPreview && setKho(owner[0]?.value ?? null);
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu kho bị lỗi' });
        })
    }

    const loadNguoiNhan = (key) => {
        if (key === null || key === undefined) key = "";
        return DanhSachNhanVien(key, 1, 10, '');
    }

    const selectNguoiNhan = (item) => {
        if (item !== undefined) {
            setIDNguoiNhan(item.id);
            setTenNguoiNhan(item.hoten);
            setMucDichXuat("noibo");
            setNoiNhan(item.vitri);
        }
    }

    const loadTonKho = (searchText = "") => {
        if (searchText === null || searchText === undefined) searchText = "";
        notiRefSecond && notiRefSecond.current.Close();
        let resultReturn = XuatKhoService.GetSLTonKhoByName(kho, searchText);
        return resultReturn;
    }

    const handleSelectItem = (item) => {
        if (item) {
            let data = listXuat;
            let foundItemIndex = data.findIndex(x => x.id === item.id && x.losx === item.losx);
            if (foundItemIndex !== -1) {
                data[foundItemIndex].selected = !data[foundItemIndex].selected;
                handleChangeListXuat(data);
            }
        }
    }

    const handleChangeListXuat = (data) => {
        let newData = data.map(function (e, index) {
            let o = Object.assign({}, e);
            return o;
        })
        if (newData) {
            tableListXuat && tableListXuat.current.setDataSource(newData);
            setListXuat(newData);
        }
    }

    const addItem = (item) => {
        notiRefSecond && notiRefSecond.current.Close();
        if (item) {
            let data = listXuat;
            let foundItem = listXuat.find(x => x.id === item.mabd && x.losx === item.losx && x.idtheodoi === item.idtheodoi);
            if (foundItem !== undefined) {
                notiRefSecond.current.Show("Thuốc - vật tư đã tồn tại.", NotifyStatus.Warning)
            } else {
                setKeySearch(item.ten);
                searchThuocVatTu.current && searchThuocVatTu.current.setValue("");
                notiRefSecond && notiRefSecond.current.Close();
                data.unshift({
                    soluong: item.soluongton,
                    selected: false,
                    soluongton: item.soluongton,
                    makho: item.makho,
                    manguon: item.manguon,
                    idPhieu: item.idPhieu,
                    idtheodoi: item.idtheodoi,
                    id: item.mabd,
                    ma: item.ma,
                    donvi: item.donvi,
                    ten: item.ten,
                    losx: item.losx,
                    ngaynhap: moment(item.ngaynhap).format('YYYY/MM/DD'),
                    nghh: item.nghh ? moment(item.nghh, SystemConfig.FORMAT_DATE).format('YYYY/MM/DD') : null,
                    key: item.mabd + item.losx,
                    quycach: item.quycach,
                    baoquan: item.baoquan,
                    hopchat: item.hopchat,
                })
                handleChangeListXuat(data);
            }
        } else {
            notiRefSecond.current.Show("Không thể thêm thuốc - vật tư.", NotifyStatus.Error)
        }
    }

    const saveOrUpdatePhieuXuat = () => {
        if (validator.current.allValid()) {
            notiRef && notiRef.current.Close();
            if (listXuat.length === 0) {
                notiRefSecond.current.Show("Không có thuốc - vật tư trong danh sách cần xuất.", NotifyStatus.Error)
                return;
            }
            let invalidDate = false;
            let errList = "";
            listXuat.sort(function (a, b) { return moment(a).diff(moment(b)) }).forEach(item => {
                if (moment(item.ngaynhap) > moment(ngayXuat)) {
                    if (errList !== "") errList += ", \n";
                    errList += `Ngày xuất kho phải sau ngày ${moment(item.ngaynhap).format(SystemConfig.FORMAT_DATE)} (là ngày nhập kho của thuốc - vật tư: ${item.ten})`
                    invalidDate = true;
                }
            })
            if (invalidDate) {
                notiRefSecond.current.Show(errList, NotifyStatus.Warning)
                return;
            }
            let errQuantity = listXuat.find(x => x.soluong <= 0);
            listXuat.map(x => {
                let quantity_array = x.soluong.toString().split('.');
                if(quantity_array.length > 1 && quantity_array[1].length > 2){
                    errQuantity = x
                }
            })
            if (errQuantity) {
                notiRefSecond.current.Show(`Số lượng không hợp lệ: ${errQuantity.ten}.`, NotifyStatus.Error)
                return;
            }
            Actions.setLoading(true);
            const hoaDonXuat = {
                'makho': kho,
                'lydo': lyDoXuat,
                'ngaynhap': moment(ngayXuat).format("YYYY-MM-DD"),
                'makhonhap': 0,
                'mucdich': mucDichXuat,
                'tennguoinhan': tenNguoiNhan,
                'idnguoinhan': IDNguoiNhan ? IDNguoiNhan : 0,
                'noinhan': noiNhan,
            }
            for (let record of listXuat) {
                if (record.nghh === "") record.nghh = null
            }
            const data = {
                hoaDonXuat,
                lstAddThuoc: listXuat,
            }
            if (!isUpdate) {
                XuatKhoService.LuuDonXuat(data).then(result => {
                    if (result.isSuccess) {
                        setIDPhieuXuat(result.id);
                        setSoPhieu(result.returnObject.sophieu);
                        setIsUpdate(true);
                        notiRefSecond.current.Show("Lưu thành công.", NotifyStatus.Success)
                    }
                    else {
                        notiRefSecond.current.Show(result.err.msgString, NotifyStatus.Error);
                    }
                }).catch(err => {
                    notiRefSecond.current.Show("Lưu dữ liệu thất bại.", NotifyStatus.Error);
                }).finally(() => {
                    Actions.setLoading(false);
                })
            } else {
                XuatKhoService.editXuatKho(IDPhieuXuat, data).then(result => {
                    if (result.isSuccess) {
                        setIDPhieuXuat(result.id);
                        notiRefSecond.current.Show("Cập nhật thành công.", NotifyStatus.Success)
                    }
                    else {
                        notiRefSecond.current.Show(result.err.msgString, NotifyStatus.Error);
                    }
                }).catch(err => {
                    notiRefSecond.current.Show("Cập nhật dữ liệu thất bại.", NotifyStatus.Error);
                }).finally(() => {
                    Actions.setLoading(false);
                })
            }
        } else {
            validator.current.showMessages();
            notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
            forceUpdate();
        }
    }

    const deleteThuocVatTuXuat = () => {
        let dataDelete = listXuat.filter(x => x.selected === true && x.selected !== undefined);
        if (dataDelete) {
            handleChangeListXuat(listXuat.filter(x => x.selected === false || x.selected === undefined));
            notiRefSecond.current.Show(`Đã xóa ${dataDelete.length} thuốc - vật tư.`, NotifyStatus.Success)
        }
        if (dataDelete.length === 0 || dataDelete === undefined) {
            notiRefSecond.current.Show("Chưa chọn thuốc - vật tư cần xóa.", NotifyStatus.Warning)
        }
    }

    const printPhieuXuat = () => {
        if (IDPhieuXuat) {
            PrintService.PrintPhieuXuatKho(IDPhieuXuat, moment(ngayXuat).format("YYYY-MM-DD")).then((result) => {
                Actions.DoPrint(result.item)
            })
        } else {
            notiRefSecond.current.Show("Không có thông tin để in.", NotifyStatus.Error)
        }
    }

    const onclose = () => {
        props.onCancel();
        setIsUpdate(false);
        setKeySearch("");
        setIsPreview(false);
        notiRef && notiRef.current.Close();
        notiRefSecond && notiRefSecond.current.Close();
    }

    useEffect(() => {
        getPermission();
    }, [])

    const getPermission = () => {
        const dataPermission = UserPermission(FEATURE_NAME);
        setListWarehousePermission(dataPermission.listWarehousePermission);
        setIsSuperAdmin(dataPermission.isSuperAdmin);
    }

    useEffect(() => {
        loadlistKho();
    }, [listWarehousePermission])

    useEffect(() => {
        if (!isPreview) {
            handleChangeListXuat([]);
            setKeySearch("");
            searchThuocVatTu.current && kho && searchThuocVatTu.current.handleSearch([]);
        }
    }, [kho, isPreview])

    const setUpdateState = (data) => {
        setIsPreview(true);
        if (data) {
            const dataInfo = data.hoadonxuat;
            const dataXuat = data.lstAddThuoc;
            setSoPhieu(dataInfo.sophieu);
            setIDPhieuXuat(dataInfo.id);
            setKho(Number(dataInfo.makho));
            setNguoiXuat(dataInfo.nguoinhap);
            setTenNguoiNhan(dataInfo.tennguoinhan);
            setLyDoXuat(dataInfo.lydo);
            setMucDichXuat(dataInfo.mucdich);
            setNoiNhan(dataInfo.noinhan);
            setNgayXuat(moment(dataInfo.ngaynhap).toDate());
            handleChangeListXuat(dataXuat);
        }
    }

    return (
        <Fragment>
            <HeaderModal title={`${isPreview ? "Chi tiết" : "Tạo mới"} - Phiếu xuất kho`} onClose={() => { props.onCancel()}} />
            <Notify ref={notiRef} />
            <div className='row'>
                <div className='col-md-4 mt-2'>
                    <label>Mã phiếu xuất</label>
                    <Input disabled={isPreview} placeholder='Tạo tự động từ hệ thống' readOnly style={{ cursor: 'not-allowed' }} value={soPhieu}></Input>
                </div>
                <div className='col-md-4 mt-2'>
                    <label>Người xuất</label>
                    <Input disabled={isPreview} placeholder='Người xuất' value={nguoiXuat} readOnly style={{ cursor: 'not-allowed' }}></Input>
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='required-field'>Ngày xuất kho</label>
                    <DateTimePicker
                        disabled={isPreview} 
                        className="w-100"
                        locale='vi'
                        format={'dd-MM-yyyy'}
                        isClockOpen={false}
                        isCalendarOpen={false}
                        calendarIcon={<Tooltip title="Mở lịch"><i className='far fa-calendar-alt customclearicon' /></Tooltip>}
                        clearIcon={null}
                        onChange={(e) => setNgayXuat(moment(e).toDate())}
                        maxDate={moment().toDate()}
                        value={ngayXuat ? ngayXuat : new Date()} />
                    {validator.current.message("Ngày xuất kho", ngayXuat, "required")}
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='required-field'>Kho xuất</label>
                    <Select
                        disabled={isPreview}
                        className='w-100'
                        value={kho}
                        onChange={(e) => {
                            setKho(e);
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={trigger => trigger.parentNode}
                    >
                        {listKho.map((row, index) => {
                            return (
                                <Option key={index} value={row.value}>{row.label}</Option>
                            )
                        })}
                    </Select>
                    {validator.current.message("Kho xuất", kho, "selection")}
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='required-field'>Mục đích xuất</label>
                    <Select
                        disabled={isPreview}
                        className='w-100'
                        value={mucDichXuat}
                        onChange={(e) => {
                            setMucDichXuat(e);
                            setTenNguoiNhan(null);
                            setIDNguoiNhan(null);
                            setNoiNhan(null);
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={trigger => trigger.parentNode}
                    >
                        {listMucDichXuat.map((row, index) => {
                            return (
                                <Option key={index} value={row.value}>{row.label}</Option>
                            )
                        })}
                    </Select>
                    {validator.current.message("Mục đích xuất", mucDichXuat, "selection")}
                </div>
                <div className='col-md-4 mt-2'>
                    <label className='required-field'>Người nhận</label>
                    <AutocompletetableNoGroup
                        disabled={isPreview}
                        ref={ThongTinNguoiNhan}
                        placeholder={"Nhập tên người nhận"}
                        width='100%'
                        tablewidth={500}
                        onSearch={(e) => loadNguoiNhan(e)}
                        onSelect={(e) => selectNguoiNhan(e)}
                        headers={columnsNguoiNhan}
                        keyValue={'id'}
                        value={tenNguoiNhan}
                        onChange={(e) => { setTenNguoiNhan(e) }}
                        showRemove={IDNguoiNhan !== '' && IDNguoiNhan !== undefined && IDNguoiNhan !== null && !isPreview && !isUpdate}
                        CleanText={() => {
                            setIDNguoiNhan(null);
                            setTenNguoiNhan(null);
                        }}
                    />
                    {validator.current.message("Tên người nhận", tenNguoiNhan, "selection")}
                </div>
                <div className='col-md-12 mt-2'>
                    <label className='required-field'>Nơi nhận</label>
                    <Input disabled={isPreview} value={noiNhan} onChange={(e) => setNoiNhan(e.target.value)} placeholder="Nhập nơi nhận" />
                    {validator.current.message("Nơi nhận", noiNhan, "required")}
                </div>
                <div className='col-md-12 mt-2'>
                    <label className='required-field'>Lý do xuất</label>
                    <TextArea disabled={isPreview} size={2} value={lyDoXuat} onChange={(e) => setLyDoXuat(e.target.value)} placeholder="Nhập lý do xuất" />
                    {validator.current.message("Lý do xuất", lyDoXuat, "required")}
                </div>
            </div>
            <div className="modal-header fit-content" style={{ display: 'flex', justifyContent: 'left' }}>
                <h5>Chi tiết phiếu xuất</h5>
            </div>
            <Notify ref={notiRefSecond} />
            <div className='row'>
                {!isPreview && <React.Fragment>
                    <div className='col-md-12 mt-2'>
                        <AutocompletetableNoGroup
                            ref={searchThuocVatTu}
                            placeholder="Tìm kiếm theo tên, mã thuốc - vật tư, hoạt chất, lô"
                            width='100%'
                            onSearch={(e) => !isPreview && loadTonKho(e)}
                            onSelect={(e) => addItem(e)}
                            headers={columnsThuocVatTu}
                            keyValue={'idtheodoi'}
                            title="tbl-search"
                            value={keySearch}
                            listHeight={400}
                            keepTransfrom={true}
                        />
                    </div>
                    <div className='col-md-12 mb-2 mt-2'>
                        <button
                            className="btn btn-danger"
                            onClick={() => { deleteThuocVatTuXuat() }}>
                            <i className="far fa-trash-alt mr-2" />Xóa hạng mục
                        </button>
                    </div>
                </React.Fragment>}
                <div className="col-md-12">
                    <EditableTable
                        ref={tableListXuat}
                        height={"300px"}
                        scrollY={'45vh'}
                        onchange={(data) => { handleChangeListXuat(data) }}
                        dataSource={listXuat}
                        columns={columnsTableListXuat}
                        pagination={false}
                        rowClassName={(record) => {
                            return `${(record && record.isNew === true) ? 'animate__animated animate__fadeInLeft' : ''}`
                        }}
                        keyFind={'ma'}
                        keyFind2={'idtheodoi'}
                    ></EditableTable>
                </div>
            </div>
            <div className='custom-hr'></div>
            <div className='row'>
                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <button className="btn btn-color" onClick={() => { printPhieuXuat() }}>
                        <i className="fa fa-print mr-2" />In
                    </button>
                    <div>
                        {/* Đóng tạm thời chức năng cập nhật */}
                        {/* <button className="btn btn-color" onClick={() => { saveOrUpdatePhieuXuat() }}>
                            <i className="fa fa-save mr-2" />{!isUpdate ? "Lưu" : "Cập nhật"}
                        </button> */}
                        {!isPreview && <button className="btn btn-color" onClick={() => { saveOrUpdatePhieuXuat() }} disabled={isUpdate || isPreview}>
                            <i className="fa fa-save mr-2" />Lưu
                        </button>}
                        <button className="btn btn-danger ml-2" onClick={() => { onclose() }}>
                            <i className="fas fa-sign-out-alt mr-2" />Đóng
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
})