import { Button, DatePicker, Form, Modal, notification, Select, Table, Tooltip } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import * as Actions from '../../libs/actions';
import * as AppointmentService from '../../services/appointmentService';
import * as CommonService from '../../services/commonService';
import * as ChiDinhService from '../../services/chiDinhService';
import * as CorporationServices from '../corporation/CorporationServices';
import Administrative from '../recept/components/administrative';
import ModalCLS from '../../components/MDCLS';
import * as CollaboratorsServices from '../nhomctv/nhomctvservices';
import SelectProductAutocomplete from '../../components/MDCLS/selectProductAutocomplete';
import * as ConfigService from '../../services/configService';
import NguonKhachHang from "../../screens/recept/register/nguonkhachhang";
import { IsActive_FeatureLCTV } from '../../configure/featureConfig';
import { COMBO, ALLOW_COMBO } from '../../configureHIS/common/constants';
import HeaderModal from '../../configureHIS/common/headerModal';
import { FORMAT_DATE_TIME } from '../../configure/systemConfig';

const initialState = {
    mabn: '',
    mabs: '',
    machidinh: '',
    maphongkham: '',
    ngayhen: moment(),
    loaiChiDinh: '',
    tenchidinh: '',
    hoten: '',
}
export default class TaoLichHen extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            ...initialState,
            lstChiDinh: [],
            lstBN: [],
            lstDoctor: [],
            lstPhong: [],
            visible: false,
            newPatient: false,
            idGiaLayMau: 0,
            dataOrder: [],
            visibleOrder: false,
            currentPage: 1,
            pageSize: 10,
            banggia: 'default',
            banggiaNguonKhacHang: '',
            banggiaSite: '',
            chinhanh: this.props.chinhanh,
            listSite: [],
            site: '',
            isUpdate: this.props.isUpdate ? this.props.isUpdate : false
        }
    }
    componentWillMount() {
        this.DanhSachPhongKham();
        this.GetListGiaLayMau();
        this.DanhSachBacSi();
        this.GetListSite();
        this.setState({
            chinhanh: this.props.chinhanh,
            site: this.props.chinhanh
        })
        if (this.props.patientID) {
            this.GetPatientById(this.props.patientID);
        }
        ConfigService.BangGiaDefaultSite(this.props.chinhanh).then((respone) => {
            if (respone.isSuccess) {
                this.setState({
                    banggiaSite: respone.item ? respone.item : '',
                });
                this.setBangGia(this.state.banggiaNguonKhacHang, respone.item ? respone.item : '');
            }
        }).catch(err => { })
    }
    componentDidMount() {
    }
    DanhSachPhongKham() {
        CommonService.GetDepartment().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhong: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu phòng khám bị lỗi!' })
        })
    }
    DanhSachBacSi() {
        CommonService.GetListDoctor().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstDoctor: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi!' })
        })
    }

    DanhSachChiDinh(key) {
        AppointmentService.AllService(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstChiDinh: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu chỉ định lỗi!' })
        })
    }
    TimKiemBenhNhan(key) {
        AppointmentService.TimKiemBN(key).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBN: result.data,
                });
            }
        }).catch(err => {
        })
    }

    reset() {
        setTimeout(() => {
            if (this.form)
                this.form.resetFields()
        }, 20)

        this.setState({
            ...initialState,
            lstChiDinh: [],
            mabs: '',
            maphongkham: '',
            visible: false,
            dataOrder: []
        })
        this.form.setFieldsValue({
            mabn: null,
            mabs: null,
            phongkham: null,
            ngayhen: null,
            maphongkham: null,
        });
        Actions.ResetBarCode(true);
        this.administrative.resetState();
    }
    async SaveLichHen() {
        Actions.setLoading(true);
        if (this.state.ngayhen === null || this.state.ngayhen === undefined) {
            notification.warning({ message: 'Chưa chọn ngày hẹn khám!' });
            Actions.setLoading(false);
            return;
        }
        let numberError = 0;
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        let newPatient = (this.state.mabn === null || this.state.mabn === undefined || this.state.mabn === "");
        if (newPatient) {
            await this.administrative.getForm().validateFields().then(values => {
                if (values.errorFields)
                    numberError += values.errorFields.length

            }).catch((ex) => {
                if (ex.errorFields)
                    numberError += ex.errorFields.length
            })
        }
        if (numberError === 0) {
            let dataAdministrative = {};
            dataAdministrative = this.administrative.getState();
            let dataNguonKhachHang = this.nguonKhachHang && this.nguonKhachHang.getState();
            let lstSelect = this.state.dataOrder;
            let listChiDinh = [];
            for (let item of lstSelect) {
                item.ma = "0";
                item.madoituong = "2";
                item.giaaTH = item.dongia.toString();
                item.dongia = item.dongia;
                item.soluong = item.soluong;
                item.ngay = moment().format("YYYY-MM-DD HH:mm")
                if (listChiDinh.filter(x => x.id == item.id).length > 0) {
                    notification.warning({ message: `Dịch vụ [${item.ten}] bị trùng` });
                    return;
                }
                listChiDinh.push(item);
                if (item.isCombo)
                    item.listChild.forEach(xChild => {
                        let itemChild = xChild;
                        itemChild.giaaTH = xChild.dongia.toString();
                        itemChild.dongia = xChild.dongia;
                        itemChild.soluong = xChild.soluong;
                        itemChild.ngay = moment().format("YYYY-MM-DD HH:mm");
                        itemChild.vienPhiIdOfCombo = item.id;

                        if (listChiDinh.filter(x => x.id == itemChild.id).length > 0) {
                            notification.warning({ message: `Dịch vụ [${itemChild.ten}] nằm trong combo [${item.ten}] bị trùng` });
                            return;
                        }

                        listChiDinh.push(itemChild);
                    });
            }
            const data = {
                mabn: this.state.mabn.toString(),
                mabs: this.state.mabs,
                machidinh: Number(this.state.machidinh),
                phongkham: this.state.maphongkham,
                tenchidinh: this.state.tenchidinh.toString(),
                ngayhen: this.state.ngayhen,
                newPatient: newPatient,
                patient: {
                    'name': dataAdministrative.name.toUpperCase(),
                    'dob': moment(dataAdministrative.dob).format('YYYY-MM-DD'),
                    'age': dataAdministrative.age,
                    'gender': dataAdministrative.gender,
                    'people': "25",
                    'cmnd': dataAdministrative.cmnd,
                    'phone': dataAdministrative.phone,
                    'email': dataAdministrative.email,
                    'address': dataAdministrative.address,
                    'city': dataAdministrative.city ? dataAdministrative.city.toString() : '',
                    'district': dataAdministrative.district ? dataAdministrative.district.toString() : '',
                    'ward': dataAdministrative.ward ? dataAdministrative.ward.toString() : '',
                    'jobAddress': dataAdministrative.jobAddress,
                    'village': dataAdministrative.village !== undefined ? dataAdministrative.village : '',
                    'country': dataAdministrative.nation,
                    'dateInHospital': moment().format('YYYY/MM/DD'),
                    'timeInHospital': moment().format('HH:mm'),
                    'department': this.state.maphongkham,
                    'subjectCode': "2",
                    'coporator': dataAdministrative.coporator,
                    'sendby': dataAdministrative.sendby,
                    'onlyyear': dataAdministrative.onlyyear,
                    'year': dataAdministrative.year,
                },
                lstChiDinh: listChiDinh,
                idsale: dataNguonKhachHang.idsale ? dataNguonKhachHang.idsale.toString() : "",
                otCopor: dataNguonKhachHang.coporator ? dataNguonKhachHang.coporator : "",
                manguoigioithieu: dataNguonKhachHang.idngt ? dataNguonKhachHang.idngt.toString() : "",
                site: this.state.site.toString(),
            }
            AppointmentService.LuuLichHen(data).then(result => {
                if (result.isSuccess) {
                    notification.success({ message: 'Lưu thành công!' })
                    this.reset();
                    this.props.onCancel();
                } else {
                    Actions.openMessageDialog("Lỗi", result.err.msgString);
                }
                Actions.setLoading(false);
            }).catch(err => {
                notification.warning({ message: 'Cập nhật thông tin bị lỗi!' });
                Actions.setLoading(false);
            })
        }
        else {
            Actions.setLoading(false);
            this.validator.showMessages();
        }
    }
    disabledDate(current) {
        return current.valueOf() < Date.now();
    }
    showModal = () => {

        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.form.setFieldsValue({
            city: null,
            district: null,
            ward: null,
            mabs: null,
            phongkham: null,
        });
        this.setState({
            visible: false,
            disabledUpdate: true,
            visibledUpdate: false,
            visibledSave: true
        });
    };
    handleLoadPatientBarCode(data) {
        this.setState({ mabn: data.patientID, hoten: data.patientName, address: data.address });
        this.form.setFieldsValue({ mabn: `${data.patientID}-${data.patientName}` })
    }

    GetListGiaLayMau = () => {
        AppointmentService.GetListGiaLayMau(true).then(result => {
            if (result.isSuccess) {
                if (result.data.length > 0) {
                    this.form.setFieldsValue({ idGiaLayMau: result.data[0].id })
                    this.setState({ idGiaLayMau: result.data[0].id })
                }
                this.setState({ listGiaLayMau: result.data })
            }
            else {
                notification.error({ message: result.err.msgString });
            }
            Actions.setLoading(false);
        }).catch(err => {
            notification.error({ message: err });
        })
    }
    XoaChiDinh(row) {
        let data = this.state.dataOrder;
        data = data.filter(x => x.id !== row.id)
        this.setState({ dataOrder: data.map(x => ({ ...x })) })
    }
    getListCollaboratorsGroup = () => {
        CollaboratorsServices.GetListGroup("", 1, -1).then(res => {
            if (res.isSuccess) {
                this.setState({
                    lstNhomCTV: res.data
                });
            } else {
                notification.warning({ message: "Không thể lấy thông tin nhóm" })
            }
        }).catch(err => console.log(err))
    }

    GetListSite() {
        ConfigService.GetListSite(false).then(result => {
            if (result.isSuccess) {
                this.setState({ listSite: result.data });
            } else {
                this.setState({ listSite: [] });
            }
        }).catch(err => {
        })
    }

    getColumn = () => {
        var columns = [
            {
                title: 'STT',
                dataIndex: 'index',
                key: 'index',
                width: 60,
                align: 'center',
                render: (text, record, index) => {
                    return (record.isChild ? "" : (Number(index) + 1));
                },
            },
            {
                title: 'Mã DV',
                dataIndex: 'mavp',
                key: 'mavp',
                width: 100,

            },
            {
                title: 'Tên DV',
                dataIndex: 'ten',
                key: 'ten',
                width: 240,

            },
            {
                title: 'Đơn giá',
                dataIndex: 'dongia',
                key: 'dongia',
                align: 'right',
                width: 120,
                render: (value, row) => {
                    return (row.isChild ? "" : (<span >{value.toLocaleString('en-US')}</span>))
                },
            },
            {
                title: '',
                key: '',
                align: 'center',
                render: (text, record) => {
                    return (
                        record.isChild ? "" : (
                            <div style={{ cursor: 'pointer' }}
                                className="text-danger"
                                onClick={() => {
                                    this.XoaChiDinh(record);
                                    if (this.OrderRef)
                                        this.OrderRef.XoaChiDinh(record);
                                }} >
                                <span style={{ width: '100%', height: '100%', marginTop: 3 }}> <i className="fa fa-trash"></i></span>
                            </div>
                        )
                    )
                }
            }
        ]
        return columns;
    }

    searchIndication(key) {
        return ChiDinhService.searchSerivce(0, 0, this.state.banggia, key, 1, 20);
    }

    onSelectIndication(item) {
        if (!ALLOW_COMBO && item.groupId == COMBO) {
            Actions.openMessageDialog("Thông báo", "Hiện tại không thể chọn Combo ở màn hình này");
            return;
        }
        const { dataOrder } = this.state;
        var existed = dataOrder.find(x => x.id === item.id);
        if (existed === undefined) {
            item.banggia = this.state.banggia;
            item.soluong = 1;
            let total = 0;
            dataOrder.push(item)
            dataOrder.map(x => total += (x.dongia * x.soluong) * ((100 - x.discount) / 100))
            this.setState({
                dataOrder: dataOrder,

            })
        }
    }
    loadCoporationDetail(id) {
        CorporationServices.CoporationDetail(id).then(result => {
            if (result.isSuccess) {
                if (result.banggia !== '') {
                    this.setState({
                        banggia: result.item.banggia,
                    })
                }
            }
        })
    }

    checkBangGia = (info) => {
        let { banggia } = this.state;
        if (info.banggia !== banggia) {
            if (this.state.dataOrder.length > 0) {
                Actions.openMessageDialog("Cảnh báo",
                    `Việc thay đổi bảng giá mới (<b>${info.banggia === "default" ? "Mặc định" : info.banggia}</b>) sẽ xóa tất cả dịch vụ đã chọn ứng với bảng giá cũ (<b>${banggia === "default" ? "Mặc định" : banggia}</b>)`)
                this.setState({
                    dataOrder: [],
                });
                this.OrderRef && this.OrderRef.UpdateSelectedFromProps([]);
            }
            this.setState({ banggia: info.banggia });
        }
    }

    setBangGia = (bangGiaNguonKH, bangGiaSite) => {
        let banggiatemp = bangGiaNguonKH;
        if (banggiatemp === undefined || banggiatemp === null || banggiatemp === '')
            banggiatemp = bangGiaSite;
        if (banggiatemp === undefined || banggiatemp === null || banggiatemp === '')
            banggiatemp = 'default';
        if (banggiatemp !== this.state.banggia)
            this.checkBangGia({ banggia: banggiatemp });
    }

    GetPatientById = (maBN) => {
        CommonService.GetPatientDetail(maBN).then(result => {
            if (result.isSuccess) {
                setTimeout(() => {
                    this.administrative && this.administrative.handleLoadPatientBarCode(result.item);
                }, 200);
            } else {
                notification.warning({ message: result.err.msgString })
            }
        }).catch(err => {
            notification.warning({ message: err })
        })
    }

    //#region SelectProductAutocomplete
    GetAllService() { //lấy all chỉ định (ra cùng cấp)
        let list = [];
        (this.state.dataOrder ?? []).forEach(item => {
            list.push(item);
            if (item.isCombo) {
                item.listChild.forEach(xChild => {
                    xChild.vienPhiTenOfCombo = item.ten;
                    xChild.key = `${item.id}-${xChild.id}`;
                    list.push(xChild);
                });
            }
        });
        return list;
    }
    onSelectIndications(products = []) {
        let listSelectedServices = [...this.state.dataOrder];
        let listAll = this.GetAllService();
        let total = 0;
        let isUpdateList = true;
        products.filter(x => x.isInBangGia).forEach(item => {
            var existed = listAll.find((x) => x.id === item.id);
            if (existed === undefined) {
                let itemConvert = this.convertToModelSaveNew(item);
                if (item.isCombo) {
                    itemConvert.listChild = [];
                    item.listChild.forEach(xChild => {
                        let itemChild = this.convertToModelSaveNew(xChild);
                        var existedChild = listAll.find((x) => x.id === itemChild.id);
                        if (existedChild !== undefined) {
                            if (existedChild.isChild)
                                notification.warning({ message: `Dịch vụ [${existedChild.ten}] đã tồn tại trong combo [<b>]${existedChild.vienPhiTenOfCombo}]` });
                            else notification.warning({ message: `Dịch vụ [${existedChild.ten}] đã tồn tại` });
                            isUpdateList = false;
                            return;
                        }
                        itemChild.vienPhiIdOfCombo = item.id;
                        itemChild.isChild = true;
                        itemChild.key = `${item.id}-${itemChild.id}`;
                        itemConvert.listChild.push(itemChild);
                    });
                }
                listSelectedServices.push(itemConvert);
            }
            else {
                if (existed.isChild)
                    notification.warning({ message: `Dịch vụ [${existed.ten}] đã tồn tại trong combo [${existed.vienPhiTenOfCombo}]` });
                else notification.warning({ message: `Dịch vụ [${existed.ten}] đã tồn tại` });
                isUpdateList = false;
                return;
            }
        })
        listSelectedServices.map(
            (x) => (total += (x.dongiakm && x.dongiakm < x.dongia ? x.dongiakm : x.dongia) * x.soluong * ((100 - x.discount) / 100))
        );

        if (isUpdateList) {
            this.setState({
                dataOrder: listSelectedServices,
                total: total,
            });
        }
    }
    convertProductToSelectComponent(arrProduct) {
        return arrProduct?.map(x => {
            let tenKhoaPhong = ((x.nhomId == 8 && (x.tenkp || x.tenPhongKham)) ? `${x.tenkp} - ${x.tenPhongKham}` : '');
            let listChild = [];
            if (x.listChild && x.listChild.length > 0) {
                listChild = this.convertProductToSelectComponent(x.listChild);
            }
            return {
                id: x.vienPhiId,
                ma: x.vienPhiMa,
                ten: x.vienPhiTen,
                allowDelete: x.allowDelete,
                idChiDinh: x.idChiDinh,
                uiStatus: 'selected',
                uiDeleted: false,
                gia_Th: x.donGia,
                tenKhoaPhong: tenKhoaPhong,
                statusText: x.status_Text,
                paid: x.paid,
                listChild: listChild,
            }
        })
    }
    convertToModelSaveNew(x) {
        return {
            banggia: this.state.banggia,
            soluong: 1,
            idChiDinh: "0",
            madoituong: "2",
            giaaTH: x.gia_Th.toString(),
            dongia: x.gia_Th,
            ngay: moment().format('YYYY/MM/DD HH:mm'),
            maql: this.state.managementID,
            mabn: this.state.code,
            id: x.id,
            mavp: x.ma,
            ten: x.ten,
            groupId: x.nhomId,
            listChild: x.listChild,
            typeId: x.loaiId,
            typeName: x.loaiTen,
            isCombo: x.isCombo,
            dongiakm: null,
            discount: 0,
        };
    }
    //#endregion

    render() {
        let dataSource = this.state.dataOrder.map(function (e, index) {
            let o = Object.assign({}, e);
            o.key = (o.isChild ? (o.vienPhiIdOfCombo.toString() + '-') : "") + o.id.toString();
            o.children = o.listChild;
            return o;
        })

        var pagination = {

            total: this.state.dataOrder.length,
            current: this.state.currentPage,
            pageSize: this.state.pageSize,
            onChange: (page, size) => { this.setState({ currentPage: page, pageSize: size }) },
            onShowSizeChange: (page, size) => {
                this.setState({ currentPage: 1, pageSize: size });
            },
            showSizeChanger: true
        }

        return (
            <React.Fragment>
                <HeaderModal title={`Tạo lịch hẹn khám bệnh`}
                    onClose={() => { this.props.onCancel(); this.reset() }} />
                <Form className='mt-2' ref={(c) => this.form = c} style={{ height: 'calc(100% - 140px)' }}>
                    <div style={{ height: '100%', display: 'flex' }}>
                        <div className="warrperStyleGroup" style={{ height: '100%', marginRight: 20, flex: 3 }} >
                            <div className="grouplabel">
                                <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Thông tin bệnh nhân</h4>
                            </div>
                            <div style={{ display: 'flex', justifyContent: "space-between", marginBottom: 5 }}>
                                <div style={{ flex: 2, textAlign: 'left', marginRight: '40px' }}><strong>Mã bệnh nhân: {this.state.mabn}</strong></div>
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    <label style={{ marginRight: 5 }}>Ngày hẹn:</label>
                                    <Form.Item name="ngayhen" rules={[{ required: true, message: 'Vui lòng chọn ngày hẹn!' }]} >
                                        <DatePicker
                                            locale={viVN}
                                            showTime
                                            format={FORMAT_DATE_TIME}
                                            className="w-100"
                                            disabledDate={this.disabledDate.bind(this)}
                                            onChange={(e) => this.setState({ ngayhen: e })}
                                            value={moment(this.state.ngayhen)} placeholder={FORMAT_DATE_TIME} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ height: 'calc(100% - 60px)', overflowY: 'auto', padding: 0, overflowX: 'hidden' }}>
                                <Administrative ref={((c) => this.administrative = c)}
                                    onSeached={(patientID) => {
                                        this.form.setFieldsValue({ mabn: patientID })
                                        this.setState({ mabn: patientID })
                                    }}
                                    isUpdate={this.state.isUpdate}
                                    onReplaceCoporation={() => { }}
                                />
                                <div className='row'>
                                    <NguonKhachHang ref={(c) => this.nguonKhachHang = c}
                                        updateInfoBangGia={(info) => {
                                            this.setBangGia(info.onClear ? null : info.banggia, this.state.banggiaSite);
                                            this.setState({ banggiaNguonKhacHang: info.onClear ? null : info.banggia })
                                        }}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <Form.Item>
                                        <label>Chi nhánh đặt lịch <Tooltip title="Cho lần đăng ký này"><i className='far fa-question-circle' /></Tooltip></label>
                                        <Select
                                            placeholder="Chọn chi nhánh"
                                            style={{ width: '100%' }}
                                            value={this.state.site}
                                            onChange={(value, item) => {
                                                this.setState({ site: value, banggiaSite: item.item.banggia });
                                                this.setBangGia(this.state.banggiaNguonKhacHang, item.item.banggia);
                                            }}
                                        >
                                            {this.state.listSite && this.state.listSite.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id} item={item}>{item.ten}</option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className="warrperStyleGroup" style={{ height: '100%', width: '40%' }} >
                            <div className="grouplabel">
                                <h4 style={{ fontWeight: 'bold', marginBottom: 0 }}>Dịch vụ</h4>
                            </div>
                            <div style={{ display: 'flex', width: "100%" }}>
                                <div style={{ width: "60%" }}>
                                    <Tooltip placement="top" title={`Bảng giá: ${this.state.banggia === 'default' ? "Mặc định" : this.state.banggia}`}>
                                        <Form.Item label="Chọn dịch vụ">
                                            <SelectProductAutocomplete
                                                ref={(c) => (this.selectProductAutocompleteRef = c)}
                                                showBangGia={true}
                                                bangGia={this.state.banggia}
                                                selectedListServices={[
                                                    ...(this.state.dataOrder ?? []),
                                                ]}
                                                width='100%'
                                                dropdownMatchSelectWidth={700}
                                                onSelect={(items) => {
                                                    this.onSelectIndications(items);
                                                }}
                                                value={this.state.serKey}
                                                keyValue={'id'}
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </div>
                                <div style={{}}>
                                    <Form.Item>
                                        <Tooltip title="Mở danh sách dịch vụ">
                                            <div style={{}}>
                                                <Button type='' className="btn btn-secondary ml-auto" onClick={e => {
                                                    e.stopPropagation();
                                                    this.setState({ visibleOrder: true })
                                                    setTimeout(() => {
                                                        this.OrderRef.UpdateSelectedFromProps(this.state.dataOrder)
                                                    }, 200)
                                                }}><i className={'fa fa-plus-circle'}></i></Button>
                                            </div>
                                        </Tooltip>
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ height: 'calc(100% - 65px)' }}>
                                <Table dataSource={dataSource}
                                    scroll={{ y: 0 }}
                                    pagination={pagination}
                                    columns={this.getColumn()} rowKey={(e) => e.key} />
                            </div>
                        </div>
                    </div>
                </Form>
                <div className='custom-hr'></div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Form.Item style={{ marginRight: 10 }}>
                        <button className="btn btn-color" onClick={() => this.SaveLichHen()}> <i style={{ marginRight: 5 }} className="fa fa-save" />Lưu</button>
                    </Form.Item>
                    <Form.Item>
                        <button className="btn btn-danger" onClick={() => {
                            this.props.onCancel();
                            this.reset();
                        }}>
                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }} />
                            Đóng</button>
                    </Form.Item>
                </div>
                <Modal
                    width={1400}
                    visible={this.state.visibleOrder}
                    onCancel={() => this.setState({ visibleOrder: false })}
                >
                    <ModalCLS
                        showGoiKham={false}
                        maskClosable={false}
                        ref={(c) => this.OrderRef = c}
                        chinhanh={this.props.chinhanh}
                        onClose={(listSelectedServices, state) => {
                            if (listSelectedServices === null) listSelectedServices = [];
                            if (state == 'save')
                                this.setState({ dataOrder: listSelectedServices });
                            this.setState({
                                visibleOrder: false,
                            });
                        }}
                        onReplaceBangGia={(tenBangGia) => {
                            if (tenBangGia !== "" && tenBangGia !== null && tenBangGia !== undefined) {
                                this.setState({
                                    banggia: tenBangGia,
                                });
                            }
                        }}
                        banggia={this.state.banggia}
                        editAfterRegister={true}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}