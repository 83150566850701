import React, { Fragment, useRef, useState, useEffect, useCallback, forwardRef, useImperativeHandle } from "react";
import { Tabs } from "antd";
import * as PatientService from '../../../../services/patientService';
import moment from "moment";
import * as Actions from '../../../../libs/actions';
import Speaker from '../../../../components/speaker';
import Notify, { NotifyStatus } from "../../../../components/notify";
import UnpayService from "./UnpayService";
import Paid from "../../trahoadon/paid";
import Refund from "../../trahoadon/refund";
import { isNullOrEmpty } from "../../../../libs/util";

export default forwardRef((props, ref) => {
    const ProcessStatusEnum = {
        'UNPAY' : '1',
        'PAID' : '2',
        'REFUND' : '3',
        'ALL' : '0'
    };
    const _notiRef = useRef();
    const firstRender = useRef(true);
    const _unpayService = useRef();
    const _paidService = useRef();
    const _refundService = useRef();
    // Data
    const [billType, setBillType] = useState(props.billType);
    const [managementId, setManagementId] = useState('');
    const [patientId, setPatientId] = useState(props.patientId);
    const [patient, setPatient] = useState({});
    // Modal
    const [tabActiveKey, setTabActiveKey] = useState(ProcessStatusEnum.UNPAY);
    //
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useImperativeHandle(ref, () => ({
        reloadUnpayService: (mid, pid) => {
            setManagementId(mid);
            GetPatientInfo(pid);
            onSwitchBillType(props.processStatus);
            _notiRef.current && _notiRef.current.Close();
            _unpayService.current && _unpayService.current.reload(mid, pid);
            _paidService.current && _paidService.current.reloadInfo(pid, mid);
            _refundService.current && _refundService.current.reloadInfo(pid, mid);
        },
        onChangeSite: () => { onChangeSite(); }
    }));

    useEffect(() => {
        setBillType(props.billType);
    }, [props.billType]);

    useEffect(() => {
        onSwitchBillType(props.processStatus);
    }, [props.processStatus]);

    const GetPatientInfo = (pid = patientId) => {
        Actions.setLoading(true);
        PatientService.GetPatientDetail(pid).then(result => {
            if (result.isSuccess) {
                setPatientId(pid);
                setPatient(result.data);
            } else {
                showErrorMessage(result)
            }
        }).catch(err => {
            showErrorMessage(err)
        }).finally(() => {
            forceUpdate();
            Actions.setLoading(false);
        })
    }

    const onSwitchBillType = (billType) => {
        switch(billType) {
            case 1:
                setTabActiveKey(ProcessStatusEnum.UNPAY);
                break;
            case 0: // Tất cả hóa đơn
            case 2:
            case 3:
                setTabActiveKey(ProcessStatusEnum.PAID);
                break;
            default:
                setTabActiveKey(ProcessStatusEnum.UNPAY);
                break;
        }
    }

    const showErrorMessage = (err) => {
        if (err && err.error && err.error.messageText)
            _notiRef.current && _notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
        else _notiRef.current && _notiRef.current.Show(err, NotifyStatus.Warning);
    }

    const onCloseErrorMessage = () => {
        _notiRef.current && _notiRef.current.Close();
    }

    const onSuccessPayment = () => {
        props.onSuccessPayment();
        onCloseErrorMessage();
    }

    const onChangeTab = (curTab) => {
        setTabActiveKey(curTab);
        if (curTab == ProcessStatusEnum.PAID)
            _paidService && _paidService.current && _paidService.current.reloadInfo(patientId, managementId);
        else if (curTab == ProcessStatusEnum.REFUND)
            _refundService && _refundService.current && _refundService.current.reloadInfo();
    }

    const onChangeSite = () => {
        setPatient({});
        _unpayService && _unpayService.current && _unpayService.current.resetState();
        _paidService && _paidService.current && _paidService.current.reloadInfo();
        _refundService && _refundService.current && _refundService.current.reloadInfo();
    }

    return (
        <Fragment>
            <div className="list-page">{/* body */}
                <Notify ref={_notiRef} />
                {patient && <div className="list-page-search">{/* Patient Information */}
                    <div className="row">
                        <div className="col-6">
                            <span>
                                <label className="mita-title">Tên bệnh nhân: </label>
                                <label className="mita-value">{patient.hoTen}</label>
                            </span>
                        </div>
                        <div className="col-3">
                            <span>
                                <label className="mita-title">Giới tính: </label>
                                <label className="mita-value">{!isNullOrEmpty(patient.phai) && (patient.phai === 0 ? 'Nam' : 'Nữ')}</label>
                            </span>
                        </div>
                        <div className="col-3">
                            <span>
                                <label className="mita-title">{patient.onlyYear ? 'Năm sinh: ' : 'Ngày sinh: '}</label>
                                <label className="mita-value">{!isNullOrEmpty(patient.onlyYear) && (patient.onlyYear ? patient.namSinh : moment(patient.ngaySinh).format('DD-MM-YYYY'))}</label>
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <span>
                                <label className="mita-title">Điện thoại: </label> 
                                <label className="mita-value">{patient.dienThoai}</label>
                            </span>
                        </div>
                        <div className="col-8">
                            <label>
                                <span className="mita-title">Địa chỉ: </span> 
                                <span className="mita-value">{patient.soNha}</span>
                            </label>
                        </div>
                    </div>
                </div>}
                <div className="list-page-body"> {/* Payment Information */}
                    <Tabs defaultActiveKey={ProcessStatusEnum.UNPAY} activeKey={tabActiveKey} onChange={(curTab) => { onChangeTab(curTab); }}
                        tabBarExtraContent={patientId !== '' && <Speaker sentence={ `Mời khách hàng ${patient.hoTen} vào quầy thanh toán`} notShowButton={true} />}>
                        <Tabs.TabPane tab="CHỜ THANH TOÁN" key={ProcessStatusEnum.UNPAY}>
                            <UnpayService
                                ref={_unpayService}
                                managementId={managementId} 
                                billType={billType}
                                patient={patient}
                                onSuccessPayment={() => {onSuccessPayment()}}
                                onError={(err) => {showErrorMessage(err)}}
                                onCloseError = {() => onCloseErrorMessage()}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="ĐÃ THANH TOÁN" key={ProcessStatusEnum.PAID}>
                            <div style={{ height: "100%", overflowY: "auto" }}>
                                <Paid
                                    ref={_paidService}
                                    patientCode={patientId}
                                    patientName={patient.hoTen}
                                    managementId={managementId} 
                                    billType={billType} />
                            </div>
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane tab="HOÀN TRẢ" key={ProcessStatusEnum.REFUND}>
                            <div style={{ height: "100%", overflowY: "auto" }}>
                                <Refund
                                    ref={_refundService}
                                    patientCode={patientId}
                                    managementId={managementId} />
                            </div>
                        </Tabs.TabPane> */}
                    </Tabs>
                </div>
            </div>
        </Fragment>
    )
    
});
