import moment from "moment";
import React, { Fragment, useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { Input, Upload, Switch, Select, notification, Modal, TreeSelect, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import SimpleReactValidator from 'simple-react-validator';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import * as Actions from '../../../libs/actions';
import * as SystemConfig from '../../../configure/systemConfig';
import * as MenuDefaultConfig from '../../../configure/menuDefaultConfig';
import { ValidationCustom } from '../../../commons/validationCustom';
import { detailScreenModeEnum } from "../../../commons/detailScreenModeEnum";
import Notify, { NotifyStatus } from '../../../components/notify';
import * as MenuService from '../../../services/MenuService';
import * as FeatureService from '../../../services/FeatureService';
import MenuCreate from './create';
import * as PermissionService from '../../../services/StoragePermissionService';

const { Option } = Select;
export default forwardRef((props, ref) => {
  const notiRef = useRef(null);
  const hiddenLink = useRef();
  const firstRender = useRef(true); 
  const validator = useRef(new SimpleReactValidator(ValidationCustom));
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  //#region khai báo state
  const [detailScreenMode, setDetailScreenMode] = useState(detailScreenModeEnum.detail);
  const [headerText, setHeaderText] = useState(''); 
  //model detail/create/update
  const [menuId, setMenuId] = useState();
  const [code, setCode] = useState();
  const [content, setContent] = useState();
  const [featureId, setFeatureId] = useState();
  const [icon, setIcon] = useState();
  const [isActive, setIsActive] = useState();
  const [isDefaultLoad, setIsDefaultLoad] = useState();
  const [menuKey, setMenuKey] = useState();
  const [label, setLabel] = useState();
  const [parentId, setParentId] = useState();
  const [urlImport, setUrlImport] = useState();
  const [sort, setSort] = useState();
  const [nodes, setNodes] = useState();
  //other
  const [listFeature, setListFeature] = useState();
  const [featureName, setFeatureName] = useState();
  const [parentLabel, setParentLabel] = useState();
  const [visibleCreate, setVisibleCreate] = useState();
  const [createNextPrev, setCreateNextPrev] = useState();
  const [groupPermission, setGroupPermission] = useState();
  const [listGroupPermission, setListGroupPermission] = useState([]);
  //#endregion 
  //#region khai báo biến cục bộ
  //#endregion 
  
  //#region useEffect
  useEffect(() => {
    if (firstRender.current) return;
    if (isDefaultLoad) MenuDefaultConfig.Add(menuId);
    else MenuDefaultConfig.Remove(menuId);
  }, [isDefaultLoad]);

  useEffect(() => {
    forceUpdate();
  }, [visibleCreate]);

  useEffect(() => {
    getById(props.menuId);
  }, [props.menuId]);

  useEffect(() => {
    if (detailScreenMode != detailScreenModeEnum.detail) getListFeature();
  }, [detailScreenMode]);

  useEffect(() => {
    PermissionService.getListGroupPermission().then(result => {
      if (result.isSuccess) {
        setListGroupPermission(result.data);
      }
    })
    firstRender.current = false;
  }, []);
  //#endregion 
  const setData = (data) => {
    setMenuId(data.id);
    setCode(data.code);
    setContent(data.content);
    setFeatureId(data.featureId);
    setIcon(data.icon);
    setIsActive(data.isActive);
    setMenuKey(data.menuKey);
    setLabel(data.label);
    setParentId(data.parentId);
    setUrlImport(data.urlImport);
    setFeatureName(data.featureName);
    setParentLabel(data.parentLabel);
    setSort(data.sort);
    setNodes(data.nodes);
    setGroupPermission(data.permissionGroup);
    
    let menuDefaultLoad = MenuDefaultConfig.List();
    setIsDefaultLoad(menuDefaultLoad.indexOf(data.id) > -1);
    
    forceUpdate();
  }

  const getById = (id) => {
    if (!id || detailScreenMode == detailScreenModeEnum.create) return;
    Actions.setLoading(true);
    MenuService.getById(id).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        notiRef && notiRef.current.Close();
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning);
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText, NotifyStatus.Warning);
      else notiRef.current.Show(err, NotifyStatus.Warning);
    }).finally(() => {
        Actions.setLoading(false);
    })
  }
  
  const getListFeature = () => {
    let filterModel = {
      maxResultCount: 0,
    }
    FeatureService.getList(filterModel).then(result => {
      if (result.isSuccess) {
        setListFeature(result.data.items);
        // notiRef && notiRef.current.Close();
      }
      else {
        // notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
      }
    }).catch(err => {
      // if (err.error && err.error.messageText)
      //   notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
      // else notiRef.current.Show(err, NotifyStatus.Error);
    }).finally(() => {
        // forceUpdate();
    })
  }

  const saveButtonHandle = (isNextStep = true) => {
    if (validator.current.allValid() && validate()) {
      setModel();
      if (detailScreenMode == detailScreenModeEnum.update && menuId) {
        saveUpdate();
      }
      else if (detailScreenMode == detailScreenModeEnum.create) {
        // saveCreate();
      }
    } else {
      validator.current.showMessages();
      notiRef.current.Show("Vui lòng kiểm tra lại dữ liệu.", NotifyStatus.Warning)
      forceUpdate();
    }
  }

  const validate = () => {
    let isValid = true;
    return isValid;
  }
  
  const model = {
    id : menuId,
    code : code,
    content : content,
    featureId : featureId,
    icon : icon,
    isActive : isActive,
    menuKey : menuKey,
    label : label,
    parentId : parentId,
    urlImport : urlImport,
    sort : sort
  }

  const setModel = () => {
    model.id = menuId;
    model.code = code;
    model.content = content;
    model.featureId = featureId;
    model.icon = icon;
    model.isActive = isActive;
    // model.isDefaultLoad = isDefaultLoad;
    model.menuKey = menuKey;
    model.label = label;
    model.parentId = parentId;
    model.urlImport = urlImport;
    model.PermissionGroup = groupPermission;
  }
  
  const saveUpdate = () => {
    Actions.setLoading(true);
    MenuService.update(model).then(result => {
      if (result.isSuccess) {
        setData(result.data);
        setDetailScreenMode(detailScreenModeEnum.detail);
        props.reloadMenu();
        notification.success({ message: "Thành công" });
      }
      else {
        notiRef.current.Show(result.error.messageText, NotifyStatus.Warning)
      }
    }).catch(err => {
      if (err.error && err.error.messageText)
        notiRef.current.Show(err.error.messageText , NotifyStatus.Warning)
      else notiRef.current.Show(err, NotifyStatus.Warning)
    }).finally(() => {
      Actions.setLoading(false);
    })
  }

  if (!menuId) return(
    <div className='list-page text-center justify-content-start' >
      <h2>Xin chào!</h2>
      <h3>Chọn 1 menu để xem</h3>
    </div>
  );

  return (
    <React.Fragment>
      <a href="#download" style={{ display: 'none' }} ref={hiddenLink} >download</a>
      <div className='list-page' hidden={visibleCreate} >
          {/* <div className="list-page-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>{ headerText }</h3>
              <h3 hidden={!props.isDialog}
                onClick={() => {
                  props.onCancel()
                }}
              ><i className='far fa-times-circle' /></h3>
          </div> */}
          <div className="list-page-body">{/* body */}
            <div className="row">{/* notification */}
              <div className="col-md-12"><Notify ref={notiRef} /></div>
            </div>
            <div className="h-100 w-100 pb-1 overflow-auto">
              <div className="row"> {/* Mã | Hoạt động | icon */}
                <div className="col-md-4 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Mã</label>
                  </div>
                  <Input value={code}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setCode(e.target.value)}
                  ></Input>
                  {validator.current.message("Mã", code, "required")}
                </div>
                <div className="col-md-4 d-flex flex-column">
                  <label>icon</label>
                  <div className="d-flex justify-content-between align-items-center">
                    <Input value={icon} 
                      readOnly={detailScreenMode==detailScreenModeEnum.detail}
                      onChange={(e) => setIcon(e.target.value)}
                    ></Input>
                    <i className={icon + " ml-2"}/>
                  </div>
                </div>
                <div className="col-md-2 d-flex flex-column">
                  <label>Hoạt động</label>
                  <div>
                    <Switch 
                      checked={isActive} 
                      onChange={(checked, e) => { if (detailScreenMode == detailScreenModeEnum.detail) return; setIsActive(checked); }} 
                    />
                  </div>
                </div>
                <div className="col-md-2 d-flex flex-column">
                  <label>Mặc định load</label>
                  <div>
                    <Tooltip content='Có thể sửa ngay mà không cần click button "Sửa".\nLưu riêng theo client (local) chứ không theo database/system'>
                      <Switch 
                        checked={isDefaultLoad} 
                        onChange={(checked, e) => { setIsDefaultLoad(checked); }} 
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row mt-2"> {/* Label - MenuKey */}
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Label</label>
                  </div>
                  <Input value={label}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setLabel(e.target.value)}
                  ></Input>
                  {validator.current.message("Label", label, "required")}
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <div>
                    <label className={`${detailScreenMode != detailScreenModeEnum.detail && "required-field"}`}>Key</label>
                  </div>
                  <Input value={menuKey}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setMenuKey(e.target.value)}
                  ></Input>
                  {validator.current.message("Key", menuKey, "required")}
                </div>
              </div>
              <div className="row mt-2"> {/* Content | Url import component*/}
                <div className="col-md-6 d-flex flex-column">
                  <label>Content</label>
                  <Input value={content}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setContent(e.target.value)}
                  ></Input>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label>Url import component</label>
                  <Input value={urlImport}
                    readOnly={detailScreenMode==detailScreenModeEnum.detail}
                    onChange={(e) => setUrlImport(e.target.value)}
                  ></Input>
                </div>
              </div>
              <div className="row mt-2"> {/* Menu cha | Phân hệ - tính năng*/}
                <div className="col-md-6 d-flex flex-column">
                  <label>Menu cha</label>
                  <TreeSelect
                    showSearch
                    allowClear
                    switcherIcon={<DownOutlined />}
                    hidden={detailScreenMode === detailScreenModeEnum.detail}
                    disabled={detailScreenMode === detailScreenModeEnum.detail}
                    value={parentId}
                    onChange={(value, option) => {  
                      setParentId(value); 
                    }}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: 'auto',
                    }}
                    treeData={props.listMenu}
                    fieldNames={{ value: 'id', children: 'nodes', }}
                  />
                  <Input value={parentLabel} hidden={detailScreenMode !== detailScreenModeEnum.detail} readOnly></Input>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <label>Phân hệ - tính năng</label>
                  <Select
                    showSearch
                    allowClear
                    hidden={detailScreenMode === detailScreenModeEnum.detail}
                    disabled={detailScreenMode === detailScreenModeEnum.detail}
                    value={ featureId }
                    onChange={(value, option) => {  
                      setFeatureId(value); 
                    }}
                    // filterOption={(input, option) => { }}
                  >
                  {(listFeature ?? []).map((record, index) => {
                    return (
                      <Option key={record.id} value={record.id}
                        // disabled={(detailScreenMode === detailScreenModeEnum.update) && record.id != cardTypeId}
                      >{record.name}</Option>
                    )
                  })}
                  </Select>
                  <Input value={featureName} hidden={detailScreenMode !== detailScreenModeEnum.detail} readOnly></Input>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6 d-flex flex-column">
                  <label>Group permission</label>
                  <Select
                    showSearch
                    allowClear
                    disabled={detailScreenMode === detailScreenModeEnum.detail}
                    value={groupPermission}
                    onChange={(value, option) => {  
                      setGroupPermission(value); 
                    }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                  {(listGroupPermission ?? []).map((record, index) => {
                    return (
                      <Option key={record.name} value={record.name}>{record.displayName}</Option>
                    )
                  })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="list-page-footer d-flex justify-content-between mt-2">{/* footer (nếu có) */}
            <div>
            </div>
            <div className='d-flex justify-content-end' >
              <button 
                className='btn btn-warning mr-2' 
                hidden={detailScreenMode !== detailScreenModeEnum.detail}
                onClick={() => { setDetailScreenMode(detailScreenModeEnum.update); }}
              >
                <i className='fas fa-pencil-alt mr-2'></i>
                Sửa
              </button>
              <button 
                className='btn btn-color mr-2' 
                hidden={detailScreenMode !== detailScreenModeEnum.detail}
                onClick={() => { setVisibleCreate(true); setCreateNextPrev(undefined); }}
              >
                <i className='fa fa-plus-circle mr-2'></i>
                Tạo menu con
              </button>
              <button 
                className='btn btn-color mr-2' 
                hidden={detailScreenMode !== detailScreenModeEnum.detail}
                onClick={() => { setVisibleCreate(true); setCreateNextPrev('next'); }}
              >
                <i className='fa fa-plus-circle mr-2'></i>
                Tạo liền sau
              </button>
              <button 
                className='btn btn-color mr-2' 
                hidden={detailScreenMode !== detailScreenModeEnum.detail}
                onClick={() => { setVisibleCreate(true); setCreateNextPrev('prev'); }}
              >
                <i className='fa fa-plus-circle mr-2'></i>
                Tạo liền trước
              </button>
              <button 
                className='btn btn-color mr-2' 
                hidden={detailScreenMode === detailScreenModeEnum.detail}
                onClick={() => { saveButtonHandle(); }}
              >
                <i className='fa fa-save mr-2'></i>
                Lưu
              </button>
              <button
                className='btn btn-danger'
                hidden={detailScreenMode === detailScreenModeEnum.detail}
                onClick={() => setDetailScreenMode(detailScreenModeEnum.detail)}
              >
                <i className='fa fa-sign-out-alt mr-2'></i>
                  Hủy
              </button>
            </div>
          </div>
      </div>
      <MenuCreate hidden={!visibleCreate}
        listMenu={props.listMenu}
        nextprev={createNextPrev}
        currentMenuId={menuId}
        currentMenuNodes={nodes}
        parentId={parentId}
        parentLabel={parentLabel}
        sort={sort}
        reloadMenu={props.reloadMenu}
        onCancel={() => {setVisibleCreate(false);}}
      ></MenuCreate>
    </React.Fragment>
  );
})