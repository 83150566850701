import { Checkbox, DatePicker, Input, Pagination, Popconfirm, notification, Popover, Select, Table, Drawer, Modal, Dropdown, Menu } from 'antd';
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react'
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import * as Actions from '../../../libs/actions';
import * as CorporationServices from '../../../services/CorporationServices';
import * as InvoiceService from '../../../services/invoiceService';
import * as TestReceivedServices from '../../../services/testReceivedService';
import * as SystemConfig from "../../../configure/systemConfig";
import * as VPService from '../../../configureHIS/update_vp/services/VPService';
import Notify, { NotifyStatus } from '../../../components/notify'
import PhieuThanhToan from './PhieuThanhToan';
import BillDetail from '../../statistic/promotion/billdetail';
import HeaderModal from '../../../configureHIS/common/headerModal';
import PrintSID from "./InSIDBarcode";
import Preview from '../trahoadon/preview';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const BILLTYPE = {
    ALL : '',
    SERVICE : '1',
    MEDICINE : '2'
}
export default forwardRef((props, ref) => {
    const _hiddenLink = useRef();
    const notiRef = useRef(null);
    const [date, setDate] = useState([moment(), moment()])
    const [coporatorFilter, setCoporatorFilter] = useState(null);
    const [dataCoporator, setDataCoporator] = useState([]);
    const [listPaymentType, setListPaymentType] = useState([]);
    const [paymentType, setPaymentType] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState(1);

    const [key, setKey] = useState('');
    const [sorting, setSorting] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);

    const [isCheckAll, setIsCheckAll] = useState(false)
    const [billSelected, setBillSelected] = useState('');
    const [visible, setVisible] = useState(false);
    const [visiblePrint, setVisiblePrint] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [bill, setBill] = useState('');
    const [paymentDetail, setPaymentDetail] = useState(null);
    const [lstPaymentDetail, setLstPaymentDetail] = useState([]);

    const [visiblePay, setVisiblePay] = useState(false);
    const [lstServicesPatient, setLstServicesPatient] = useState([]);
    const [isPaid, setIsPaid] = useState(false);
    const [selectedBillId, setSelectedBillId] = useState(null);
    const [receiptNo, setReceiptNo] = useState(null);
    const [selectedListSid, setSelectedListSid] = useState([]);

    useEffect(() => {
        LoadCoporation();
        GetPaymentMethod();
    }, [])

    useEffect(() => {
        GetListPaidBill();
    }, [page, size, sorting, paymentStatus])

    const LoadCoporation = (key = "") => {
        CorporationServices.GetList(key, 1, 40).then(res => {
            if (res.isSuccess) {
                setDataCoporator(res.data)
            }
        })
    }

    const GetPaymentMethod = () => {
        VPService.GetDanhSachLoaiThanhToan().then(result => {
            if (result.isSuccess) {
                setListPaymentType(result.data)
            }
        }).catch(err => {
        })
    }

    const GetListPaidBill = () => {
        Actions.setLoading(true);
        InvoiceService.ListPatientPaymentInBill(key, moment(date[0]).format(SystemConfig.FORMAT_YYYYMMDD), moment(date[1]).format(SystemConfig.FORMAT_YYYYMMDD), isPaid, BILLTYPE.SERVICE, paymentStatus, page, size, coporatorFilter, sorting).then(result => {
            if (result.isSuccess) {
                setDataSource(result.data);
                setTotalRecord(result.totalRecords);
            } else {
                if (result.err.msgString)
                    notification.error({ message: result.err.msgString });
                else notification.error({ message: "Đã xảy ra lỗi tải dữ liệu" });
            }
        }).catch(err => {
            notification.error({ message: err });
        }).finally(() => Actions.setLoading(false))
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        if (sorter.column && sorter.order) {
            let sort = sorter.field + (sorter.order == 'descend' ? ' desc' : '');
            setSorting(sort);
        }
        else {
            setSorting('');
        }
    };

    const onSubmit = () => {
        setVisible(false);
        setVisiblePrint(false);
        GetListPaidBill();
        setIndeterminate(false);
        setIsCheckAll(false);
        SelectAll(false);
    }

    const showError = (msgString) => {
        if (notiRef && notiRef.current)
            notiRef.current.Show(msgString, NotifyStatus.Success)
    }


    const SelectAll = (checked) => {
        setIndeterminate(false)
        setIsCheckAll(checked)
        const data = dataSource.map(x => ({ ...x, checked: checked }))
        setDataSource(data)
    }

    const onCancel = () => {
        setVisible(false);
        setVisiblePrint(false);
        SelectAll(false);
    }

    const handleOpenChange = (newOpen) => {
        setVisible(newOpen);
    };

    const handleOpenPrintChange = (newOpen) => {
        setVisiblePrint(newOpen);
    };

    const getListSidByListManagementId = (listManagementId = []) => {
        TestReceivedServices.getListSidByListManagementId(listManagementId).then(result => {
            if (result.isSuccess) {
                setSelectedListSid(result.data);
                // forceUpdate();
            }
            else {
                notiRef.current.Show(result.error.messageText, NotifyStatus.Error);
            }
        }).catch(err => {
            if (err.error && err.error.messageText)
            notiRef.current.Show(err.error.messageText, NotifyStatus.Error);
            else notiRef.current.Show(err, NotifyStatus.Error);
        }).finally(() => {
            // Actions.setLoading(false);
            // forceUpdate();
        })
    };

    // 20230803 : Ẩn do sử dụng màn hình mới
    // const LoadPaymentDetail = (data) => {
    //     InvoiceService.PatientPaymentInvoiceDetail(data.sohoadon, moment(data.ngaycd).format(SystemConfig.FORMAT_YYYYMMDD), false).then(result => {
    //         if (result.isSuccess) {
    //             setLstPaymentDetail(result.data)
    //             setVisiblePay(true)
    //         }
    //         else {
    //             Actions.openMessageDialog("Lỗi", result.err.msgString);
    //         }
    //         Actions.setLoading(false);
    //     }).catch(err => {
    //         Actions.setLoading(false);
    //         Actions.openMessageDialog("Lỗi", err);
    //     })
    // }

    const columnData = [
        {
            title: '#',
            width: 60,
            align: 'Center',
            render: (text, record, index) => (page - 1) * size + Number(index) + 1,
        },
        {
            title: <div>
                <Checkbox indeterminate={indeterminate} checked={isCheckAll} onChange={e => {
                    SelectAll(e.target.checked)
                }} />
            </div>,
            width: 60,
            align: 'Center',
            render: (text, record, index) => <div>
                <Checkbox
                    checked={record.checked == true}
                    onChange={e => {
                        let data = dataSource;
                        data[index].checked = e.target.checked
                        let count = data.filter(x => x.checked == true).length
                        if (count > 0 && count < data.length) {
                            setIndeterminate(true)
                        }
                        else {
                            setIndeterminate(false)
                        }
                        if (count == 0) {
                            setIsCheckAll(false)
                        }
                        if (count == data.length) {
                            setIsCheckAll(true)
                        }
                        setDataSource(data.map(x => ({ ...x })))
                    }}
                />
            </div>
        },
        ...(isPaid ? [{
            title: 'Mã phiếu thanh toán',
            dataIndex: 'sohoadon',
            align: 'center',
            key: 'sohoadon',
            sorter: () => { },
            width: 100,
            render: (sophieu, record) => {
                return <a className="anchor-color" href='#'
                    onClick={() => {
                        setPaymentDetail(record)
                        setSelectedBillId(record.sohoadon)
                        setVisiblePay(true)
                    }}>{sophieu}</a>
            }
        }] : [{
            title: 'Mã quản lý',
            dataIndex: 'maql',
            align: 'center',
            key: 'maql',
            width: 100,
        }]),
        {
            title: 'Thao tác',
            align: 'center',
            width: 100,
            render: (row, record) => {
                return (
                    <>
                        <Dropdown overlay={rowOptions(record)} trigger={['click']}>
                            <a className="ant-dropdown-link"><i className='fas fa-ellipsis-h'></i></a>
                        </Dropdown>
                        {!isPaid ? <Popover
                            placement="right"
                            content={
                                <div style={{ width: 400 }}>
                                    <PhieuThanhToan billSelected={bill} dateRange={date} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()} />
                                </div>
                            }
                            trigger="click"
                            visible={visible && bill == record.maql}
                            onVisibleChange={handleOpenChange}
                        /> :
                        <Popover
                            placement="right"
                            content={
                                <div style={{ width: 400 }}>
                                    <PrintSID 
                                    // isPayment={true}
                                    billSelected={selectedListSid} 
                                    onSubmit={() => onSubmit()} 
                                    showError={(msgString) => showError(msgString)} 
                                    onCancel={() => onCancel()} />
                                </div>
                            }
                            trigger="click"
                            visible={visiblePrint && bill == record.maql && receiptNo == record.sohoadon}
                            onVisibleChange={handleOpenPrintChange}
                        />}
                    </>
                )
            }
        },
        {
            title: 'Nguồn khách hàng',
            dataIndex: 'nguonKhachHang',
            key: 'nguonKhachHang',
            width: 120,
        },
        {
            title: 'Mã khách hàng',
            dataIndex: 'mabn',
            key: 'mabn',
            sorter: () => { },
            width: 100,
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'hoten',
            key: 'hoten',
            sorter: () => { },
            width: 150,
            orderby: "bn.hoten",
        },
        {
            title: 'Ngày đăng kí',
            dataIndex: 'ngaycd',
            key: 'ngaycd',
            sorter: () => { },
            width: 120,
            render: (value) => {
                return <span>{value ? moment(value).format(SystemConfig.FORMAT_DATE_TIME) : ''}</span>;
            },
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'sotienkm',
            key: 'sotienkm',
            align: 'right',
            width: 120,
            render: (value) => {
                return <span>{isNaN(value) ? '' : Number(value).toLocaleString('en-US')}</span>;
            },
        },
    ]

    const rowOptions = (record) => {
        return (
            <Menu
                items={!isPaid ? 
                [
                    {
                        label:
                            <a href="#" onClick={() => {
                                setVisible(true);
                                setBill(record.maql);
                                setReceiptNo(record.sohoadon);
                            }}><i className='far fa-money-bill-alt mr-1' />Thanh toán</a>,
                        key: '0',
                    },
                ] : [
                    {
                        label:
                            <a href="#" onClick={() => {
                                setVisiblePrint(true);
                                setBill(record.maql);
                                setReceiptNo(record.sohoadon);
                                getListSidByListManagementId([record.maql]);
                                notiRef.current.Close();
                            }}><i className='fas fa-barcode mr-1' />In barcode</a>,
                        key: '1',
                    },
                ]}
            />
        )
    }

    return (
        <React.Fragment>
            <div className='containerChilderWrapper'>
                <a href="#download" style={{ display: 'none' }} ref={_hiddenLink} ></a>
                <HeaderModal title={'Thanh toán hàng loạt'} />
                <Notify ref={notiRef} />
                <div className='row'>
                    <div className='col-md-4 py-1' >
                        <label>Chọn ngày</label>
                        <RangePicker allowClear={false} format={SystemConfig.FORMAT_DATE} value={date} locale={viVN}
                            onChange={(value) => {
                                if (value == null)
                                    setDate([null, null])
                                else
                                    setDate(value)
                            }}
                        />
                    </div>
                    <div className='col-md-4 py-1' >
                        <label>Nguồn khách hàng</label>
                        <Select value={coporatorFilter} placeholder="Chọn nguồn khách hàng"
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                setCoporatorFilter(e)
                            }}
                            showSearch
                            allowClear
                            onSearch={(value) => LoadCoporation(value)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {dataCoporator.map((copr, index) => <Option key={index} value={copr.ma}>{copr.ten}</Option>)}
                        </Select>
                    </div>
                    <div className='col-md-4 py-1' >
                        <label>Trạng thái thanh toán</label>
                        <Select
                            value={paymentStatus}
                            placeholder="Chọn trạng thanh toán"
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                setPaymentStatus(e);
                                setIndeterminate(false);
                                setIsCheckAll(false);
                                e === 1 ? setIsPaid(false) : setIsPaid(true);
                            }}>
                            <Option key={0} value={1}>Chưa thanh toán</Option>
                            <Option key={1} value={2}>Đã thanh toán</Option>
                        </Select>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 py-1' >
                        <label>Từ khóa</label>
                        <Input
                            placeholder="Nhập tên khách hàng"
                            value={key}
                            onChange={(e) => { setKey(e.target.value) }}
                            onPressEnter={() => {
                                setPage(1);
                                GetListPaidBill();
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 py-1'>
                        <div className='text-right'>
                            <button className='btn btn-primary mr-1'
                                onClick={() => {
                                    setPage(1);
                                    GetListPaidBill();
                                    setIsCheckAll(false);
                                }}
                            ><i className='fa fa-search mr-1'></i>Tìm kiếm</button>
                            <button className='btn btn-secondary'
                                onClick={() => {
                                    setDate([moment(), moment()]);
                                    setKey('');
                                    setPaymentType(0);
                                    setCoporatorFilter(null);
                                }}
                            ><i className='fa fa-eraser mr-1'></i>Xóa dữ liệu nhập</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 py-1'>
                        {!isPaid && <Popover
                            placement="right"
                            title={""}
                            content={
                                <div style={{ width: 400 }}>
                                    <PhieuThanhToan billSelected={billSelected} dateRange={date} onSubmit={() => onSubmit()} showError={(msgString) => showError(msgString)} onCancel={() => onCancel()} />
                                </div>
                            }
                            open={visible && bill == ''}
                            onOpenChange={handleOpenChange}
                            trigger="click">
                            <button className='btn btn-secondary mr-1'
                                onClick={() => {
                                    setBillSelected(dataSource.filter(x => x.checked == true).map(x => x.maql).toString())
                                    setBill('');
                                    setReceiptNo(null);
                                }}
                            ><i className='far fa-money-bill-alt mr-1 mr-1'></i>Thanh toán theo lô</button>
                        </Popover>}
                        {isPaid && <Popover
                            placement="right"
                            content={
                                <div style={{ width: 400 }}>
                                    <PrintSID 
                                    // isPayment={true}
                                    billSelected={selectedListSid} 
                                    onSubmit={() => onSubmit()} 
                                    showError={(msgString) => showError(msgString)} 
                                    onCancel={() => onCancel()} />
                                </div>
                            }
                            open={visiblePrint && bill == ''}
                            onOpenChange={handleOpenPrintChange}
                            trigger="click"
                        >
                            <button className='btn btn-secondary'
                                onClick={() => {
                                    let listManagementId = dataSource.filter(x => x.checked == true).map(x => x.maql);
                                    notiRef.current.Close();
                                    setBillSelected(listManagementId.toString());
                                    setBill('');
                                    setReceiptNo(null);
                                    getListSidByListManagementId(listManagementId);
                                }}
                            ><i className='fas fa-barcode mr-1'></i>In barcode hàng loạt</button>
                        </Popover>}
                    </div>
                </div>
                <div style={{ height: 'calc(100% - 325px)' }}>
                    <Table
                        scroll={{ x: 'max-content', y: 0 }}
                        columns={columnData}
                        dataSource={dataSource}
                        pagination={false}
                        rowKey={(row, index) => index}
                        onChange={onTableChange}
                        locale={{
                            triggerDesc: "Bấm để sắp xếp giảm dần",
                            triggerAsc: "Bấm để sắp xếp tăng dần",
                            cancelSort: "Bấm để hủy bỏ sắp xếp",
                        }}
                    />
                </div>
                <div className='row'>
                    <div className='col-12 py-1'>
                        <Pagination
                            style={{ textAlignLast: 'center', marginTop: 10, marginBottom: 10 }}
                            total={totalRecord}
                            showTotal={total => `Tổng: ${total}`}
                            size={size}
                            current={page}
                            onChange={(page, size) => {
                                setPage(page);
                                setSize(size);
                            }}
                            onShowSizeChange={(page, size) => {
                                setPage(page);
                                setSize(size);
                            }}
                            showSizeChanger={true}
                        />
                    </div>
                </div>

                {/* <Modal
                    width={1000}
                    visible={visiblePay}
                    maskClosable={true}
                    onClose={() => setVisiblePay(false)}>
                    <BillDetail lstPaymentDetail={lstPaymentDetail} billId={selectedBillId} onClose={() => setVisiblePay(false)} />
                </Modal> */}

                { visiblePay &&
                    <Drawer
                        className='custom-ant-drawer'
                        closable={false}
                        width={1400}
                        placement="right"
                        onClose={() => setVisiblePay(false)}
                        open={visiblePay}
                        destroyOnClose={true}
                        maskClosable={true}
                    >
                        <Preview
                            patientCode={paymentDetail.mabn}
                            patientName={paymentDetail.hoten}
                            managermentId={paymentDetail.maql}
                            receiptNumber={selectedBillId}
                            onClose={() => setVisiblePay(false)}
                            showBtnPrint={false}
                            showBtnRefund={false}
                            enableEditPayment={false}
                            // onReload = {() => resetToFirstPage()}
                        />
                    </Drawer>
                }
            </div>
        </React.Fragment>
    )
})
